import { useFormik } from "formik";
import {
  InputFluid,
  InputSelect,
  Margin,
  Modal,
  Radio,
} from "prosperita-dumbo-react";
import { useState } from "react";
import styled from "styled-components";
import Label from "~/components/Label";
import useDebitor from "~/hooks/useDebitor";

import { cnaeOptions, entityOptions } from "~/utils/options";

const FormGrid = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  & > div:nth-child(1) {
    grid-column: span 12;
  }

  & > div:nth-child(n + 2):nth-child(-n + 7) {
    grid-column: span 6;
  }

  & > div:last-child {
    grid-column: span 12;
  }
`;

const FormGridAddress = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  & > div:nth-child(1) {
    grid-column: span 12;
  }

  & > div:nth-child(2) {
    grid-column: span 9;
  }

  & > div:nth-child(3) {
    grid-column: span 3;
  }

  & > div:nth-child(4),
  & > div:nth-child(5),
  & > div:nth-child(6),
  & > div:nth-child(7) {
    grid-column: span 6;
  }

  & > div:nth-child(8) {
    grid-column: span 12;
  }
`;

type IInitialValues = {
  profile: string;
  name: string;
  document_number: string;
  email: string;
  phone: string;
  birth_date: string;
  nationality: string;
  marital_status: string;
  occupation: string;
  foundation_date: string;
  cnae: string;
  fantasy_name: string;
  legal_nature: string;
  zip_code: string;
  street: string;
  number: string;
  district: string;
  city: string;
  state: string;
  country: string;
  type: string;
};

const initialValues: IInitialValues = {
  profile: "",
  name: "",
  document_number: "",
  email: "",
  phone: "",
  birth_date: "",
  nationality: "",
  marital_status: "",
  occupation: "",
  foundation_date: "",
  cnae: "",
  fantasy_name: "",
  legal_nature: "",
  zip_code: "",
  street: "",
  number: "",
  district: "",
  city: "",
  state: "",
  country: "",
  type: "",
};

const NewDebitor = () => {
  const [step, setStep] = useState("type");
  const { openNewDebitorModal, setOpenNewDebitorModal } = useDebitor();

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues,

    onSubmit: () => undefined,
  });

  const settersAdvanceDict: { [key: string]: () => void } = {
    type: () => setStep("data"),
    data: () => setStep("address"),
  };
  const settersReturnDict: { [key: string]: () => void } = {
    data: () => setStep("type"),
    address: () => setStep("data"),
  };

  // const handleSelect = (fieldName, selectedOption) => {
  //   if (selectedOption) {
  //     setFieldValue(fieldName, selectedOption.value);
  //   }
  // };

  const renderDict: { [key: string]: React.ReactNode } = {
    type: (
      <div style={{ padding: "30px" }}>
        <Margin bottom={50} />
        <Label text="Tipo de tomador" />
        <Radio
          label="Pessoa física"
          value="customer"
          checked={values.profile === "customer"}
          onChange={() => setFieldValue("profile", "customer")}
        />
        <Radio
          label="Pessoa jurídica"
          value="provider"
          checked={values.profile === "provider"}
          onChange={() => setFieldValue("profile", "provider")}
        />
      </div>
    ),
    data: (
      <FormGrid>
        <div>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            {" "}
            <InputFluid
              name="name"
              value={values.name}
              onChange={handleChange}
              label="Razão social"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>

          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              top: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>

        <div style={{ position: "relative", top: "-4px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              label="CNPJ"
              noRadius
              placeholder="Digite"
              borderNone
              max={14}
              maxLength={14}
              name="document_number"
              value={values.document_number}
              onChange={(e) => {
                let inputValue = e.target.value;

                inputValue = inputValue.replace(/\D/g, "");

                setFieldValue("document_number", inputValue);
              }}
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              top: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>

        <div style={{ position: "relative", top: "-4px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              name="fantasy_name"
              value={values.fantasy_name}
              onChange={handleChange}
              label="Nome fantasia"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              top: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>

        <div style={{ position: "relative", top: "-8px", zIndex: "2" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              name="foundation_date"
              value={values.foundation_date}
              onChange={handleChange}
              type="date"
              label="Data de abertura (dd/mm/aaaa)"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              top: "-9px",
              zIndex: "0",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-8px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputSelect
              name="legal_nature"
              onChange={(e) => setFieldValue("legal_nature", e)}
              kind="fluid"
              size="large"
              label="Natureza jurídica"
              options={entityOptions}
              placeholder="Selecione uma opção"
              noRadius
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              top: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-25px", zIndex: "1" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputSelect
              name="cnae"
              onChange={(e) => setFieldValue("cnae", e)}
              kind="fluid"
              size="large"
              label="CNAE"
              options={cnaeOptions}
              placeholder="Selecione uma opção"
              noRadius
            />
          </div>
        </div>
        <div style={{ position: "relative", top: "-16px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              name="phone"
              value={values.phone}
              onChange={handleChange}
              label="Telefone celular"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              width: "110%",
              top: "-6px",
              left: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-30px", zIndex: 0 }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              name="email"
              value={values.email}
              onChange={handleChange}
              label="Email"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              top: "-7px",
              zIndex: "0",
            }}
          ></div>
        </div>
      </FormGrid>
    ),
    address: (
      <FormGridAddress>
        {" "}
        <div style={{ position: "relative", top: "10px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              label="CEP"
              noRadius
              placeholder="Digite aqui"
              borderNone
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              width: "110%",
              top: "-6px",
              left: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-11px", zIndex: "2" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              label="Endereço"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>
          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              width: "110%",
              top: "-6px",
              left: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-11px", zIndex: "2" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              label="Número"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>

          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              width: "110%",
              top: "-6px",
              left: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-32px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              label="Complemento (opcional)"
              noRadius
              placeholder="Digite (opcional)"
              borderNone
            />
          </div>

          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              width: "110%",
              top: "-6px",
              left: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-41px", zIndex: "1" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputSelect
              kind="fluid"
              size="medium"
              label="Tipo de residência"
              options={[
                { label: "Residencial", value: "residential" },
                { label: "Comercial", value: "commercial" },
              ]}
              placeholder="Escolha uma opção"
              onChange={(e) => setFieldValue("", e)}
              noRadius
            />
          </div>
        </div>
        <div style={{ position: "relative", top: "-62px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              label="Bairro"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>

          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              width: "110%",
              top: "-6px",
              left: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-62px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputFluid
              label="Cidade"
              noRadius
              placeholder="Digite"
              borderNone
            />
          </div>

          <div
            style={{
              borderBottom: "1px solid #8D8D8D",
              position: "relative",
              width: "110%",
              top: "-6px",
              left: "-5px",
              zIndex: "-3",
            }}
          ></div>
        </div>
        <div style={{ position: "relative", top: "-82px" }}>
          <div style={{ padding: "0 1.8px 6px 2px" }}>
            <InputSelect
              size="large"
              kind="fluid"
              label="UF"
              options={[]} // Insira as opções de estados
              placeholder="Escolha uma opção"
              noRadius
            />
          </div>
        </div>
      </FormGridAddress>
    ),
  };

  const disabledAdvanceDict: { [key: string]: boolean } = {
    type: values.profile === "",
  };

  const handleClose = () => {
    setOpenNewDebitorModal(false);
    setStep("type");
  };

  return (
    <Modal
      radius
      height={step === "type" ? "368px" : "662px"}
      width="960px"
      background="#f4f4f4"
      open={openNewDebitorModal}
      onClose={() => handleClose()}
      label={<h4>Novo contato</h4>}
      buttons={
        step === "type" && values.profile === "provider"
          ? [
              {
                kind: "ghost",
                label: "Cancelar",
                onClick: () => handleClose(),
              },
              {
                kind: "primary",
                label: "Avançar",
                onClick: () => settersAdvanceDict[step](),
                disabled: disabledAdvanceDict[step],
              },
            ]
          : step === "data" && values.profile === "provider"
            ? [
                {
                  kind: "ghost",
                  label: "Cancelar",
                  onClick: () => handleClose(),
                },
                {
                  kind: "secondary",
                  label: "voltar",
                  onClick: () => settersReturnDict[step](),
                },
                {
                  kind: "primary",
                  label: "Avançar",
                  onClick: () => settersAdvanceDict[step](),
                  disabled:
                    values.cnae === "" ||
                    values.document_number.length < 14 ||
                    values.fantasy_name === "" ||
                    values.foundation_date === "" ||
                    values.name === "" ||
                    values.email === "" ||
                    values.legal_nature === "" ||
                    values.phone === "",
                },
              ]
            : [
                {
                  kind: "ghost",
                  label: "Cancelar",
                  onClick: () => handleClose(),
                },
                {
                  kind: "secondary",
                  label: "voltar",
                  onClick: () => settersReturnDict[step](),
                },
                {
                  kind: "primary",
                  label: "Avançar",
                  onClick: () => settersAdvanceDict[step](),
                  disabled: true,
                },
              ]
      }
    >
      {renderDict[step]}
    </Modal>
  );
};

export default NewDebitor;
