import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Breadcrumb,
  Button,
  InputSelect,
  Modal,
  TableBasic,
  Tag,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
// import filterIcon from "~/assets/Filter.png";
// import editIcon from "~/assets/Edit.png";
import ViewIcon from "~/assets/View.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import moneyIcon from "~/assets/Money.png";
import renewIcon from "~/assets/Renew.png";
import NewIcon from "~/assets/Addd.png";
// import trashCanIcon from "~/assets/Trash-can.png";
import SendIcon from "~/assets/License--draft.png";
import styled from "styled-components";

type Values = {
  signature_template: string;
};

const initialValues: Values = {
  signature_template: "",
};

import {
  getOperationsAPI,
  getTemplateProduct,
  IOperations,
} from "~/services/api/operations";
import useOperation from "~/hooks/useOperation";
import {
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
  getLabelByValue,
} from "~/services/utils";
import { format, parseISO } from "date-fns";
import { PropsTag } from "prosperita-dumbo-react/dist/types/Components/Tag";
import useLoading from "~/hooks/useLoading";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  ICreateDocumentBody,
  postCreateDocument,
  postSendDocumentToSignature,
} from "~/services/api/signature";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const ContainerCanViewOperations = styled.div`
  width: 100%;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

// const titlesColumn = [
//   <Checkbox disabled />,
//   "Status",
//   "Produto",
//   "Operações",
//   "Tomador",
//   "CPF / CNPJ",
//   "Valor Principal",
//   "Data de início",
//   "Taxa",
//   "Status da Assinatura",
//   "",
// ];

const statusOperationsSign: { [key: string]: string } = {
  processing: "Não enviado",
  denied: "-",
  opened: "Não enviado",
  expired: "-",
  signing: "Enviado para assinatura",
  confirmed: "Assinado",
  finished: "Assinado",
  canceled: "-",
  error: "-",
};

const statusOperations: { [key: string]: string } = {
  processing: "Processando",
  denied: "Negado",
  opened: "Aberto",
  expired: "Removido",
  signing: "Assinando",
  confirmed: "Confirmado",
  finished: "Finalizado",
  canceled: "Cancelado",
  error: "Erro",
};

const statusOperationsTagColor: { [key: string]: PropsTag["type"] } = {
  processing: "gray",
  denied: "red",
  opened: "gray",
  expired: "red",
  signing: "blue",
  confirmed: "green",
  finished: "green",
  canceled: "red",
  error: "red",
};

const notifySuccess = () => {
  toast.success("Operação enviada pra assinatura com sucesso!");
};

const notifyError = () => {
  toast.error("Não foi possível enviar o documento para assinatura!");
};

const Operations: FunctionComponent = () => {
  const navigate = useNavigate();
  const { operations, setOperations, sendTosign, setSendToSign } =
    useOperation();
  const [operationUuid, setOperationUuid] = useState<string>("");
  const { setLoading } = useLoading();

  const [render, setRender] = useState<boolean>(false);

  const [templates, setTemplates] =
    useState<{ name: string; uuid: string; status?: string }[]>();

  const [currentDate, setCurrentDate] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );
  const calculationTypeOptions = useMemo(() => {
    const baseOptions = [
      { value: "360", label: "360 Dias" },
      { value: "365", label: "365 Dias" },
      { value: "252", label: "252 Dias" },
    ];

    const detailedOptions = [
      { value: "252_daily", label: "Base 252 (Dias Úteis)" },
      { value: "252_monthly", label: "Base 252 (Meses x 21)" },
      { value: "360_daily", label: "Base 360 (Dias Corridos)" },
      { value: "360_monthly", label: "Base 360 (Meses)" },
      { value: "365_daily", label: "Base 365 (Dias Corridos)" },
      { value: "365_monthly", label: "Base 365 (Meses)" },
    ];

    const combinedOptions = [...baseOptions, ...detailedOptions];

    return combinedOptions.sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, []);

  type IDataTableItem = {
    // Checkbox: JSX.Element;
    Status: JSX.Element;
    Produto: string;
    Operações: string;
    Tomador: string;
    "CPF / CNPJ": string;
    "Valor Principal": string;
    "Data de início": string;
    Taxa: string;
    "Status da Assinatura": JSX.Element;
    "": JSX.Element;
  };

  // const canViewOperations: boolean = true;

  const { setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const templateOptions = useMemo(() => {
    return (
      templates &&
      templates
        .filter((template) => template.status === "active")
        .map((template) => ({
          value: template.uuid,
          label: template.name,
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
        )
    );
  }, [templates]);

  const fetchProductTemplate = useCallback(
    async (operation: IOperations): Promise<void> => {
      setLoading(true);
      try {
        const { data } = await getTemplateProduct({
          product_uuid: operation.product_uuid as string,
        });
        console.log(data);
        setSendToSign(true);
        setTemplates(data.payload);
        setOperationUuid(operation.uuid);
        setLoading(false);
      } catch (e) {
        console.error("Erro ao buscar operações:", e);
        setLoading(false);
      }
    },
    [setSendToSign, setLoading]
  );

  const canViewOperations: boolean = true;

  const dataTable: IDataTableItem[] = useMemo(() => {
    return operations?.map((operation: IOperations) => {
      return {
        // Checkbox: (
        //   <div>
        //     <Checkbox
        //       disabled
        //       key={index}
        //       onClick={() => {
        //         // handleCheckboxChange(index, operation);
        //       }}
        //     />
        //   </div>
        // ),
        Status: (
          <div style={{ maxWidth: "150px" }}>
            <Tag
              type={statusOperationsTagColor[operation.status]}
              size="medium"
              position="center"
            >
              <div style={{ padding: "2px" }}>
                {statusOperations[operation.status]}
              </div>
            </Tag>
          </div>
        ),
        Produto: operation.product_type === "ccb" ? "CCB" : "Nota Comercial",
        Operações: `${getLabelByValue(operation?.operation_type, calculationTypeOptions)}`,
        Tomador: operation?.debtor_name ?? "-",
        "CPF / CNPJ": formatCPFCNPJ(operation?.debtor_document),
        "Valor Principal": `R$ ${formatNumberToCurrencyWithoutSimbol(operation?.principal_amount?.toFixed(2))}`,
        "Data de início": format(
          parseISO(operation?.operation_date),
          "dd/MM/yyyy"
        ),
        Taxa: operation?.interest_rate ? `${operation?.interest_rate}%` : "-",
        "Status da Assinatura": (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            {operation?.status === "opened" &&
            operation.product_type !== "ccb" ? (
              <Tooltip align="top" size="large" label="Enviar para assinatura">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    operation?.product_uuid && fetchProductTemplate(operation)
                  }
                >
                  <p>{statusOperationsSign[operation.status]}</p>
                  <Margin mr={10} />
                  <img
                    src={SendIcon}
                    alt="Enviar ícone"
                    style={{
                      filter: "brightness(50%) saturate(60%)",
                      width: "15px",
                      height: "15px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
              </Tooltip>
            ) : (
              <p>
                {operation.status
                  ? statusOperationsSign[operation.status]
                  : "-"}
              </p>
            )}
          </div>
        ),
        "": "",

        "": (
          <div
            style={{
              display: "flex",
              height: "20px",
              marginRight: "50px",
            }}
          >
            <div style={{ position: "relative", top: "-8px" }}>
              <Tooltip align="top" size="large" label="Visualizar detalhes">
                <Button
                  kind="ghost"
                  size="small"
                  icon={ViewIcon}
                  label=""
                  onClick={() =>
                    navigate(`/app/credit/operations/details/${operation.uuid}`)
                  }
                />
              </Tooltip>
            </div>
            {/* <Button
              kind="ghost"
              size="small"
              icon={trashCanIcon} // Ícone para o botão de Editar
              label=""
            /> */}
          </div>
        ),
      };
    });
  }, [operations, fetchProductTemplate, navigate, calculationTypeOptions]);

  const fetchOperations = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await getOperationsAPI();
      setOperations(data.payload.reverse());
      setLoading(false);
      console.log(data.payload);
      const currentDateTime = new Date();

      setCurrentDate(
        currentDateTime.toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      );

      setCurrentTime(
        currentDateTime.toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );
    } catch (e) {
      console.error("Erro ao buscar operações:", e);
      setLoading(false);
    } finally {
      setRender(true);
    }
  }, [setOperations, setLoading]);

  const sendCreateDocumentAndSendToSignature =
    useCallback(async (): Promise<void> => {
      setLoading(true);
      const payload: ICreateDocumentBody = {
        asset_uuid: operationUuid,
        template_uuid: values.signature_template,
      };

      try {
        const { data } = await postCreateDocument(payload);
        console.log(data);
        await postSendDocumentToSignature(data.payload.uuid);
        setLoading(false);
        notifySuccess();
        setSendToSign(false);
        fetchOperations();
      } catch (e) {
        console.error("Erro ao buscar operações:", e);
        setLoading(false);
        notifyError();
      }
    }, [operationUuid, setLoading, values, setSendToSign, fetchOperations]);

  useEffect(() => {
    localStorage.removeItem("@:involveds");
    localStorage.removeItem("@:simulate_operation");
    localStorage.removeItem("@:operation");
    localStorage.removeItem("@:documents");
  }, []);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  return (
    render && (
      <Container>
        <Breadcrumb
          crumbsOptions={[
            { crumb: "Início" },
            { crumb: "Crédito" },
            { crumb: "Operações" },
          ]}
          endHiddenIndex={5}
          startHiddenIndex={5}
          aria-label="Navegação de operações"
        />

        <ContainerCanViewOperations>
          <>
            {canViewOperations && operations?.length > 0 ? (
              <>
                <Margin mt={30} />
                <div>
                  <TableBasic
                    label="Operações"
                    search
                    sizeSearch="large"
                    // headerAction={{
                    //   active: false,
                    //   count: 0,
                    // }}
                    columnTitles={[
                      // "",
                      // "Checkbox",
                      "Status",
                      "Produto",
                      "Operações",
                      "Tomador",
                      "CPF / CNPJ",
                      "Valor Principal",
                      "Data de início",
                      "Taxa",
                      "Status da Assinatura",
                      "",
                    ]}
                    rowData={dataTable}
                    pageSizes={[10, 5, 20, 50, 100]}
                    width="1000px"
                    buttons={[
                      // {
                      //   kind: "ghost",
                      //   label: "",
                      //   position: "right",
                      //   icon: filterIcon,
                      //   width: "40px",
                      //   dimension: "36",
                      // },
                      {
                        kind: "ghost",
                        label: "",
                        position: "right",
                        icon: renewIcon,
                        size: "large",
                        width: "57px",
                        dimension: "55.5",
                        onClick: () => fetchOperations(),
                      },
                      {
                        kind: "primary",
                        label: "Nova Operação",
                        position: "right",
                        size: "large",
                        icon: NewIcon,

                        onClick: () => {
                          localStorage.removeItem("@:involveds");
                          localStorage.removeItem("@:simulate_operation");
                          localStorage.removeItem("@:operation");
                          localStorage.removeItem("@:simulation_uuid");
                          localStorage.removeItem("@:documents");
                          navigate("/app/credit/operations/new/select-type");
                        },
                      },
                    ]}
                  />
                </div>
                <div id="lastUpdate">
                  <Margin ml={16} mt={25}>
                    <h5>
                      Atualizado em{" "}
                      <span style={{ fontWeight: "bold" }}>{currentDate}</span>,
                      às{" "}
                      <span style={{ fontWeight: "bold" }}>{currentTime}h</span>
                    </h5>
                  </Margin>
                </div>
              </>
            ) : (
              canViewOperations && (
                <div>
                  <Margin mt={70} />
                  <div>
                    <TagLup
                      src={lupaIcon}
                      aria-label="Ícone indicando que não há operações"
                    />
                    <Text>Sem operações</Text>
                    <Subtext>
                      Você não tem operações para serem exibidas no momento
                    </Subtext>
                  </div>
                  <Margin mt={50} />
                  <div style={{ width: "320px" }}>
                    <Button
                      kind="primary"
                      size="medium"
                      label={"Nova operação"}
                      aria-label="Criar nova operação"
                      onClick={() =>
                        navigate("/app/credit/operations/new/select-type")
                      }
                      icon={moneyIcon}
                    />
                  </div>
                </div>
              )
            )}
          </>

          {!canViewOperations && (
            <div>
              <Margin mt={70} />
              <TagLup
                src={lockIcon}
                aria-label="Ícone indicando não há permissão para visualizar as operações"
              />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ver as operações de crédito, <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          )}
        </ContainerCanViewOperations>
        <div style={{ zIndex: "999" }}>
          {" "}
          <Modal
            radius
            height={"462px"}
            width="760px"
            background="#f4f4f4"
            open={sendTosign}
            onClose={() => setSendToSign(false)}
            label={<h4>Enviar para assinatura</h4>}
            buttons={[
              {
                kind: "ghost",
                label: "Cancelar",
                onClick: () => setSendToSign(false),
              },
              {
                kind: "primary",
                label: "Enviar",
                disabled: !values.signature_template,
                onClick: () => {
                  sendCreateDocumentAndSendToSignature();
                },
              },
            ]}
          >
            <div style={{ padding: "30px" }}>
              <h3>Escolha o template</h3>
              <Margin mt={30} />
              <InputSelect
                name="signature_template"
                id="signature_template"
                kind="default"
                size="large"
                label="Template de assinatura"
                options={templateOptions}
                placeholder="Selecione uma opção"
                onChange={(e) => handleSelect("signature_template", e)}
              />
            </div>
          </Modal>
        </div>
      </Container>
    )
  );
};

export default Operations;
