import { Button, TableBasic } from "prosperita-dumbo-react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import useOperation from "~/hooks/useOperation";
import { useCallback, useEffect, useMemo } from "react";
import { InvolvedData } from "~/types";
import { getPartnersAPI } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import ArrowIcon from "~/assets/arrow-right-_1_.png";
// import TrashIcon from "~/assets/Trash-can.png";
import { useNavigate, useParams } from "react-router-dom";
import MoreIcon from "~/assets/Addd.png";
import { getSubscriberAPI } from "~/services/api/subscriber";
import useLoading from "~/hooks/useLoading";
import { getLabelByValue } from "~/services/utils";

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const titlesColumnInvolveds = [
  "Nome",
  "Relacionado à",
  "Tipo de relação",
  "Forma de envio",
  "Tipo de autenticação",
  // "",
];

const InvolvedsSubscriber = () => {
  const {
    setAddInvolved,
    setTab,

    // removeInvolved,
    subscriber,
    setSubscriber,
  } = useOperation();
  const { setPartners } = usePartner();
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const { id, uuid } = useParams();

  const canViewOperations = true;

  const listRelationship = useMemo(() => {
    return [
      { label: "Correntista", value: "account_holder" },
      { label: "Administrador", value: "administrator" },
      { label: "Procurador", value: "attorney" },
      { label: "Comodatário", value: "borrower" },
      { label: "Responsável Solidário", value: "co_responsible" },
      { label: "Caucionante", value: "collateral_provider" },
      { label: "Anuente", value: "consenting" },
      { label: "Interveniente Anuente", value: "consenting_intervenor" },
      { label: "Credor", value: "creditor" },
      { label: "Devedor", value: "debtor" },
      { label: "Endossante", value: "endorser" },
      { label: "Endossatário", value: "endorsee" },
      { label: "Avalista / Fiador", value: "guarantor" },
      { label: "Cônjuge do Fiador", value: "guarantor_spouse" },
      { label: "Intermediário", value: "intermediary" },
      { label: "Interveniente Garantidor", value: "intervening_guarantor" },
      { label: "Emitente", value: "issuer" },
      { label: "Devedor Solidário", value: "joint_debtor" },
      { label: "Responsável Legal", value: "legal_guardian" },
      { label: "Representante Legal", value: "legal_representative" },
      { label: "Mutuante", value: "lender" },
      { label: "Sócio", value: "partner" },
      { label: "Presidente", value: "president" },
      { label: "Cedente", value: "transferor" },
      { label: "Cessionário", value: "transferee" },
      { label: "Testemunha", value: "witness" },
    ];
  }, []);

  const listSends = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "SMS", value: "sms" },
      { label: "Whatsapp", value: "whatsapp" },
    ];
  }, []);

  const getLabelByValueRelatioship = useCallback(
    (value: string) => {
      const relationship = listRelationship?.find(
        (item) => item.value === value
      );
      return relationship ? relationship?.label : "-";
    },
    [listRelationship]
  );

  const getLabelByValueSendType = useCallback(
    (value: string) => {
      const listSend = listSends?.find((item) => item.value === value);
      return listSend ? listSend?.label : "-";
    },
    [listSends]
  );

  const listAuths = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "Biometria", value: "biometric" },
      { label: "ICP Brasil", value: "icp_brasil" },
    ];
  }, []);

  const dataTableInvolveds = useMemo(() => {
    if (subscriber) {
      return subscriber?.involveds?.map((involved: InvolvedData) => ({
        Nome: involved?.name,
        "Relacionado à": involved?.related_to,
        "Tipo de relação": getLabelByValueRelatioship(involved?.relationship),
        "Forma de envio": getLabelByValueSendType(involved?.send_type),
        "Tipo de autenticação": getLabelByValue(involved.auth_type, listAuths),
        // "": (
        //   <div>
        //     <Tooltip align="top" size="medium" label="Remover">
        //       <img
        //         src={TrashIcon}
        //         loading="lazy"
        //         alt="Deletar"
        //         onClick={() => {
        //           setInvolvedToRemove({
        //             name: involved?.name ?? "",
        //             uuid: involved?.uuid ?? "",
        //           });

        //           setRemoveInvolved(true);
        //         }}
        //       />
        //     </Tooltip>
        //   </div>
        // ),
      }));
    }
  }, [
    subscriber,
    getLabelByValueRelatioship,
    getLabelByValueSendType,

    listAuths,
  ]);

  const fetchPartners = useCallback(async () => {
    try {
      const { data } = await getPartnersAPI();
      setPartners(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setPartners]);

  const fetchSubscriber = useCallback(async () => {
    setLoading(true);
    if (id) {
      try {
        const { data } = await getSubscriberAPI(id);
        setSubscriber(data.payload);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  }, [setSubscriber, id, setLoading]);

  // const removeSubscriberInvolved = useCallback(
  //   async (id_involved_to_delete: string) => {
  //     try {
  //       await deleteSubscriberIvolvedAPI(id_involved_to_delete);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   },
  //   []
  // );

  // const handleClose = () => {
  //   setRemoveInvolved(false);
  // };

  // const [involvedToRemove, setInvolvedToRemove] = useState<{
  //   name: string;
  //   uuid: string;
  // } | null>(null);

  useEffect(() => {
    fetchPartners();
    fetchSubscriber();

    setTab(2);
  }, [setTab, fetchPartners, fetchSubscriber]);

  return (
    <div style={{ width: "100%" }}>
      <>
        {canViewOperations &&
        subscriber &&
        subscriber?.involveds?.length > 0 ? (
          <>
            <div>
              <Margin mt={30} />
              <div>
                <TableBasic
                  search
                  columnTitles={titlesColumnInvolveds}
                  rowData={dataTableInvolveds || []}
                  pageSizes={[5, 10, 20, 50, 100]}
                  width="1000px"
                  sizeSearch="large"
                  buttons={[
                    {
                      kind: "ghost",
                      label: "",
                      position: "right",
                      icon: renewIcon,
                      size: "large",
                      width: "57px",
                      dimension: "55.5",
                    },
                    {
                      kind: "primary",
                      size: "large",
                      icon: MoreIcon,
                      label: "Adicionar envolvido",
                      position: "right",

                      onClick: () => setAddInvolved(true),
                    },
                  ]}
                />
              </div>
            </div>
          </>
        ) : (
          canViewOperations && (
            <div>
              <Margin mt={70} />
              <div>
                <TagLup src={lupaIcon} />
                <Text>Sem Envolvidos</Text>
                <Subtext>
                  Você não tem envolvidos para serem exibidos no momento
                </Subtext>
              </div>
              <Margin mt={50} />
              <div style={{ width: "320px" }}>
                <Button
                  kind="primary"
                  size="medium"
                  icon={MoreIcon}
                  label={"Adicionar envolvido"}
                  onClick={() => setAddInvolved(true)}
                />
              </div>
            </div>
          )
        )}
      </>
      <Margin mt={50} />
      <div
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <div>
            <Button
              kind="primary"
              size="large"
              label={
                subscriber && subscriber?.involveds?.length > 0
                  ? "Avançar"
                  : "Continuar sem envolvidos"
              }
              icon={ArrowIcon}
              onClick={() => {
                navigate(
                  `/app/credit/operations/details/${uuid}/subscriber/new/${id}/documents/`
                );
                setTab(3);
              }}
            />
          </div>
        </div>
      </div>

      {/* <Modal
        height={"468px"}
        width="960px"
        background="#f4f4f4"
        label={<h3>Remover envolvido</h3>}
        radius
        onClose={() => {
          handleClose();
        }}
        open={removeInvolved}
        buttons={[
          { kind: "ghost", label: "Cancelar", onClick: () => handleClose() },
          {
            kind: "primary",
            label: "Sim, remover",
            onClick: () =>
              involvedToRemove &&
              removeSubscriberInvolved(involvedToRemove?.uuid),
          },
        ]}
      >
        <div style={{ padding: "50px" }}>
          <h2>
            Deseja realmente remover o envolvido {involvedToRemove?.name} ?{" "}
          </h2>
        </div>
      </Modal> */}
    </div>
  );
};

export default InvolvedsSubscriber;
