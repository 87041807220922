import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputCurrency,
  InputSelect,
  Radio,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import ArrowIcon from "~/assets/arrow-right-_1_.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  convertStringToNumber,
  formatCPFCNPJ,
  onlyNumbers,
} from "~/services/utils";

import useLoading from "~/hooks/useLoading";

import { toast } from "react-toastify";
import {
  cnaeOptions,
  documentTypes,
  employmentStatusOptions,
  genderOptionsMore,
  maritalStatusOptions,
  occupationNatureOptions,
  residenceOptions,
  ufOptions,
} from "~/utils/options";
import {
  getPartnerCompanyAPI,
  getPartnersCompanyAPI,
  IPartnerCompany,
  postPartnerCompanyAPI,
} from "~/services/api/partner";
import { getCepAPI } from "~/services/api/cep";
import { isValidFullName } from "~/services/validators";
import Stepper from "~/components/Stepper";
import documentNumberValidator from "~/services/validateDocumentNumber";
import { isDateInThePast, validateAge } from "~/services/validateBirthDate";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

type Values = {
  name: string;
  document_number: string;
  document_type: string;
  owner_uuid: string;
  contact_email: string;
  contact_phone: string;
  address_zip_code: string;
  address_street: string;
  address_number: string;
  address_district: string;
  address_city: string;
  address_state: string;
  address_country: string;
  address_type: string;
  identification_document_document_type: string;
  identification_document_document: string;
  identification_document_emission_date: string;
  identification_document_expiration_date: string;
  identification_document_pep: boolean;
  identification_document_issuing_state: string;
  professional_info_occupation_partner_company_uuid: string;
  professional_info_occupation_nature: string;
  professional_info_employment_status: string;
  professional_info_net_salary: string;
  professional_info_additional_income: string;
  professional_info_hiring_date: string;
  professional_info_employee_number: string;
  professional_info_department: string;
  parents_parent_type: string;
  parents_name: string;
  parents_document_number: string;
  parents_company_document_number: string;
  parents_occupation: string;
  parents_phone: string;
  parents_birth_date: string;
  parents_net_salary: string;
  parents_additional_income: string;
  health_declaration: boolean;
  birth_date: string;
  nationality: string;
  marital_status: string;
  occupation: string;
  social_name: string;
  birth_country: string;
  mother_name: string;
  father_name: string;
  gender: string;
  type: string;
};

const initialValues: Values = {
  name: "",
  document_number: "",
  document_type: "",
  owner_uuid: "",
  contact_email: "",
  contact_phone: "",
  address_zip_code: "",
  address_street: "",
  address_number: "",
  address_district: "",
  address_city: "",
  address_state: "",
  address_country: "",
  address_type: "",
  identification_document_document_type: "",
  identification_document_document: "",
  identification_document_emission_date: "",
  identification_document_expiration_date: "",
  identification_document_pep: false,
  identification_document_issuing_state: "",
  professional_info_occupation_partner_company_uuid: "",
  professional_info_occupation_nature: "",
  professional_info_employment_status: "",
  professional_info_net_salary: "",
  professional_info_additional_income: "",
  professional_info_hiring_date: "",
  professional_info_employee_number: "",
  professional_info_department: "",
  parents_parent_type: "",
  parents_name: "",
  parents_document_number: "",
  parents_company_document_number: "",
  parents_occupation: "",
  parents_phone: "",
  parents_birth_date: "",
  parents_net_salary: "",
  parents_additional_income: "",
  health_declaration: false,
  birth_date: "",
  nationality: "Brasileira",
  marital_status: "",
  occupation: "",
  social_name: "",
  birth_country: "",
  mother_name: "",
  father_name: "",
  gender: "",
  type: "",
};

const NewContactPerson = () => {
  const navigate = useNavigate();
  const [errorCep, setErrorCep] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const [error, setError] = useState<string>("");
  const [partnersCompany, setPartnersCompany] = useState<IPartnerCompany[]>();
  const [partnerCompanyData, setPartnerCompanyData] = useState<
    IPartnerCompany | undefined
  >();

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const canDoSimulations = true;

  const steps = [
    {
      label: "Informações",
      isCompleted: false,
      isActive: true,
    },
    {
      label: "Dados bancários",
      isCompleted: false,
      isActive: false,
    },
    { label: "Documentos", isCompleted: false, isActive: false },
  ];

  const isValidDocument = useMemo(() => {
    return (
      values.document_number.length > 13 &&
      documentNumberValidator(values.document_number)
    );
  }, [values.document_number]);

  const isValidCNPJ = useMemo(() => {
    return (
      values.parents_company_document_number.length > 13 &&
      documentNumberValidator(values.parents_company_document_number)
    );
  }, [values.parents_company_document_number]);

  const isValidParentDocument = useMemo(() => {
    return (
      values.parents_document_number.length > 13 &&
      documentNumberValidator(values.parents_document_number)
    );
  }, [values.parents_document_number]);

  const partnersCompanyOptions = useMemo(() => {
    return partnersCompany
      ?.map((partnerCompany: IPartnerCompany) => ({
        value: partnerCompany.uuid,
        label: partnerCompany.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partnersCompany]);

  const createContactPerson = useCallback(async () => {
    const payload = {
      ...(values.owner_uuid !== "" && { owner_uuid: values.owner_uuid }),
      name: values.name,
      document_number: onlyNumbers(values.document_number),
      document_type: "person",
      contact: {
        email: values.contact_email,
        phone: onlyNumbers(values.contact_phone),
      },
      address: {
        zip_code: onlyNumbers(values.address_zip_code),
        street: values.address_street,
        number: values.address_number,
        district: values.address_district,
        city: values.address_city,
        state: values.address_state,
        country: "Brasil",
        type: values.address_type,
      },
      identification_document: {
        document_type:
          values.identification_document_document_type === ""
            ? null
            : values.identification_document_document_type,
        document:
          values.identification_document_document === ""
            ? null
            : values.identification_document_document,
        emission_date:
          values.identification_document_emission_date === ""
            ? null
            : values.identification_document_emission_date,
        expiration_date:
          values.identification_document_expiration_date === ""
            ? null
            : values.identification_document_expiration_date,
        pep: values.identification_document_pep ?? null,
        issuing_state: "SP",
      },
      professional_info: {
        occupation_nature:
          values.professional_info_occupation_nature === ""
            ? null
            : values.professional_info_occupation_nature,
        employment_status:
          values.professional_info_employment_status === ""
            ? null
            : values.professional_info_employment_status,
        net_salary:
          values?.professional_info_net_salary === ""
            ? null
            : convertStringToNumber(values?.professional_info_net_salary),
        additional_income:
          values?.professional_info_additional_income === ""
            ? null
            : convertStringToNumber(
                values?.professional_info_additional_income
              ),
        hiring_date:
          values.professional_info_hiring_date === ""
            ? null
            : values.professional_info_hiring_date,
        employee_number:
          values.professional_info_employee_number === ""
            ? null
            : values.professional_info_employee_number,
        department:
          values.professional_info_department === ""
            ? null
            : values.professional_info_department,
      },
      parents: [
        {
          parent_type: "spouse",
          name: values.parents_name,
          document_number: onlyNumbers(values.parents_document_number),
          company_document_number: onlyNumbers(
            values.parents_company_document_number
          ),
          occupation: values.parents_occupation,
          phone: onlyNumbers(values.parents_phone),
          birth_date: values.parents_birth_date,
          net_salary: values.parents_net_salary
            ? convertStringToNumber(values.parents_net_salary)
            : null,
          additional_income: values.parents_additional_income
            ? convertStringToNumber(values.parents_additional_income)
            : null,
        },
      ],
      health_declaration: String(values.health_declaration),
      birth_date: values.birth_date,
      nationality: values.nationality,
      marital_status: values.marital_status,
      occupation: values.occupation,
      social_name: values.social_name,
      birth_country: values.birth_country,
      mother_name: values.mother_name,
      father_name: values.father_name,
      gender: values.gender,
      type: "contact",
    };

    setLoading(true);

    try {
      const { data } = await postPartnerCompanyAPI(payload);

      localStorage.setItem("@:partner_uuid", data.payload.uuid);
      toast.success("Contato Pessoa Física criado com sucesso !");
      setLoading(false);
      navigate(`/app/contacts/new/person/${data.payload.uuid}/bank-data`);
    } catch (e) {
      console.log(e);

      toast.error(
        "Não foi possível criar o Contato Pessoa Física. Tente novamente"
      );
      setLoading(false);
    }
  }, [values, setLoading, navigate]);

  const fetchPartnersCompany = useCallback(async (): Promise<void> => {
    try {
      const { data } = await getPartnersCompanyAPI({
        document_type: "company",
      });
      setPartnersCompany(data.payload.reverse());
    } catch (e) {
      console.error("Erro ao buscar contatos:", e);
    }
  }, [setPartnersCompany]);

  const fetchPartnerCompany = useCallback(
    async (uuid: string): Promise<void> => {
      try {
        const { data } = await getPartnerCompanyAPI(uuid);
        setPartnerCompanyData(data?.payload);
      } catch (e) {
        console.error("Erro ao buscar contatos:", e);
      }
    },
    [setPartnerCompanyData]
  );

  const fetchCEP = useCallback(
    async (cep: string): Promise<void> => {
      try {
        const { data } = await getCepAPI(cep);

        if (data.erro) {
          setErrorCep(true);
          setFieldValue("address_street", "");
          setFieldValue("address_district", "");
          setFieldValue("address_city", "");
          setFieldValue("address_state", "Selecione");
        } else {
          setErrorCep(false);
          setFieldValue("address_street", data.logradouro);
          setFieldValue("address_district", data.bairro);
          setFieldValue("address_city", data.localidade);
          setFieldValue("address_state", data.uf);
        }
      } catch (e) {
        console.error("Erro ao buscar contatos:", e);
        setErrorCep(false);
      }
    },
    [setFieldValue]
  );

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (values.address_zip_code.length === 9) {
      fetchCEP(onlyNumbers(values.address_zip_code));
    }
  }, [values.address_zip_code, fetchCEP]);

  useEffect(() => {
    if (values.professional_info_occupation_partner_company_uuid) {
      fetchPartnerCompany(
        values.professional_info_occupation_partner_company_uuid
      );
    }
  }, [
    values.professional_info_occupation_partner_company_uuid,
    fetchPartnerCompany,
  ]);

  useEffect(() => {
    if (values.address_zip_code.length === 8) {
      setErrorCep(false);
    }
  }, [values.address_zip_code]);

  const [errorCNPJ, setErrorCNPJ] = useState<string>("");
  console.log(errorCNPJ);

  useEffect(() => {
    if (
      onlyNumbers(values.parents_company_document_number).length <= 17 &&
      !isValidCNPJ
    ) {
      setErrorCNPJ("initialState");
    } else if (
      onlyNumbers(values.parents_company_document_number).length >= 17 &&
      isValidCNPJ
    ) {
      setErrorCNPJ("notError");
    } else if (
      onlyNumbers(values.parents_company_document_number).length > 17 &&
      !isValidCNPJ
    ) {
      setErrorCNPJ("CNPJ inválido");
    }
  }, [values.parents_company_document_number, setErrorCNPJ, isValidCNPJ]);

  useEffect(() => {
    localStorage.removeItem("@:partner_uuid");
    fetchPartnersCompany();
  }, [fetchPartnersCompany]);

  const [errorParentCPF, setErrorParentCPF] = useState<string>("");

  useEffect(() => {
    if (values?.document_number.length <= 13 && !isValidDocument) {
      setError("initialState");
    } else if (values?.document_number.length >= 13 && isValidDocument) {
      setError("notError");
    } else if (values?.document_number.length > 13 && !isValidDocument) {
      setError("Documento inválido");
    }

    if (
      values?.parents_document_number.length <= 13 &&
      !isValidParentDocument
    ) {
      setErrorParentCPF("initialState");
    } else if (
      values?.parents_document_number.length >= 13 &&
      isValidParentDocument
    ) {
      setErrorParentCPF("notError");
    } else if (
      values?.parents_document_number.length > 13 &&
      !isValidParentDocument
    ) {
      setErrorParentCPF("Documento inválido");
    }
  }, [
    values.document_number,
    setError,
    isValidDocument,
    isValidParentDocument,
    values.parents_document_number,
  ]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          { crumb: "Início", onClick: () => navigate("/app") },
          { crumb: "Crédito" },
          {
            crumb: "Contatos",
            onClick: () => navigate("/app/contacts"),
          },
          { crumb: "Novo Contato" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <>
              <>
                <Margin mb={30} />
                <div
                  style={{
                    position: "sticky",
                    top: 113,

                    gap: "10px",
                    width: "100%",
                    zIndex: "3",
                    padding: "10px",
                    background: "#fff",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      kind={"ghost"}
                      label="Cancelar"
                      size="large"
                      onClick={() => navigate("/app/contacts")}
                    />
                    <div style={{ display: "flex", gap: "10px" }}>
                      {/* <Button
                        kind={"tertiary"}
                        label="Voltar"
                        size="large"
                        onClick={() =>
                          navigate("/app/contacts/new/select-type")
                        }
                      /> */}
                      <Button
                        kind="primary"
                        icon={ArrowIcon}
                        label="Avançar"
                        disabled={
                          (values.parents_company_document_number.length > 17 &&
                            !isValidCNPJ) ||
                          (values.parents_birth_date.length &&
                            values.parents_birth_date.length > 9 &&
                            !validateAge(values.parents_birth_date)) ||
                          (values.identification_document_emission_date &&
                            values.identification_document_emission_date
                              .length > 9 &&
                            !isDateInThePast(
                              values.identification_document_emission_date
                            )) ||
                          (values.identification_document_expiration_date &&
                            values.identification_document_expiration_date
                              .length > 9 &&
                            isDateInThePast(
                              values.identification_document_expiration_date
                            )) ||
                          !isValidFullName(values?.name) ||
                          values.document_number.length < 11 ||
                          !validateEmail(values.contact_email) ||
                          !values.marital_status ||
                          !validateAge(values.birth_date) ||
                          values.birth_date.length < 10 ||
                          !values.gender ||
                          !isValidDocument ||
                          (values.parents_document_number.length > 13 &&
                            !isValidParentDocument) ||
                          values.contact_phone.length !== 15 ||
                          !isValidFullName(values?.father_name) ||
                          !isValidFullName(values?.mother_name) ||
                          !values.nationality ||
                          !values.contact_email ||
                          (values.professional_info_hiring_date &&
                            values.professional_info_hiring_date.length > 9 &&
                            !isDateInThePast(
                              values.professional_info_hiring_date
                            )) ||
                          !values.contact_phone ||
                          !values.address_zip_code ||
                          !values.address_type ||
                          !values.address_street ||
                          !values.address_state ||
                          !values.address_number ||
                          !values.address_city ||
                          !values.address_district ||
                          !values.birth_date
                        }
                        size="large"
                        onClick={() => createContactPerson()}
                      />
                    </div>
                  </div>
                  <Stepper steps={steps} />
                  <Margin mb={10} />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Margin mb={30} />
                    <Text style={{ fontSize: "25px" }}>
                      Novo contato Pessoa Física{" "}
                    </Text>
                    <Margin mb={20} />
                    <h4>Dados básicos</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 1fr",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Input
                      size="large"
                      name="document_number"
                      label={"* CPF"}
                      placeholder="000.000.000-00"
                      value={values.document_number}
                      maxLength={14}
                      error={
                        values.document_number.length > 13 && !isValidDocument
                      }
                      message={
                        values.document_number.length > 13 && !isValidDocument
                          ? error
                          : ""
                      }
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/\D/g, "");
                        if (value.length <= 11) {
                          value = value
                            .replace(/^(\d{3})(\d)/, "$1.$2")
                            .replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3")
                            .replace(
                              /^(\d{3})\.(\d{3})\.(\d{3})(\d)/,
                              "$1.$2.$3-$4"
                            );
                        }

                        setFieldValue("document_number", value);
                      }}
                    />

                    <Input
                      size="large"
                      name="name"
                      label="* Nome Completo"
                      placeholder="Digite"
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                    />
                    <Input
                      size="large"
                      name="social_name"
                      label="Nome Social (Se aplicável)"
                      placeholder="Digite"
                      required
                      value={values.social_name}
                      onChange={(e) =>
                        setFieldValue("social_name", e.target.value)
                      }
                    />
                  </>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="marital_status"
                        label="* Estado Civil"
                        options={maritalStatusOptions}
                        placeholder="Escolha uma opção"
                        onChange={(e) =>
                          setFieldValue("marital_status", e.value)
                        }
                      />
                    </div>
                    <Input
                      size="large"
                      type="date"
                      name="birth_date"
                      label="* Data de Nascimento"
                      placeholder="dd/mm/aaaa"
                      message={
                        values.birth_date.length > 9 &&
                        !validateAge(values.birth_date)
                          ? "O contato deve ser maior de 18 anos"
                          : ""
                      }
                      error={
                        values.birth_date.length > 9 &&
                        !validateAge(values.birth_date)
                      }
                      value={values.birth_date}
                      onChange={(e) =>
                        setFieldValue("birth_date", e.target.value)
                      }
                    />
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="gender"
                        label="* Sexo"
                        placeholder="Escolha uma opção"
                        options={genderOptionsMore}
                        onChange={(e) => setFieldValue("gender", e.value)}
                      />
                    </div>
                    <Input
                      size="large"
                      name="father_name"
                      label="* Nome completo do Pai"
                      placeholder="Digite"
                      value={values.father_name}
                      onChange={(e) =>
                        setFieldValue("father_name", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="mother_name"
                      label="* Nome completo da Mãe"
                      placeholder="Digite"
                      value={values.mother_name}
                      onChange={(e) =>
                        setFieldValue("mother_name", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="nationality"
                      label="* Nacionalidade"
                      placeholder="Escolha uma opção"
                      value={values.nationality}
                      onChange={(e) =>
                        setFieldValue("nationality", e.target.value)
                      }
                    />
                    {/* <InputSelect
                      size="large"
                      kind="default"
                      name="birth_country"
                      label="País de Nascimento"
                      placeholder="Escolha uma opção"
                      onChange={(e) => setFieldValue("birth_country", e)}
                    /> */}
                  </>
                </div>
                <Margin mt={50} />
              </>
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Dados de contato</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Input
                      size="large"
                      name="contact_email"
                      label="* E-mail"
                      placeholder="contato@mail.com"
                      value={values.contact_email}
                      onChange={(e) =>
                        setFieldValue("contact_email", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      maxLength={15}
                      name="contact_phone"
                      label="* Celular"
                      placeholder="(00) 00000-0000"
                      value={values.contact_phone}
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^\d]/g, "");
                        if (value.length > 0 && value.length <= 2) {
                          value = `(${value}`;
                        } else if (value.length > 2 && value.length <= 6) {
                          value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
                        } else if (value.length > 6) {
                          value = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
                        }

                        setFieldValue("contact_phone", value);
                      }}
                    />
                  </>
                </div>
                <Margin mt={50} />
              </>
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Endereço</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <Input
                      size="large"
                      name="address_zip_code"
                      max={9}
                      maxLength={9}
                      label="* CEP"
                      placeholder="Digite"
                      value={values.address_zip_code}
                      error={errorCep && values.address_zip_code.length === 9}
                      message={
                        errorCep && values.address_zip_code.length === 9
                          ? "CEP inválido"
                          : ""
                      }
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^\d]/g, "");

                        if (value.length <= 5) {
                          value = `${value.slice(0, 5)}`;
                        } else if (value.length <= 8) {
                          value = `${value.slice(0, 5)}-${value.slice(5, 8)}`;
                        }

                        setFieldValue("address_zip_code", value);
                      }}
                    />
                    <div style={{ gridColumn: "span 2" }}>
                      <Input
                        size="large"
                        name="address_street"
                        label="* Endereço"
                        disabled={!values.address_zip_code}
                        placeholder="Digite"
                        value={values.address_street}
                        onChange={(e) =>
                          setFieldValue("address_street", e.target.value)
                        }
                      />
                    </div>
                    <Input
                      max={7}
                      maxLength={7}
                      size="large"
                      name="address_number"
                      label="* Número"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_number}
                      onChange={(e) =>
                        setFieldValue("address_number", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="address_district"
                      label="* Bairro"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_district}
                      onChange={(e) =>
                        setFieldValue("address_district", e.target.value)
                      }
                    />
                    <Input
                      size="large"
                      name="address_city"
                      label="* Cidade"
                      placeholder="Digite"
                      disabled={!values.address_zip_code}
                      value={values.address_city}
                      onChange={(e) =>
                        setFieldValue("address_city", e.target.value)
                      }
                    />
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="address_state"
                        disabled={!values.address_zip_code}
                        label="* Estado"
                        placeholder={
                          values.address_state ? values.address_state : "UF"
                        }
                        options={ufOptions}
                        onChange={(e) =>
                          setFieldValue("address_state", e.value)
                        }
                      />
                    </div>
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        disabled={!values.address_zip_code}
                        name="address_type"
                        label="* Tipo de residência"
                        placeholder="Escolha uma opção"
                        options={residenceOptions}
                        onChange={(e) => setFieldValue("address_type", e.value)}
                      />
                    </div>
                  </>
                </div>
                <Margin mt={50} />
              </>

              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Documento de identificação</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <div>
                      {" "}
                      <InputSelect
                        size="small"
                        kind="default"
                        name="identification_document_document_type"
                        label="Tipo de Documento"
                        options={documentTypes}
                        placeholder="Escolha uma opção"
                        onChange={(e) =>
                          setFieldValue(
                            "identification_document_document_type",
                            e.value
                          )
                        }
                      />
                    </div>

                    <Input
                      size="large"
                      max={11}
                      maxLength={11}
                      name="identification_document_document"
                      label="Documento de Identidade"
                      placeholder="Digite"
                      value={values.identification_document_document}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        inputValue = inputValue.replace(/[^0-9Xx]/g, "");

                        inputValue = inputValue.toUpperCase();

                        if (inputValue.includes("X")) {
                          inputValue = inputValue.slice(
                            0,
                            inputValue.indexOf("X") + 1
                          );
                        }

                        setFieldValue(
                          "identification_document_document",
                          inputValue
                        );
                      }}
                    />
                    <Input
                      size="large"
                      type="date"
                      name="identification_document_emission_date"
                      label="Data de Emissão"
                      placeholder="dd/mm/aaaa"
                      message={
                        values.identification_document_emission_date.length >
                          9 &&
                        !isDateInThePast(
                          values.identification_document_emission_date
                        )
                          ? "A data de emissão não pode ser uma data futura"
                          : ""
                      }
                      error={
                        values.identification_document_emission_date.length >
                          9 &&
                        !isDateInThePast(
                          values.identification_document_emission_date
                        )
                      }
                      value={values.identification_document_emission_date}
                      onChange={(e) =>
                        setFieldValue(
                          "identification_document_emission_date",
                          e.target.value
                        )
                      }
                    />
                    <Input
                      size="large"
                      type="date"
                      name="identification_document_expiration_date"
                      label="Data de Expiração"
                      message={
                        values.identification_document_expiration_date.length >
                          9 &&
                        isDateInThePast(
                          values.identification_document_expiration_date
                        )
                          ? "A data de expiração não pode ser uma data passada "
                          : ""
                      }
                      error={
                        values.identification_document_expiration_date.length >
                          9 &&
                        isDateInThePast(
                          values.identification_document_expiration_date
                        )
                      }
                      placeholder="dd/mm/aaaa"
                      value={values.identification_document_expiration_date}
                      onChange={(e) =>
                        setFieldValue(
                          "identification_document_expiration_date",
                          e.target.value
                        )
                      }
                    />
                    <InputSelect
                      size="small"
                      kind="default"
                      name="identification_document_issuing_state"
                      label="Estado emissor"
                      placeholder="UF"
                      options={ufOptions}
                      onChange={(e) =>
                        setFieldValue(
                          "identification_document_issuing_state",
                          e.value
                        )
                      }
                    />
                    <div>
                      {" "}
                      <div style={{ position: "relative", top: "10px" }}>
                        <span style={{ fontSize: "12px", color: "#666" }}>
                          Pessoa políticamente exposta (PEP) ?
                        </span>
                        <Margin mt={10} />
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              position: "relative",
                              left: "-5px",
                              justifyContent: "start",
                            }}
                          >
                            <Radio
                              name="identification_document_pep"
                              label={"Não"}
                              placeholder="Escolha uma opção"
                              checked={!values.identification_document_pep}
                              onClick={() =>
                                setFieldValue(
                                  "identification_document_pep",
                                  false
                                )
                              }
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              position: "relative",
                              left: "10px",
                              justifyContent: "start",
                            }}
                          >
                            <Radio
                              name="identification_document_pep"
                              label={"Sim"}
                              placeholder="Escolha uma opção"
                              checked={values.identification_document_pep}
                              onClick={() =>
                                setFieldValue(
                                  "identification_document_pep",
                                  true
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* <span style={{ fontSize: "12px", color: "#666" }}>
                          Pessoa que ocupa ou ocupou cargo público relevante, ou
                          possui relação com uma.
                        </span> */}
                      </div>
                    </div>

                    {/* <Input
                      size="large"
                      name="professional_info_department"
                      label="Departamento"
                      placeholder="Digite"
                      value={values.professional_info_department}
                      onChange={(e) =>
                        setFieldValue(
                          "professional_info_department",
                          e.target.value
                        )
                      }
                    />
                    <Input
                      size="large"
                      name="professional_info_net_salary"
                      label="Salário Líquido"
                      placeholder="R$ 0,00"
                      value={values.professional_info_net_salary}
                      onChange={(e) =>
                        setFieldValue(
                          "professional_info_net_salary",
                          e.target.value
                        )
                      }
                    />
                    <Input
                      size="large"
                      name="professional_info_additional_income"
                      label="Outras Rendas"
                      placeholder="R$ 0,00"
                      value={values.professional_info_additional_income}
                      onChange={(e) =>
                        setFieldValue(
                          "professional_info_additional_income",
                          e.target.value
                        )
                      }
                    /> */}
                  </>
                </div>
                <Margin mt={50} />
              </>
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h4>Dados profissionais</h4>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "32px",
                    rowGap: "30px",
                    marginTop: "20px",
                  }}
                >
                  <>
                    <div style={{ gridColumn: "span 2" }}>
                      {" "}
                      <InputSelect
                        size="small"
                        kind="default"
                        name="professional_info_occupation_partner_company_uuid"
                        label="Empresa onde trabalha"
                        placeholder="Escolha uma opção"
                        options={partnersCompanyOptions}
                        onChange={(e) => {
                          setFieldValue(
                            "professional_info_occupation_partner_company_uuid",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <Tooltip
                      align="top"
                      size="medium"
                      label="Este campo não é editavel"
                    >
                      <Input
                        size="large"
                        label="CNPJ da empresa"
                        readOnly={
                          !values.professional_info_occupation_partner_company_uuid
                        }
                        placeholder="Digite"
                        value={
                          partnerCompanyData?.document_number &&
                          formatCPFCNPJ(partnerCompanyData?.document_number)
                        }
                      />
                    </Tooltip>
                    <Tooltip
                      align="top"
                      size="medium"
                      label="Este campo não é editavel"
                    >
                      <Input
                        size="large"
                        label="CNAE"
                        disabled={
                          !values.professional_info_occupation_partner_company_uuid
                        }
                        placeholder="Digite"
                        value={String(
                          cnaeOptions.filter(
                            (cnae) => cnae.value === partnerCompanyData?.cnae
                          )[0]?.label ?? "Não encontrado"
                        )}
                      />
                    </Tooltip>
                    <div>
                      <InputSelect
                        size="small"
                        kind="default"
                        name="professional_info_occupation_nature"
                        label="Natureza da ocupação"
                        placeholder="Escolha uma opção"
                        options={occupationNatureOptions}
                        onChange={(e) => {
                          setFieldValue(
                            "professional_info_occupation_nature",
                            e.value
                          );
                        }}
                      />
                    </div>
                    <Input
                      max={30}
                      maxLength={30}
                      size="large"
                      name="professional_info_department"
                      label="Departamento"
                      placeholder="Digite"
                      value={values.professional_info_department}
                      onChange={(e) =>
                        setFieldValue(
                          "professional_info_department",
                          e.target.value
                        )
                      }
                    />

                    <InputCurrency
                      name="professional_info_net_salary"
                      id="professional_info_net_salary"
                      size="large"
                      label="Salário líquido"
                      max={20}
                      maxLength={20}
                      placeholder="R$"
                      value={values.professional_info_net_salary}
                      onChange={(e) =>
                        setFieldValue(
                          "professional_info_net_salary",
                          e.target.value
                        )
                      }
                    />
                    <InputCurrency
                      size="large"
                      name="professional_info_additional_income"
                      label="Outras Rendas"
                      placeholder="R$ 0,00"
                      max={20}
                      maxLength={20}
                      value={values.professional_info_additional_income}
                      onChange={(e) =>
                        setFieldValue(
                          "professional_info_additional_income",
                          e.target.value
                        )
                      }
                    />
                    <div>
                      {" "}
                      <InputSelect
                        size="small"
                        kind="default"
                        name="professional_info_occupation_partner_company_uuid"
                        label="Situação funcional"
                        placeholder="Escolha uma opção"
                        options={employmentStatusOptions}
                        onChange={(e) => {
                          setFieldValue(
                            "professional_info_employment_status",
                            e.value
                          );
                        }}
                      />
                    </div>

                    <Input
                      type="date"
                      size="large"
                      name="professional_info_hiring_date"
                      error={
                        values.professional_info_hiring_date.length > 9 &&
                        !isDateInThePast(values.professional_info_hiring_date)
                      }
                      message={
                        values.professional_info_hiring_date.length > 9 &&
                        !isDateInThePast(values.professional_info_hiring_date)
                          ? "A data de admissão não pode ser uma data futura"
                          : ""
                      }
                      label="Data de adimissão"
                      placeholder="Digite"
                      value={values.professional_info_hiring_date}
                      onChange={(e) =>
                        setFieldValue(
                          "professional_info_hiring_date",
                          e.target.value
                        )
                      }
                    />
                    <Input
                      size="large"
                      name="professional_info_employee_number"
                      label="Matrícula do funcionário"
                      max={20}
                      maxLength={20}
                      placeholder="Digite"
                      value={values.professional_info_employee_number}
                      onChange={(e) =>
                        setFieldValue(
                          "professional_info_employee_number",
                          e.target.value
                        )
                      }
                    />
                  </>
                </div>
                <Margin mt={50} />
              </>
            </>
          )}

          <Margin mt={50} />
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h4>Informações do cônjuge</h4>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: "32px",
                rowGap: "30px",
                marginTop: "20px",
              }}
            >
              <>
                <div style={{ gridColumn: "span 3" }}>
                  {" "}
                  <Input
                    size="large"
                    name="parents_name"
                    label="Nome completo"
                    placeholder="Digite"
                    value={values.parents_name}
                    onChange={(e) =>
                      setFieldValue("parents_name", e.target.value)
                    }
                  />
                </div>

                <Input
                  maxLength={14}
                  error={
                    values.parents_document_number.length > 13 &&
                    !isValidParentDocument
                  }
                  message={
                    values.parents_document_number.length > 13 &&
                    !isValidParentDocument
                      ? errorParentCPF
                      : ""
                  }
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/\D/g, "");
                    if (value.length <= 11) {
                      value = value
                        .replace(/^(\d{3})(\d)/, "$1.$2")
                        .replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3")
                        .replace(
                          /^(\d{3})\.(\d{3})\.(\d{3})(\d)/,
                          "$1.$2.$3-$4"
                        );
                    }

                    setFieldValue("parents_document_number", value);
                  }}
                  size="large"
                  name="parents_document_number"
                  label="CPF"
                  placeholder="Digite"
                  value={values.parents_document_number}
                />
                <Input
                  size="large"
                  name="parents_company_document_number"
                  label="CNPJ da empresa onde trabalha"
                  maxLength={18}
                  error={
                    values.parents_company_document_number.length > 17 &&
                    !isValidCNPJ
                  }
                  message={
                    values.parents_company_document_number.length > 17 &&
                    !isValidCNPJ
                      ? "CNPJ Inválido"
                      : ""
                  }
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d]/g, "");
                    if (value.length <= 2) {
                      value = `${value}`;
                    } else if (value.length <= 5) {
                      value = `${value.slice(0, 2)}.${value.slice(2)}`;
                    } else if (value.length <= 8) {
                      value = `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5)}`;
                    } else if (value.length <= 12) {
                      value = `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}/${value.slice(8)}`;
                    } else if (value.length <= 14) {
                      value = `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}/${value.slice(8, 12)}-${value.slice(12)}`;
                    }

                    setFieldValue("parents_company_document_number", value);
                  }}
                  placeholder="Digite"
                  value={values.parents_company_document_number}
                />
                <Input
                  size="large"
                  name="parents_occupation"
                  label="Ocupação"
                  max={30}
                  maxLength={30}
                  placeholder="Digite"
                  value={values.parents_occupation}
                  onChange={(e) =>
                    setFieldValue("parents_occupation", e.target.value)
                  }
                />
                <Input
                  size="large"
                  maxLength={15}
                  name="parents_phone"
                  label="* Celular"
                  placeholder="(00) 00000-0000"
                  value={values.parents_phone}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^\d]/g, "");
                    if (value.length > 0 && value.length <= 2) {
                      value = `(${value}`;
                    } else if (value.length > 2 && value.length <= 6) {
                      value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
                    } else if (value.length > 6) {
                      value = `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`;
                    }

                    setFieldValue("parents_phone", value);
                  }}
                />
                <Input
                  type="date"
                  size="large"
                  name="parents_birth_date"
                  label="Data de nascimento"
                  placeholder="Digite"
                  value={values.parents_birth_date}
                  message={
                    values.parents_birth_date.length > 9 &&
                    !validateAge(values.parents_birth_date)
                      ? "O cônjuge deve ser maior de 18 anos"
                      : ""
                  }
                  error={
                    values.parents_birth_date.length > 9 &&
                    !validateAge(values.parents_birth_date)
                  }
                  onChange={(e) =>
                    setFieldValue("parents_birth_date", e.target.value)
                  }
                />

                <InputCurrency
                  name="parents_net_salary"
                  id="parents_net_salary"
                  size="large"
                  label="Salário líquido"
                  max={20}
                  maxLength={20}
                  placeholder="R$"
                  value={values.parents_net_salary}
                  onChange={(e) =>
                    setFieldValue("parents_net_salary", e.target.value)
                  }
                />
                <InputCurrency
                  size="large"
                  name="parents_additional_income"
                  maxLength={20}
                  max={20}
                  label="Outras Rendas"
                  placeholder="R$ 0,00"
                  value={values.parents_additional_income}
                  onChange={(e) =>
                    setFieldValue("parents_additional_income", e.target.value)
                  }
                />
              </>
            </div>
            <Margin mt={50} />
          </>
          {/* 
          <div>
            <h4>Declaração de saúde</h4>
            <Margin mt={20} />

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignContent: "start",
                alignItems: "start",
                cursor: "pointer",
              }}
              onClick={() =>
                setFieldValue("health_declaration", !values.health_declaration)
              }
            >
              <div
                style={{
                  width: "800px",
                  display: "inline",
                }}
              >
                <div
                  style={{
                    display: "inline",
                  }}
                >
                  <Checkbox checked={values.health_declaration} />
                </div>
                <div
                  style={{
                    display: "inline",
                  }}
                >
                  <p>
                    {" "}
                    Declaro que possuo boas condições de saúde e que não
                    apresento nenhuma das doenças listadas
                  </p>{" "}
                </div>
              </div>
            </div>
          </div> */}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ~visualizar as simulações <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default NewContactPerson;
