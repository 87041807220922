import axios, { AxiosResponse } from "axios";

import AppStorage from "~/services/storage";

// export const baseURL = "https://dev.api.cred.prosperita.io";
// export const baseURL = "http://localhost:8000";

export const getAPIBaseURL = () => {
  const location = window.location.href;

  if (location.includes("localhost") || location.includes("dev")) {
    return "https://dev.api.cred.prosperita.io";
  }

  if (location.includes("stage") || location.includes("staging")) {
    return "https://dev.api.cred.prosperita.io";
  }

  return "https://api.cred.prosperita.io";
};

export const baseURL = getAPIBaseURL();

export const api = axios.create({
  baseURL,
});

type HttpBaseProps = {
  route: string;
  params?: Record<string, unknown>;
  noDefautHeaders?: boolean;
  customHeaders?: object;
  external?: boolean;
  onUploadProgress?: (_progressEvent: unknown) => void;
  token?: boolean;
};

type HttpBodyProps<IRequest = unknown> = {
  body?: IRequest;
};

type GetUrl = HttpBaseProps;

export type HttpGetProps = HttpBaseProps;
export type HttpDeleteProps = HttpBaseProps;

export type HttpPostProps<IRequest = unknown> = HttpBaseProps &
  HttpBodyProps<IRequest>;
export type HttpPutProps<IRequest = unknown> = HttpBaseProps &
  HttpBodyProps<IRequest>;
export type HttpPatchProps<IRequest = unknown> = HttpBaseProps &
  HttpBodyProps<IRequest>;

const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const getHeaders = (
  token: boolean,
  noDefaultHeaders: boolean,
  customHeaders = {}
) => {
  const baseHeaders = noDefaultHeaders ? {} : defaultHeaders;
  const headers = {
    ...baseHeaders,
    ...customHeaders,
  };

  if (token) {
    const tokenString = AppStorage.getToken();

    return {
      ...headers,
      Authorization: `Bearer ${tokenString}`,
    };
  } else {
    return headers;
  }
};

const getUrl = (props: GetUrl): string => {
  const { route, params, external = false } = props;

  const url = external ? route : `${baseURL}/${route}`;

  let urlString = url;

  if (params) {
    urlString += "?";

    const paramsArr = Object.keys(params).map((key) => {
      const paramValue = params[key];
      return `${key}=${paramValue}`;
    });

    urlString += paramsArr.join("&");
  }

  return urlString;
};

const HttpGet = async <IResponse = unknown, IRequest = unknown>(
  props: HttpGetProps
) => {
  const { token = false, noDefautHeaders, customHeaders = {} } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.get<IRequest, AxiosResponse<IResponse, IRequest>>(url, {
    headers,
  });
};

const HttpPost = async <IResponse = unknown, IRequest = unknown>(
  props: HttpPostProps<IRequest>
) => {
  const {
    body = {},
    token = false,
    noDefautHeaders,
    customHeaders = {},
  } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.post<IRequest, AxiosResponse<IResponse, IRequest>>(url, body, {
    headers,
  });
};

const HttpPut = async <IResponse = unknown, IRequest = unknown>(
  props: HttpPutProps<IRequest>
) => {
  const {
    body = {},
    token = false,
    noDefautHeaders,
    customHeaders = {},
  } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.put<IRequest, AxiosResponse<IResponse, IRequest>>(url, body, {
    headers,
  });
};

const HttpDelete = async <IResponse = unknown, IRequest = unknown>(
  props: HttpDeleteProps
) => {
  const { token = false, noDefautHeaders, customHeaders = {} } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.delete<IRequest, AxiosResponse<IResponse, IRequest>>(url, {
    headers,
  });
};

const HttpPatch = async <IResponse = unknown, IRequest = unknown>(
  props: HttpPatchProps<IRequest>
) => {
  const {
    body = {},
    token = false,
    noDefautHeaders,
    customHeaders = {},
  } = props;

  const headers = getHeaders(token, noDefautHeaders ?? false, customHeaders);

  const url = getUrl(props);

  return api.patch<IRequest, AxiosResponse<IResponse, IRequest>>(url, body, {
    headers,
  });
};

const ApiService = {
  HttpGet,
  HttpPost,
  HttpPut,
  HttpDelete,
  HttpPatch,
};

export default ApiService;
