import {
  Breadcrumb,
  Button,
  Margin,
  TableBasic,
  Tooltip,
} from "prosperita-dumbo-react";
import styled from "styled-components";

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@carbon/react";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import editIcon from "~/assets/Edit.png";

import addIcon from "~/assets/Addd.png";
import {
  formatCPFCNPJ,
  formatPhoneNumber,
  getLabelByValue,
} from "~/services/utils";
import { format } from "date-fns";

import useLoading from "~/hooks/useLoading";

import {
  getPartnerDocumentsAPI,
  getPartnersDetailsAPI,
} from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import { getBankAccountsAPI, IBankAccount } from "~/services/api/bankAccount";
import AddBankAccount from "./AddBankAccount";
import {
  bussinesSectorOptions,
  entityOptions,
  genderOptions,
  maritalStatusOptions,
  residenceOptions,
} from "~/utils/options";
import EditBankAccount from "./EditBankAccount";
import { IDocumentDataPartner } from "~/types";
import NewDocument from "../NewContact/NewDocument";

// import { getPartnerPersonAPI, getPartnersAPI } from "~/services/api/partner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
`;

const TitleContainer = styled.div`
  background-color: #f4f4f4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 32px;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  gap: 16px;
`;

const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Content = styled.div`
  width: 100%;
  padding: 24px 32px;
`;

const tabs = [
  {
    label: "Contas bancárias",
    value: "bank_accounts",
  },
  {
    label: "Documentos",
    value: "documents",
  },
];

const titlesColumnBankAccounts = ["Instituição", "Agência", "Conta", ""];
const titlesColumnDocuments = ["Tipo de documento"];

const ContactsDetails = () => {
  const { uuid } = useParams();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const {
    setPartner,
    partner,
    bankAccounts,
    setBankAccounts,
    setAddBankAccounts,
    setDocuments,
    setAddDocument,
    documents,
    setBankAccountData,
  } = usePartner();

  const listDocuments = useMemo(() => {
    return [
      { label: "Documento", value: "document" },
      { label: "Autorização", value: "authorization" },
      { label: "Carteira de Motorista", value: "drivers_license" },
      { label: "Carteira de Trabalho", value: "work_card" },
      {
        label: "Comprovante de Residência da Empresa",
        value: "residence_proof_company",
      },
      {
        label: "Comprovante de Residência dos Sócios",
        value: "residence_proof_partners",
      },
      { label: "Rascunho", value: "draft" },
      { label: "Contrato Social", value: "social_contract" },
      { label: "Número de Registro", value: "registration_number" },
      {
        label: "Documento de Identificação com Foto",
        value: "identification_document_with_photo",
      },
      { label: "Extrato Bancário", value: "bank_statement" },
      { label: "Faturamento Gerencial", value: "managerial_billing" },
      { label: "Formulário de Cadastro", value: "registration_form" },
      { label: "Outros", value: "others" },
      { label: "Selfie", value: "selfie" },
      {
        label: "Selfie com Documento de Identidade",
        value: "selfie_with_identity_card",
      },
      { label: "Última Declaração", value: "last_declaration" },
      { label: "Contrato Assinado", value: "signed_contract" },
      {
        label: "Contrato com Selo de Assinatura",
        value: "signing_stamp_contract",
      },
      { label: "Relatório de Crédito", value: "credit_report" },
      { label: "Cessão", value: "assignment" },
      { label: "Adição", value: "addition" },
      { label: "Cessão Fiduciária", value: "fiduciary_assignment" },
      { label: "P7S", value: "p7s" },
      { label: "Arquivos Compactados", value: "ziped_files" },
      { label: "CNAB", value: "cnab" },
      { label: "Nota Fiscal", value: "invoice" },
      { label: "Ata de Eleição", value: "minutes_of_election" },
      { label: "Procuração", value: "procuration" },
      { label: "Termo de Posse", value: "term_of_ownership" },
      { label: "Estatuto Social", value: "bylaws" },
      { label: "Carteira de Identidade", value: "identity_card" },
      { label: "Garantia", value: "collateral" },
      { label: "Regulamento", value: "regulation" },
      { label: "Saldo", value: "balance" },
      { label: "Balanço Patrimonial", value: "balance_sheet" },
      { label: "PLDFT", value: "pldft" },
      { label: "Imposto de Renda", value: "income_tax" },
      { label: "DRE", value: "dre" },
      { label: "Recibo", value: "receipt" },
      { label: "Cartão da Empresa", value: "company_card" },
      { label: "Relatório de Cessão", value: "assignment_report" },
      {
        label: "Registro Nacional de Estrangeiros",
        value: "national_registry_of_foreigners",
      },
      {
        label: "Documento Nacional de Identidade",
        value: "national_identity_card",
      },
      {
        label: "Carteira de Registro Regional",
        value: "regional_registration_card",
      },
      { label: "Passaporte", value: "passport" },
      { label: "Contra-cheque", value: "paycheck" },
    ];
  }, []);

  const dataTableDocuments = useMemo(() => {
    return documents?.map((document: IDocumentDataPartner) => ({
      "Tipo de documento": getLabelByValue(document.type, listDocuments),
    }));
  }, [documents, listDocuments]);

  const bankAccountsData = bankAccounts?.map((bankAccount: IBankAccount) => {
    return {
      Instituição: `${bankAccount.bank_code} - ${bankAccount.bank_name}`,
      Agência: bankAccount.agency_digit
        ? `${bankAccount.agency}-${bankAccount.agency_digit}`
        : bankAccount.agency,
      Conta: `${bankAccount.account_number}-${bankAccount.account_number_digit}`,
      actionsColumn: (
        <Tooltip align="top" size="medium" label="Editar">
          <div
            style={{
              position: "relative",
              top: "-8px",
              height: "20px",
            }}
          >
            <Button
              kind="ghost"
              size="small"
              icon={editIcon}
              label=""
              onClick={() => setBankAccountData(bankAccount)}
            />
          </div>
        </Tooltip>
      ),
    };
  });

  const fetchDocuments = useCallback(async () => {
    try {
      const { data } = await getPartnerDocumentsAPI(uuid as string);

      setDocuments(data.payload);

      setLoading(false);
    } catch (e) {
      console.log(e);

      setLoading(false);
    }
  }, [setLoading, uuid, setDocuments]);

  const fetchBankAccounts = useCallback(async () => {
    try {
      if (uuid) {
        const { data } = await getBankAccountsAPI(uuid);

        setBankAccounts(data.payload.reverse());
      } else {
        console.error("UUID is undefined");
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
    }
  }, [uuid, setBankAccounts]);

  const fetchPartnerDetails = useCallback(async () => {
    setPartner(null);
    setLoading(true);
    try {
      if (uuid) {
        const { data } = await getPartnersDetailsAPI(uuid);

        setPartner(data.payload);

        await fetchBankAccounts();

        setLoading(false);
      } else {
        console.error("UUID is undefined");
        setLoading(false);
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
      setLoading(false);
    }
  }, [uuid, setLoading, setPartner, fetchBankAccounts]);

  useEffect(() => {
    fetchDocuments();
    fetchPartnerDetails();
  }, [fetchPartnerDetails, fetchDocuments]);

  return (
    <>
      <Container>
        <TitleContainer>
          <Breadcrumb
            crumbsOptions={[
              { crumb: "Início" },
              {
                crumb: "Contatos",
                onClick: () => navigate("/app/contacts"),
              },
              {
                crumb: "Detalhes do contato",
              },
            ]}
            endHiddenIndex={5}
            startHiddenIndex={5}
            aria-label="Navegação de detalhes de operações"
          />
          <TitleBar>
            <Title style={{ fontFamily: "sans-serif", fontSize: "25px" }}>
              {partner?.name}
            </Title>
          </TitleBar>
        </TitleContainer>
        <Content>
          <h3 style={{ fontSize: "24px" }}>Dados básicos</h3>
          {/* <Margin top={10} /> */}
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "32px",
              rowGap: "30px",
              marginTop: "20px",
            }}
          >
            {[
              {
                label: partner?.document_type === "company" ? "CNPJ" : "CPF",
                value: partner?.document_number
                  ? formatCPFCNPJ(String(partner?.document_number))
                  : "-",
              },
              ...(partner?.document_type === "person"
                ? [
                    {
                      label: "Data de nascimento",
                      value: partner?.birth_date
                        ? format(new Date(partner?.birth_date), "dd/MM/yyyy")
                        : "-",
                    },
                    {
                      label: "Nacionalidade",
                      value: partner?.nationality || "-",
                    },
                    {
                      label: "Estado civil",
                      value:
                        getLabelByValue(
                          partner?.marital_status,
                          maritalStatusOptions
                        ) || "-",
                    },
                    {
                      label: "Nome da mãe",
                      value: partner?.mother_name || "-",
                    },
                    {
                      label: "Nome do pai",
                      value: partner?.father_name || "-",
                    },
                    {
                      label: "Gênero",
                      value:
                        getLabelByValue(partner?.gender, genderOptions) || "-",
                    },
                  ]
                : [
                    {
                      label: "Data de fundação",
                      value: partner?.foundation_date
                        ? format(
                            new Date(partner?.foundation_date),
                            "dd/MM/yyyy"
                          )
                        : "-",
                    },
                    {
                      label: "CNAE",
                      value: partner?.cnae || "-",
                    },
                    {
                      label: "Natureza jurídica",
                      value:
                        (partner?.legal_nature &&
                          getLabelByValue(
                            partner?.legal_nature,
                            entityOptions
                          )) ||
                        "-",
                    },
                    {
                      label: "Inscrição estadual",
                      value: partner?.state_registration || "-",
                    },

                    {
                      label: "Setor de atuação",
                      value:
                        (partner?.business_sector &&
                          getLabelByValue(
                            partner?.business_sector,
                            bussinesSectorOptions
                          )) ||
                        "-",
                    },
                    {
                      label: "Site",
                      value: partner?.website || "-",
                    },
                  ]),

              {
                label: "Email",
                value: `${
                  partner?.contact?.email ? partner.contact.email : "-"
                }`,
              },

              {
                label: "Telefone",
                value: ` ${partner?.contact?.phone ? formatPhoneNumber(partner.contact.phone) : "-"}`,
              },
            ].map((item, index) => (
              <div key={index}>
                <p style={{ color: "#525252" }}>{item.label}</p>
                <h4>{item.value}</h4>
              </div>
            ))}
          </div>
          <Margin top={40} />
          {partner?.address && <h3 style={{ fontSize: "24px" }}>Endereço</h3>}
          {/* <Margin top={10} /> */}
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "32px",
              rowGap: "30px",
              marginTop: "20px",
            }}
          >
            {[
              ...(partner?.address
                ? [
                    {
                      label: "Logradouro",
                      value: partner?.address?.street || "-",
                    },
                    {
                      label: "Número",
                      value: partner?.address?.number || "-",
                    },
                    {
                      label: "Complemento",
                      value: partner?.address?.additional_info || "-",
                    },
                    {
                      label: "Bairro",
                      value: partner?.address?.district || "-",
                    },
                    {
                      label: "Cidade",
                      value: partner?.address?.city || "-",
                    },
                    {
                      label: "Estado",
                      value: partner?.address?.state || "-",
                    },
                    {
                      label: "CEP",
                      value: partner?.address?.zip_code || "-",
                    },
                    {
                      label: "Tipo de endereço",
                      value:
                        getLabelByValue(
                          partner?.address.type,
                          residenceOptions
                        ) || "-",
                    },
                  ]
                : []),
            ].map((item, index) => (
              <div key={index}>
                <p style={{ color: "#525252" }}>{item.label}</p>
                <h4>{item.value}</h4>
              </div>
            ))}
          </div>

          <Margin top={50} />
          <Tabs>
            <TabList aria-label="List of tabs">
              {tabs.map((tab, index) => (
                <Tab key={index}>{tab.label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel key={"index"}>
                <div style={{ marginTop: "20px" }}>
                  <TableBasic
                    search
                    sizeSearch="large"
                    pageSizes={[5, 10, 20, 50, 100]}
                    columnTitles={titlesColumnBankAccounts}
                    rowData={bankAccountsData}
                    width="1000px"
                    buttons={[
                      {
                        kind: "primary",
                        position: "right",
                        size: "large",
                        label: "Adicionar conta bancária",
                        icon: addIcon,
                        onClick: () => setAddBankAccounts(true),
                      },
                    ]}
                  />
                </div>
              </TabPanel>
              <TabPanel key={"index"}>
                <div style={{ marginTop: "20px" }}>
                  <TableBasic
                    search
                    sizeSearch="large"
                    pageSizes={[10, 20, 50, 100]}
                    columnTitles={titlesColumnDocuments}
                    rowData={dataTableDocuments}
                    width="1000px"
                    buttons={[
                      {
                        kind: "primary",
                        position: "right",
                        size: "large",
                        label: "Adicionar Documento",
                        icon: addIcon,
                        onClick: () => setAddDocument(true),
                      },
                    ]}
                  />
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Content>
        <div style={{ zIndex: "999" }}>
          <AddBankAccount />
          <EditBankAccount />
          <NewDocument />
        </div>
      </Container>
    </>
  );
};

export default ContactsDetails;
