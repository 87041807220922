import {
  Breadcrumb,
  Button,
  InputSelect,
  Margin,
  Modal,
  Table,
  TableBasic,
  Tag,
  Tooltip,
} from "prosperita-dumbo-react";
import styled from "styled-components";
import ViewIcon from "~/assets/View.png";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@carbon/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOperationDetailAPI,
  getTemplateProduct,
  Installment,
} from "~/services/api/operations";
import addIcon from "~/assets/Addd.png";
import trashCanIcon from "~/assets/Trash-can.png";
import viewIcon from "~/assets/View.png";
import SendIcon from "~/assets/License--draft.png";
import {
  formatNumberToCurrencyWithoutSimbol,
  getLabelByValue,
} from "~/services/utils";
import { format, formatDate } from "date-fns";
import useOperation from "~/hooks/useOperation";
import useLoading from "~/hooks/useLoading";
import NewDocument from "./NewDocument";
import NewInvolved from "./NewInvolved";
import { PropsTag } from "prosperita-dumbo-react/dist/types/Components/Tag";
import { InvolvedData } from "~/types";
import DeleteInvolved from "./DeleteInvolved";
import DeleteDocument from "./DeleteDocument";
import { useFormik } from "formik";
import {
  ICreateDocumentBody,
  postCreateDocument,
  postSendDocumentToSignature,
} from "~/services/api/signature";

import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
`;

const TitleContainer = styled.div`
  background-color: #f4f4f4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 32px;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  gap: 16px;
`;

const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

// const SubTitle = styled.h2`
//   font-family: IBM Plex Sans;
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 16px;
//   letter-spacing: 0.3199999928474426px;
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;

// `;

const Content = styled.div`
  width: 100%;
  padding: 24px 32px;
`;

const listDocuments = {
  document: "Documento",
  authorization: "Autorização",
  drivers_license: "Carteira de Motorista",
  work_card: "Carteira de Trabalho",
  residence_proof_company: "Comprovante de Residência da Empresa",
  residence_proof_partners: "Comprovante de Residência dos Sócios",
  draft: "Rascunho",
  social_contract: "Contrato Social",
  registration_number: "Número de Registro",
  identification_document_with_photo: "Documento de Identificação com Foto",
  bank_statement: "Extrato Bancário",
  managerial_billing: "Faturamento Gerencial",
  registration_form: "Formulário de Cadastro",
  others: "Outros",
  selfie: "Selfie",
  selfie_with_identity_card: "Selfie com Documento de Identidade",
  last_declaration: "Última Declaração",
  signed_contract: "Contrato Assinado",
  signing_stamp_contract: "Contrato com Selo de Assinatura",
  credit_report: "Relatório de Crédito",
  assignment: "Cessão",
  addition: "Adição",
  fiduciary_assignment: "Cessão Fiduciária",
  p7s: "P7S",
  ziped_files: "Arquivos Compactados",
  cnab: "CNAB",
  invoice: "Nota Fiscal",
  minutes_of_election: "Ata de Eleição",
  procuration: "Procuração",
  term_of_ownership: "Termo de Posse",
  bylaws: "Estatuto Social",
  identity_card: "Carteira de Identidade",
  collateral: "Garantia",
  regulation: "Regulamento",
  balance: "Saldo",
  balance_sheet: "Balanço Patrimonial",
  pldft: "PLDFT",
  income_tax: "Imposto de Renda",
  dre: "DRE",
  receipt: "Recibo",
  company_card: "Cartão da Empresa",
  assignment_report: "Relatório de Cessão",
  national_registry_of_foreigners: "Registro Nacional de Estrangeiros",
  national_identity_card: "Documento Nacional de Identidade",
  regional_registration_card: "Carteira de Registro Regional",
  passport: "Passaporte",
  paycheck: "Contra-cheque",
};

const titlesColumnInstallmentsCCB = [
  "Parcela",
  "Vencimento",
  "Saldo devedor",
  "Amortização",
  "Juros",
  "Pagamento",
  // "",
];

const titlesColumnInstallmentsCommercialNote = [
  "Parcela",
  "Data",
  "Valor nominal",
  "Juros + indexador",
  "Amortização",
  "Preço unitário",
  // "",
];

const titlesColumnSubscriptions = [
  "Status",
  "Subscritor",
  "Data da subscrição",
  "Valor subscrito",
  "Quantidade adiquirida",
  "Status da Assinatura",
  "",
];

const statusOperationsSign: { [key: string]: string } = {
  processing: "Não enviado",
  denied: "-",
  opened: "Não enviado",
  expired: "-",
  signing: "Enviado para assinatura",
  confirmed: "Assinado",
  finished: "Assinado",
  canceled: "C-",
  error: "-",
};

const statusOperations: { [key: string]: string } = {
  processing: "Processando",
  denied: "Negado",
  opened: "Aberto",
  expired: "Removido",
  signing: "Assinando",
  confirmed: "Confirmado",
  finished: "Finalizado",
  canceled: "Cancelado",
  error: "Erro",
};

const statusOperationsTagColor: { [key: string]: PropsTag["type"] } = {
  processing: "gray",
  denied: "red",
  opened: "gray",
  expired: "red",
  signing: "blue",
  confirmed: "green",
  finished: "green",
  canceled: "red",
  error: "red",
};

const typeBiometricDict = {
  email: "Email",
  biometric: "Biometria",
  icp_brasil: "ICP Brasil",
};

const typeSendDict = {
  email: "Email",
  sms: "SMS",
  whatsapp: "Whatsapp",
};

const titlesColumnDocuments = ["Relacionado à", "Tipo de documento", ""];

const titlesColumnInvolveds = [
  "Nome",
  "Relacionado à",
  "Tipo de relação",
  "Assinante",
  "% da assinatura",
  "Forma de envio",
  "Forma de autenticação",
  "",
];

const indexesDict: { [key: string]: string } = {
  selic: "Selic",
  "igp-m": "IGP-M",
  ipca: "IPCA",
  cdi: "CDI",
};

type Values = {
  signature_template: string;
};

const initialValues: Values = {
  signature_template: "",
};

const OperationsDetails = () => {
  const { uuid } = useParams();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  const [sendToSign, setSendToSign] = useState<boolean>(false);
  const [subscriberUuid, setSubscriberUuid] = useState<string>("");

  const {
    operationDetails,
    setOperationDetails,
    setAddDocument,
    setAddInvolved,
    setDeleteDocumentData,
    setDeleteInvolvedData,
  } = useOperation();

  const [templates, setTemplates] =
    useState<{ name: string; uuid: string; status?: string }[]>();

  const tabs = [
    {
      label:
        operationDetails?.operation?.product?.product_type === "commercial_note"
          ? "Parcelas (Simulação)"
          : "Parcelas",
      value: "parcelas",
    },
    ...(operationDetails?.operation?.product?.product_type === "commercial_note"
      ? [
          {
            label: "Subscritores",
            value: "subscritores",
          },
        ]
      : []),
    {
      label: "Envolvidos",
      value: "envolvidos",
    },
    {
      label: "Documentos",
      value: "documentos",
    },
  ];

  const { setFieldValue, values } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const dataTableInstallmentsCCB =
    operationDetails?.operation.installments?.map(
      (installmentDetail: Installment) => {
        return {
          number: installmentDetail.number,
          dua_date: formatDate(installmentDetail.due_date, "dd/MM/yyyy"),
          outstanding_amount: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentDetail.outstanding_amount)}`,
          amortization_amount: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentDetail.amortization_amount)}`,
          interest: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentDetail.interest)}`,
          pmt: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentDetail.pmt)}`,
          // actionsColumn: (
          //   <div style={{ display: "flex", height: "20px" }}>
          //     <div style={{ position: "relative", top: "-8px" }}>
          //       <Tooltip align="top" size="medium" label="Editar parcelas">
          //         <Button kind="ghost" size="small" icon={editIcon} label="" />
          //       </Tooltip>
          //     </div>
          //   </div>
          // ),
        };
      }
    );

  const dataTableInstallmentsCommercialNote =
    operationDetails?.operation.installments?.map(
      (installmentDetail: Installment) => {
        return {
          number: installmentDetail.number,
          dua_date: formatDate(installmentDetail.due_date, "dd/MM/yyyy"),
          outstanding_amount: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentDetail.outstanding_amount)}`,
          interest: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentDetail.interest)}`,
          amortization_amount: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentDetail.amortization_amount)}`,
          pmt: `R$ ${formatNumberToCurrencyWithoutSimbol(installmentDetail.pmt)}`,
          // actionsColumn: (
          //   <div style={{ display: "flex", height: "20px" }}>
          //     <div style={{ position: "relative", top: "-8px" }}>
          //       <Tooltip align="top" size="medium" label="Editar parcelas">
          //         <Button kind="ghost" size="small" icon={editIcon} label="" />
          //       </Tooltip>
          //     </div>
          //   </div>
          // ),
        };
      }
    );

  const listRelationship = useMemo(() => {
    return [
      { label: "Correntista", value: "account_holder" },
      { label: "Administrador", value: "administrator" },
      { label: "Procurador", value: "attorney" },
      { label: "Comodatário", value: "borrower" },
      { label: "Responsável Solidário", value: "co_responsible" },
      { label: "Caucionante", value: "collateral_provider" },
      { label: "Anuente", value: "consenting" },
      { label: "Interveniente Anuente", value: "consenting_intervenor" },
      { label: "Credor", value: "creditor" },
      { label: "Devedor", value: "debtor" },
      { label: "Endossante", value: "endorser" },
      { label: "Endossatário", value: "endorsee" },
      { label: "Avalista / Fiador", value: "guarantor" },
      { label: "Cônjuge do Fiador", value: "guarantor_spouse" },
      { label: "Intermediário", value: "intermediary" },
      { label: "Interveniente Garantidor", value: "intervening_guarantor" },
      { label: "Emitente", value: "issuer" },
      { label: "Devedor Solidário", value: "joint_debtor" },
      { label: "Responsável Legal", value: "legal_guardian" },
      { label: "Representante Legal", value: "legal_representative" },
      { label: "Mutuante", value: "lender" },
      { label: "Sócio", value: "partner" },
      { label: "Presidente", value: "president" },
      { label: "Cedente", value: "transferor" },
      { label: "Cessionário", value: "transferee" },
      { label: "Testemunha", value: "witness" },
    ];
  }, []);

  const calculateTotalUnitsAcquired = (
    subscribers: { unit_amount_acquired: number }[] | undefined
  ): number => {
    if (!subscribers || subscribers.length === 0) return 0;

    return subscribers.reduce(
      (total, subscriber) => total + subscriber.unit_amount_acquired,
      0
    );
  };

  const totalUnitsAcquired = useMemo(
    () =>
      calculateTotalUnitsAcquired(
        operationDetails?.commercial_note?.subscribers
      ),
    [operationDetails?.commercial_note?.subscribers]
  );

  const dataTableSubscribers = operationDetails?.commercial_note?.subscribers
    ?.reverse()
    ?.map((subscriber) => {
      return {
        Status: (
          <div style={{ maxWidth: "150px" }}>
            <Tag
              type={statusOperationsTagColor[subscriber.status]}
              size="medium"
              position="center"
            >
              <div style={{ padding: "2px" }}>
                {statusOperations[subscriber.status]}
              </div>
            </Tag>
          </div>
        ),
        subscritor: subscriber.name,
        "Data da subscrição": formatDate(
          subscriber?.subscription_date,
          "dd/MM/yyyy"
        ),
        "Valor subscrito": `R$ ${formatNumberToCurrencyWithoutSimbol(subscriber.unit_amount_acquired)}`,
        "Quantidade adiquirida": subscriber.quantity_of_units_acquired,
        "Status da Assinatura": (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            {subscriber?.status === "opened" &&
            operationDetails.operation.product.product_type !== "ccb" ? (
              <Tooltip align="top" size="large" label="Enviar para assinatura">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    operationDetails.operation.product.uuid &&
                    fetchProductTemplate(subscriber.uuid)
                  }
                >
                  <p>{statusOperationsSign[subscriber.status]}</p>

                  <img
                    src={SendIcon}
                    alt="Enviar ícone"
                    style={{
                      filter: "brightness(50%) saturate(60%)",
                      width: "15px",
                      height: "15px",
                      position: "relative",
                      top: "6px",
                    }}
                  />
                </div>
              </Tooltip>
            ) : (
              <p>
                {subscriber.status
                  ? statusOperationsSign[subscriber.status]
                  : "-"}
              </p>
            )}
          </div>
        ),

        "": (
          <div style={{ display: "flex", height: "10px" }}>
            <Tooltip align="top" size="medium" label="Visualizar detalhes">
              <Button
                kind="ghost"
                size="small"
                icon={viewIcon}
                onClick={() =>
                  navigate(
                    `/app/credit/operations/details/${uuid}/subscriber/${subscriber.uuid}/`
                  )
                }
              />
            </Tooltip>
          </div>
        ),
      };
    });

  const dataTableDocuments = operationDetails?.documents
    ?.reverse()
    ?.map((documentsTable) => {
      return {
        "Relacionado à": documentsTable.related_to,
        "Tipo de documento": listDocuments[documentsTable.type],

        "": (
          <div style={{ display: "flex" }}>
            <Tooltip size="medium" align="top" label="Visualizar">
              <Button
                kind="ghost"
                size="small"
                icon={ViewIcon}
                onClick={() => window.open(documentsTable?.file, "_blank")}
              />
            </Tooltip>{" "}
            {(operationDetails?.status !== "processing" &&
              operationDetails?.status !== "opened") ||
            operationDetails.operation.product.product_type === "ccb" ? (
              <Tooltip
                size="medium"
                align="top"
                label="Não é possível remover documentos"
              >
                <Button
                  kind="ghost"
                  size="small"
                  disabled={true}
                  icon={trashCanIcon}
                />
              </Tooltip>
            ) : (
              <Tooltip size="medium" align="top" label="Remover">
                <Button
                  kind="ghost"
                  size="small"
                  icon={trashCanIcon}
                  onClick={() =>
                    setDeleteDocumentData({
                      uuid: String(documentsTable?.uuid),
                    })
                  }
                />
              </Tooltip>
            )}
          </div>
        ),
      };
    });

  const dataTableInvolveds = operationDetails?.involveds
    ?.reverse()
    ?.map((involved) => {
      return {
        Nome: involved.name,
        "Relacionado à": involved.related_to,
        "Tipo de relação": `${getLabelByValue(involved.relationship, listRelationship)}`,
        Assinante: involved.is_signer ? "Sim" : "Não",
        "% da assinatura": involved.is_signer
          ? `${involved.sign_weight} %`
          : "-",
        "Forma de envio": typeSendDict[involved.send_type],
        "Tipo de autenticação": typeBiometricDict[involved.auth_type],
        "": (
          <div style={{ display: "flex", gap: "8px" }}>
            <>
              {/* <Button
            kind="ghost"
            size="small"
            icon={editIcon}
            onClick={() => setEditInvolvedData(involved)}
          /> */}
              {(operationDetails?.status !== "processing" &&
                operationDetails?.status !== "opened") ||
              operationDetails.operation.product.product_type === "ccb" ? (
                <Tooltip
                  size="medium"
                  align="top"
                  label="Não é possível remover envolvidos"
                >
                  <Button
                    kind="ghost"
                    size="small"
                    icon={trashCanIcon}
                    disabled
                  />
                </Tooltip>
              ) : (
                <Tooltip size="medium" align="top" label="Remover">
                  <Button
                    kind="ghost"
                    size="small"
                    icon={trashCanIcon}
                    onClick={() =>
                      setDeleteInvolvedData(involved as InvolvedData)
                    }
                  />
                </Tooltip>
              )}
            </>
          </div>
        ),
      };
    });

  const calculateTotalPayments = () => {
    return operationDetails?.operation.installments
      .reduce((total, installment) => {
        return total + installment.pmt;
      }, 0)
      .toFixed(2);
  };

  const templateOptions = useMemo(() => {
    return (
      templates &&
      templates
        .filter((template) => template.status === "active")
        .map((template) => ({
          value: template.uuid,
          label: template.name,
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
        )
    );
  }, [templates]);

  const fetchProductTemplate = useCallback(
    async (subscriber_uuid: string): Promise<void> => {
      setLoading(true);
      try {
        const { data } = await getTemplateProduct({
          product_uuid: operationDetails?.operation.product.uuid as string,
        });

        setSubscriberUuid(subscriber_uuid);

        setSendToSign(true);
        setTemplates(data.payload);

        setLoading(false);
      } catch (e) {
        console.error("Erro ao buscar operações:", e);
        setLoading(false);
        setSubscriberUuid("");
      }
    },
    [setSendToSign, setLoading, operationDetails]
  );

  const fetchOperationDetails = useCallback(async () => {
    setLoading(true);
    setOperationDetails(null);
    try {
      if (uuid) {
        const { data } = await getOperationDetailAPI(uuid);

        setOperationDetails(data.payload);
        setLoading(false);
      } else {
        console.error("UUID is undefined");
        setLoading(false);
      }
    } catch (err) {
      console.error("Erro ao buscar detalhes da operação:", err);
      setLoading(false);
    }
  }, [uuid, setOperationDetails, setLoading]);

  const sendCreateDocumentAndSendToSignature =
    useCallback(async (): Promise<void> => {
      setLoading(true);
      const payload: ICreateDocumentBody = {
        subscriber_uuid: subscriberUuid,
        template_uuid: values.signature_template,
      };

      try {
        const { data } = await postCreateDocument(payload);
        console.log(data);
        await postSendDocumentToSignature(data.payload.uuid);
        setLoading(false);
        toast.success("Subscritor enviado para assinatura com sucesso!");
        setSendToSign(false);
        fetchOperationDetails();
        setSubscriberUuid("");
      } catch (e) {
        console.error("Erro ao buscar operações:", e);
        setLoading(false);
        toast.error("Não foi possível enviar o subscritor para assinatura!");
      }
    }, [
      fetchOperationDetails,
      values.signature_template,
      setLoading,
      subscriberUuid,
    ]);

  useEffect(() => {
    fetchOperationDetails();
  }, [fetchOperationDetails]);

  return (
    <>
      <Container>
        <TitleContainer>
          <Breadcrumb
            crumbsOptions={[
              { crumb: "Início" },
              { crumb: "Crédito" },
              {
                crumb: "Operações",
                onClick: () => navigate("/app/credit/operations"),
              },
              { crumb: "Detalhes" },
            ]}
            endHiddenIndex={5}
            startHiddenIndex={5}
            aria-label="Navegação de detalhes de operações"
          />
          <TitleBar>
            <Title style={{ fontFamily: "sans-serif", fontSize: "25px" }}>
              Operação de {operationDetails?.operation.product.name} - ID:{" "}
              {operationDetails?.uuid}
            </Title>
          </TitleBar>
        </TitleContainer>
        <Content style={{ padding: "50px" }}>
          <h4>Detalhes da operação</h4>
          <Margin top={30} />
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "32px",
              rowGap: "30px",
              marginTop: "20px",
            }}
          >
            {[
              {
                label: "Status",
                value: (
                  <div style={{ maxWidth: "150px" }}>
                    <Tag
                      type={
                        statusOperationsTagColor[
                          String(operationDetails?.status)
                        ]
                      }
                      size="medium"
                      position="center"
                    >
                      <div style={{ padding: "2px" }}>
                        {statusOperations[String(operationDetails?.status)]}
                      </div>
                    </Tag>
                  </div>
                ),
              },
              {
                label: "Data de emissão",
                value: operationDetails?.operation?.operation_date
                  ? format(
                      operationDetails?.operation?.operation_date,
                      "dd/MM/yyyy"
                    )
                  : "-",
              },
              // {
              //   label: "Data de liberação",
              //   value: operationDetails?.operation?.release_date
              //     ? format(
              //         operationDetails?.operation?.release_date,
              //         "dd/MM/yyyy"
              //       )
              //     : "-",
              // },
              {
                label: "Dia de vencimento",
                value: operationDetails?.operation?.due_day ?? "-",
              },
              {
                label: "Prazo",
                value: operationDetails?.operation?.duration
                  ? `${operationDetails?.operation?.duration} Meses`
                  : "-",
              },
              ...(operationDetails?.operation.product.product_type === "ccb"
                ? [
                    {
                      label: "IOF",
                      value: operationDetails?.operation?.taxes_values.iof_value
                        ? `R$ ${formatNumberToCurrencyWithoutSimbol(operationDetails?.operation?.taxes_values.iof_value.toFixed(2))}`
                        : "R$ 0,00",
                    },
                    {
                      label: "Valor do contrato",
                      value: operationDetails?.operation?.payable_amount
                        ? `R$ ${formatNumberToCurrencyWithoutSimbol(operationDetails?.operation?.payable_amount)}`
                        : "-",
                    },
                    {
                      label: "Valor líquido",
                      value: operationDetails?.operation?.net_amount
                        ? `R$ ${formatNumberToCurrencyWithoutSimbol(operationDetails?.operation?.net_amount)}`
                        : "-",
                    },
                  ]
                : []),

              // {
              //   label: "Quantidade de parcelas",
              //   value:
              //     operationDetails?.operation
              //       ?.number_of_installments ?? "-",
              // },

              {
                label: "Custo da emissão",
                value: operationDetails?.operation?.taxes_values?.emission_cost
                  ? `R$ ${formatNumberToCurrencyWithoutSimbol(
                      operationDetails?.operation?.taxes_values.emission_cost
                    )}`
                  : "R$ 0,00",
              },
              {
                label: "Indexador",
                value: operationDetails?.operation?.taxes_settings?.indexer
                  ? indexesDict[
                      operationDetails?.operation?.taxes_settings?.indexer
                    ]
                  : "Sem indexador pós-fixado",
              },
              {
                label: "Taxa A.M.",
                value: operationDetails?.operation?.taxes_settings
                  ?.interest_rate_monthly
                  ? `${operationDetails?.operation?.taxes_settings.interest_rate_monthly.toFixed(
                      4
                    )} %`
                  : "0.0000 %",
              },
              {
                label: "Taxa A.A.",
                value: operationDetails?.operation?.taxes_settings
                  ?.interest_rate_annually
                  ? `${operationDetails?.operation?.taxes_settings.interest_rate_annually.toFixed(
                      4
                    )} %`
                  : "0.0000 %",
              },

              ...(operationDetails?.operation.product.product_type === "ccb"
                ? [
                    {
                      label: "CET A.M.",
                      value: operationDetails?.operation?.taxes_settings
                        ?.cet_monthly
                        ? `${operationDetails?.operation?.taxes_settings.cet_monthly.toFixed(
                            4
                          )} %`
                        : "0.0000 %",
                    },
                    {
                      label: "CET A.A.",
                      value: operationDetails?.operation?.taxes_settings
                        ?.cet_annually
                        ? `${operationDetails?.operation?.taxes_settings.cet_annually.toFixed(
                            4
                          )} %`
                        : "-",
                    },
                  ]
                : []),
            ].map((item, index) => (
              <div key={index}>
                <p style={{ color: "#525252" }}>{item.label}</p>
                <h4>{item.value}</h4>
              </div>
            ))}
          </div>
          <Margin top={50} />

          <Tabs>
            <TabList aria-label="List of tabs">
              {tabs.map((tab, index) => (
                <Tab key={index}>{tab.label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <div style={{ marginLeft: "-20px", marginTop: "20px" }}>
                  {operationDetails?.operation?.product?.product_type ===
                  "commercial_note" ? (
                    <Table
                      columnTitles={titlesColumnInstallmentsCommercialNote}
                      rowData={dataTableInstallmentsCommercialNote || []}
                      width="1000px"
                      radius
                      // buttons={[
                      //   {
                      //     kind: "primary",
                      //     position: "right",
                      //     label: "Adicionar parcelas",
                      //     icon: addIcon,
                      //   },
                      // ]}
                    />
                  ) : (
                    <Table
                      columnTitles={titlesColumnInstallmentsCCB}
                      rowData={dataTableInstallmentsCCB || []}
                      width="1000px"
                      radius
                      // buttons={[
                      //   {
                      //     kind: "primary",
                      //     position: "right",
                      //     label: "Adicionar parcelas",
                      //     icon: addIcon,
                      //   },
                      // ]}
                      footer={{
                        ht: "50px",
                        bg: "",
                        element: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              padding: "8px",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                fontFamily: "sans-serif",
                                position: "relative",
                                left: "-15px",
                              }}
                            >
                              Total: R${" "}
                              {formatNumberToCurrencyWithoutSimbol(
                                calculateTotalPayments() as string
                              )}
                            </p>{" "}
                          </div>
                        ),
                      }}
                    />
                  )}
                </div>
              </TabPanel>

              {operationDetails?.operation?.product?.product_type ===
              "commercial_note" ? (
                <TabPanel>
                  {operationDetails?.status !== "confirmed" && (
                    <p
                      style={{
                        marginTop: "20px",
                        fontSize: "20px",
                        color: "red",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Atenção! Para adicionar um subscritor, todos os documentos
                      da operação desta nota comercial devem estar assinados e o
                      status da operação deve ser "Confirmado".
                    </p>
                  )}
                  <div style={{ marginLeft: "-20px", marginTop: "20px" }}>
                    <Table
                      columnTitles={titlesColumnSubscriptions}
                      rowData={dataTableSubscribers || []}
                      width="1000px"
                      sizeSearch="large"
                      radius
                      search
                      buttons={[
                        {
                          kind: "primary",
                          position: "right",

                          label: "Adicionar subscritor",
                          disabled: operationDetails?.status !== "confirmed",
                          // tooltip: {
                          //   align: "top",
                          //   size: "medium",
                          //   label: "opa",
                          //   width: "1000px",
                          // },
                          size: "large",
                          icon: addIcon,
                          onClick: () =>
                            navigate(
                              `/app/credit/operations/details/${uuid}/subscriber/new/basic-data/`
                            ),
                        },
                      ]}
                      footer={{
                        ht: "50px",
                        bg: "",
                        element: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              padding: "8px",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                fontFamily: "sans-serif",
                                position: "relative",
                                left: "-15px",
                              }}
                            >
                              Total: R${" "}
                              {formatNumberToCurrencyWithoutSimbol(
                                totalUnitsAcquired
                              )}
                            </p>{" "}
                          </div>
                        ),
                      }}
                    />
                  </div>
                </TabPanel>
              ) : (
                <TabPanel>
                  <div style={{ marginLeft: "-20px", marginTop: "20px" }}>
                    <TableBasic
                      sizeSearch="large"
                      pageSizes={[10, 5, 20, 50, 100]}
                      columnTitles={titlesColumnInvolveds}
                      rowData={dataTableInvolveds || []}
                      width="1000px"
                      search
                      buttons={[
                        {
                          kind: "primary",
                          position: "right",
                          disabled:
                            (operationDetails?.status !== "processing" &&
                              operationDetails?.status !== "opened") ||
                            operationDetails.operation.product.product_type ===
                              "ccb",
                          size: "large",

                          label: "Adicionar envolvido",
                          icon: addIcon,
                          onClick: () => setAddInvolved(true),
                        },
                      ]}
                    />
                  </div>
                </TabPanel>
              )}

              {operationDetails?.operation?.product?.product_type ===
              "commercial_note" ? (
                <TabPanel>
                  <div style={{ marginLeft: "-20px", marginTop: "20px" }}>
                    <TableBasic
                      sizeSearch="large"
                      pageSizes={[10, 5, 20, 50, 100]}
                      search
                      columnTitles={titlesColumnInvolveds}
                      rowData={dataTableInvolveds || []}
                      width="1000px"
                      buttons={[
                        {
                          kind: "primary",
                          position: "right",
                          label: "Adicionar envolvido",
                          size: "large",
                          icon: addIcon,
                          disabled:
                            operationDetails?.status !== "processing" &&
                            operationDetails?.status !== "opened",
                          onClick: () => setAddInvolved(true),
                        },
                      ]}
                    />
                  </div>
                </TabPanel>
              ) : (
                <TabPanel>
                  <div style={{ marginLeft: "-20px", marginTop: "20px" }}>
                    <TableBasic
                      sizeSearch="large"
                      pageSizes={[10, 5, 20, 50, 100]}
                      search
                      columnTitles={titlesColumnDocuments}
                      rowData={dataTableDocuments ?? []}
                      buttons={[
                        {
                          kind: "primary",
                          position: "right",
                          label: "Adicionar documento",
                          disabled:
                            (operationDetails?.status !== "processing" &&
                              operationDetails?.status !== "opened") ||
                            operationDetails.operation.product.product_type ===
                              "ccb",
                          size: "large",
                          onClick: () => setAddDocument(true),
                          icon: addIcon,
                        },
                      ]}
                    />
                  </div>
                </TabPanel>
              )}

              {operationDetails?.operation?.product?.product_type ===
              "commercial_note" ? (
                <TabPanel>
                  <div style={{ marginLeft: "-20px", marginTop: "20px" }}>
                    <TableBasic
                      sizeSearch="large"
                      search
                      pageSizes={[10, 5, 20, 50, 100]}
                      columnTitles={titlesColumnDocuments}
                      rowData={dataTableDocuments ?? []}
                      width="1000px"
                      buttons={[
                        {
                          kind: "primary",
                          position: "right",
                          size: "large",
                          disabled:
                            operationDetails?.status !== "processing" &&
                            operationDetails?.status !== "opened",
                          label: "Adicionar documento",
                          onClick: () => setAddDocument(true),
                          icon: addIcon,
                        },
                      ]}
                    />
                  </div>
                </TabPanel>
              ) : (
                <></>
              )}
            </TabPanels>
          </Tabs>
        </Content>
        <div style={{ zIndex: 999 }}>
          <NewDocument />
          <NewInvolved />
          <DeleteInvolved />
          <DeleteDocument />
        </div>
        <div style={{ zIndex: "999" }}>
          {" "}
          <Modal
            radius
            height={"462px"}
            width="760px"
            background="#f4f4f4"
            open={sendToSign}
            onClose={() => {
              setSubscriberUuid("");
              setSendToSign(false);
            }}
            label={<h4>Enviar para assinatura</h4>}
            buttons={[
              {
                kind: "ghost",
                label: "Cancelar",
                onClick: () => {
                  setSendToSign(false);
                  setSubscriberUuid("");
                },
              },
              {
                kind: "primary",
                label: "Enviar",
                disabled: !values.signature_template,
                onClick: () => {
                  sendCreateDocumentAndSendToSignature();
                },
              },
            ]}
          >
            <div style={{ padding: "30px" }}>
              <h3>Escolha o template</h3>
              <Margin top={30} />
              <InputSelect
                name="signature_template"
                id="signature_template"
                kind="default"
                size="large"
                label="Template de assinatura"
                options={templateOptions}
                placeholder="Selecione uma opção"
                onChange={(e) => handleSelect("signature_template", e)}
              />
            </div>
          </Modal>
        </div>
      </Container>
    </>
  );
};

export default OperationsDetails;
