import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Input,
  InputCurrency,
  InputSelect,
  Modal,
  Table,
  Tile,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import ActivityIcon from "~/assets/Activity.png";
import ActivityBlueIcon from "~/assets/Activity_blue.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EditIcon from "~/assets/Edit.png";

// import openOperationIcon from "~/assets/side-panel--open.png";

import {
  getCreditProductAPI,
  getCreditProductDetailsAPI,
  ICreditProducts,
  IProductDetails,
} from "~/services/api/creditProduct";
import useOperation from "~/hooks/useOperation";

import {
  // getSimulateOperationAPI,
  Installment,
  ISimulateOperationBody,
  ISimulateOperationInstallmentPatch,
  patchSimulateOperationAPI,
  postSimulateOperationAPI,
} from "~/services/api/operations";
import {
  convertStringToNumber,
  convertStringCurrencyToNumber,
  formatNumberToCurrencyWithoutSimbol,
} from "~/services/utils";
import AppStorage from "~/services/storage";
import useLoading from "~/hooks/useLoading";
import { format, isBefore, isPast, isToday, parseISO } from "date-fns";
import { toast } from "react-toastify";
// import MoreIcon from "~/assets/Addd.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

type Values = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: string;
  duration: string;
  calculation_base: string;
  operation_date: string;
  release_date: string;
  due_date: string;
  interest_rate_monthly: string;
  interest_periodicity: string;
  amortization_grace_period: string;
  interest_grace_period: string;
  commission: string;
  indexer: string;
  indexer_rate: string;
  amortization_amount: string | number;
  due_date_installment: string;
  interest: string | number;
  unit_amount: string;
  number: number;
  outstanding_amount: string | number;
  pmt: string | number;
};

const initialValues: Values = {
  nickname: "",
  product_uuid: "",
  calculation_type: "",
  requested_amount: "10.000,00",
  duration: "",
  calculation_base: "",
  operation_date: "",
  release_date: "",
  due_date: "",
  interest_rate_monthly: "",
  interest_periodicity: "",
  amortization_grace_period: "",
  interest_grace_period: "",
  commission: "",
  unit_amount: "1.000,00",
  indexer: "",
  indexer_rate: "",
  amortization_amount: 0,
  due_date_installment: "",
  interest: "",
  number: 0,
  outstanding_amount: "",
  pmt: "",
};

const SimulationCommercialNote = () => {
  const navigate = useNavigate();

  const {
    setPayloadSimulateOperation,
    setSimulateResult,
    simulateResult,
    setCreditProducts,
    creditProducts,
    editInstallment,
    setEditInstallment,
    installmentData,
    setInstallmentData,
  } = useOperation();

  const [previousInstallment, setPreviousInstallment] =
    useState<Installment | null>(null);

  const { setLoading } = useLoading();

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const titlesColumnInstallments = [
    "Parcela",
    "Data",
    "Valor nominal",
    "Juros + indexador",
    "Amortização",
    "Preço unitário",
    "",
  ];

  const dataTableInstallments = simulateResult?.installments.map(
    (installment, index) => {
      const isFirstInstallment = index === 0;
      const previousInstallmentData =
        index > 0 ? simulateResult?.installments[index - 1] : null;
      return {
        Parcela: installment.number,
        Data: format(parseISO(installment.due_date), "dd/MM/yyyy"),
        "Valor nominal": `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.outstanding_amount
        )}`,
        "Juros + indexador": `R$ ${formatNumberToCurrencyWithoutSimbol(installment.interest)}`,
        Amortização: `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.amortization_amount
        )}`,
        "Preço unitário": `R$ ${formatNumberToCurrencyWithoutSimbol(installment.pmt)}`,
        "": isFirstInstallment ? null : (
          <div
            style={{
              display: "flex",
              position: "relative",
              left: "-60px",
              height: "8px",
            }}
          >
            <Tooltip
              align={
                simulateResult.product.amortization_type === "sac"
                  ? "top-right"
                  : "top-right"
              }
              size="large"
              label={
                simulateResult.product.amortization_type !== "sac"
                  ? "Não é possivel editar parcelas quando a amortização não é do tipo SAC"
                  : "Editar parcela"
              }
            >
              <Button
                kind="ghost"
                size="small"
                icon={EditIcon}
                // disabled={simulateResult.product.amortization_type !== "sac"}
                onClick={() => {
                  setPreviousInstallment(previousInstallmentData);
                  setInstallmentData(installment);
                  setEditInstallment(true);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    }
  );

  const canDoSimulations = true;

  const indexesOptions = useMemo(() => {
    return [
      { index: "SELIC", value: "selic" },
      { index: "IGPM", value: "igpm" },
      { index: "IPCA", value: "ipca" },
      { index: "CDI", value: "cdi" },
      { index: "Nenhum", value: "no_one" },
    ].map((index) => ({
      value: index.value,
      label: index.index,
    }));
  }, []);

  const calculationBaseOptions = useMemo(() => {
    return [
      { value: "net_value", label: "Valor Líquido" },
      { value: "gross_value", label: "Valor Bruto" },
    ].sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, []);

  const periodicityOptions = useMemo(() => {
    return [
      { label: "Diário", value: "daily" },
      { label: "Mensal", value: "monthly" },
      { label: "Anual", value: "annually" },
    ]
      .map((periodicity) => ({
        value: periodicity.value,
        label: periodicity.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const creditProductsOptions = useMemo(() => {
    return creditProducts
      .filter(
        (creditProduct: ICreditProducts) =>
          creditProduct?.product_type === "commercial_note"
      )
      .map((creditProduct: ICreditProducts) => ({
        value: creditProduct.uuid,
        label: creditProduct.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [creditProducts]);

  const calculationTypeOptions = useMemo(() => {
    return [
      { value: "360", label: "360 Dias" },
      { value: "365", label: "365 Dias" },
      { value: "252", label: "252 Dias" },
    ]
      .map((type) => ({
        value: type.value,
        label: type.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const indexesDict: { [key: string]: string } = {
    igpm: "IGPM",
    selic: "SELIC",
    ipca: "IPCA",
    cdi: "CDI",
    no_one: "Nenhum",
  };

  const payloadSimulateOperation: ISimulateOperationBody = useMemo(() => {
    const taxesSettings: ISimulateOperationBody["taxes_settings"] = {
      interest_rate: values.interest_rate_monthly?.includes(",")
        ? convertStringToNumber(values.interest_rate_monthly)
        : Number(values.interest_rate_monthly),
      interest_periodicity: values.interest_periodicity,
      amortization_grace_period: Number(values.amortization_grace_period),
      interest_grace_period: Number(values.interest_grace_period),
      commission: values.commission?.includes(",")
        ? convertStringToNumber(values.commission)
        : Number(values.commission),
      ...(values.indexer !== "no_one"
        ? {
            indexer: values.indexer,
            indexer_rate: values.indexer_rate?.includes(",")
              ? convertStringToNumber(values.indexer_rate)
              : Number(values.indexer_rate),
          }
        : {}),
    };

    return {
      nickname: values.nickname,
      product_uuid: values.product_uuid,
      calculation_type: values.calculation_type,
      requested_amount: convertStringToNumber(values.requested_amount),
      unit_amount: convertStringToNumber(values.unit_amount),
      duration: Number(values.duration),
      calculation_base: values.calculation_base,
      release_date: values.release_date,
      due_day: values.due_date,
      taxes_settings: taxesSettings,
    };
  }, [values]);

  const sendNewSimulate = useCallback(async (): Promise<void> => {
    setLoading(true);
    setSimulateResult(null);
    try {
      const payloadSimulateOperation = AppStorage.get(
        "@:simulate_operation"
      ) as string;
      const payloadSimulateOperationParsed = JSON.parse(
        payloadSimulateOperation
      );

      const { data } = await postSimulateOperationAPI(
        payloadSimulateOperationParsed
      );
      setSimulateResult(data.payload);

      toast.success("Simulação gerada com sucesso!");

      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível gerar a simulação !");

      setLoading(false);
    }
  }, [setSimulateResult, setLoading]);

  const fetchCreditProducts = useCallback(async () => {
    try {
      const { data } = await getCreditProductAPI();
      setCreditProducts(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setCreditProducts]);

  const sendUpdateInstallment = useCallback(async () => {
    setLoading(true);
    if (simulateResult && installmentData) {
      const payload: ISimulateOperationInstallmentPatch = {
        installment_number: installmentData.number,
      };

      const amortizationAmount = convertStringCurrencyToNumber(
        values.amortization_amount as string
      );
      if (amortizationAmount !== installmentData.amortization_amount) {
        payload.amortization_amount = amortizationAmount;
      }

      const interestAmount = convertStringCurrencyToNumber(
        values.interest as string
      );
      if (interestAmount !== installmentData.interest) {
        payload.interest = interestAmount;
      }

      if (values.due_date_installment !== installmentData.due_date) {
        payload.due_date = values.due_date_installment;
      }

      if (payload.amortization_amount || payload.interest || payload.due_date) {
        try {
          const { data } = await patchSimulateOperationAPI(
            simulateResult.uuid,
            payload
          );

          setLoading(false);
          setEditInstallment(false);
          setSimulateResult(data.payload);
          toast.success("Parcela atualizada com sucesso!");
        } catch (e) {
          console.log(e);
          setLoading(false);
          toast.error("Não foi possível alterar a parcela!");
        }
      } else {
        setLoading(false);
        toast.info("Nenhuma alteração foi feita.");
      }
    }
  }, [
    simulateResult,
    installmentData,
    setLoading,
    values,
    setEditInstallment,
    setSimulateResult,
  ]);

  // const fetchSimulation = async () => {
  //   setLoading(true);

  //   try {
  //     if (simulateResult?.uuid) {
  //       const { data } = await getSimulateOperationAPI(simulateResult?.uuid);

  //       const simulation = data.payload;

  //       localStorage.setItem(
  //         "@:simulation_uuid",
  //         JSON.stringify({ simulation_uuid: simulateResult?.uuid })
  //       );

  //       const payload = {
  //         nickname: simulation.nickname,
  //         product_uuid: simulation.product.uuid,
  //         calculation_type: simulation.calculation_type,
  //         requested_amount: String(simulation.requested_amount),
  //         duration: simulation.duration,
  //         calculation_base: simulation.calculation_base,
  //         operation_date: simulation.operation_date,
  //         release_date: simulation.release_date,
  //         due_day: simulation.due_day,
  //         taxes_settings: {
  //           interest_rate: simulation.taxes_settings.interest_rate_monthly,
  //           interest_periodicity:
  //             simulation.taxes_settings.interest_periodicity,
  //           amortization_grace_period:
  //             simulation.taxes_settings.amortization_grace_period,
  //           interest_grace_period:
  //             simulation.taxes_settings.interest_grace_period,
  //           commission: simulation.taxes_settings.commission,
  //           indexer: simulation.taxes_settings.indexer,
  //           indexer_rate: simulation.taxes_settings.indexer_rate,
  //         },
  //       };

  //       localStorage.setItem("@:simulate_operation", JSON.stringify(payload));

  //       navigate("/app/credit/operations/new/comercial-note/product-info");
  //       setSimulateResult(null);

  //       setLoading(false);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     toast.error("Não foi possível recuperar a simulação !");
  //     setLoading(false);
  //   }
  // };

  const printRef = useRef<HTMLDivElement>(null);

  const isNotDivisible = useMemo(() => {
    const requested = convertStringCurrencyToNumber(values.requested_amount);
    const unit = convertStringCurrencyToNumber(values.unit_amount);

    if (unit === 0) return true;
    return !Number.isInteger(requested / unit);
  }, [values.requested_amount, values.unit_amount]);

  const [errorDueDateInstallment, setErrorDueDateInstallment] =
    useState<boolean>(false);
  const [
    errorAmortizationInstallmentAmount,
    setErrorAmortizationInstallmentAmount,
  ] = useState<boolean>(false);
  const [errorInterestAmountInstallment, setErrorInterestAmountInstallment] =
    useState<boolean>(false);

  useEffect(() => {
    const currentDueDate = parseISO(values.due_date_installment);

    if (previousInstallment) {
      const previousInstallmentDueDate = parseISO(previousInstallment.due_date);

      if (isBefore(currentDueDate, previousInstallmentDueDate)) {
        setErrorDueDateInstallment(true);
      } else {
        setErrorDueDateInstallment(false);
      }

      const currentAmortizationAmount = convertStringCurrencyToNumber(
        values.amortization_amount
      );
      const previousInstallmentOutstandingAmount = Number(
        previousInstallment?.outstanding_amount || 0
      );

      if (
        currentAmortizationAmount < 0 ||
        currentAmortizationAmount > previousInstallmentOutstandingAmount
      ) {
        setErrorAmortizationInstallmentAmount(true);
      } else {
        setErrorAmortizationInstallmentAmount(false);
      }

      const currentInterestAmortizationAmount = convertStringCurrencyToNumber(
        values.interest || 0
      );
      const currentInterestAmount = Number(installmentData?.interest || 0);

      if (
        currentInterestAmortizationAmount < 0 ||
        currentInterestAmortizationAmount > currentInterestAmount
      ) {
        setErrorInterestAmountInstallment(true);
      } else {
        setErrorInterestAmountInstallment(false);
      }
    }
  }, [values, previousInstallment, installmentData]);

  useEffect(() => {
    fetchCreditProducts();
  }, [fetchCreditProducts]);

  useEffect(() => {
    if (installmentData) {
      setFieldValue("outstanding_amount", installmentData.outstanding_amount);
      setFieldValue("due_date_installment", installmentData.due_date);
      setFieldValue("pmt", String(installmentData.pmt));
      setFieldValue(
        "interest",
        formatNumberToCurrencyWithoutSimbol(installmentData.interest)
      );
      setFieldValue(
        "amortization_amount",
        formatNumberToCurrencyWithoutSimbol(installmentData.amortization_amount)
      );
    }
  }, [installmentData, setFieldValue]);

  useEffect(() => {
    setPayloadSimulateOperation(payloadSimulateOperation);
    localStorage.setItem(
      "@:simulate_operation",
      JSON.stringify(payloadSimulateOperation)
    );
  }, [payloadSimulateOperation, setPayloadSimulateOperation]);

  const [productDetails, setProductDetails] = useState<IProductDetails>();

  const fetchProductDetails = useCallback(async (uuid: string) => {
    try {
      const { data } = await getCreditProductDetailsAPI(uuid);
      setProductDetails(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (values.product_uuid) {
      fetchProductDetails(values.product_uuid);
    }
  }, [values.product_uuid, fetchProductDetails]);

  const [errorInterestRate, setErrorInterestRate] = useState<boolean>(false);
  const [errorAmortizationPeriod, setErrorAmortizationPeriod] =
    useState<boolean>(false);
  const [errorCommission, setErrorCommission] = useState<boolean>(false);
  const [errorPrincipalAmount, setErrorPrincipalAmount] =
    useState<boolean>(false);

  const [errorDuration, setErrorDuration] = useState<boolean>(false);

  const [errorReleaseDate, setErrorReleaseDate] = useState<boolean>(false);

  useEffect(() => {
    const interestRate = values.interest_rate_monthly?.includes(",")
      ? convertStringToNumber(values.interest_rate_monthly)
      : Number(values.interest_rate_monthly);

    const commissionRate = values.commission?.includes(",")
      ? convertStringToNumber(values.commission)
      : Number(values.commission);

    const parsedReleaseDate = parseISO(values.release_date);

    if (productDetails) {
      if (
        interestRate < productDetails?.min_interest_rate ||
        interestRate > productDetails?.max_interest_rate
      ) {
        setErrorInterestRate(true);
      } else {
        setErrorInterestRate(false);
      }

      if (
        Number(values.amortization_grace_period) <
          productDetails?.min_amortization_period ||
        Number(values.amortization_grace_period) >
          productDetails?.max_amortization_period
      ) {
        setErrorAmortizationPeriod(true);
      } else {
        setErrorAmortizationPeriod(false);
      }

      if (
        convertStringToNumber(values.requested_amount) >
          productDetails.max_principal_amount ||
        convertStringToNumber(values.requested_amount) <
          productDetails.min_principal_amount
      ) {
        setErrorPrincipalAmount(true);
      } else {
        setErrorPrincipalAmount(false);
      }
    }

    if (Number(values.duration) < 1) {
      setErrorDuration(true);
    } else {
      setErrorDuration(false);
    }
    if (commissionRate < 0 || commissionRate > 100) {
      setErrorCommission(true);
    } else {
      setErrorCommission(false);
    }

    if (isToday(parsedReleaseDate) || isPast(parsedReleaseDate)) {
      setErrorReleaseDate(true);
    } else {
      setErrorReleaseDate(false);
    }
  }, [
    values.interest_rate_monthly,
    values.amortization_grace_period,
    values.commission,
    productDetails,
    values.requested_amount,
    values.duration,
    values.release_date,
  ]);

  useEffect(() => {
    if (productDetails) {
      setFieldValue(
        "interest_rate_monthly",
        productDetails?.min_interest_rate !== undefined &&
          String(productDetails?.min_interest_rate)
      );

      setFieldValue(
        "amortization_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );

      setFieldValue(
        "interest_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );
    }
    setFieldValue("duration", "1");
    setFieldValue("commission", "0");
  }, [productDetails, setFieldValue]);

  useEffect(() => {
    setSimulateResult(null);
  }, [setSimulateResult]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          { crumb: "Início", onClick: () => navigate("/app") },
          { crumb: "Crédito" },
          {
            crumb: "Simulações",
            onClick: () => navigate("/app/credit/simulations"),
          },
          { crumb: "Nova simulação (Nota Comercial)" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        ></div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Margin mb={30} />
                  <Text style={{ fontSize: "25px" }}>
                    Simulação Nota Comercial{" "}
                    {simulateResult?.uuid && `- ID: ${simulateResult.uuid}`}
                  </Text>
                  <Margin mb={20} />
                  <h4>Parâmetros da operação</h4>
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  {/* <Button
                    kind="tertiary"
                    label="Imprimir"
                    size="large"
                    icon={PrinterIcon}
                  /> */}
                  {/* 
                  <Button
                    kind={"tertiary"}
                    label="Imprimir"
                    size="large"
                    // onClick={() => reactToPrintFn()}
                  /> */}
                  <Button
                    kind={simulateResult?.nickname ? "tertiary" : "primary"}
                    label="Simular"
                    size="large"
                    icon={
                      simulateResult?.nickname ? ActivityBlueIcon : ActivityIcon
                    }
                    onClick={() => sendNewSimulate()}
                    disabled={
                      !values.release_date ||
                      isNotDivisible ||
                      convertStringCurrencyToNumber(values.unit_amount) < 1 ||
                      convertStringCurrencyToNumber(values.unit_amount) >
                        10000 ||
                      convertStringToNumber(values.requested_amount) <=
                        convertStringCurrencyToNumber(values.unit_amount) ||
                      !values.amortization_grace_period ||
                      !values.calculation_base ||
                      !values.calculation_type ||
                      !values.due_date ||
                      !values.commission ||
                      !values.duration ||
                      !values.indexer ||
                      Number(values.indexer_rate) < 1 ||
                      !values.interest_grace_period ||
                      !values.interest_periodicity ||
                      !values.interest_rate_monthly ||
                      !values.nickname ||
                      errorAmortizationPeriod ||
                      errorCommission ||
                      errorDuration ||
                      errorInterestRate ||
                      errorPrincipalAmount ||
                      errorReleaseDate
                    }
                  />

                  {simulateResult?.nickname && (
                    <>
                      {/* <Button
                        kind={"primary"}
                        label="Abrir operação"
                        size="large"
                        icon={openOperationIcon}
                        onClick={() => fetchSimulation()}
                      /> */}
                    </>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gap: "32px",
                  rowGap: "30px",
                  marginTop: "20px",
                }}
              >
                <div>
                  {" "}
                  <Input
                    name="nickname"
                    id="nickname"
                    value={values.nickname}
                    size="large"
                    label="Nome (Apelido)"
                    placeholder="Digite"
                    onChange={(e) => setFieldValue("nickname", e.target.value)}
                  />
                </div>
                <div>
                  {" "}
                  <InputSelect
                    name="product_uuid"
                    kind="default"
                    size="small"
                    label="Produto"
                    options={creditProductsOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("product_uuid", e)}
                  />
                </div>
                <div>
                  <InputSelect
                    name="calculation_type"
                    kind="default"
                    size="small"
                    label="Tipo de cálculo"
                    options={calculationTypeOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("calculation_type", e)}
                    error={
                      values.calculation_type !== "252" &&
                      (values.indexer === "cdi" || values.indexer === "selic")
                    }
                    message={
                      values.indexer === "cdi" &&
                      values.calculation_type !== "252"
                        ? "Para o indexador pós-fixado 'CDI' mude o tipo de calculo para 252 dias "
                        : values.indexer === "selic" &&
                            values.calculation_type !== "252"
                          ? "Para o indexador pós-fixado 'SELIC' mude o tipo de calculo para 252 dias "
                          : ""
                    }
                  />
                </div>
                <div>
                  <InputCurrency
                    name="requested_amount"
                    id="requested_amount"
                    size="large"
                    label="Valor solicitado (R$)"
                    max={24}
                    maxLength={24}
                    placeholder="R$"
                    error={
                      errorPrincipalAmount ||
                      isNotDivisible ||
                      convertStringToNumber(values.requested_amount) <=
                        convertStringCurrencyToNumber(values.unit_amount)
                    }
                    message={
                      productDetails && errorPrincipalAmount
                        ? ` Digite um valor entre R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.min_principal_amount)} e R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.max_principal_amount)}`
                        : convertStringToNumber(values.requested_amount) <=
                            convertStringCurrencyToNumber(values.unit_amount)
                          ? "O valor solicitado deve ser maior do que o valor unitário"
                          : isNotDivisible &&
                              convertStringToNumber(values.requested_amount) >
                                convertStringCurrencyToNumber(
                                  values.unit_amount
                                )
                            ? "O valor solicitado precisa ser divisível pelo valor unitário"
                            : ""
                    }
                    value={values.requested_amount}
                    onChange={(e) =>
                      setFieldValue("requested_amount", e.target.value)
                    }
                  />
                </div>
                <div>
                  <InputCurrency
                    name="unit_amount"
                    id="unit_amount"
                    max={24}
                    maxLength={24}
                    size="large"
                    label="Valor unitário (R$)"
                    placeholder="R$"
                    error={
                      convertStringCurrencyToNumber(values.unit_amount) < 1 ||
                      convertStringCurrencyToNumber(values.unit_amount) > 10000
                    }
                    message={
                      convertStringCurrencyToNumber(values.unit_amount) < 1 ||
                      convertStringCurrencyToNumber(values.unit_amount) > 10000
                        ? ` Digite um valor entre R$ 1,00 e R$ 10.000,00`
                        : ""
                    }
                    value={values.unit_amount}
                    onChange={(e) =>
                      setFieldValue("unit_amount", e.target.value)
                    }
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="duration"
                    id="duration"
                    maxLength={2}
                    max={2}
                    error={errorDuration}
                    size="large"
                    label="Duração (Em meses)"
                    value={values.duration}
                    placeholder="Meses"
                    message={
                      errorDuration ? "Digite um valor maior que 0" : "Em meses"
                    }
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9]/g, "");
                      setFieldValue("duration", value);
                    }}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Tooltip
                    align="top"
                    size="medium"
                    label="Data simulada da primeira subscrição"
                  >
                    {" "}
                    <Input
                      type="date"
                      name="release_date"
                      size="large"
                      label="Data de lançamento"
                      placeholder="Digite"
                      error={errorReleaseDate}
                      message={
                        errorReleaseDate
                          ? "A data de lançamento precisa ser igual ou posterior a data atual"
                          : ""
                      }
                      value={values.release_date}
                      onChange={(e) =>
                        setFieldValue("release_date", e.target.value)
                      }
                    />
                  </Tooltip>
                </div>
                <div>
                  <Input
                    name="due_date"
                    size="large"
                    label="Dia de vencimento"
                    placeholder="Digite"
                    value={values.due_date}
                    onChange={(e) => {
                      let inputValue = e.target.value;
                      inputValue = inputValue.replace(/\D/g, "");
                      if (inputValue !== "") {
                        const numericValue = parseInt(inputValue, 10);
                        if (numericValue > 30) {
                          inputValue = "30";
                        } else if (numericValue < 1) {
                          inputValue = "1";
                        }
                      }
                      setFieldValue("due_date", inputValue);
                    }}
                  />
                </div>

                <div style={{ position: "relative", top: "3px" }}>
                  <Input
                    name="interest_rate_monthly"
                    id="interest_rate_monthly"
                    maxLength={6}
                    max={6}
                    size="large"
                    label="Taxa de juros (%)"
                    placeholder="%"
                    message={
                      errorInterestRate
                        ? `Informe um valor entre ${productDetails?.min_interest_rate}% e ${productDetails?.max_interest_rate}%`
                        : ""
                    }
                    error={errorInterestRate}
                    value={values.interest_rate_monthly}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        return;
                      }

                      setFieldValue("interest_rate_monthly", value);
                    }}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <InputSelect
                    name="interest_periodicity"
                    id="interest_periodicity"
                    kind="default"
                    size="small"
                    label="Periodicidade dos juros"
                    options={periodicityOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("interest_periodicity", e)}
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="amortization_grace_period"
                    id="amortization_grace_period"
                    maxLength={2}
                    max={2}
                    size="large"
                    label="Carência de amortização (Em meses)"
                    placeholder="Meses"
                    message={
                      values.amortization_grace_period &&
                      values.duration &&
                      Number(values.amortization_grace_period) >=
                        Number(values.duration)
                        ? "A Carência de amortização não pode ser maior ou igual ao valor da Duração"
                        : errorAmortizationPeriod
                          ? ` Informe um valor entre ${productDetails?.min_amortization_period}  e ${productDetails?.max_amortization_period}`
                          : "Em meses"
                    }
                    error={
                      values.amortization_grace_period &&
                      values.duration &&
                      Number(values.amortization_grace_period) >=
                        Number(values.duration)
                        ? true
                        : errorAmortizationPeriod
                          ? true
                          : false
                    }
                    value={values.amortization_grace_period}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9]/g, "");
                      setFieldValue("amortization_grace_period", value);
                    }}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="interest_grace_period"
                    id="interest_grace_period"
                    maxLength={2}
                    max={2}
                    size="large"
                    label="Carência de juros (Em meses)"
                    placeholder="Meses"
                    value={values.interest_grace_period}
                    message={
                      values.interest_grace_period &&
                      values.duration &&
                      Number(values.interest_grace_period) >=
                        Number(values.duration)
                        ? "O valor da Carência de juros não pode ser maior ou igual ao valor da Duração"
                        : values.interest_grace_period &&
                            values.amortization_grace_period &&
                            Number(values.interest_grace_period) >
                              Number(values.amortization_grace_period)
                          ? "O valor da Carência de juros não pode ser maior do que o valor da Carência de amortização"
                          : "Em meses"
                    }
                    error={
                      values.interest_grace_period &&
                      values.duration &&
                      Number(values.interest_grace_period) >=
                        Number(values.duration)
                        ? true
                        : values.interest_grace_period &&
                            values.amortization_grace_period &&
                            Number(values.interest_grace_period) >
                              Number(values.amortization_grace_period)
                          ? true
                          : false
                    }
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9]/g, "");
                      setFieldValue("interest_grace_period", value);
                    }}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <InputSelect
                    name="calculation_base"
                    kind="default"
                    size="small"
                    label="Base de cálculo"
                    options={calculationBaseOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("calculation_base", e)}
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="commission"
                    id="commission"
                    size="large"
                    error={errorCommission || values.commission === ""}
                    message={
                      errorCommission || values.commission === ""
                        ? "Informe um valor entre 0 e 100"
                        : ""
                    }
                    maxLength={6}
                    max={6}
                    label="Correspondente bancário (%)"
                    placeholder="%"
                    value={values.commission}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        return;
                      }

                      setFieldValue("commission", value);
                    }}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <InputSelect
                    name="indexer"
                    kind="default"
                    size="small"
                    label="Indexador pós-fixado"
                    options={indexesOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("indexer", e)}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="indexer_rate"
                    id="indexer_rate"
                    size="large"
                    disabled={!values.indexer || values.indexer === "no_one"}
                    maxLength={6}
                    max={6}
                    value={values.indexer_rate}
                    label="Percentual indexador (%)"
                    placeholder="%"
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        return;
                      }

                      setFieldValue("indexer_rate", value);
                    }}
                    error={
                      values.indexer !== "" && Number(values.indexer_rate) < 1
                    }
                    message={
                      values.indexer !== "" && Number(values.indexer_rate) < 1
                        ? "Digite um valor maior que 0"
                        : ""
                    }
                  />
                </div>
              </div>
              <Margin mt={50} />

              {simulateResult?.calculation_type && (
                <div ref={printRef}>
                  {" "}
                  <h4>Resultados da operação</h4>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "32px",
                      rowGap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Data de emissão
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.operation_date
                            ? format(
                                simulateResult?.operation_date,
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Dia de vencimento
                        </h4>
                        <Margin mt={17} />
                        <p>{simulateResult?.due_day}</p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Prazo
                        </h4>
                        <Margin mt={17} />
                        <p>{simulateResult?.duration} Meses</p>
                      </div>
                    </Tile>

                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Valor do contrato
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.requested_amount ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>

                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Custo da emissão
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.taxes_values?.emission_cost ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Indexador
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings?.indexer
                            ? indexesDict[
                                simulateResult?.taxes_settings?.indexer
                              ]
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Taxa A.M.
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings.interest_rate_monthly?.toFixed(
                            4
                          )}{" "}
                          %
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Taxa A.A.
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings.interest_rate_annually?.toFixed(
                            4
                          )}{" "}
                          %
                        </p>
                      </div>
                    </Tile>
                  </div>
                </div>
              )}
            </>
          )}

          <Margin mt={50} />
          {simulateResult?.calculation_type && (
            <div ref={printRef}>
              {" "}
              <Table
                columnTitles={titlesColumnInstallments}
                rowData={dataTableInstallments ?? []}
                width="1000px"
                radius
                // buttons={[
                //   {
                //     kind: "primary",
                //     position: "right",
                //     label: "Adicionar parcelas",
                //     icon: MoreIcon,
                //   },
                // ]}
              />
            </div>
          )}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para visualizar as simulações <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
      {/* 
      <LateralModal
        toggleModal={() => null}
        open
        title="Novo"
        header
      ></LateralModal> */}

      <Modal
        height="600px"
        width="755px"
        background="#f7f4f4"
        onClose={() => setEditInstallment(false)}
        label={
          <div>
            <h4>
              {" "}
              Simulação Nota Comercial{" "}
              {simulateResult?.uuid ? ` - ID: ${simulateResult.uuid}` : ""}
            </h4>
            <h4>Parcela {installmentData?.number}</h4>
          </div>
        }
        buttons={[
          {
            kind: "ghost",
            label: "Cancelar",
            onClick: () => setEditInstallment(false),
          },
          {
            kind: "primary",
            label: "Salvar",
            disabled:
              errorAmortizationInstallmentAmount ||
              errorDueDateInstallment ||
              errorInterestAmountInstallment,
            onClick: () => {
              sendUpdateInstallment();
            },
          },
        ]}
        open={editInstallment}
        radius
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
            padding: "20px",
          }}
        >
          <InputCurrency
            label="Valor para amortização"
            name="amortization_amount"
            id="amortization_amount"
            size="medium"
            error={errorAmortizationInstallmentAmount}
            message={
              errorAmortizationInstallmentAmount
                ? `O valor para amortização não pode ser superior à R$ ${previousInstallment ? formatNumberToCurrencyWithoutSimbol(previousInstallment?.outstanding_amount) : ""}`
                : ""
            }
            onChange={(e) =>
              setFieldValue("amortization_amount", e.target.value)
            }
            value={values.amortization_amount}
            disabled={
              Number(installmentData?.number) + 1 ===
              simulateResult?.installments?.length
            }
          />
          <InputCurrency
            name="interest"
            id="interest"
            label="Juros + indexador"
            onChange={(e) => setFieldValue("interest", e.target.value)}
            value={values.interest}
            error={errorInterestAmountInstallment}
            message={
              errorInterestAmountInstallment
                ? `O valor do juros não pode ser superior à R$ ${installmentData?.interest ? formatNumberToCurrencyWithoutSimbol(installmentData?.interest) : ""}`
                : ""
            }
            disabled={
              Number(installmentData?.number) + 1 ===
              simulateResult?.installments?.length
            }
          />

          <Input
            name="due_date_installment"
            id="due_date_installment"
            size="large"
            type="date"
            error={errorDueDateInstallment}
            message={
              errorDueDateInstallment
                ? `A data de vencimento não pode ser anterior à ${previousInstallment?.due_date ? format(parseISO(String(previousInstallment?.due_date)), "dd/MM/yyyy") : ""}`
                : ""
            }
            label="Data de vencimento"
            onChange={(e) => {
              setFieldValue("due_date_installment", e.target.value);
            }}
            value={values.due_date_installment}
          />

          <Input
            name="outstanding_amount"
            id="outstanding_amount"
            size="large"
            label="Saldo devedor"
            readOnly
            placeholder={`R$ ${formatNumberToCurrencyWithoutSimbol(
              values.outstanding_amount
            )}`}
            onChange={(e) =>
              setFieldValue("outstanding_amount", e.target.value)
            }
          />
        </div>
      </Modal>
    </Container>
  );
};

export default SimulationCommercialNote;
