import ApiService from "../api";

export type ICreditProducts = {
  uuid: string;
  name: string;
  product_type: string;
};

export type IProductDetails = {
  uuid: string;
  name: string;
  amortization_type: string;
  product_type: string;
  warranty_type: string;
  default_iof_additional: number;
  default_iof_daily: number;
  min_interest_rate: number;
  max_interest_rate: number;
  max_interest_period: number;
  min_principal_amount: number;
  max_principal_amount: number;
  min_amortization_period: number;
  max_amortization_period: number;
  min_emission_cost: number;
  max_emission_cost: number;
  default_emission_cost: number;
  time_limit_to_sign: number;
  time_limit_to_approve: number;
  default_bank_comission: number;
  owner_uuid: string;
  external_label_name: string;
  raw: {
    gateway: string;
    amortization_type: string;
    external_product_uuid: string;
  };
};

export const getCreditProductAPI = () => {
  return ApiService.HttpGet<IResponse<ICreditProducts[]>>({
    route: "operation/credit-product/",
    token: true,
  });
};

export const getCreditProductDetailsAPI = (uuid: string) => {
  return ApiService.HttpGet<IResponse<IProductDetails>>({
    route: `operation/credit-product/detail/${uuid}/`,
    token: true,
  });
};
