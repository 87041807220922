import ApiService from "../api";

type ICreateDocument = {
  uuid: string;
  status: string;
  filename: string;
  updated_at: string;
};

export type ICreateDocumentBody = {
  asset_uuid?: string;
  subscriber_uuid?: string;
  template_uuid: string;
  filename?: string;
};

export const postCreateDocument = (data: ICreateDocumentBody) => {
  return ApiService.HttpPost<IResponse<ICreateDocument>>({
    route: "signature/document/",
    token: true,
    body: data,
  });
};

export const postSendDocumentToSignature = (
  uuid: string,
  signature_platform?: string
) => {
  return ApiService.HttpPost({
    route: `signature/send/`,
    token: true,
    body: {
      signature_platform: signature_platform ?? "clicksign",
      document_uuid: uuid,
    },
  });
};
