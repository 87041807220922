import { useFormik } from "formik";
import {
  Button,
  Checkbox,
  Input,
  InputCurrency,
  InputSelect,
  Modal,
} from "prosperita-dumbo-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOperation from "~/hooks/useOperation";
import {
  getCreditProductAPI,
  getCreditProductDetailsAPI,
  ICreditProducts,
  IProductDetails,
} from "~/services/api/creditProduct";
import {
  getSimulateOperationAPI,
  ISimulateOperation,
  ISimulateOperationBodyCCB,
  postSimulateOperationAPI,
} from "~/services/api/operations";
import {
  convertStringToNumber,
  formatNumberToCurrencyWithoutSimbol,
  getLabelByValue,
} from "~/services/utils";
import ArrowIcon from "~/assets/arrow-right-_1_.png";
import { useNavigate } from "react-router-dom";
import Margin from "~/components/Margin";
import {
  addDays,
  addMonths,
  addYears,
  format,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  isToday,
  parseISO,
  startOfDay,
} from "date-fns";
import { toast } from "react-toastify";
import AppStorage from "~/services/storage";
import useLoading from "~/hooks/useLoading";

type IInitialValues = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: string;
  duration: string;
  calculation_base: string;
  release_date: string;
  interest_rate_monthly: string;
  amortization_grace_period: string;
  commission: string;
  indexer: string;
  indexer_rate: string;
  amortization_amount: string | number;
  due_date_installment: string;
  interest: string | number;
  number: number;
  outstanding_amount: string | number;
  pmt: string | number;
  legal_person: string;
  payment_every: string;
  payment_periodicity: string;
  first_payment_date: string;
  fiduciary_guarantee: string;
  due_date_on_business: boolean;
};

const OperationCCB = () => {
  const {
    setCreditProducts,
    creditProducts,
    setPayloadSimulateOperationCCB,
    setTab,
  } = useOperation();

  const { setLoading } = useLoading();

  const [confirmNewSimulation, setConfirmNewSimulation] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    const storedOperation = localStorage.getItem("@:simulate_operation");
    const defaultValues: IInitialValues = {
      nickname: "",
      product_uuid: "",
      calculation_type: "",
      requested_amount: "10.000,00",
      duration: "2",
      calculation_base: "",
      release_date: "",
      interest_rate_monthly: "",
      amortization_grace_period: "",
      commission: "0",
      indexer: "",
      indexer_rate: "0",
      amortization_amount: 0,
      due_date_installment: "",
      interest: formatNumberToCurrencyWithoutSimbol(1000),
      number: 0,
      outstanding_amount: "",
      pmt: "",
      legal_person: "",
      payment_every: "1",
      payment_periodicity: "",
      first_payment_date: "",
      fiduciary_guarantee: "0",
      due_date_on_business: false,
    };

    if (storedOperation) {
      try {
        const parsedOperation = JSON.parse(storedOperation);
        return {
          ...defaultValues,
          nickname: parsedOperation.nickname,
          product_uuid: parsedOperation.product_uuid || "",
          calculation_type: parsedOperation.calculation_type || "",
          requested_amount: parsedOperation.requested_amount
            ? `${formatNumberToCurrencyWithoutSimbol(parsedOperation.requested_amount)}`
            : "10.000,00",
          duration: parsedOperation.duration || "2",
          calculation_base: parsedOperation.calculation_base || "",
          release_date: parsedOperation.release_date || "",
          payment_periodicity: parsedOperation.payment_periodicity || "",
          interest_rate_monthly:
            String(parsedOperation?.taxes_settings?.interest_rate_monthly) ||
            "",
          interest_periodicity:
            String(parsedOperation?.taxes_settings?.interest_periodicity) || "",
          amortization_grace_period:
            String(
              parsedOperation?.taxes_settings?.amortization_grace_period
            ) || "",
          interest_grace_period:
            String(parsedOperation?.taxes_settings?.interest_grace_period) ||
            "",

          commission: String(parsedOperation?.taxes_settings?.commission) || "",
          indexer: String(parsedOperation?.taxes_settings?.indexer) || "",
          indexer_rate:
            String(parsedOperation?.taxes_settings?.indexer_rate) || "0",
          legal_person: String(parsedOperation.legal_person) || "",
          payment_every: String(parsedOperation?.payment_every) || "",
          first_payment_date: String(parsedOperation?.first_payment_date) || "",
          fiduciary_guarantee:
            String(parsedOperation?.taxes_settings.fiduciary_guarantee) || "0",
          due_date_on_business:
            Boolean(parsedOperation.due_date_on_business) || false,
        };
      } catch (error) {
        console.error("Erro ao parsear o localStorage:", error);
      }
    }

    return defaultValues;
  }, []);

  const [diferentSimulation, setDiferentSimulation] = useState<string>();

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const indexesOptions = useMemo(() => {
    return [
      { index: "SELIC", value: "selic" },
      { index: "IGPM", value: "igpm" },
      { index: "IPCA", value: "ipca" },
      { index: "CDI", value: "cdi" },
      { index: "TR", value: "tr" },
      { index: "INPC", value: "inpc" },
      { index: "Nenhum", value: "no_one" },
    ].map((index) => ({
      value: index.value,
      label: index.index,
    }));
  }, []);
  const calculationBaseOptions = useMemo(() => {
    return [
      { value: "net_value", label: "Líquido" },
      { value: "installment_value", label: "Pagamento" },
      { value: "gross_value", label: "Bruto" },
    ].sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, []);

  const creditProductsOptions = useMemo(() => {
    return creditProducts
      .filter(
        (creditProduct: ICreditProducts) => creditProduct.product_type === "ccb"
      )
      .map((creditProduct: ICreditProducts) => ({
        value: creditProduct.uuid,
        label: creditProduct.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [creditProducts]);

  const calculationTypeOptions = useMemo(() => {
    return [
      { value: "252_daily", label: "Base 252 (Dias Úteis)" },
      { value: "252_monthly", label: "Base 252 (Meses x 21)" },
      { value: "360_daily", label: "Base 360 (Dias Corridos)" },
      { value: "360_monthly", label: "Base 360 (Meses)" },
      { value: "365_daily", label: "Base 365 (Dias Corridos)" },
      { value: "365_monthly", label: "Base 365 (Meses)" },
    ]
      .map((type) => ({
        value: type.value,
        label: type.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const periodicityPaymentOptions = useMemo(() => {
    return [
      { label: "Dias", value: "daily" },
      { label: "Meses", value: "monthly" },
      { label: "Anos", value: "annually" },
    ]
      .map((periodicity) => ({
        value: periodicity.value,
        label: periodicity.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const personOptions = useMemo(() => {
    return [
      { value: "legal_person", label: "Pessoa Jurídica" },
      { value: "not_legal_person", label: "Pessoa Física" },
    ]
      .map((type) => ({
        value: type.value,
        label: type.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const payloadSimulateOperation: ISimulateOperationBodyCCB = useMemo(() => {
    return {
      nickname: values.nickname,
      product_uuid: values.product_uuid,
      calculation_type: values.calculation_type,
      requested_amount: convertStringToNumber(values?.requested_amount),
      duration: Number(values.duration),
      calculation_base: values.calculation_base,
      release_date: values.release_date,
      legal_person: String(values.legal_person),
      payment_every: values.payment_every,
      payment_periodicity: values.payment_periodicity,
      first_payment_date: values.first_payment_date,
      due_date_on_business: values.due_date_on_business,
      taxes_settings: {
        fiduciary_guarantee: Number(values.fiduciary_guarantee),
        interest_rate_monthly: Number(values?.interest_rate_monthly),
        amortization_grace_period: Number(values.amortization_grace_period),
        commission: Number(values.commission),
        indexer: values.indexer,
        indexer_rate: Number(values.indexer_rate),
      },
    };
  }, [values]);

  const [simulationData, setSimulationData] = useState<ISimulateOperation>();

  const sendNewSimulation = useCallback(async () => {
    const payloadSimulateOperation = AppStorage.get(
      "@:simulate_operation"
    ) as string;
    const payloadSimulateOperationParsed = payloadSimulateOperation
      ? JSON.parse(payloadSimulateOperation)
      : null;
    if (payloadSimulateOperationParsed) {
      if (payloadSimulateOperationParsed.taxes_settings?.indexer === "no_one") {
        delete payloadSimulateOperationParsed.taxes_settings.indexer;
        delete payloadSimulateOperationParsed.taxes_settings.indexer_rate;
      }
      payloadSimulateOperationParsed.requested_amount = Number(
        payloadSimulateOperationParsed.requested_amount
      );
      if (
        payloadSimulateOperationParsed.taxes_settings?.interest_rate_monthly
      ) {
        payloadSimulateOperationParsed.taxes_settings.interest_rate =
          payloadSimulateOperationParsed.taxes_settings.interest_rate_monthly;
        delete payloadSimulateOperationParsed.taxes_settings
          .interest_rate_monthly;
      }
    }

    setLoading(true);

    try {
      const { data } = await postSimulateOperationAPI(
        payloadSimulateOperationParsed
      );

      setConfirmNewSimulation(false);

      localStorage.setItem(
        "@:simulation_uuid",
        JSON.stringify({
          simulation_uuid: data.payload.uuid,
        })
      );

      setLoading(false);
      navigate("/app/credit/operations/new/ccb/product-info/");
      setTab(1);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [setLoading, setTab, navigate]);

  const fetchCreditProducts = useCallback(async () => {
    try {
      const { data } = await getCreditProductAPI();
      setCreditProducts(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setCreditProducts]);

  useEffect(() => {
    fetchCreditProducts();
  }, [fetchCreditProducts]);

  useEffect(() => {
    setPayloadSimulateOperationCCB(payloadSimulateOperation);
    localStorage.setItem(
      "@:simulate_operation",
      JSON.stringify(payloadSimulateOperation)
    );
  }, [payloadSimulateOperation, setPayloadSimulateOperationCCB]);

  useEffect(() => {
    setTab(0);
  }, [setTab]);

  const [productDetails, setProductDetails] = useState<IProductDetails>();

  const fetchProductDetails = useCallback(async (uuid: string) => {
    try {
      const { data } = await getCreditProductDetailsAPI(uuid);
      setProductDetails(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (values.product_uuid) {
      fetchProductDetails(values.product_uuid);
    }
  }, [values.product_uuid, fetchProductDetails]);

  const [errorInterestRate, setErrorInterestRate] = useState<boolean>(false);
  const [errorAmortizationPeriod, setErrorAmortizationPeriod] =
    useState<boolean>(false);
  const [errorCommission, setErrorCommission] = useState<boolean>(false);
  const [errorPrincipalAmount, setErrorPrincipalAmount] =
    useState<boolean>(false);

  const [errorDuration, setErrorDuration] = useState<boolean>(false);

  const [errorCalculationType, setErrorCalculationType] =
    useState<boolean>(false);

  const [errorReleaseDate, setErrorReleaseDate] = useState<boolean>(false);
  const [errorFirstPaymentDate, setErrorFirstPaymentDate] =
    useState<string>("");

  useEffect(() => {
    const interestRate = Number(values.interest_rate_monthly);

    const commissionRate = values.commission?.includes(",")
      ? convertStringToNumber(values.commission)
      : Number(values.commission);

    const parsedReleaseDate = startOfDay(parseISO(values.release_date));
    const parsedFirstPaymentDate = startOfDay(
      parseISO(values.first_payment_date)
    );

    if (productDetails) {
      if (
        interestRate < productDetails?.min_interest_rate ||
        interestRate > productDetails?.max_interest_rate
      ) {
        setErrorInterestRate(true);
      } else {
        setErrorInterestRate(false);
      }

      if (
        Number(values.amortization_grace_period) <
          productDetails?.min_amortization_period ||
        Number(values.amortization_grace_period) >
          productDetails?.max_amortization_period
      ) {
        setErrorAmortizationPeriod(true);
      } else {
        setErrorAmortizationPeriod(false);
      }

      if (
        convertStringToNumber(values.requested_amount) >
          productDetails.max_principal_amount ||
        convertStringToNumber(values.requested_amount) <
          productDetails.min_principal_amount
      ) {
        setErrorPrincipalAmount(true);
      } else {
        setErrorPrincipalAmount(false);
      }
    }

    if (Number(values.duration) < 1) {
      setErrorDuration(true);
    } else {
      setErrorDuration(false);
    }
    if (commissionRate < 0 || commissionRate > 100) {
      setErrorCommission(true);
    } else {
      setErrorCommission(false);
    }

    if (isPast(parsedReleaseDate)) {
      setErrorReleaseDate(true);
    } else {
      setErrorReleaseDate(false);
    }

    if (isToday(parsedFirstPaymentDate) || isPast(parsedFirstPaymentDate)) {
      setErrorFirstPaymentDate(
        "A data do primeiro pagamento precisa ser igual ou posterior a data atual"
      );
    } else if (
      isAfter(parsedReleaseDate, parsedFirstPaymentDate) ||
      isEqual(parsedReleaseDate, parsedFirstPaymentDate)
    ) {
      setErrorFirstPaymentDate(
        "A data do primeiro pagamento precisa ser posterior a data de lançamento"
      );
    } else {
      setErrorFirstPaymentDate("");
    }
  }, [values, productDetails]);

  useCallback(() => {
    const { payment_periodicity, calculation_type } = values;

    if (!payment_periodicity || !calculation_type) {
      setErrorCalculationType(false);
      return;
    }

    const validOptions = {
      daily: ["252_daily", "360_daily", "365_daily"],
      monthly: ["252_monthly", "360_monthly", "365_monthly"],
      annually: [
        "252_monthly",
        "360_daily",
        "360_monthly",
        "365_daily",
        "365_monthly",
      ],
    };

    const isValid =
      validOptions[payment_periodicity]?.includes(calculation_type);

    setErrorCalculationType(!isValid);
  }, [values]);

  const validateCalculationType = useCallback(() => {
    const { payment_periodicity, calculation_type } = values;

    if (!payment_periodicity || !calculation_type) {
      setErrorCalculationType(false);
      return;
    }

    const validOptions = {
      daily: ["252_daily", "360_daily", "365_daily"],
      monthly: ["252_monthly", "360_monthly", "365_monthly"],
      annually: [
        "252_monthly",
        "360_daily",
        "360_monthly",
        "365_daily",
        "365_monthly",
      ],
    };

    const isValid =
      validOptions[payment_periodicity]?.includes(calculation_type);

    setErrorCalculationType(!isValid);
  }, [values]);

  useEffect(() => {
    validateCalculationType();
  }, [validateCalculationType]);

  // useEffect(() => {
  //   if (values.indexer === "no_one") {
  //     setFieldValue("indexer_rate", "0");
  //   } else if (values.indexer !== "no_one") {
  //     setFieldValue("indexer_rate", "100");
  //   }
  // }, [values, setFieldValue]);

  useEffect(() => {
    const storedSimulationUUID = localStorage.getItem("@:simulation_uuid");
    const parsedSimulationUUID = storedSimulationUUID
      ? JSON.parse(storedSimulationUUID)
      : null;

    const storedSimulation = localStorage.getItem("@:simulate_operation");
    const parsedSimulation = storedSimulation
      ? JSON.parse(storedSimulation)
      : null;

    if (
      parsedSimulation.taxes_settings.indexer === null ||
      parsedSimulation.taxes_settings.indexer === "null"
    ) {
      setFieldValue("indexer", "no_one");
    }

    if (parsedSimulation.legal_person) {
      setFieldValue("legal_person", parsedSimulation.legal_person);
    }

    if (productDetails && !parsedSimulationUUID?.simulation_uuid) {
      if (parsedSimulation?.taxes_settings?.interest_rate_monthly === "") {
        setFieldValue(
          "interest_rate_monthly",
          productDetails?.min_interest_rate !== undefined &&
            String(productDetails?.min_interest_rate)
        );
      } else {
        setFieldValue(
          "interest_rate_monthly",
          parsedSimulation?.taxes_settings?.interest_rate_monthly
        );
      }

      if (
        productDetails?.min_amortization_period !== undefined &&
        parsedSimulation.amortization_grace_period === ""
      ) {
        setFieldValue(
          "amortization_grace_period",
          String(productDetails?.min_amortization_period)
        );
      }
    }

    if (!parsedSimulation?.duration && !parsedSimulationUUID?.simulation_uuid) {
      setFieldValue("duration", "1");
    } else {
      setFieldValue("duration", String(parsedSimulation.duration));
    }

    if (
      !parsedSimulation.taxes_settings.commission &&
      !parsedSimulationUUID?.simulation_uuid
    ) {
      setFieldValue("commission", "0");
    } else {
      setFieldValue(
        "commission",
        String(parsedSimulation.taxes_settings.commission)
      );
    }
  }, [productDetails, setFieldValue]);

  const fetchSimulationNoReload = useCallback(async (uuid: string) => {
    try {
      const { data } = await getSimulateOperationAPI(uuid);

      setSimulationData(data.payload);

      const simulation = data.payload;

      localStorage.setItem(
        "@:simulation_uuid",
        JSON.stringify({ simulation_uuid: uuid })
      );

      const payload = {
        nickname: simulation.nickname,
        product_uuid: simulation.product.uuid,
        calculation_type: simulation.calculation_type,
        requested_amount: String(simulation.requested_amount),
        duration: simulation.duration,
        calculation_base: simulation.calculation_base,
        operation_date: simulation.operation_date,
        first_payment_date: simulation.first_payment_date,
        release_date: simulation.release_date,
        legal_person: simulation.legal_person,
        payment_periodicity: simulation.payment_periodicity,
        taxes_settings: {
          fiduciary_guarantee: simulation.taxes_settings.fiduciary_guarantee,
          interest_rate_monthly:
            simulation.taxes_settings.interest_rate_monthly,
          interest_periodicity: simulation.taxes_settings.interest_periodicity,
          amortization_grace_period:
            simulation.taxes_settings.amortization_grace_period,
          interest_grace_period:
            simulation.taxes_settings.interest_grace_period,
          commission: simulation.taxes_settings.commission,
          indexer: simulation.taxes_settings.indexer,
          indexer_rate: simulation.taxes_settings.indexer_rate,
        },
      };

      localStorage.setItem("@:simulate_operation", JSON.stringify(payload));
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível recuperar a simulação !");
    }
  }, []);

  const validateFirstPaymentDate = useCallback(() => {
    const {
      first_payment_date,
      payment_every,
      payment_periodicity,
      release_date,
    } = values;

    const currentDate = new Date(release_date);

    if (!first_payment_date) {
      return null;
    }

    const firstPaymentDate = parseISO(first_payment_date);

    let minPaymentDate: Date;
    switch (payment_periodicity) {
      case "daily":
        minPaymentDate = addDays(currentDate, Number(payment_every));
        break;
      case "monthly":
        minPaymentDate = addMonths(currentDate, Number(payment_every));
        break;
      case "annually":
        minPaymentDate = addYears(currentDate, Number(payment_every));
        break;
      default:
        return "Periodicidade do pagamento inválida";
    }

    if (isBefore(firstPaymentDate, minPaymentDate)) {
      return `A data do primeiro pagamento deve ser posterior a ${format(minPaymentDate, "dd/MM/yyyy")}.`;
    }

    return null;
  }, [values]);

  useEffect(() => {
    validateFirstPaymentDate();
  }, [validateFirstPaymentDate]);

  useEffect(() => {
    if (values.indexer === "no_one") {
      setFieldValue("indexer_rate", "0");
    } else if (values.indexer !== "no_one" && values.indexer_rate === "0") {
      setFieldValue("indexer_rate", "100");
    }
  }, [values, setFieldValue]);

  useEffect(() => {
    const storedSimulation = localStorage.getItem("@:simulation_uuid");
    const parsedSimulation = storedSimulation
      ? JSON.parse(storedSimulation)
      : null;

    if (parsedSimulation?.simulation_uuid) {
      fetchSimulationNoReload(parsedSimulation?.simulation_uuid);
      toast.success("Simulação recuperada com sucesso!");
    }
  }, [fetchSimulationNoReload]);

  console.log("OX", values.calculation_type);

  const compareSimulationData = (
    payloadSimulateOperation,
    simulationData
  ): boolean => {
    if (
      payloadSimulateOperation?.nickname !== simulationData?.nickname ||
      payloadSimulateOperation?.product_uuid !==
        simulationData?.product?.uuid ||
      payloadSimulateOperation?.calculation_type !==
        simulationData?.calculation_type ||
      payloadSimulateOperation?.requested_amount !==
        simulationData?.requested_amount ||
      payloadSimulateOperation?.duration !== simulationData?.duration ||
      payloadSimulateOperation?.calculation_base !==
        simulationData?.calculation_base ||
      payloadSimulateOperation?.release_date !== simulationData?.release_date ||
      String(payloadSimulateOperation?.due_day) !==
        String(simulationData?.due_day)
    ) {
      return false;
    }

    const payloadTaxes = payloadSimulateOperation?.taxes_settings;
    const simulationTaxes = simulationData?.taxes_settings;

    if (
      payloadTaxes?.interest_rate_monthly !==
        simulationTaxes?.interest_rate_monthly ||
      payloadTaxes?.interest_periodicity !==
        simulationTaxes?.interest_periodicity ||
      payloadTaxes?.amortization_grace_period !==
        simulationTaxes?.amortization_grace_period ||
      payloadTaxes?.interest_grace_period !==
        simulationTaxes?.interest_grace_period ||
      payloadTaxes?.commission !== simulationTaxes?.commission ||
      (payloadTaxes?.indexer === "no_one"
        ? simulationTaxes?.indexer !== null
        : payloadTaxes?.indexer !== simulationTaxes?.indexer) ||
      payloadTaxes?.indexer_rate !== simulationTaxes?.indexer_rate
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const storedSimulationUUID = localStorage.getItem("@:simulation_uuid");
    const parsedSimulationUUID = storedSimulationUUID
      ? JSON.parse(storedSimulationUUID)
      : null;

    if (parsedSimulationUUID?.simulation_uuid) {
      if (payloadSimulateOperation && simulationData) {
        const isEqual = compareSimulationData(
          payloadSimulateOperation,
          simulationData
        );

        if (isEqual) {
          localStorage.setItem("@:simulation_modify", "false");
        } else {
          localStorage.setItem("@:simulation_modify", "true");
        }
      } else {
        localStorage.setItem("@:simulation_modify", "true");
      }

      const modify_simulation = localStorage.getItem("@:simulation_modify");

      setDiferentSimulation(String(modify_simulation));
    }
  }, [payloadSimulateOperation, simulationData]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "32px",
          rowGap: "30px",
          marginTop: "20px",
        }}
      >
        <div>
          {" "}
          <Input
            name="nickname"
            id="nickname"
            value={values.nickname}
            size="large"
            label="Nome"
            placeholder="Digite"
            onChange={(e) => setFieldValue("nickname", e.target.value)}
          />
        </div>
        <div>
          {" "}
          <InputSelect
            name="product_uuid"
            kind="default"
            size="small"
            label="Produto"
            options={creditProductsOptions}
            placeholder={
              values.product_uuid
                ? getLabelByValue(values.product_uuid, creditProductsOptions)
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("product_uuid", e)}
          />
        </div>
        <div>
          {" "}
          <InputSelect
            name="legal_person"
            kind="default"
            size="small"
            label="Tipo de pessoa"
            options={personOptions}
            value=""
            placeholder={
              values.legal_person
                ? getLabelByValue(values.legal_person, personOptions)
                : "Selecione"
            }
            onChange={(e) => handleSelect("legal_person", e)}
          />
        </div>
        <div>
          <InputSelect
            name="calculation_type"
            kind="default"
            size="small"
            label="Tipo de cálculo"
            value=""
            options={calculationTypeOptions}
            placeholder={
              values.calculation_type !== ""
                ? getLabelByValue(
                    values.calculation_type,
                    calculationTypeOptions
                  )
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("calculation_type", e)}
            error={
              (values.calculation_type !== "252_daily" &&
                values.calculation_type !== "252_monthly" &&
                (values.indexer === "cdi" || values.indexer === "selic")) ||
              errorCalculationType
            }
            message={
              errorCalculationType
                ? "Combinação de Periodicidade de pagamento e Tipo de Cálculo inválida"
                : values.indexer === "cdi" &&
                    values.calculation_type !== "252_daily" &&
                    values.calculation_type !== "252_monthly"
                  ? "Para o indexador pós-fixado 'CDI' mude o tipo de cálculo para algum com base em 252 dias"
                  : values.indexer === "selic" &&
                      values.calculation_type !== "252_daily" &&
                      values.calculation_type !== "252_monthly"
                    ? "Para o indexador pós-fixado 'SELIC' mude o tipo de cálculo para algum com base em 252 dias"
                    : ""
            }
          />{" "}
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <InputCurrency
            name="requested_amount"
            id="requested_amount"
            size="medium"
            max={15}
            maxLength={18}
            label="Valor solicitado (R$)"
            placeholder="R$"
            value={values.requested_amount}
            error={errorPrincipalAmount}
            message={
              productDetails && errorPrincipalAmount
                ? ` Digite um valor entre R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.min_principal_amount)} e R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.max_principal_amount)}`
                : ""
            }
            onChange={(e) => setFieldValue("requested_amount", e.target.value)}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="interest_rate_monthly"
            id="interest_rate_monthly"
            maxLength={6}
            max={6}
            size="large"
            label="Taxa de juros (%)"
            placeholder="%"
            message={
              errorInterestRate
                ? `Informe um valor entre ${productDetails?.min_interest_rate}% e ${productDetails?.max_interest_rate}%`
                : ""
            }
            error={errorInterestRate}
            value={values.interest_rate_monthly}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^\d.,]/g, "");

              const hasDot = value.includes(".");
              const hasComma = value.includes(",");

              if (
                (hasDot && hasComma) ||
                (hasDot && (value.match(/\./g) || []).length > 1) ||
                (hasComma && (value.match(/,/g) || []).length > 1)
              ) {
                return;
              }
              setFieldValue("interest_rate_monthly", value);
            }}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="duration"
            id="duration"
            maxLength={2}
            max={2}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("duration", value);
            }}
            size="large"
            label="Duração"
            value={values.duration}
            placeholder="Meses"
            message={errorDuration ? "Digite um valor maior que 0" : "Em meses"}
            error={errorDuration}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="amortization_grace_period"
            id="amortization_grace_period"
            maxLength={2}
            max={2}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("amortization_grace_period", value);
            }}
            size="large"
            label="Carência de amortização (Em meses)"
            placeholder="Meses"
            message={
              values.amortization_grace_period &&
              values.duration &&
              Number(values.amortization_grace_period) >=
                Number(values.duration)
                ? "A Carência de amortização não pode ser maior ou igual ao valor da Duração"
                : errorAmortizationPeriod
                  ? ` Informe um valor entre ${productDetails?.min_amortization_period}  e ${productDetails?.max_amortization_period}`
                  : "Em meses"
            }
            error={
              values.amortization_grace_period &&
              values.duration &&
              Number(values.amortization_grace_period) >=
                Number(values.duration)
                ? true
                : errorAmortizationPeriod
                  ? true
                  : false
            }
            value={values.amortization_grace_period}
          />
        </div>

        <div>
          <InputSelect
            name="calculation_base"
            kind="default"
            size="small"
            label="Base de cálculo"
            options={calculationBaseOptions}
            placeholder={
              values.calculation_base
                ? getLabelByValue(
                    values.calculation_base,
                    calculationBaseOptions
                  )
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("calculation_base", e)}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <Input
            type="date"
            name="release_date"
            size="large"
            label="Data de lançamento"
            placeholder="Digite"
            value={values.release_date}
            error={errorReleaseDate}
            message={
              errorReleaseDate
                ? "A data de lançamento precisa ser igual ou posterior a data atual"
                : ""
            }
            onChange={(e) => setFieldValue("release_date", e.target.value)}
          />
        </div>
        <div>
          {" "}
          <Input
            name="payment_every"
            id="payment_every"
            maxLength={3}
            max={3}
            size="large"
            label="Pagamento a cada"
            placeholder=""
            error={values.payment_every === "0"}
            message={
              values.payment_every === "0" ? "Digite um valor maior que 0" : ""
            }
            value={values.payment_every}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("payment_every", value);
            }}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <InputSelect
            name="payment_periodicity"
            kind="default"
            size="small"
            label="Periodicidade do pagamento"
            error={errorCalculationType}
            value=""
            placeholder={
              values.payment_periodicity
                ? getLabelByValue(
                    values.payment_periodicity,
                    periodicityPaymentOptions
                  )
                : "Selecione uma opção"
            }
            message={
              errorCalculationType
                ? "Combinação de Periodicidade de pagamento e Tipo de Cálculo inválida"
                : ""
            }
            options={periodicityPaymentOptions}
            onChange={(e) => handleSelect("payment_periodicity", e)}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            type="date"
            name="first_payment_date"
            size="large"
            label="Data do primeiro pagamento"
            placeholder="Digite"
            error={
              errorFirstPaymentDate !== "" ||
              validateFirstPaymentDate() !== null
            }
            message={
              validateFirstPaymentDate() !== null
                ? String(validateFirstPaymentDate())
                : errorFirstPaymentDate !== ""
                  ? errorFirstPaymentDate
                  : ""
            }
            value={values.first_payment_date}
            onChange={(e) =>
              setFieldValue("first_payment_date", e.target.value)
            }
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="commission"
            id="commission"
            size="large"
            maxLength={6}
            max={6}
            error={errorCommission || values.commission === ""}
            message={
              errorCommission || values.commission === ""
                ? "Informe um valor entre 0 e 100"
                : ""
            }
            label="Correspondente bancário (%)"
            placeholder="%"
            value={values.commission}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("commission", value);
            }}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="fiduciary_guarantee"
            id="fiduciary_guarantee"
            size="large"
            maxLength={6}
            max={6}
            label="Garantia fiduciária (%)"
            error={Number(values.fiduciary_guarantee) > 100}
            message={
              Number(values.fiduciary_guarantee) > 100
                ? "Digite um valor menor que 100"
                : ""
            }
            placeholder="%"
            value={values.fiduciary_guarantee}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("fiduciary_guarantee", value);
            }}
          />
        </div>
        <div>
          <InputSelect
            name="indexer"
            kind="default"
            size="small"
            label="Indexador pós-fixado"
            options={indexesOptions}
            placeholder={
              values.indexer !== null || values.indexer === "null"
                ? getLabelByValue(values.indexer, indexesOptions)
                : values.indexer === null || values.indexer === "null"
                  ? "Nenhum"
                  : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("indexer", e)}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="indexer_rate"
            id="indexer_rate"
            size="large"
            disabled={!values.indexer || values.indexer === "no_one"}
            maxLength={6}
            max={6}
            value={values.indexer_rate}
            label="Percentual indexador (%)"
            placeholder="%"
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("indexer_rate", value);
            }}
            error={
              values.indexer !== "no_one" && Number(values.indexer_rate) < 1
            }
            message={
              values.indexer !== "no_one" && Number(values.indexer_rate) < 1
                ? "Digite um valor maior que 0"
                : ""
            }
          />
        </div>

        <div
          style={{
            position: "relative",
            top: "37px",
            width: "340px",
            height: "10px",
            cursor: "pointer",
          }}
          onClick={() =>
            setFieldValue("due_date_on_business", !values.due_date_on_business)
          }
        >
          <Checkbox checked={values.due_date_on_business} />
          <p style={{ position: "relative", top: "-23px", left: "30px" }}>
            Data de vencimento no próximo dia útil
          </p>
        </div>
      </div>
      <Margin mt={50} />
      <div
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <Button
            kind="ghost"
            size="large"
            label="Cancelar"
            onClick={() => {
              setTab(0);
              localStorage.removeItem("@:involveds");
              localStorage.removeItem("@:simulate_operation");
              localStorage.removeItem("@:operation");
              localStorage.removeItem("@:documents");
              navigate("/app/credit/operations/");
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Button
              kind="primary"
              size="large"
              label="Avançar"
              icon={ArrowIcon}
              onClick={() => {
                if (diferentSimulation === "true") {
                  // setConfirmNewSimulation(true); // validar depois
                  navigate("/app/credit/operations/new/ccb/product-info/");
                  setTab(1);
                } else {
                  navigate("/app/credit/operations/new/ccb/product-info/");
                  setTab(1);
                }
              }}
              disabled={
                (values.calculation_type !== "252_daily" &&
                  values.calculation_type !== "252_monthly" &&
                  (values.indexer === "cdi" || values.indexer === "selic")) ||
                !values.amortization_grace_period ||
                !values.calculation_base ||
                !values.calculation_type ||
                !values.commission ||
                !values.duration ||
                !values.fiduciary_guarantee ||
                Number(values.fiduciary_guarantee) > 100 ||
                validateFirstPaymentDate() !== null ||
                !values.indexer ||
                !values.payment_every ||
                !values.payment_periodicity ||
                values.legal_person === null ||
                !values.release_date ||
                !values.first_payment_date ||
                values.payment_every === "0" ||
                (values.indexer !== "no_one" &&
                  convertStringToNumber(values.indexer_rate) < 1) ||
                !values.interest_rate_monthly ||
                !values.nickname ||
                errorAmortizationPeriod ||
                errorCommission ||
                errorCalculationType ||
                errorDuration ||
                errorInterestRate ||
                errorPrincipalAmount ||
                errorReleaseDate ||
                errorFirstPaymentDate !== ""
              }
            />
          </div>
        </div>
      </div>
      <Modal
        radius
        height={"368px"}
        width="760px"
        background="#f4f4f4"
        open={confirmNewSimulation}
        onClose={() => setConfirmNewSimulation(false)}
        label={<h3>Atenção!</h3>}
        buttons={[
          {
            kind: "secondary",
            label: "Não, cancelar",
            onClick: () => setConfirmNewSimulation(false),
          },
          {
            kind: "primary",
            label: "Sim, avançar",
            onClick: () => sendNewSimulation(),
          },
        ]}
      >
        <div style={{ padding: "20px" }}>
          <h4>
            Algumas alterações foram dectadas na simulação recuperada. Ao
            continuar, uma nova simulação/operação será gerada com base nas
            novas informações. Deseja prosseguir?
          </h4>
        </div>
      </Modal>
    </div>
  );
};

export default OperationCCB;
