import { useFormik } from "formik";
import {
  Button,
  Input,
  InputCurrency,
  InputSelect,
  Tooltip,
} from "prosperita-dumbo-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useOperation from "~/hooks/useOperation";
import {
  getCreditProductAPI,
  getCreditProductDetailsAPI,
  ICreditProducts,
  IProductDetails,
} from "~/services/api/creditProduct";
import { ISimulateOperationBody } from "~/services/api/operations";
import {
  convertStringCurrencyToNumber,
  convertStringToNumber,
  formatNumberToCurrencyWithoutSimbol,
  getLabelByValue,
} from "~/services/utils";
import ArrowIcon from "~/assets/arrow-right-_1_.png";
import { useNavigate } from "react-router-dom";
import Margin from "~/components/Margin";
import { isPast, parseISO } from "date-fns";

type IInitialValues = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: string;
  duration: string;
  calculation_base: string;
  unit_amount: string;
  release_date: string;
  due_day: string;
  interest_rate_monthly: string;
  interest_periodicity: string;
  amortization_grace_period: string;
  interest_grace_period: string;
  commission: string;
  indexer: string;
  indexer_rate: string;
};

const Operation = () => {
  const {
    setCreditProducts,
    creditProducts,
    setPayloadSimulateOperation,
    setTab,
  } = useOperation();

  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    const storedOperation = localStorage.getItem("@:simulate_operation");
    const defaultValues: IInitialValues = {
      nickname: "",
      product_uuid: "",
      calculation_type: "",
      requested_amount: "10.000,00",
      duration: "",
      calculation_base: "",
      release_date: "",
      due_day: "",
      interest_rate_monthly: "",
      interest_periodicity: "",
      amortization_grace_period: "",
      interest_grace_period: "",
      commission: "",
      unit_amount: "1.000,00",
      indexer: "",
      indexer_rate: "",
    };

    if (storedOperation) {
      try {
        const parsedOperation = JSON.parse(storedOperation);
        console.log(parsedOperation.unit_amount < 100);
        return {
          ...defaultValues,
          nickname: parsedOperation.nickname,
          product_uuid: parsedOperation.product_uuid || "",
          calculation_type: parsedOperation.calculation_type || "",
          requested_amount: `${formatNumberToCurrencyWithoutSimbol(parsedOperation.requested_amount)}`,
          unit_value:
            parsedOperation.unit_value < 100
              ? `${formatNumberToCurrencyWithoutSimbol(1000)}`
              : `${formatNumberToCurrencyWithoutSimbol(parsedOperation.unit_amount)}`,
          duration: parsedOperation.duration || "",
          calculation_base: parsedOperation.calculation_base || "",
          release_date: parsedOperation.release_date || "",
          due_day: parsedOperation.due_day || "",
          interest_rate_monthly:
            String(parsedOperation?.taxes_settings?.interest_rate_monthly) ||
            "",
          interest_periodicity:
            String(parsedOperation?.taxes_settings?.interest_periodicity) || "",
          amortization_grace_period:
            String(
              parsedOperation?.taxes_settings?.amortization_grace_period
            ) || "",
          interest_grace_period:
            String(parsedOperation?.taxes_settings?.interest_grace_period) ||
            "",

          commission: String(parsedOperation?.taxes_settings?.commission) || "",
          indexer: String(parsedOperation?.taxes_settings?.indexer) || "",
          indexer_rate:
            String(parsedOperation?.taxes_settings?.indexer_rate) || "100",
        };
      } catch (error) {
        console.error("Erro ao parsear o localStorage:", error);
      }
    }

    return defaultValues;
  }, []);

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const indexesOptions = useMemo(() => {
    return [
      { index: "SELIC", value: "selic" },
      { index: "IGPM", value: "igpm" },
      { index: "IPCA", value: "ipca" },
      { index: "CDI", value: "cdi" },
      { index: "Nenhum", value: "no_one" },
    ].map((index) => ({
      value: index.value,
      label: index.index,
    }));
  }, []);

  const calculationBaseOptions = useMemo(() => {
    return [
      { value: "net_value", label: "Valor Líquido" },
      { value: "gross_value", label: "Valor Bruto" },
    ].sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, []);

  const periodicityOptions = useMemo(() => {
    return [
      { label: "Diário", value: "daily" },
      { label: "Mensal", value: "monthly" },
      { label: "Anual", value: "annually" },
    ]
      .map((periodicity) => ({
        value: periodicity.value,
        label: periodicity.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const creditProductsOptions = useMemo(() => {
    return creditProducts
      .filter(
        (product: ICreditProducts) => product.product_type === "commercial_note"
      )
      .map((creditProduct: ICreditProducts) => ({
        value: creditProduct.uuid,
        label: creditProduct.name,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [creditProducts]);

  const calculationTypeOptions = useMemo(() => {
    return [
      { value: "360", label: "360 Dias" },
      { value: "365", label: "365 Dias" },
      { value: "252", label: "252 Dias" },
    ]
      .map((type) => ({
        value: type.value,
        label: type.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const isNotDivisible = useMemo(() => {
    const requested = convertStringCurrencyToNumber(values.requested_amount);
    const unit = convertStringCurrencyToNumber(values.unit_amount);

    if (unit === 0) return true;
    return !Number.isInteger(requested / unit);
  }, [values.requested_amount, values.unit_amount]);

  const payloadSimulateOperation: ISimulateOperationBody = useMemo(() => {
    return {
      nickname: values.nickname,
      product_uuid: values.product_uuid,
      calculation_type: values.calculation_type,
      requested_amount: convertStringToNumber(values?.requested_amount),
      duration: Number(values.duration),
      calculation_base: values.calculation_base,
      release_date: values.release_date,
      due_day: values.due_day,
      unit_amount: convertStringToNumber(values?.unit_amount),
      taxes_settings: {
        interest_rate: Number(values.interest_rate_monthly),
        interest_periodicity: values.interest_periodicity,
        amortization_grace_period: Number(values.amortization_grace_period),
        interest_grace_period: Number(values.interest_grace_period),
        commission: Number(values.commission),
        indexer: values.indexer,
        indexer_rate: Number(values.indexer_rate),
      },
    };
  }, [values]);

  const fetchCreditProducts = useCallback(async () => {
    try {
      const { data } = await getCreditProductAPI();
      setCreditProducts(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setCreditProducts]);

  useEffect(() => {
    fetchCreditProducts();
  }, [fetchCreditProducts]);

  useEffect(() => {
    setPayloadSimulateOperation(payloadSimulateOperation);
    localStorage.setItem(
      "@:simulate_operation",
      JSON.stringify(payloadSimulateOperation)
    );
  }, [payloadSimulateOperation, setPayloadSimulateOperation]);

  useEffect(() => {
    setTab(0);
  }, [setTab]);

  const [productDetails, setProductDetails] = useState<IProductDetails>();

  const fetchProductDetails = useCallback(async (uuid: string) => {
    try {
      const { data } = await getCreditProductDetailsAPI(uuid);
      setProductDetails(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (values.product_uuid) {
      fetchProductDetails(values.product_uuid);
    }
  }, [values.product_uuid, fetchProductDetails]);

  const [errorInterestRate, setErrorInterestRate] = useState<boolean>(false);
  const [errorAmortizationPeriod, setErrorAmortizationPeriod] =
    useState<boolean>(false);
  const [errorCommission, setErrorCommission] = useState<boolean>(false);
  const [errorPrincipalAmount, setErrorPrincipalAmount] =
    useState<boolean>(false);

  const [errorDuration, setErrorDuration] = useState<boolean>(false);
  const [errorReleaseDate, setErrorReleaseDate] = useState<boolean>(false);

  useEffect(() => {
    const interestRate = values.interest_rate_monthly?.includes(",")
      ? convertStringToNumber(values.interest_rate_monthly)
      : Number(values.interest_rate_monthly);

    const commissionRate = values.commission?.includes(",")
      ? convertStringToNumber(values.commission)
      : Number(values.commission);

    const parsedReleaseDate = parseISO(values.release_date);

    if (productDetails) {
      if (
        interestRate < productDetails?.min_interest_rate ||
        interestRate > productDetails?.max_interest_rate
      ) {
        setErrorInterestRate(true);
      } else {
        setErrorInterestRate(false);
      }

      if (
        Number(values.amortization_grace_period) <
          productDetails?.min_amortization_period ||
        Number(values.amortization_grace_period) >
          productDetails?.max_amortization_period
      ) {
        setErrorAmortizationPeriod(true);
      } else {
        setErrorAmortizationPeriod(false);
      }

      if (
        convertStringToNumber(values.requested_amount) >
          productDetails.max_principal_amount ||
        convertStringToNumber(values.requested_amount) <
          productDetails.min_principal_amount
      ) {
        setErrorPrincipalAmount(true);
      } else {
        setErrorPrincipalAmount(false);
      }
    }

    // out if

    if (Number(values.duration) < 1) {
      setErrorDuration(true);
    } else {
      setErrorDuration(false);
    }
    if (commissionRate < 0 || commissionRate > 100) {
      setErrorCommission(true);
    } else {
      setErrorCommission(false);
    }

    if (isPast(parsedReleaseDate)) {
      setErrorReleaseDate(true);
    } else {
      setErrorReleaseDate(false);
    }
  }, [
    values.interest_rate_monthly,
    values.amortization_grace_period,
    values.commission,
    productDetails,
    values.requested_amount,
    values.duration,
    values.release_date,
  ]);

  useEffect(() => {
    const storedSimulationUUID = localStorage.getItem("@:simulation_uuid");
    const parsedSimulationUUID = storedSimulationUUID
      ? JSON.parse(storedSimulationUUID)
      : null;

    const storedSimulation = localStorage.getItem("@:simulate_operation");
    const parsedSimulation = storedSimulation
      ? JSON.parse(storedSimulation)
      : null;

    if (productDetails && !parsedSimulationUUID?.simulation_uuid) {
      setFieldValue(
        "interest_rate_monthly",
        productDetails?.min_interest_rate !== undefined &&
          String(productDetails?.min_interest_rate)
      );

      if (
        productDetails?.min_amortization_period !== undefined &&
        parsedSimulation.amortization_grace_period === ""
      ) {
        setFieldValue(
          "amortization_grace_period",
          String(productDetails?.min_amortization_period)
        );
      }

      setFieldValue(
        "interest_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );
    }

    if (
      parsedSimulation.taxes_settings.indexer === null ||
      parsedSimulation.taxes_settings.indexer === "null"
    ) {
      setFieldValue("indexer", "no_one");
    }

    if (!parsedSimulation?.duration && !parsedSimulationUUID?.simulation_uuid) {
      setFieldValue("duration", "1");
    } else {
      setFieldValue("duration", String(parsedSimulation.duration));
    }

    if (
      !parsedSimulation.taxes_settings.commission &&
      !parsedSimulationUUID?.simulation_uuid
    ) {
      setFieldValue("commission", "0");
    } else {
      setFieldValue(
        "commission",
        String(parsedSimulation.taxes_settings.commission)
      );
    }
  }, [productDetails, setFieldValue]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "32px",
          rowGap: "30px",
          marginTop: "20px",
        }}
      >
        <div>
          {" "}
          <Input
            name="nickname"
            id="nickname"
            value={values.nickname}
            size="large"
            label="Nome"
            placeholder="Digite"
            onChange={(e) => setFieldValue("nickname", e.target.value)}
          />
        </div>
        <div>
          {" "}
          <InputSelect
            name="product_uuid"
            kind="default"
            size="small"
            label="Produto"
            options={creditProductsOptions}
            placeholder={
              values.product_uuid
                ? getLabelByValue(values.product_uuid, creditProductsOptions)
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("product_uuid", e)}
          />
        </div>
        <div>
          <InputSelect
            name="calculation_type"
            kind="default"
            size="small"
            label="Tipo de cálculo"
            options={calculationTypeOptions}
            placeholder={
              values.calculation_type
                ? getLabelByValue(
                    values.calculation_type,
                    calculationTypeOptions
                  )
                : "Selecione uma opção"
            }
            error={
              values.calculation_type !== "252" &&
              (values.indexer === "cdi" || values.indexer === "selic")
            }
            message={
              values.indexer === "cdi" && values.calculation_type !== "252"
                ? "Para o indexador pós-fixado 'CDI' mude o tipo de calculo para 252 dias "
                : values.indexer === "selic" &&
                    values.calculation_type !== "252"
                  ? "Para o indexador pós-fixado 'SELIC' mude o tipo de calculo para 252 dias "
                  : ""
            }
            onChange={(e) => handleSelect("calculation_type", e)}
          />
        </div>
        <div>
          {" "}
          <InputCurrency
            name="requested_amount"
            id="requested_amount"
            size="large"
            label="Valor solicitado (R$)"
            max={24}
            maxLength={24}
            placeholder="R$"
            error={
              errorPrincipalAmount ||
              isNotDivisible ||
              convertStringToNumber(values.requested_amount) <=
                convertStringCurrencyToNumber(values.unit_amount)
            }
            message={
              productDetails && errorPrincipalAmount
                ? ` Digite um valor entre R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.min_principal_amount)} e R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.max_principal_amount)}`
                : convertStringToNumber(values.requested_amount) <=
                    convertStringCurrencyToNumber(values.unit_amount)
                  ? "O valor solicitado deve ser maior do que o valor unitário"
                  : isNotDivisible &&
                      convertStringToNumber(values.requested_amount) >
                        convertStringCurrencyToNumber(values.unit_amount)
                    ? "O valor solicitado precisa ser divisível pelo valor unitário"
                    : ""
            }
            value={values.requested_amount}
            onChange={(e) => setFieldValue("requested_amount", e.target.value)}
          />
        </div>
        <div>
          <InputCurrency
            name="unit_amount"
            id="unit_amount"
            max={24}
            maxLength={24}
            size="large"
            label="Valor unitário (R$)"
            placeholder="R$"
            error={
              convertStringCurrencyToNumber(values.unit_amount) < 1 ||
              convertStringCurrencyToNumber(values.unit_amount) > 10000
            }
            message={
              convertStringCurrencyToNumber(values.unit_amount) < 1 ||
              convertStringCurrencyToNumber(values.unit_amount) > 10000
                ? ` Digite um valor entre R$ 1,00 e R$ 10.000,00`
                : ""
            }
            value={values.unit_amount}
            onChange={(e) => setFieldValue("unit_amount", e.target.value)}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="duration"
            id="duration"
            maxLength={2}
            max={2}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("duration", value);
            }}
            size="large"
            label="Duração"
            value={values.duration}
            placeholder="Meses"
            message={errorDuration ? "Digite um valor maior que 1" : "Em meses"}
            error={errorDuration}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Tooltip
            align="top"
            size="medium"
            label="Data simulada da primeira subscrição"
          >
            {" "}
            <Input
              type="date"
              name="release_date"
              size="large"
              label="Data de lançamento"
              placeholder="Digite"
              error={errorReleaseDate}
              message={
                errorReleaseDate
                  ? "A data de lançamento precisa ser igual ou posterior a data atual"
                  : ""
              }
              value={values.release_date}
              onChange={(e) => setFieldValue("release_date", e.target.value)}
            />
          </Tooltip>
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="due_day"
            size="large"
            label="Dia de vencimento"
            placeholder="Digite"
            min={3}
            minLength={3}
            value={values.due_day}
            onChange={(e) => {
              let inputValue = e.target.value;

              inputValue = inputValue.replace(/\D/g, "");

              if (inputValue) {
                const numericValue = Math.min(
                  Math.max(parseInt(inputValue, 10), 1),
                  30
                );
                inputValue = numericValue.toString();
              }

              setFieldValue("due_day", inputValue);
            }}
          />
        </div>

        <div style={{ position: "relative", top: "3px" }}>
          <Input
            name="interest_rate_monthly"
            id="interest_rate_monthly"
            maxLength={6}
            max={6}
            size="large"
            label="Taxa de juros (%)"
            placeholder="%"
            message={
              errorInterestRate
                ? `Informe um valor entre ${productDetails?.min_interest_rate}% e ${productDetails?.max_interest_rate}%`
                : ""
            }
            error={errorInterestRate}
            value={values.interest_rate_monthly}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("interest_rate_monthly", value);
            }}
          />
        </div>
        <div>
          <InputSelect
            name="interest_periodicity"
            id="interest_periodicity"
            kind="default"
            size="small"
            label="Periodicidade dos juros"
            options={periodicityOptions}
            placeholder={
              values.interest_periodicity
                ? getLabelByValue(
                    values.interest_periodicity,
                    periodicityOptions
                  )
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("interest_periodicity", e)}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="amortization_grace_period"
            id="amortization_grace_period"
            maxLength={2}
            max={2}
            size="large"
            label="Carência de amortização (Em meses)"
            placeholder="Meses"
            message={
              values.amortization_grace_period &&
              values.duration &&
              Number(values.amortization_grace_period) >=
                Number(values.duration)
                ? "A Carência de amortização não pode ser maior ou igual ao valor da Duração"
                : errorAmortizationPeriod
                  ? ` Informe um valor entre ${productDetails?.min_amortization_period}  e ${productDetails?.max_amortization_period}`
                  : "Em meses"
            }
            error={
              values.amortization_grace_period &&
              values.duration &&
              Number(values.amortization_grace_period) >=
                Number(values.duration)
                ? true
                : errorAmortizationPeriod
                  ? true
                  : false
            }
            value={values.amortization_grace_period}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("amortization_grace_period", value);
            }}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="interest_grace_period"
            id="interest_grace_period"
            maxLength={2}
            max={2}
            size="large"
            label="Carência de juros (Em meses)"
            placeholder="Meses"
            value={values.interest_grace_period}
            message={
              values.interest_grace_period &&
              values.duration &&
              Number(values.interest_grace_period) >= Number(values.duration)
                ? "O valor da Carência de juros não pode ser maior ou igual ao valor da Duração"
                : values.interest_grace_period &&
                    values.amortization_grace_period &&
                    Number(values.interest_grace_period) >
                      Number(values.amortization_grace_period)
                  ? "O valor da Carência de juros não pode ser maior do que o valor da Carência de amortização"
                  : "Em meses"
            }
            error={
              values.interest_grace_period &&
              values.duration &&
              Number(values.interest_grace_period) >= Number(values.duration)
                ? true
                : values.interest_grace_period &&
                    values.amortization_grace_period &&
                    Number(values.interest_grace_period) >
                      Number(values.amortization_grace_period)
                  ? true
                  : false
            }
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^0-9]/g, "");
              setFieldValue("interest_grace_period", value);
            }}
          />
        </div>

        <div>
          <InputSelect
            name="calculation_base"
            kind="default"
            size="small"
            label="Base de cálculo"
            options={calculationBaseOptions}
            placeholder={
              values.calculation_base
                ? getLabelByValue(
                    values.calculation_base,
                    calculationBaseOptions
                  )
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("calculation_base", e)}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="commission"
            id="commission"
            size="large"
            error={errorCommission || values.commission === ""}
            message={
              errorCommission || values.commission === ""
                ? "Informe um valor entre 0 e 100"
                : ""
            }
            maxLength={6}
            max={6}
            label="Correspondente bancário (%)"
            placeholder="%"
            value={values.commission}
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("commission", value);
            }}
          />
        </div>

        <div style={{ position: "relative", top: "7px" }}>
          <InputSelect
            name="indexer"
            kind="default"
            size="small"
            label="Indexador pós-fixado"
            options={indexesOptions}
            placeholder="Selecione uma opção"
            onChange={(e) => handleSelect("indexer", e)}
          />
        </div>
        <div style={{ position: "relative", top: "7px" }}>
          <Input
            name="indexer_rate"
            id="indexer_rate"
            size="large"
            disabled={!values.indexer || values.indexer === "no_one"}
            maxLength={6}
            max={6}
            value={values.indexer_rate}
            label="Percentual indexador (%)"
            placeholder="%"
            onChange={(e) => {
              const value = e.target.value.replace(/[^\d.]/g, "");
              const parts = value.split(".");
              if (parts.length > 2) {
                return;
              }

              setFieldValue("indexer_rate", value);
            }}
            error={values.indexer !== "" && Number(values.indexer_rate) < 1}
            message={
              values.indexer !== "" && Number(values.indexer_rate) < 1
                ? "Digite um valor maior que 0"
                : ""
            }
          />
        </div>
      </div>
      <Margin mt={50} />
      <div
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <Button
            kind="ghost"
            size="large"
            label="Cancelar"
            onClick={() => {
              setTab(0);
              localStorage.removeItem("@:involveds");
              localStorage.removeItem("@:simulate_operation");
              localStorage.removeItem("@:operation");
              localStorage.removeItem("@:documents");
              navigate("/app/credit/operations/");
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Button
              kind="primary"
              size="large"
              label="Avançar"
              icon={ArrowIcon}
              onClick={() => {
                navigate(
                  "/app/credit/operations/new/commercial-note/product-info/"
                );
                setTab(1);
              }}
              disabled={
                !values.release_date ||
                isNotDivisible ||
                convertStringCurrencyToNumber(values.unit_amount) < 1 ||
                convertStringCurrencyToNumber(values.unit_amount) > 10000 ||
                convertStringToNumber(values.requested_amount) <=
                  convertStringCurrencyToNumber(values.unit_amount) ||
                !values.amortization_grace_period ||
                !values.calculation_base ||
                !values.calculation_type ||
                !values.due_day ||
                !values.commission ||
                !values.duration ||
                !values.indexer ||
                Number(values.indexer_rate) < 1 ||
                !values.interest_grace_period ||
                !values.interest_periodicity ||
                !values.interest_rate_monthly ||
                !values.nickname ||
                errorAmortizationPeriod ||
                errorCommission ||
                errorDuration ||
                errorInterestRate ||
                errorPrincipalAmount ||
                errorReleaseDate
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Operation;
