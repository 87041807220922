import { Navigate } from "react-router-dom";
import AppStorage from "~/services/storage";

type PrivateRouterProps = {
  children: JSX.Element;
};

const PrivateRouter = ({ children }: PrivateRouterProps): JSX.Element => {
  const token = AppStorage.getToken();

  if (token) {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default PrivateRouter;
