import React from "react";
import styled from "styled-components";

interface FileItemProps {
  text: string;
  onRemove: () => void;
}

const FileItem: React.FC<FileItemProps> = ({ text, onRemove }) => {
  return (
    <Container>
      <FileName>{text}</FileName>
      <RemoveButton onClick={onRemove}>✕</RemoveButton>
    </Container>
  );
};

export default FileItem;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  background: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  width: 100%;
`;

const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  margin-right: 8px;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #161616;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #161616;
  }
`;
