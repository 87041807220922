import { useState, useRef } from "react";

interface NotificationProps {
  status: "success" | "error" | "warning" | "info";
  title: string;
  message: string;
  zindex?: string;
}

interface UseNotificationReturn {
  notification: NotificationProps & { actived: boolean };
  showNotification: (props: NotificationProps) => void;
  closeNotification: () => void;
}

export const useNotification = (): UseNotificationReturn => {
  const [notification, setNotification] = useState<
    NotificationProps & { actived: boolean }
  >({
    status: "info",
    title: "",
    message: "",
    zindex: "9999",
    actived: false,
  });

  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Para cancelar timers antigos

  const showNotification = (props: NotificationProps) => {
    setNotification({ ...props, actived: true });
    console.log("Notificação ativada:", { ...props, actived: true });

    setTimeout(() => {
      setNotification((prev) => ({ ...prev, actived: false }));
    }, 4000);
  };

  const closeNotification = () => {
    // Cancela o timeout atual se existir
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    // Fecha a notificação manualmente
    setNotification((prev) => ({ ...prev, actived: false }));
  };

  return { notification, showNotification, closeNotification };
};
