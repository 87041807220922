import constate from "constate";
import { useState } from "react";
import { ITemplate } from "~/services/api/template";

const [TemplateProvider, useTemplate] = constate(() => {
  const [templates, setTemplates] = useState<ITemplate[]>([]);

  return {
    templates,
    setTemplates,
  };
});

export { TemplateProvider };

export default useTemplate;
