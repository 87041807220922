import ApiService from "../api";

export type IOperationDetails = {
  uuid: string;
  status: string;
  debtor: {
    uuid: string;
    type: string;
    name: string;
    document_number: string;
    document_type: string;
    contact: string | null;
    address: string | null;
    foundation_date: string;
    cnae: string;
    fantasy_name: string;
    legal_nature: string;
    municipal_registration: string | null;
    state_registration: string | null;
    website: string | null;
    business_sector: string | null;
    employer_code: string | null;
    b3_account: string | null;
    subscriber_type: string | null;
  };
  operation: {
    uuid: string;
    status: string;
    nickname: string;
    operation_date: string;
    release_date: string;
    due_day: number;
    duration: number;
    number_of_installments: number;
    quantity_of_units: number;
    calculation_type: string;
    calculation_base: string;
    requested_amount: number;
    face_amount: number;
    payable_amount: number;
    net_amount: number;
    unit_amount: number;
    product: {
      uuid: string;
      name: string;
      product_type: string;
      amortization_type: string;
      external_label_name: string | null;
    };
    taxes_settings: {
      uuid: string;
      default: boolean;
      indexer: string;
      indexer_rate: number;
      interest_rate_monthly: number;
      interest_rate_annually: number;
      interest_periodicity: string;
      interest_grace_period: number;
      amortization_grace_period: number;
      iof_additional: number;
      iof_daily: number;
      cet_monthly: number;
      cet_annually: number;
      commission: number;
      fiduciary_guarante: number;
    };
    taxes_values: {
      uuid: string;
      iof_value: number;
      interest_value: number;
      emission_cost: number;
      commission_value: number;
    };
    installments: {
      number: number;
      outstanding_amount: number;
      amortization_amount: number;
      interest: number;
      pmt: number;
      due_date: string;
    }[];
  };
  involveds: {
    uuid: string;
    involved_partner_uuid: string;
    relationship: string;
    auth_type: string;
    send_type: string;
    related_to: string;
    name: string;
    is_signer: boolean;
    sign_weight: string | number;
  }[];
  documents: {
    uuid: string;
    type: string;
    file_url: string | null;
    file: string;
    description: string;
    related_to_uuid: string;
    related_to: string;
    operation_uuid: string;
  }[];
  commercial_note: {
    uuid: string;
    status: string;
    unit_amount: number;
    quantity_of_units: number;
    available_quantity_of_units: number;
    subscribers: {
      uuid: string;
      status: string;
      name: string;
      document_number: string;
      unit_amount_acquired: number;
      quantity_of_units_acquired: number;
      subscription_date: string;
    }[];
  };
};

export type IOperations = {
  uuid: string;
  status: string;
  operation_type: string;
  debtor_name: string;
  debtor_document: string;
  principal_amount: number;
  operation_date: string;
  interest_rate: number;
  product_type: string;
  product_uuid?: string;
};

export type IOperationBody = {
  operation_uuid: string;
  debtor_uuid: string;
  assignor_uuid: string;
  liquidation_bank_account_uuid: string;
  liquidation_type: string;
  receivables_register: boolean;
  batch_assignment: boolean;
  details: string;
};

type TaxesSettings = {
  uuid: string;
  fiduciary_guarantee?: number;
  default: boolean;
  indexer: string;
  indexer_rate: number;
  interest_rate_monthly: number;
  interest_rate_annually: number | null;
  interest_periodicity: string;
  interest_grace_period: number;
  amortization_grace_period: number;
  iof_additional: number;
  iof_daily: number;
  commission: number;
  fiduciary_guarante: number;
  cet_monthly: number;
  cet_annually: number;
};

type TaxesValues = {
  uuid: string;
  iof_value: number;
  interest_value: number;
  emission_cost: number;
  comission_value: number;
};

export type Installment = {
  number: number;
  outstanding_amount: number;
  amortization_amount: number;
  interest: number;
  pmt: number;
  due_date: string;
};

export type ISimulateOperation = {
  nickname: string;
  uuid: string;
  operation_date: string;
  release_date: string;
  due_date?: string;
  due_day?: string;
  unit_amount: string;
  duration: number;
  number_of_installments: number;
  calculation_type: string;
  calculation_base: string;
  requested_amount: number;
  face_amount: number;
  due_date_on_business: boolean;
  first_payment_date?: string;
  payment_every?: number;
  payment_periodicity?: string;
  payable_amount: number;
  legal_person?: boolean;
  net_amount: number;
  product: {
    uuid: string;
    name: string;
    product_type: string;
    amortization_type: string;
  };
  taxes_settings: TaxesSettings;
  taxes_values: TaxesValues;
  installments: Installment[];
};

export type ISimulateOperationBody = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: number;
  duration: number;
  calculation_base?: string;
  operation_date?: string;
  release_date: string;
  unit_amount?: number;
  due_date?: string;
  legal_person?: boolean | null;
  due_day?: string;
  payment_every?: string | number;
  payment_periodicity?: string;
  first_payment_date?: string;
  fiduciary_guarantee?: string;
  due_date_on_business?: boolean;
  taxes_settings: {
    fiduciary_guarantee?: number;
    interest_rate?: number;
    interest_periodicity?: string;
    amortization_grace_period: number;
    interest_grace_period?: number;
    commission: number;
    indexer?: string;
    indexer_rate?: number;
  };
};

export type ISimulateOperationBodyCCB = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: number;
  duration: number;
  calculation_base?: string;
  release_date: string;
  legal_person?: string;
  payment_every?: string;
  payment_periodicity?: string;
  first_payment_date?: string;
  fiduciary_guarantee?: string | number;
  due_date_on_business?: boolean;
  taxes_settings: {
    interest_rate_monthly?: number;
    amortization_grace_period: number;
    commission: number;
    indexer?: string;
    indexer_rate?: number;
  };
};

export type ISimulateOperationInstallmentPatch = {
  installment_number: number;
  amortization_amount?: number;
  interest?: number;
  due_date?: string;
};

export type IOperationInvolved = {
  relationship: string;
  auth_type: string;
  send_type: string;
  involved_partner_uuid: string;
  related_to_name: string;
  sign_weight: number | string;
  is_signer: boolean;
  related_to_uuid: string;
};

export type IOperationInvolvedBody = {
  asset_uuid: string;
  relationship: string;
  auth_type: string;
  send_type: string;
  involved_partner_uuid: string;
  related_to_uuid: string;
  sign_weight: number;
  is_signer: boolean;
};

export type IOperationInvolvedDocumentsBody = {
  related_to_uuid: string;
  type: string;
  description: string;
  file: string;
  operation_uuid: string;
  file_url: string;
};

export const getTemplateProduct = (params: { product_uuid: string }) => {
  return ApiService.HttpGet<IResponse<{ name: string; uuid: string }[]>>({
    route: "operation/template/",
    token: true,
    params,
  });
};

export const getOperationsAPI = (params?: { status?: string[] }) => {
  return ApiService.HttpGet<IResponse<IOperations[]>>({
    route: "asset/asset/",
    token: true,
    params,
  });
};

export const getOperationDetailAPI = (uuid: string) => {
  return ApiService.HttpGet<IResponse<IOperationDetails>>({
    route: `asset/detail/${uuid}/`,
    token: true,
  });
};

export const postOperationAPI = (data: IOperationBody) => {
  return ApiService.HttpPost<IResponse<IOperations>>({
    route: "asset/asset/",
    body: data,
    token: true,
  });
};

export const postConfirmationOperationAPI = (uuid: string) => {
  return ApiService.HttpPost<IResponse<IOperations>>({
    route: "asset/confirmation/",
    body: { asset_uuid: uuid },
    token: true,
  });
};

export const getSimulateOperationsAPI = (params?: { status?: string }) => {
  return ApiService.HttpGet<IResponse<ISimulateOperation[]>>({
    route: "operation/simulate/",
    token: true,
    params,
  });
};

export const getSimulateOperationAPI = (uuid: string) => {
  return ApiService.HttpGet<IResponse<ISimulateOperation>>({
    route: `operation/simulate/${uuid}/`,
    token: true,
  });
};

export const postSimulateOperationAPI = (data: ISimulateOperationBody) => {
  return ApiService.HttpPost<IResponse<ISimulateOperation>>({
    route: "operation/simulate/",
    body: data,
    token: true,
  });
};

export const patchSimulateOperationAPI = (
  uuid: string,
  data: ISimulateOperationInstallmentPatch
) => {
  return ApiService.HttpPatch<IResponse<ISimulateOperation>>({
    route: `operation/simulate/${uuid}/`,
    body: data,
    token: true,
  });
};

export const getOperationInvolvedsAPI = () => {
  return ApiService.HttpGet<IResponse<IOperationInvolved[]>>({
    route: "asset/involved/",
    token: true,
  });
};

export const postOperationInvolvedsAPI = (data: IOperationInvolvedBody) => {
  return ApiService.HttpPost({
    route: "asset/involved/",
    token: true,
    body: data,
  });
};

export const deleteOperationInvolvedAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `asset/involved/${id}/`,
    token: true,
  });
};

export const deleteOperationDocumentAPI = (id: string) => {
  return ApiService.HttpDelete({
    route: `asset/document/${id}/`,
    token: true,
  });
};

const boundary = "----WebKitFormBoundary7MA4YWxkTrZu0gW";

export const postOperationInvolvedDocumentsAPI = (data) => {
  return ApiService.HttpPost({
    route: "asset/document/",
    token: true,
    body: data,

    customHeaders: {
      "Content-Type": `multipart/form-data; boundary=${boundary}`,
    },
  });
};
