import { parseISO, differenceInYears, isBefore, startOfDay } from "date-fns";

export const validateAge = (birthDate: string): boolean => {
  const today = new Date();
  const parsedBirthDate = parseISO(birthDate);

  const age = differenceInYears(today, parsedBirthDate);

  return age >= 18;
};

export const isDateInThePast = (date: string): boolean => {
  const today = new Date();
  const parsedDate = startOfDay(parseISO(date));

  return isBefore(parsedDate, today);
};
