import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Input,
  InputCurrency,
  InputSelect,
  Modal,
  Table,
  Tile,
  Tooltip,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import ActivityIcon from "~/assets/Activity.png";
import ActivityBlueIcon from "~/assets/Activity_blue.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import EditIcon from "~/assets/Edit.png";

import openOperationIcon from "~/assets/side-panel--open.png";

import {
  getCreditProductAPI,
  getCreditProductDetailsAPI,
  ICreditProducts,
  IProductDetails,
} from "~/services/api/creditProduct";
import useOperation from "~/hooks/useOperation";

import {
  getSimulateOperationAPI,
  ISimulateOperationBody,
  patchSimulateOperationAPI,
  postSimulateOperationAPI,
} from "~/services/api/operations";
import {
  convertStringToNumber,
  convertStringCurrencyToNumber,
  formatNumberToCurrencyWithoutSimbol,
} from "~/services/utils";
import AppStorage from "~/services/storage";
import useLoading from "~/hooks/useLoading";
import {
  addDays,
  addMonths,
  addYears,
  format,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  isToday,
  parseISO,
  startOfDay,
} from "date-fns";
import { toast } from "react-toastify";
// import MoreIcon from "~/assets/Addd.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

type Values = {
  nickname: string;
  product_uuid: string;
  calculation_type: string;
  requested_amount: string;
  duration: string;
  calculation_base: string;
  operation_date: string;
  release_date: string;
  interest_rate_monthly: string;
  amortization_grace_period: string;
  commission: string;
  indexer: string;
  indexer_rate: string;
  amortization_amount: string | number;
  due_date_installment: string;
  interest: string | number;
  number: number;
  outstanding_amount: string | number;
  pmt: string | number;
  legal_person: boolean | null;
  payment_every: string;
  payment_periodicity: string;
  first_payment_date: string;

  fiduciary_guarantee: string;
  due_date_on_business: boolean;
};

const initialValues: Values = {
  nickname: "",
  product_uuid: "",
  calculation_type: "",
  requested_amount: "10.000,00",
  duration: "2",
  calculation_base: "",
  operation_date: "",
  release_date: "",
  interest_rate_monthly: "",
  amortization_grace_period: "",
  commission: "0",
  indexer: "",
  indexer_rate: "0",
  amortization_amount: 0,
  due_date_installment: "",
  interest: formatNumberToCurrencyWithoutSimbol(1000),
  number: 0,
  outstanding_amount: "",
  pmt: "",
  legal_person: null,
  payment_every: "1",
  payment_periodicity: "",
  first_payment_date: "",

  fiduciary_guarantee: "0",
  due_date_on_business: false,
};

const SimulationCCB = () => {
  const navigate = useNavigate();

  const {
    setPayloadSimulateOperation,
    setSimulateResult,
    simulateResult,
    setCreditProducts,
    creditProducts,
    editInstallment,
    setEditInstallment,
    installmentData,
    setInstallmentData,
  } = useOperation();

  const [errorInterestRate, setErrorInterestRate] = useState<boolean>(false);
  const [errorAmortizationPeriod, setErrorAmortizationPeriod] =
    useState<boolean>(false);
  const [errorCommission, setErrorCommission] = useState<boolean>(false);
  const [errorPrincipalAmount, setErrorPrincipalAmount] =
    useState<boolean>(false);

  const [errorDuration, setErrorDuration] = useState<boolean>(false);

  const [errorReleaseDate, setErrorReleaseDate] = useState<boolean>(false);
  const [errorFirstPaymentDate, setErrorFirstPaymentDate] =
    useState<string>("");

  const { setLoading } = useLoading();

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const titlesColumnInstallments = [
    "Parcela",
    "Vencimento",
    "Saldo devedor",
    "Amortização",
    "Juros",
    "Pagamento",
    "",
  ];

  const dataTableInstallments = simulateResult?.installments.map(
    (installment) => {
      return {
        Parcela: installment.number,
        Vencimento: format(parseISO(installment.due_date), "dd/MM/yyyy"),
        "Saldo devedor": `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.outstanding_amount
        )}`,
        Amortização: `R$ ${formatNumberToCurrencyWithoutSimbol(
          installment.amortization_amount
        )}`,
        Juros: `R$ ${formatNumberToCurrencyWithoutSimbol(installment.interest)}`,
        Pagamento: `R$ ${formatNumberToCurrencyWithoutSimbol(installment.pmt)}`,
        "": simulateResult.product.product_type !== "ccb" && (
          <div
            style={{
              display: "flex",
              position: "relative",
              left: "-60px",
              height: "1px",
            }}
          >
            <Tooltip
              align={
                simulateResult.product.amortization_type === "sac"
                  ? "top"
                  : "top-right"
              }
              size="large"
              label={
                simulateResult.product.amortization_type !== "sac"
                  ? "Não é possivel editar parcelas quando a amortização não é do tipo SAC"
                  : "Editar parcela"
              }
            >
              <Button
                kind="ghost"
                size="small"
                icon={EditIcon}
                // disabled={simulateResult.product.amortization_type !== "sac"}
                onClick={() => {
                  setInstallmentData(installment);
                  setEditInstallment(true);
                }}
              />
            </Tooltip>
          </div>
        ),
      };
    }
  );

  const canDoSimulations = true;

  const indexesOptions = useMemo(() => {
    return [
      { index: "SELIC", value: "selic" },
      { index: "IGPM", value: "igpm" },
      { index: "IPCA", value: "ipca" },
      { index: "CDI", value: "cdi" },
      { index: "TR", value: "tr" },
      { index: "INPC", value: "inpc" },
      { index: "Nenhum", value: "no_one" },
    ].map((index) => ({
      value: index.value,
      label: index.index,
    }));
  }, []);

  const calculationBaseOptions = useMemo(() => {
    return [
      { value: "net_value", label: "Líquido" },
      { value: "installment_value", label: "Pagamento" },
      { value: "gross_value", label: "Bruto" },
    ].sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, []);

  const periodicityPaymentOptions = useMemo(() => {
    return [
      { label: "Dias", value: "daily" },
      { label: "Meses", value: "monthly" },
      { label: "Anos", value: "annually" },
    ]
      .map((periodicity) => ({
        value: periodicity.value,
        label: periodicity.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const creditProductsOptions = useMemo(() => {
    return creditProducts
      .filter(
        (creditProduct: ICreditProducts) =>
          creditProduct?.product_type === "ccb"
      )
      .map((creditProduct: ICreditProducts) => ({
        value: creditProduct.uuid,
        label: creditProduct.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [creditProducts]);

  const calculationTypeOptions = useMemo(() => {
    return [
      { value: "252_daily", label: "Base 252 (Dias Úteis)" },
      { value: "252_monthly", label: "Base 252 (Meses x 21)" },
      { value: "360_daily", label: "Base 360 (Dias Corridos)" },
      { value: "360_monthly", label: "Base 360 (Meses)" },
      { value: "365_daily", label: "Base 365 (Dias Corridos)" },
      { value: "365_monthly", label: "Base 365 (Meses)" },
    ]
      .map((type) => ({
        value: type.value,
        label: type.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const personOptions = useMemo(() => {
    return [
      { value: String(true), label: "Pessoa Jurídica" },
      { value: String(false), label: "Pessoa Física" },
    ]
      .map((type) => ({
        value: type.value,
        label: type.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const indexesDict: { [key: string]: string } = {
    selic: "SELIC",
    ipca: "IPCA",
    cdi: "CDI",
    no_one: "Nenhum",
    tr: "TR",
    inpc: "INPC",
    igpm: "IGPM",
  };

  const payloadSimulateOperation: ISimulateOperationBody = useMemo(() => {
    const taxesSettings: ISimulateOperationBody["taxes_settings"] = {
      interest_rate: values.interest_rate_monthly?.includes(",")
        ? convertStringToNumber(values.interest_rate_monthly)
        : Number(values.interest_rate_monthly),
      amortization_grace_period: Number(values.amortization_grace_period),
      fiduciary_guarantee: Number(values.fiduciary_guarantee),
      commission: values.commission?.includes(",")
        ? convertStringToNumber(values.commission)
        : Number(values.commission),
      ...(values.indexer !== "no_one"
        ? {
            indexer: values.indexer,
            indexer_rate: values.indexer_rate?.includes(",")
              ? convertStringToNumber(values.indexer_rate)
              : Number(values.indexer_rate),
          }
        : {}),
    };

    return {
      nickname: values.nickname,
      product_uuid: values.product_uuid,
      calculation_type: values.calculation_type,
      requested_amount: convertStringToNumber(values.requested_amount),
      duration: Number(values.duration),
      calculation_base: values.calculation_base,
      operation_date: values.operation_date,
      payment_every: Number(values.payment_every),
      due_date_on_business: values.due_date_on_business,
      first_payment_date: values.first_payment_date,
      payment_periodicity: values.payment_periodicity,
      release_date: values.release_date,
      legal_person: Boolean(values.legal_person),
      taxes_settings: taxesSettings,
    };
  }, [values]);

  const sendNewSimulate = useCallback(async (): Promise<void> => {
    setLoading(true);
    setSimulateResult(null);
    try {
      const payloadSimulateOperation = AppStorage.get(
        "@:simulate_operation"
      ) as string;
      const payloadSimulateOperationParsed = JSON.parse(
        payloadSimulateOperation
      );

      const { data } = await postSimulateOperationAPI(
        payloadSimulateOperationParsed
      );
      setSimulateResult(data.payload);

      toast.success("Simulação gerada com sucesso!");

      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível gerar a simulação !");

      setLoading(false);
    }
  }, [setSimulateResult, setLoading]);

  const fetchCreditProducts = useCallback(async () => {
    try {
      const { data } = await getCreditProductAPI();
      setCreditProducts(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setCreditProducts]);

  const sendUpdateInstallment = useCallback(async () => {
    setLoading(true);
    if (simulateResult && installmentData) {
      const payload = {
        installment_number: installmentData.number,
        amortization_amount: convertStringCurrencyToNumber(
          values.amortization_amount as string
        ),
        interest: convertStringCurrencyToNumber(values.interest as string),
        due_date: values.due_date_installment,
      };
      try {
        const { data } = await patchSimulateOperationAPI(
          simulateResult.uuid,
          payload
        );

        setLoading(false);
        setEditInstallment(false);
        setSimulateResult(data.payload);
        toast.success("Parcela atualizada com sucesso!");
      } catch (e) {
        console.log(e);
        setLoading(false);
        toast.error("Não foi possível alterar a parcela!");
      }
    }
  }, [
    simulateResult,
    installmentData,
    setLoading,
    values,
    setEditInstallment,
    setSimulateResult,
  ]);

  const fetchSimulation = async () => {
    setLoading(true);

    try {
      if (simulateResult?.uuid) {
        const { data } = await getSimulateOperationAPI(simulateResult?.uuid);

        const simulation = data.payload;

        localStorage.setItem(
          "@:simulation_uuid",
          JSON.stringify({ simulation_uuid: simulateResult?.uuid })
        );

        const payload = {
          nickname: simulation.nickname,
          product_uuid: simulation.product.uuid,
          calculation_type: simulation.calculation_type,
          requested_amount: String(simulation.requested_amount),
          duration: simulation.duration,
          calculation_base: simulation.calculation_base,
          first_payment_date: simulation.first_payment_date,
          release_date: simulation.release_date,
          legal_person: simulation.legal_person
            ? "legal_person"
            : "not_legal_person",
          payment_every: simulation.payment_every,
          due_date_on_business: simulation.due_date_on_business,
          payment_periodicity: simulation.payment_periodicity,
          taxes_settings: {
            fiduciary_guarantee: simulation.taxes_settings.fiduciary_guarantee,
            interest_rate_monthly:
              simulation.taxes_settings.interest_rate_monthly,
            interest_periodicity:
              simulation.taxes_settings.interest_periodicity,
            amortization_grace_period:
              simulation.taxes_settings.amortization_grace_period,
            interest_grace_period:
              simulation.taxes_settings.interest_grace_period,
            commission: simulation.taxes_settings.commission,
            indexer: simulation.taxes_settings.indexer,
            indexer_rate: simulation.taxes_settings.indexer_rate,
          },
        };

        localStorage.setItem("@:simulate_operation", JSON.stringify(payload));

        navigate("/app/credit/operations/new/ccb/product-info");
        setSimulateResult(null);

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível recuperar a simulação !");
      setLoading(false);
    }
  };

  const printRef = useRef<HTMLDivElement>(null);

  const validateFirstPaymentDate = useCallback(() => {
    const {
      first_payment_date,
      payment_every,
      payment_periodicity,
      release_date,
    } = values;

    const currentDate = new Date(release_date);

    if (!first_payment_date) {
      return null;
    }

    const firstPaymentDate = parseISO(first_payment_date);

    let minPaymentDate: Date;
    switch (payment_periodicity) {
      case "daily":
        minPaymentDate = addDays(currentDate, Number(payment_every));
        break;
      case "monthly":
        minPaymentDate = addMonths(currentDate, Number(payment_every));
        break;
      case "annually":
        minPaymentDate = addYears(currentDate, Number(payment_every));
        break;
      default:
        return "Periodicidade do pagamento inválida";
    }

    if (isBefore(firstPaymentDate, minPaymentDate)) {
      return `A data do primeiro pagamento deve ser posterior a ${format(minPaymentDate, "dd/MM/yyyy")}.`;
    }

    return null;
  }, [values]);

  useEffect(() => {
    validateFirstPaymentDate();
  }, [validateFirstPaymentDate]);

  useEffect(() => {
    fetchCreditProducts();
  }, [fetchCreditProducts]);

  useEffect(() => {
    if (installmentData) {
      setFieldValue("outstanding_amount", installmentData.outstanding_amount);
      setFieldValue("due_date_installment", installmentData.due_date);
      setFieldValue("pmt", String(installmentData.pmt));
    }
  }, [installmentData, setFieldValue]);

  useEffect(() => {
    setPayloadSimulateOperation(payloadSimulateOperation);
    localStorage.setItem(
      "@:simulate_operation",
      JSON.stringify(payloadSimulateOperation)
    );
  }, [payloadSimulateOperation, setPayloadSimulateOperation]);

  const [productDetails, setProductDetails] = useState<IProductDetails>();

  const fetchProductDetails = useCallback(async (uuid: string) => {
    try {
      const { data } = await getCreditProductDetailsAPI(uuid);
      setProductDetails(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (values.product_uuid) {
      fetchProductDetails(values.product_uuid);
    }
  }, [values.product_uuid, fetchProductDetails]);

  const [errorCalculationType, setErrorCalculationType] =
    useState<boolean>(false);
  const validateCalculationType = useCallback(() => {
    const { payment_periodicity, calculation_type } = values;

    if (!payment_periodicity || !calculation_type) {
      setErrorCalculationType(false);
      return;
    }

    const validOptions = {
      daily: ["252_daily", "360_daily", "365_daily"],
      monthly: ["252_monthly", "360_monthly", "365_monthly"],
      annually: [
        "252_monthly",
        "360_daily",
        "360_monthly",
        "365_daily",
        "365_monthly",
      ],
    };

    const isValid =
      validOptions[payment_periodicity]?.includes(calculation_type);

    setErrorCalculationType(!isValid);
  }, [values]);

  useEffect(() => {
    validateCalculationType();
  }, [validateCalculationType]);

  useEffect(() => {
    const interestRate = values.interest_rate_monthly?.includes(",")
      ? convertStringToNumber(values.interest_rate_monthly)
      : Number(values.interest_rate_monthly);

    const commissionRate = values.commission?.includes(",")
      ? convertStringToNumber(values.commission)
      : Number(values.commission);

    const parsedReleaseDate = startOfDay(parseISO(values.release_date));
    const parsedFirstPaymentDate = startOfDay(
      parseISO(values.first_payment_date)
    );

    if (productDetails) {
      if (
        interestRate < productDetails?.min_interest_rate ||
        interestRate > productDetails?.max_interest_rate
      ) {
        setErrorInterestRate(true);
      } else {
        setErrorInterestRate(false);
      }

      if (
        Number(values.amortization_grace_period) <
          productDetails?.min_amortization_period ||
        Number(values.amortization_grace_period) >
          productDetails?.max_amortization_period
      ) {
        setErrorAmortizationPeriod(true);
      } else {
        setErrorAmortizationPeriod(false);
      }

      if (
        convertStringToNumber(values.requested_amount) >
          productDetails.max_principal_amount ||
        convertStringToNumber(values.requested_amount) <
          productDetails.min_principal_amount
      ) {
        setErrorPrincipalAmount(true);
      } else {
        setErrorPrincipalAmount(false);
      }
    }

    if (Number(values.duration) < 1) {
      setErrorDuration(true);
    } else {
      setErrorDuration(false);
    }
    if (commissionRate < 0 || commissionRate > 100) {
      setErrorCommission(true);
    } else {
      setErrorCommission(false);
    }

    if (isToday(parsedReleaseDate) || isPast(parsedReleaseDate)) {
      setErrorReleaseDate(true);
    } else {
      setErrorReleaseDate(false);
    }

    if (isToday(parsedFirstPaymentDate) || isPast(parsedFirstPaymentDate)) {
      setErrorFirstPaymentDate(
        "A data do primeiro pagamento precisa ser igual ou posterior a data atual"
      );
    } else if (
      isAfter(parsedReleaseDate, parsedFirstPaymentDate) ||
      isEqual(parsedReleaseDate, parsedFirstPaymentDate)
    ) {
      setErrorFirstPaymentDate(
        "A data do primeiro pagamento precisa ser posterior a data de lançamento"
      );
    } else {
      setErrorFirstPaymentDate("");
    }
  }, [values, productDetails]);

  useEffect(() => {
    if (values.indexer === "no_one") {
      setFieldValue("indexer_rate", "0");
    } else if (values.indexer !== "no_one" && values.indexer_rate === "0") {
      setFieldValue("indexer_rate", "100");
    }
  }, [values, setFieldValue]);

  useEffect(() => {
    if (productDetails) {
      setFieldValue(
        "interest_rate_monthly",
        productDetails?.min_interest_rate !== undefined &&
          String(productDetails?.min_interest_rate)
      );

      setFieldValue(
        "amortization_grace_period",
        productDetails?.min_amortization_period !== undefined &&
          String(productDetails?.min_amortization_period)
      );
    }
    setFieldValue("duration", "1");
    setFieldValue("commission", "0");
  }, [productDetails, setFieldValue]);

  useEffect(() => {
    setSimulateResult(null);
  }, [setSimulateResult]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          { crumb: "Início", onClick: () => navigate("/app") },
          { crumb: "Crédito" },
          {
            crumb: "Simulações",
            onClick: () => navigate("/app/credit/simulations"),
          },
          { crumb: "Nova simulação (CCB)" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Margin mb={30} />
                  <Text style={{ fontSize: "25px" }}>
                    Simulação CCB{" "}
                    {simulateResult?.uuid && `- ID: ${simulateResult.uuid}`}
                  </Text>
                  <Margin mb={20} />
                  <h4>Parâmetros da operação</h4>
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  {/* <Button
                    kind="tertiary"
                    label="Imprimir"
                    size="large"
                    icon={PrinterIcon}
                  /> */}
                  {/* 
                  <Button
                    kind={"tertiary"}
                    label="Imprimir"
                    size="large"
                    // onClick={() => reactToPrintFn()}
                  /> */}
                  <Button
                    kind={simulateResult?.nickname ? "tertiary" : "primary"}
                    label="Simular"
                    size="large"
                    icon={
                      simulateResult?.nickname ? ActivityBlueIcon : ActivityIcon
                    }
                    onClick={() => sendNewSimulate()}
                    disabled={
                      (values.calculation_type !== "252_daily" &&
                        values.calculation_type !== "252_monthly" &&
                        (values.indexer === "cdi" ||
                          values.indexer === "selic")) ||
                      !values.amortization_grace_period ||
                      !values.calculation_base ||
                      !values.calculation_type ||
                      !values.commission ||
                      values.legal_person === null ||
                      !values.duration ||
                      !values.fiduciary_guarantee ||
                      Number(values.fiduciary_guarantee) > 100 ||
                      validateFirstPaymentDate() !== null ||
                      !values.indexer ||
                      !values.payment_every ||
                      !values.payment_periodicity ||
                      !values.release_date ||
                      !values.first_payment_date ||
                      values.payment_every === "0" ||
                      (values.indexer !== "no_one" &&
                        convertStringToNumber(values.indexer_rate) < 1) ||
                      !values.interest_rate_monthly ||
                      !values.nickname ||
                      errorAmortizationPeriod ||
                      errorCommission ||
                      errorCalculationType ||
                      errorDuration ||
                      errorInterestRate ||
                      errorPrincipalAmount ||
                      errorReleaseDate ||
                      errorFirstPaymentDate !== ""
                    }
                  />

                  {simulateResult?.nickname && (
                    <Button
                      kind={"primary"}
                      label="Abrir operação"
                      size="large"
                      icon={openOperationIcon}
                      onClick={() => fetchSimulation()}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gap: "32px",
                  rowGap: "30px",
                  marginTop: "20px",
                }}
              >
                <div>
                  {" "}
                  <Input
                    name="nickname"
                    id="nickname"
                    value={values.nickname}
                    size="large"
                    label="Nome (Apelido)"
                    placeholder="Digite"
                    onChange={(e) => setFieldValue("nickname", e.target.value)}
                  />
                </div>
                <div>
                  {" "}
                  <InputSelect
                    name="product_uuid"
                    kind="default"
                    size="small"
                    label="Produto"
                    options={creditProductsOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("product_uuid", e)}
                  />
                </div>
                <div>
                  {" "}
                  <InputSelect
                    name="legal_person"
                    kind="default"
                    size="small"
                    label="Tipo de pessoa"
                    options={personOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("legal_person", e)}
                  />
                </div>
                <div>
                  <InputSelect
                    name="calculation_type"
                    kind="default"
                    size="small"
                    label="Tipo de cálculo"
                    options={calculationTypeOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("calculation_type", e)}
                    error={
                      (values.calculation_type !== "252_daily" &&
                        values.calculation_type !== "252_monthly" &&
                        (values.indexer === "cdi" ||
                          values.indexer === "selic")) ||
                      errorCalculationType
                    }
                    message={
                      errorCalculationType
                        ? "Combinação de Periodicidade de pagamento e Tipo de Cálculo inválida"
                        : values.indexer === "cdi" &&
                            values.calculation_type !== "252_daily" &&
                            values.calculation_type !== "252_monthly"
                          ? "Para o indexador pós-fixado 'CDI' mude o tipo de cálculo para algum com base em 252 dias"
                          : values.indexer === "selic" &&
                              values.calculation_type !== "252_daily" &&
                              values.calculation_type !== "252_monthly"
                            ? "Para o indexador pós-fixado 'SELIC' mude o tipo de cálculo para algum com base em 252 dias"
                            : ""
                    }
                  />
                </div>
                <div>
                  <InputCurrency
                    name="requested_amount"
                    max={24}
                    maxLength={24}
                    id="requested_amount"
                    size="large"
                    label="Valor solicitado (R$)"
                    placeholder="R$"
                    error={errorPrincipalAmount}
                    message={
                      productDetails && errorPrincipalAmount
                        ? ` Digite um valor entre R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.min_principal_amount)} e R$ ${formatNumberToCurrencyWithoutSimbol(productDetails.max_principal_amount)}`
                        : ""
                    }
                    value={values.requested_amount}
                    onChange={(e) =>
                      setFieldValue("requested_amount", e.target.value)
                    }
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="interest_rate_monthly"
                    id="interest_rate_monthly"
                    maxLength={6}
                    max={6}
                    size="large"
                    label="Taxa de juros (%)"
                    placeholder="%"
                    message={
                      errorInterestRate
                        ? `Informe um valor entre ${productDetails?.min_interest_rate}% e ${productDetails?.max_interest_rate}%`
                        : ""
                    }
                    error={errorInterestRate}
                    value={values.interest_rate_monthly}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        return;
                      }

                      setFieldValue("interest_rate_monthly", value);
                    }}
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="duration"
                    id="duration"
                    maxLength={2}
                    max={2}
                    error={errorDuration}
                    size="large"
                    label="Duração (Em meses)"
                    value={values.duration}
                    placeholder="Meses"
                    message={
                      errorDuration ? "Digite um valor maior que 0" : "Em meses"
                    }
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9]/g, "");
                      setFieldValue("duration", value);
                    }}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="amortization_grace_period"
                    id="amortization_grace_period"
                    maxLength={2}
                    max={2}
                    size="large"
                    label="Carência de amortização (Em meses)"
                    placeholder="Meses"
                    message={
                      values.amortization_grace_period &&
                      values.duration &&
                      Number(values.amortization_grace_period) >=
                        Number(values.duration)
                        ? "A Carência de amortização não pode ser maior ou igual ao valor da Duração"
                        : errorAmortizationPeriod
                          ? ` Informe um valor entre ${productDetails?.min_amortization_period}  e ${productDetails?.max_amortization_period}`
                          : "Em meses"
                    }
                    error={
                      values.amortization_grace_period &&
                      values.duration &&
                      Number(values.amortization_grace_period) >=
                        Number(values.duration)
                        ? true
                        : errorAmortizationPeriod
                          ? true
                          : false
                    }
                    value={values.amortization_grace_period}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9]/g, "");
                      setFieldValue("amortization_grace_period", value);
                    }}
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <InputSelect
                    name="calculation_base"
                    kind="default"
                    size="small"
                    label="Base de cálculo"
                    options={calculationBaseOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("calculation_base", e)}
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    type="date"
                    name="release_date"
                    size="large"
                    label="Data de lançamento"
                    placeholder="Digite"
                    error={errorReleaseDate}
                    message={
                      errorReleaseDate
                        ? "A data de lançamento precisa ser igual ou posterior a data atual"
                        : ""
                    }
                    value={values.release_date}
                    onChange={(e) =>
                      setFieldValue("release_date", e.target.value)
                    }
                  />
                </div>
                <div>
                  {" "}
                  <Input
                    name="payment_every"
                    id="payment_every"
                    maxLength={3}
                    max={3}
                    size="large"
                    label="Pagamento a cada"
                    placeholder=""
                    error={values.payment_every === "0"}
                    message={
                      values.payment_every === "0"
                        ? "Digite um valor maior que 0"
                        : ""
                    }
                    value={values.payment_every}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9]/g, "");
                      setFieldValue("payment_every", value);
                    }}
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <InputSelect
                    name="payment_periodicity"
                    kind="default"
                    size="small"
                    label="Periodicidade do pagamento"
                    error={errorCalculationType}
                    message={
                      errorCalculationType
                        ? "Combinação de Periodicidade de pagamento e Tipo de Cálculo inválida"
                        : ""
                    }
                    options={periodicityPaymentOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("payment_periodicity", e)}
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    type="date"
                    name="first_payment_date"
                    size="large"
                    label="Data do primeiro pagamento"
                    placeholder="Digite"
                    error={
                      errorFirstPaymentDate !== "" ||
                      validateFirstPaymentDate() !== null
                    }
                    message={
                      validateFirstPaymentDate() !== null
                        ? String(validateFirstPaymentDate())
                        : errorFirstPaymentDate !== ""
                          ? errorFirstPaymentDate
                          : ""
                    }
                    value={values.first_payment_date}
                    onChange={(e) =>
                      setFieldValue("first_payment_date", e.target.value)
                    }
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="commission"
                    id="commission"
                    size="large"
                    error={errorCommission || values.commission === ""}
                    message={
                      errorCommission || values.commission === ""
                        ? "Informe um valor entre 0 e 100"
                        : ""
                    }
                    maxLength={6}
                    max={6}
                    label="Correspondente bancário (%)"
                    placeholder="%"
                    value={values.commission}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        return;
                      }

                      setFieldValue("commission", value);
                    }}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="fiduciary_guarantee"
                    id="fiduciary_guarantee"
                    size="large"
                    maxLength={6}
                    max={6}
                    label="Garantia fiduciária (%)"
                    error={Number(values.fiduciary_guarantee) > 100}
                    message={
                      Number(values.fiduciary_guarantee) > 100
                        ? "Digite um valor menor que 100"
                        : ""
                    }
                    placeholder="%"
                    value={values.fiduciary_guarantee}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        return;
                      }

                      setFieldValue("fiduciary_guarantee", value);
                    }}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <InputSelect
                    name="indexer"
                    kind="default"
                    size="small"
                    label="Indexador pós-fixado"
                    options={indexesOptions}
                    placeholder="Selecione uma opção"
                    onChange={(e) => handleSelect("indexer", e)}
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    name="indexer_rate"
                    id="indexer_rate"
                    size="large"
                    disabled={!values.indexer || values.indexer === "no_one"}
                    maxLength={6}
                    max={6}
                    value={values.indexer_rate}
                    label="Percentual indexador (%)"
                    placeholder="%"
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^\d.]/g, "");
                      const parts = value.split(".");
                      if (parts.length > 2) {
                        return;
                      }

                      setFieldValue("indexer_rate", value);
                    }}
                    error={
                      values.indexer !== "no_one" &&
                      Number(values.indexer_rate) < 1
                    }
                    message={
                      values.indexer !== "no_one" &&
                      Number(values.indexer_rate) < 1
                        ? "Digite um valor maior que 0"
                        : ""
                    }
                  />
                </div>

                <div
                  style={{
                    position: "relative",
                    top: "37px",
                    width: "340px",
                    height: "10px",
                  }}
                >
                  <Checkbox
                    checked={values.due_date_on_business}
                    onChange={() =>
                      setFieldValue(
                        "due_date_on_business",
                        !values.due_date_on_business
                      )
                    }
                  />
                  <p
                    style={{ position: "relative", top: "-23px", left: "30px" }}
                  >
                    Data de vencimento no próximo dia útil
                  </p>
                </div>
                {/* <div>
                  <Checkbox
                    label="Incluir valor na parcela fixa"
                    onClick={() => null}
                  />
                </div> */}
              </div>

              <Margin mt={50} />

              {simulateResult?.calculation_type && (
                <div ref={printRef}>
                  {" "}
                  <h4>Resultados da operação</h4>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "32px",
                      rowGap: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Data de emissão
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.operation_date
                            ? format(
                                simulateResult?.operation_date,
                                "dd/MM/yyyy"
                              )
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Dia de vencimento
                        </h4>
                        <Margin mt={17} />
                        <p>{simulateResult?.due_day}</p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Prazo
                        </h4>
                        <Margin mt={17} />
                        <p>{simulateResult?.duration} Meses</p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          IOF
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.taxes_values.iof_value ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Valor do contrato
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.requested_amount ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Valor líquido
                        </h4>
                        <Margin mt={17} />
                        <p>
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.net_amount ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Custo da emissão
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {" "}
                          R${" "}
                          {formatNumberToCurrencyWithoutSimbol(
                            simulateResult?.taxes_values?.emission_cost ?? 0
                          )}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Indexador
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings?.indexer
                            ? indexesDict[
                                simulateResult?.taxes_settings?.indexer
                              ]
                            : "-"}
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Taxa A.M.
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings.interest_rate_monthly?.toFixed(
                            4
                          )}{" "}
                          %
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          Taxa A.A.
                        </h4>
                        <Margin mt={17} />
                        <p>
                          {simulateResult?.taxes_settings.interest_rate_annually?.toFixed(
                            4
                          )}{" "}
                          %
                        </p>
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          CET A.M.
                        </h4>
                        <Margin mt={17} />
                        {simulateResult?.taxes_settings.cet_monthly?.toFixed(
                          4
                        )}{" "}
                        %
                      </div>
                    </Tile>
                    <Tile variations="default">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          padding: "10px 20px",
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "400",
                            fontFamily: "sans-serif",
                            fontSize: "18px",
                          }}
                        >
                          CET A.A.
                        </h4>
                        <Margin mt={17} />
                        {simulateResult?.taxes_settings.cet_annually?.toFixed(
                          4
                        )}{" "}
                        %
                      </div>
                    </Tile>
                  </div>
                </div>
              )}
            </>
          )}

          <Margin mt={50} />
          {simulateResult?.calculation_type && (
            <div ref={printRef}>
              {" "}
              <Table
                columnTitles={titlesColumnInstallments}
                rowData={dataTableInstallments ?? []}
                width="1000px"
                radius
                // buttons={[
                //   {
                //     kind: "primary",
                //     position: "right",
                //     label: "Adicionar parcelas",
                //     icon: MoreIcon,
                //   },
                // ]}
                footer={{
                  ht: "50px",
                  bg: "",
                  element: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        padding: "8px",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                          position: "relative",
                          left: "-15px",
                        }}
                      >
                        Total: R${" "}
                        {formatNumberToCurrencyWithoutSimbol(
                          simulateResult?.payable_amount
                        )}
                      </p>{" "}
                    </div>
                  ),
                }}
              />
            </div>
          )}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ~visualizar as simulações <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
      {/* 
      <LateralModal
        toggleModal={() => null}
        open
        title="Novo"
        header
      ></LateralModal> */}

      <Modal
        height="600px"
        width="755px"
        background="#f7f4f4"
        onClose={() => setEditInstallment(false)}
        label={
          <div>
            <h4>
              {" "}
              Simulação CCB{" "}
              {simulateResult?.uuid ? ` - ID: ${simulateResult.uuid}` : ""}
            </h4>
            <h4>Parcela {installmentData?.number}</h4>
          </div>
        }
        buttons={[
          {
            kind: "ghost",
            label: "Cancelar",
            onClick: () => setEditInstallment(false),
          },
          {
            kind: "primary",
            label: "Salvar",
            onClick: () => {
              sendUpdateInstallment();
            },
          },
        ]}
        open={editInstallment}
        radius
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
            padding: "20px",
          }}
        >
          <InputCurrency
            label="Valor para amortização"
            name="amortization_amount"
            id="amortization_amount"
            size="medium"
            onChange={(e) =>
              setFieldValue("amortization_amount", e.target.value)
            }
            value={values.amortization_amount}
          />
          <InputCurrency
            name="interest"
            id="interest"
            label="Juros"
            onChange={(e) => setFieldValue("interest", e.target.value)}
            value={values.interest}
          />

          <Input
            name="due_date_installment"
            id="due_date_installment"
            size="large"
            type="date"
            label="Data de vencimento"
            onChange={(e) =>
              setFieldValue("due_date_installment", e.target.value)
            }
            value={values.due_date_installment}
          />

          <Input
            name="outstanding_amount"
            id="outstanding_amount"
            size="large"
            label="Saldo devedor"
            readOnly
            placeholder={`R$ ${formatNumberToCurrencyWithoutSimbol(
              values.outstanding_amount
            )}`}
            onChange={(e) =>
              setFieldValue("outstanding_amount", e.target.value)
            }
          />
        </div>
      </Modal>
    </Container>
  );
};

export default SimulationCCB;
