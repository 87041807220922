import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Tile } from "prosperita-dumbo-react";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const canDoSimulations = true;

const SelectTypeContact = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          { crumb: "Início", onClick: () => navigate("/app") },
          { crumb: "Crédito" },
          {
            crumb: "Contatos",
            onClick: () => navigate("/app/contacts"),
          },
          { crumb: "Novo Contato" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <div style={{ width: "500px" }}>
              <Margin mt={40} />

              <Text style={{ fontSize: "25px" }}>
                Primeiro, escolha o tipo de contato que deseja cadastrar
              </Text>

              <Margin mt={50} />
              <Tile
                variations="clickable"
                label={"Pessoa Física"}
                textlabel={"Cadastro para Pessoas Físicas"}
                onClick={() => navigate("/app/contacts/new/person/basic-data")}
              />
              <Margin mt={10} />

              <Tile
                variations="clickable"
                label={"Pessoa Jurídica"}
                textlabel={"Cadastro para Pessoas Jurídicas"}
                onClick={() => navigate("/app/contacts/new/company/basic-data")}
              />
            </div>
          )}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ~visualizar as simulações <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default SelectTypeContact;
