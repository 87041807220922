import React from "react";
import styled from "styled-components";
import DoneIcon from "~/assets/Checkmark--outline.png";
import ActiveIcon from "~/assets/Incomplete.png";
import CircleIcon from "~/assets/Circle-dash.png";

interface Step {
  label: string;
  isCompleted: boolean;
  isActive: boolean;
}

interface StepperProps {
  steps: Step[];
}

const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  position: relative;
`;

const StepLine = styled.div<{ completedWidth: number }>`
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ccc;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: ${({ completedWidth }) => completedWidth}%;
    background-color: #000b3b;
  }
`;

const StepContainer = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isActive, isCompleted }) =>
    isCompleted ? "black" : isActive ? "black" : "black"};
  position: relative;
  top: 20px;
`;

const StepIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const StepLabel = styled.span`
  font-size: 14px;
`;

const Stepper: React.FC<StepperProps> = ({ steps }) => {
  const activeIndex = steps.findIndex((step) => step.isActive);
  const completedWidth = ((activeIndex + 1) / steps.length) * 100;

  return (
    <StepperContainer>
      <StepLine completedWidth={completedWidth} />
      {steps.map((step, index) => (
        <StepContainer
          key={index}
          isActive={step.isActive}
          isCompleted={step.isCompleted}
        >
          <StepIcon
            src={
              step.isCompleted
                ? DoneIcon
                : step.isActive
                  ? ActiveIcon
                  : CircleIcon
            }
            alt={`${step.label} icon`}
          />
          <StepLabel>{step.label}</StepLabel>
        </StepContainer>
      ))}
    </StepperContainer>
  );
};

export default Stepper;
