import ApiService from "../api";

export type ITemplate = {
  uuid: string;
  product_uuid: string;
  name: string;
  status: "active" | "inactive";
  updated_date: string;
  filename: string;
  html_content: string;
  css_stylesheet: string;
  pdf_file: string | null;
  product_name;
};

export type IBodyTemplate = {
  product_uuid: string;
  name: string;
  html_content: string;
  css_stylesheet: string;
};

export const getTemplatesAPI = () => {
  return ApiService.HttpGet<IResponse<ITemplate[]>>({
    route: `operation/template/`,
    token: true,
  });
};

export const getTemplateAPI = (uuid: string) => {
  return ApiService.HttpGet<IResponse<ITemplate>>({
    route: `operation/template/detail/${uuid}/`,
    token: true,
  });
};

export const postTemplateAPI = (body: IBodyTemplate) => {
  return ApiService.HttpPost<IResponse<ITemplate>>({
    route: `operation/template/`,
    token: true,
    body,
  });
};

export const putTemplateAPI = (body: IBodyTemplate, uuid: string) => {
  return ApiService.HttpPatch<IResponse<ITemplate>>({
    route: `operation/template/${uuid}/`,
    token: true,
    body,
  });
};

export const getVariablesTemplate = (params: { product_uuid: string }) => {
  return ApiService.HttpGet<IResponse<string[]>>({
    route: `operation/template/variables/`,
    token: true,
    params,
  });
};
