import { Button, Input, InputSelect, Radio } from "prosperita-dumbo-react";
import { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import LateralModal from "~/components/LateralModal";
import useOperation from "~/hooks/useOperation";
import CloseIcon from "~/assets/Close--outline.png";
import SaveIcon from "~/assets/Save.png";
import usePartner from "~/hooks/usePartner";
import { IPartner } from "~/services/api/partner";
import { zIndex } from "~/services/utils";
import { useFormik } from "formik";
import Label from "~/components/Label";
import { InvolvedData } from "~/types";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
  padding: 30px;
  display: grid;
  height: auto;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  z-index: 2;
`;

const Footer = styled.div`
  width: 90%;
  position: fixed;
  bottom: 0;
  display: grid;
  padding-bottom: 50px;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
`;

type Values = {
  involved_partner: string;
  related_to: string;
  relationship: string;
  auth_type: string;
  send_type: string;
  is_signer: boolean;
  sign_weight: string;
};

const initialValues: Values = {
  involved_partner: "",
  related_to: "",
  relationship: "",
  auth_type: "",
  send_type: "",
  is_signer: false,
  sign_weight: "",
};

const notifySuccess = () => {
  toast.success("Envolvido adicionado com sucesso!");
};

const notifyError = () => {
  toast.error("Envolvido já cadastrado!");
};

const NewInvolvedCCB = () => {
  const { addInvolved, setAddInvolved, setInvolvedsRender } = useOperation();
  const { partners } = usePartner();

  const { setFieldValue, values, resetForm } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleClose = () => {
    setAddInvolved(false);
    resetForm();
    setFieldValue("involved_partner", "");
    setFieldValue("related_to", "");
    setFieldValue("relationship", "");
    setFieldValue("auth_type", "");
    setFieldValue("send_type", "");
    setFieldValue("is_signer", false);
    setFieldValue("sign_weight", "");
  };

  const bodyRef = useRef(document.body);

  const handleAddInvolvedInStorage = () => {
    try {
      const involvedData: InvolvedData[] = JSON.parse(
        localStorage.getItem("@:involveds") || "[]"
      );

      const newInvolved: InvolvedData = {
        involved_partner_name: values.involved_partner.split("/*?")[0],
        involved_partner_uuid: values.involved_partner.split("/*?")[1],
        related_to_name: values.related_to.split("/*?")[0],
        related_to_uuid: values.related_to.split("/*?")[1],
        relationship_name: values.relationship.split("/*?")[0],
        relationship: values.relationship.split("/*?")[1],
        auth_type_name: values.auth_type.split("/*?")[0],
        auth_type: values.auth_type.split("/*?")[1],
        send_type_name: values.send_type.split("/*?")[0],
        send_type: values.send_type.split("/*?")[1],
        is_signer: String(values.is_signer),
        sign_weight: values.sign_weight,
      };

      const isDuplicate = involvedData.some(
        (involved) =>
          involved.involved_partner_uuid === newInvolved.involved_partner_uuid
      );

      if (isDuplicate) {
        notifyError();
        return;
      }

      involvedData.push(newInvolved);

      localStorage.setItem("@:involveds", JSON.stringify(involvedData));

      // Reseta os valores do formulário
      setFieldValue("involved_partner", "");
      setFieldValue("related_to", "");
      setFieldValue("relationship", "");
      setFieldValue("auth_type", "");
      setFieldValue("send_type", "");
      setFieldValue("is_signer", false);
      setFieldValue("sign_weight", "");

      handleClose();

      getInvolvedFromStorage();
      notifySuccess();
    } catch (error) {
      console.error("Erro ao adicionar envolvido no localStorage:", error);
    }
  };

  const areValuesEmptyOrFalse = (values) => {
    if (
      !values.involved_partner ||
      !values.related_to ||
      !values.relationship ||
      !values.auth_type ||
      !values.send_type ||
      (values.is_signer === true && !values.sign_weight)
    ) {
      return true;
    }

    return false;
  };

  const listRelationships = useMemo(() => {
    return [
      { label: "Correntista", value: "account_holder" },
      { label: "Administrador", value: "administrator" },
      { label: "Procurador", value: "attorney" },
      { label: "Comodatário", value: "borrower" },
      { label: "Responsável Solidário", value: "co_responsible" },
      { label: "Caucionante", value: "collateral_provider" },
      { label: "Anuente", value: "consenting" },
      { label: "Interveniente Anuente", value: "consenting_intervenor" },
      { label: "Credor", value: "creditor" },
      { label: "Devedor", value: "debtor" },
      { label: "Endossante", value: "endorser" },
      { label: "Endossatário", value: "endorsee" },
      { label: "Avalista / Fiador", value: "guarantor" },
      { label: "Cônjuge do Fiador", value: "guarantor_spouse" },
      { label: "Intermediário", value: "intermediary" },
      { label: "Interveniente Garantidor", value: "intervening_guarantor" },
      { label: "Emitente", value: "issuer" },
      { label: "Devedor Solidário", value: "joint_debtor" },
      { label: "Responsável Legal", value: "legal_guardian" },
      { label: "Representante Legal", value: "legal_representative" },
      { label: "Mutuante", value: "lender" },
      { label: "Sócio", value: "partner" },
      { label: "Presidente", value: "president" },
      { label: "Cedente", value: "transferor" },
      { label: "Cessionário", value: "transferee" },
      { label: "Testemunha", value: "witness" },
    ];
  }, []);

  const listAuths = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "Biometria", value: "biometric" },
      { label: "ICP Brasil", value: "icp_brasil" },
    ];
  }, []);

  const listSends = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "SMS", value: "sms" },
      { label: "Whatsapp", value: "whatsapp" },
    ];
  }, []);

  const optionsRelationship = useMemo(() => {
    return listRelationships
      ?.map((relationship) => ({
        value: `${relationship.label}/*?${relationship.value}`,
        label: relationship.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listRelationships]);

  const partnersOptions = useMemo(() => {
    return partners
      .map((partner: IPartner) => ({
        value: `${partner.name}/*?${partner.uuid}`,
        label: partner.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partners]);

  const authOptions = useMemo(() => {
    return listAuths
      .map((auth) => ({
        value: `${auth.label}/*?${auth.value}`,
        label: auth.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listAuths]);

  const sendsOptions = useMemo(() => {
    return listSends
      .map((send) => ({
        value: `${send.label}/*?${send.value}`,
        label: send.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listSends]);

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const getInvolvedFromStorage = () => {
    const data = localStorage.getItem("@:involveds");
    if (data) {
      setInvolvedsRender(JSON.parse(data));
    }
  };

  useEffect(() => {
    if (addInvolved) {
      const body = bodyRef.current;
      body.style.overflow = "hidden";
    } else {
      const body = bodyRef.current;
      body.style.overflow = "";
    }

    return () => {
      const body = bodyRef.current;
      body.style.overflow = "";
    };
  }, [addInvolved]);

  useEffect(() => {
    if (values.is_signer) {
      setFieldValue("sign_weight", "1");
    } else {
      setFieldValue("sign_weight", "");
    }
  }, [values.is_signer, setFieldValue]);

  return (
    <LateralModal
      open={addInvolved}
      title="Envolvido"
      header
      toggleModal={() => handleClose()}
    >
      <Container>
        <div style={{ gridColumn: "span 6", zIndex: zIndex }}>
          <InputSelect
            name="involved_partner"
            id="involved_partner"
            kind="default"
            size="large"
            value=""
            label="Envolvido"
            onChange={(e) => handleSelect("involved_partner", e)}
            options={partnersOptions}
            placeholder={
              values.involved_partner !== ""
                ? values.involved_partner.split("/*?")[0]
                : "Selecione uma opção"
            }
          />
        </div>

        <div style={{ gridColumn: "span 6", zIndex: zIndex }}>
          <InputSelect
            name="related_to"
            kind="default"
            size="large"
            label="Relacionado à"
            value=""
            placeholder={
              values.related_to !== ""
                ? values.related_to.split("/*?")[0]
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("related_to", e)}
            options={partnersOptions}
          />
        </div>

        <div style={{ gridColumn: "span 12" }}>
          <InputSelect
            name="relationship"
            kind="default"
            size="large"
            value=""
            options={optionsRelationship}
            label="Tipo de relação"
            placeholder={
              values.relationship !== ""
                ? values.relationship.split("/*?")[0]
                : "Selecione uma opção"
            }
            onChange={(e) => handleSelect("relationship", e)}
          />
        </div>

        <div style={{ gridColumn: "span 6" }}>
          <InputSelect
            name="send_type"
            kind="default"
            size="large"
            value=""
            label="Forma de envio"
            placeholder={
              values.send_type !== ""
                ? values.send_type.split("/*?")[0]
                : "Selecione uma opção"
            }
            options={sendsOptions}
            onChange={(e) => handleSelect("send_type", e)}
          />
        </div>

        <div style={{ gridColumn: "span 6" }}>
          <InputSelect
            name="auth_type"
            kind="default"
            size="large"
            value=""
            label="Forma de validação"
            placeholder={
              values.auth_type !== ""
                ? values.auth_type.split("/*?")[0]
                : "Selecione uma opção"
            }
            options={authOptions}
            onChange={(e) => handleSelect("auth_type", e)}
          />
        </div>

        <div
          style={{
            gridColumn: "span 6",
          }}
        >
          <Label text="Assinatura" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",

              gap: "16px",
            }}
          >
            <div
              style={{
                marginTop: "10px",

                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "16px",
              }}
            >
              <div>
                <Radio
                  label="Não Signatário"
                  checked={!values.is_signer}
                  onClick={() => setFieldValue("is_signer", false)}
                />
              </div>
              <div>
                <Radio
                  label="Signatário"
                  checked={values.is_signer}
                  onClick={() => setFieldValue("is_signer", true)}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            gridColumn: "span 6",
          }}
        >
          <Input
            max={3}
            maxLength={3}
            name="sign_weight"
            id="sign_weight"
            type="text"
            value={values.sign_weight}
            size="large"
            disabled={!values.is_signer}
            error={
              values.is_signer &&
              (Number(values.sign_weight) < 1 ||
                Number(values.sign_weight) > 100)
            }
            message={
              values.is_signer &&
              (Number(values.sign_weight) < 1 ||
                Number(values.sign_weight) > 100)
                ? "Digite um valor entre 1 e 100"
                : ""
            }
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^\d]/g, "");
              const numericValue = value;
              setFieldValue("sign_weight", numericValue);
            }}
            placeholder="%"
            label="Porcentagem da assinatura (%)"
          />
        </div>

        <div style={{ height: "200px" }}></div>
        <Footer>
          {" "}
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="ghost"
              size="large"
              onClick={() => handleClose()}
              label="Cancelar"
              icon={CloseIcon}
            />
          </div>
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="primary"
              size="large"
              icon={SaveIcon}
              onClick={() => handleAddInvolvedInStorage()}
              label="Adicionar envolvido"
              disabled={
                areValuesEmptyOrFalse(values) ||
                (values.is_signer &&
                  (Number(values.sign_weight) < 1 ||
                    Number(values.sign_weight) > 100))
              }
            />
          </div>
        </Footer>

        {/* <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
           
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
          
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer> */}
      </Container>
    </LateralModal>
  );
};

export default NewInvolvedCCB;
