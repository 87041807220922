import Margin from "~/components/Margin";
import { useParams } from "react-router-dom";
import { Button, Input, InputSelect } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import styled from "styled-components";
import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { zIndex } from "~/services/utils";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";
import { putPartnerBankAccountAPI } from "~/services/api/partner";
import LateralModal from "~/components/LateralModal";
// import MoreIcon from "~/assets/Addd.png";
import CloseIcon from "~/assets/Close--outline.png";
import SaveIcon from "~/assets/Save.png";
import { banksOptions } from "~/utils/banks";
import { getBankAccountsAPI } from "~/services/api/bankAccount";
import usePartner from "~/hooks/usePartner";

const Footer = styled.div`
  width: 90%;
  position: fixed;
  bottom: 0;
  display: grid;
  padding-bottom: 50px;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
`;

type Values = {
  agency: string;
  agency_digit: string;
  account_number: string;
  account_number_digit: string;
  bank_identification_code: string;
};

const initialValues: Values = {
  agency: "",
  agency_digit: "",
  account_number: "",
  account_number_digit: "",
  bank_identification_code: "",
};

const EditBankAccount = () => {
  const { uuid } = useParams();
  const { setLoading } = useLoading();
  const { setBankAccounts, setBankAccountData, bankAccountData } = usePartner();

  const { values, setFieldValue, resetForm } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const banks = banksOptions.map((item) => ({
    value: `${item.code}/*?${item.name}`,
    label: item.name,
  }));

  const handleClose = useCallback(() => {
    resetForm();
    setBankAccountData(null);
  }, [setBankAccountData, resetForm]);

  const getBankAccount = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await getBankAccountsAPI(uuid as string);

      setBankAccounts(data.payload.reverse());

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [setLoading, setBankAccounts, uuid]);

  const EditBankAccount = useCallback(async () => {
    const payload = {
      agency: values.agency,
      agency_digit: values.agency_digit,
      account_number: values.account_number,
      account_number_digit: values.account_number_digit,
      bank_code: values.bank_identification_code?.split("/*?")[0],
      partner_uuid: uuid,
    };
    setLoading(true);

    try {
      await putPartnerBankAccountAPI(payload, String(bankAccountData?.uuid));

      toast.success("Conta bancária atualizada com sucesso");
      handleClose();
      getBankAccount();
      setLoading(false);
    } catch (e) {
      console.log(e);

      toast.error(
        "Não foi possível atualizar a conta bancária. Tente novamente"
      );
      setLoading(false);
    }
  }, [values, setLoading, getBankAccount, uuid, handleClose, bankAccountData]);

  useEffect(() => {
    if (bankAccountData) {
      setFieldValue("agency", bankAccountData.agency);
      setFieldValue("account_number", bankAccountData.account_number);
      setFieldValue(
        "account_number_digit",
        bankAccountData.account_number_digit
      );
      setFieldValue(
        "bank_identification_code",
        `${bankAccountData.bank_code}/*?${bankAccountData.bank_name}`
      );
    }
  }, [bankAccountData, setFieldValue]);

  return (
    <div style={{ zIndex: zIndex }}>
      <LateralModal
        open={bankAccountData !== null}
        title="Editar conta bancária"
        header
        toggleModal={() => {
          handleClose();
        }}
      >
        <div style={{ padding: "20px" }}>
          <InputSelect
            label="Banco"
            kind="default"
            name="bank_identification_code"
            size="small"
            onChange={(e) => setFieldValue("bank_identification_code", e.value)}
            options={banks}
            value=""
            placeholder={
              values.bank_identification_code !== ""
                ? values.bank_identification_code.split("/*?")[1]
                : "Selecione"
            }
          />
          <Margin mb={10} />
          <Input
            max={5}
            maxLength={5}
            name="agency"
            size="large"
            placeholder="digite"
            label="Agência"
            value={values.agency}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, "");
              setFieldValue("agency", numericValue);
            }}
          />{" "}
          <Margin mb={10} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "15px",
              rowGap: "30px",
              height: "400px",
            }}
          >
            <Input
              name="account_number"
              size="large"
              max={10}
              placeholder="digite"
              maxLength={10}
              label="Conta"
              value={values.account_number}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                setFieldValue("account_number", numericValue);
              }}
            />
            <Input
              max={1}
              maxLength={1}
              size="large"
              placeholder="digite"
              label="Dígito"
              value={values.account_number_digit}
              name="account_number_digit"
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, "");
                setFieldValue("account_number_digit", numericValue);
              }}
            />
          </div>
          <Footer>
            {" "}
            <div style={{ gridColumn: "span 6" }}>
              <Button
                kind="ghost"
                size="large"
                onClick={() => handleClose()}
                label="Cancelar"
                icon={CloseIcon}
              />
            </div>
            <div style={{ gridColumn: "span 6" }}>
              <Button
                kind="primary"
                size="large"
                icon={SaveIcon}
                onClick={() => EditBankAccount()}
                label="Salvar"
                disabled={
                  !values.account_number ||
                  !values.agency ||
                  !values.bank_identification_code ||
                  !values.account_number_digit
                }
              />
            </div>
          </Footer>
        </div>
      </LateralModal>
    </div>
  );
};

export default EditBankAccount;
