import constate from "constate";
import { useState } from "react";
import { IBankAccount } from "~/services/api/bankAccount";
import { IPartner, IPartnerCompany } from "~/services/api/partner";
import { IDocumentDataPartner } from "~/types";

const [PartnerProvider, usePartner] = constate(() => {
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [partner, setPartner] = useState<(IPartner & IPartnerCompany) | null>();
  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);
  const [addBankAccounts, setAddBankAccounts] = useState<boolean>(false);
  const [bankAccountData, setBankAccountData] = useState<IBankAccount | null>(
    null
  );
  const [documents, setDocuments] = useState<IDocumentDataPartner[]>([]);

  const [tab, setTab] = useState(0);

  const [addDocument, setAddDocument] = useState<boolean>(false);

  return {
    addDocument,
    setAddDocument,
    partners,
    setPartners,
    bankAccounts,
    setBankAccounts,
    tab,
    setTab,
    partner,
    setPartner,
    addBankAccounts,
    setAddBankAccounts,
    bankAccountData,
    setBankAccountData,
    setDocuments,
    documents,
  };
});

export { PartnerProvider };
export default usePartner;
