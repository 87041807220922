import { Button, TableBasic } from "prosperita-dumbo-react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import { useNavigate, useParams } from "react-router-dom";
import useOperation from "~/hooks/useOperation";
import ArrowLeftIcon from "~/assets/arrow-lftt-_1_.png";
import { useCallback, useEffect, useMemo } from "react";
import { getPartnersAPI } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import useLoading from "~/hooks/useLoading";
import activityIcon from "~/assets/Activity.png";
import { getSubscriberAPI } from "~/services/api/subscriber";
import { getLabelByValue } from "~/services/utils";
import MoreIcon from "~/assets/Addd.png";

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const canViewOperations = true;

const DocumentsSubscriber = () => {
  const navigate = useNavigate();
  const {
    setTab,
    setAddDocument,
    setSubscriber,

    subscriber,
  } = useOperation();

  const { setPartners } = usePartner();
  const { setLoading } = useLoading();
  const { uuid, id } = useParams();

  const titlesColumnDocuments = ["Relacionado à", "Tipo de documento"];

  const listDocuments = useMemo(() => {
    return [
      { label: "Documento", value: "document" },
      { label: "Autorização", value: "authorization" },
      { label: "Carteira de Motorista", value: "drivers_license" },
      { label: "Carteira de Trabalho", value: "work_card" },
      {
        label: "Comprovante de Residência da Empresa",
        value: "residence_proof_company",
      },
      {
        label: "Comprovante de Residência dos Sócios",
        value: "residence_proof_partners",
      },
      { label: "Rascunho", value: "draft" },
      { label: "Contrato Social", value: "social_contract" },
      { label: "Número de Registro", value: "registration_number" },
      {
        label: "Documento de Identificação com Foto",
        value: "identification_document_with_photo",
      },
      { label: "Extrato Bancário", value: "bank_statement" },
      { label: "Faturamento Gerencial", value: "managerial_billing" },
      { label: "Formulário de Cadastro", value: "registration_form" },
      { label: "Outros", value: "others" },
      { label: "Selfie", value: "selfie" },
      {
        label: "Selfie com Documento de Identidade",
        value: "selfie_with_identity_card",
      },
      { label: "Última Declaração", value: "last_declaration" },
      { label: "Contrato Assinado", value: "signed_contract" },
      {
        label: "Contrato com Selo de Assinatura",
        value: "signing_stamp_contract",
      },
      { label: "Relatório de Crédito", value: "credit_report" },
      { label: "Cessão", value: "assignment" },
      { label: "Adição", value: "addition" },
      { label: "Cessão Fiduciária", value: "fiduciary_assignment" },
      { label: "P7S", value: "p7s" },
      { label: "Arquivos Compactados", value: "ziped_files" },
      { label: "CNAB", value: "cnab" },
      { label: "Nota Fiscal", value: "invoice" },
      { label: "Ata de Eleição", value: "minutes_of_election" },
      { label: "Procuração", value: "procuration" },
      { label: "Termo de Posse", value: "term_of_ownership" },
      { label: "Estatuto Social", value: "bylaws" },
      { label: "Carteira de Identidade", value: "identity_card" },
      { label: "Garantia", value: "collateral" },
      { label: "Regulamento", value: "regulation" },
      { label: "Saldo", value: "balance" },
      { label: "Balanço Patrimonial", value: "balance_sheet" },
      { label: "PLDFT", value: "pldft" },
      { label: "Imposto de Renda", value: "income_tax" },
      { label: "DRE", value: "dre" },
      { label: "Recibo", value: "receipt" },
      { label: "Cartão da Empresa", value: "company_card" },
      { label: "Relatório de Cessão", value: "assignment_report" },
      {
        label: "Registro Nacional de Estrangeiros",
        value: "national_registry_of_foreigners",
      },
      {
        label: "Documento Nacional de Identidade",
        value: "national_identity_card",
      },
      {
        label: "Carteira de Registro Regional",
        value: "regional_registration_card",
      },
      { label: "Passaporte", value: "passport" },
      { label: "Contra-cheque", value: "paycheck" },
    ];
  }, []);

  const dataTableDocuments = subscriber?.documents?.map((doc) => {
    const document = doc as {
      related_to: string;
      type: string;
      file: string;
    };
    return {
      "Relacionado à": document.related_to,
      "Tipo de documento": getLabelByValue(document.type, listDocuments),
    };
  });

  const fetchPartners = useCallback(async () => {
    try {
      const { data } = await getPartnersAPI();
      setPartners(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setPartners]);

  const fetchSubscriber = useCallback(async () => {
    setLoading(true);
    if (id) {
      try {
        const { data } = await getSubscriberAPI(id);
        setSubscriber(data.payload);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  }, [setSubscriber, id, setLoading]);

  // const convertToPercentage = (value: number): number => {
  //   if (value < 0 || value > 1) {
  //     throw new Error("O número deve estar entre 0 e 1.");
  //   }
  //   return value * 100;
  // };

  useEffect(() => {
    fetchSubscriber();
    setTab(3);
    fetchPartners();
  }, [fetchPartners, setTab, fetchSubscriber]);

  return (
    <div style={{ width: "100%" }}>
      <>
        {canViewOperations &&
        subscriber &&
        subscriber?.documents?.length > 0 ? (
          <>
            <>
              <Margin mt={30} />
              <div>
                <TableBasic
                  search
                  columnTitles={titlesColumnDocuments}
                  rowData={dataTableDocuments || []}
                  pageSizes={[5, 10, 20, 50, 100]}
                  sizeSearch="large"
                  width="1000px"
                  buttons={[
                    {
                      kind: "ghost",
                      label: "",
                      position: "right",
                      icon: renewIcon,
                      size: "large",
                      width: "57px",
                      dimension: "55.5",
                    },
                    {
                      kind: "primary",
                      label: "Adicionar documento",
                      position: "right",
                      size: "large",
                      icon: MoreIcon,
                      onClick: () => setAddDocument(true),
                    },
                  ]}
                />
              </div>
            </>
          </>
        ) : (
          canViewOperations && (
            <div>
              <Margin mt={70} />
              <div>
                <TagLup src={lupaIcon} />
                <Text>Sem Documentos</Text>
                <Subtext>
                  Você não tem documentos para serem exibidas no momento
                </Subtext>
              </div>
              <Margin mt={50} />
              <div style={{ width: "320px" }}>
                <Button
                  kind="primary"
                  size="medium"
                  label={"Adicionar documento"}
                  onClick={() => setAddDocument(true)}
                  icon={MoreIcon}
                />
              </div>
            </div>
          )
        )}
      </>
      <Margin mt={20} />

      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "end",
          width: "100%",
        }}
      >
        <div>
          <Button
            kind="tertiary"
            size="large"
            label="Voltar"
            icon={ArrowLeftIcon}
            onClick={() => {
              navigate(
                `/app/credit/operations/details/${uuid}/subscriber/new/${id}/involveds/`
              );
              setTab(2);
            }}
          />
        </div>
        <div>
          <Button
            kind="primary"
            size="large"
            label="Finalizar"
            icon={activityIcon}
            onClick={() => {
              navigate(
                `/app/credit/operations/details/${uuid}/subscriber/${id}/`
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default DocumentsSubscriber;
