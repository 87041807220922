import ApiService from "../api";

export type IBankAccount = {
  uuid: string;
  partner_uuid: string;
  agency: string;
  agency_digit: string;
  account_number: string;
  account_number_digit: string;
  bank_name: string;
  bank_code: string;
};

export const getBankAccountsAPI = (partner_uuid: string) => {
  return ApiService.HttpGet<IResponse<IBankAccount[]>>({
    route: "register/bank-account/",
    token: true,
    params: { partner_uuid },
  });
};
