export const banksOptions = [
  {
    id: 1,
    name: "Banco do Brasil",
    description: "Banco do Brasil S.A.",
    code: "001",
    ispb: "0",
    website: "http://www.bb.com.br",
    slug: "bancodobrasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 65,
    name: "Bco Da Amazonia S.A.",
    description: "Bco Da Amazonia S.A.",
    code: "003",
    ispb: null,
    website: "",
    slug: "bco-da-amazonia-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 79,
    name: "Bco Do Nordeste Do Brasil S.A.",
    description: "Bco Do Nordeste Do Brasil S.A.",
    code: "004",
    ispb: null,
    website: "",
    slug: "bco-do-nordeste-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 200,
    name: "Bndes",
    description: "Bndes",
    code: "007",
    ispb: null,
    website: "",
    slug: "bndes",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 302,
    name: "Credicoamo",
    description: "Credicoamo",
    code: "010",
    ispb: null,
    website: "",
    slug: "credicoamo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 274,
    name: "C.Suisse Hedging-Griffo Cv S/A",
    description: "C.Suisse Hedging-Griffo Cv S/A",
    code: "011",
    ispb: null,
    website: "",
    slug: "csuisse-hedginggriffo-cv-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 64,
    name: "Banco Inbursa",
    description: "Banco Inbursa",
    code: "012",
    ispb: null,
    website: "",
    slug: "banco-inbursa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 99,
    name: "State Street Br S.A. Bco Comercial",
    description: "State Street Br S.A. Bco Comercial",
    code: "014",
    ispb: null,
    website: "",
    slug: "state-street-br-sa-bco-comercial",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 41,
    name: "Ubs Brasil Cctvm S.A.",
    description: "Ubs Brasil Cctvm S.A.",
    code: "015",
    ispb: null,
    website: "",
    slug: "ubs-brasil-cctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 62,
    name: "Ccm Desp Trâns Sc E Rs",
    description: "Ccm Desp Trâns Sc E Rs",
    code: "016",
    ispb: null,
    website: "",
    slug: "ccm-desp-trans-sc-e-rs",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 235,
    name: "Bny Mellon Bco S.A.",
    description: "Bny Mellon Bco S.A.",
    code: "017",
    ispb: null,
    website: "",
    slug: "bny-mellon-bco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 249,
    name: "Bco Tricury S.A.",
    description: "Bco Tricury S.A.",
    code: "018",
    ispb: null,
    website: "",
    slug: "bco-tricury-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 173,
    name: "Bco Banestes S.A.",
    description: "Bco Banestes S.A.",
    code: "021",
    ispb: null,
    website: "",
    slug: "bco-banestes-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 111,
    name: "Bco Bandepe S.A.",
    description: "Bco Bandepe S.A.",
    code: "024",
    ispb: null,
    website: "",
    slug: "bco-bandepe-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 48,
    name: "Bco Alfa S.A.",
    description: "Bco Alfa S.A.",
    code: "025",
    ispb: null,
    website: "",
    slug: "bco-alfa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 203,
    name: "Banco Itaú Consignado S.A.",
    description: "Banco Itaú Consignado S.A.",
    code: "029",
    ispb: null,
    website: "",
    slug: "banco-itau-consignado-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 5,
    name: "Santander",
    description: "Banco Santander (Brasil) S.A.",
    code: "033",
    ispb: "90400888",
    website: "http://www.santander.com.br",
    slug: "santander",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 77,
    name: "Bco Bbi S.A.",
    description: "Bco Bbi S.A.",
    code: "036",
    ispb: null,
    website: "",
    slug: "bco-bbi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 67,
    name: "Bco Do Est. Do Pa S.A.",
    description: "Bco Do Est. Do Pa S.A.",
    code: "037",
    ispb: null,
    website: "",
    slug: "bco-do-est-do-pa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 51,
    name: "Bco Cargill S.A.",
    description: "Bco Cargill S.A.",
    code: "040",
    ispb: null,
    website: "",
    slug: "bco-cargill-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 307,
    name: "Bco Do Estado Do Rs S.A.",
    description: "Bco Do Estado Do Rs S.A.",
    code: "041",
    ispb: null,
    website: "",
    slug: "bco-do-estado-do-rs-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 121,
    name: "Bco Do Est. De Se S.A.",
    description: "Bco Do Est. De Se S.A.",
    code: "047",
    ispb: null,
    website: "",
    slug: "bco-do-est-de-se-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 66,
    name: "Confidence Cc S.A.",
    description: "Confidence Cc S.A.",
    code: "060",
    ispb: null,
    website: "",
    slug: "confidence-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 43,
    name: "Hipercard Bm S.A.",
    description: "Hipercard Bm S.A.",
    code: "062",
    ispb: null,
    website: "",
    slug: "hipercard-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 56,
    name: "Banco Bradescard",
    description: "Banco Bradescard",
    code: "063",
    ispb: null,
    website: "",
    slug: "banco-bradescard",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 59,
    name: "Goldman Sachs Do Brasil Bm S.A",
    description: "Goldman Sachs Do Brasil Bm S.A",
    code: "064",
    ispb: null,
    website: "",
    slug: "goldman-sachs-do-brasil-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 241,
    name: "Bco Andbank S.A.",
    description: "Bco Andbank S.A.",
    code: "065",
    ispb: null,
    website: "",
    slug: "bco-andbank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 40,
    name: "Bco Morgan Stanley S.A.",
    description: "Bco Morgan Stanley S.A.",
    code: "066",
    ispb: null,
    website: "",
    slug: "bco-morgan-stanley-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 265,
    name: "Bco Crefisa S.A.",
    description: "Bco Crefisa S.A.",
    code: "069",
    ispb: null,
    website: "",
    slug: "bco-crefisa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 10,
    name: "Brb - Bco De Brasilia S.A.",
    description: "Brb - Bco De Brasilia S.A.",
    code: "070",
    ispb: null,
    website: "",
    slug: "brb-bco-de-brasilia-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 44,
    name: "Bco. J.Safra S.A.",
    description: "Bco. J.Safra S.A.",
    code: "074",
    ispb: null,
    website: "",
    slug: "bco-jsafra-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 50,
    name: "Bco Abn Amro S.A.",
    description: "Bco Abn Amro S.A.",
    code: "075",
    ispb: null,
    website: "",
    slug: "bco-abn-amro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 84,
    name: "Bco Kdb Brasil S.A.",
    description: "Bco Kdb Brasil S.A.",
    code: "076",
    ispb: null,
    website: "",
    slug: "bco-kdb-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 15,
    name: "Banco Inter",
    description: "Banco Inter",
    code: "077",
    ispb: "00416968",
    website: "",
    slug: "banco-inter",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 207,
    name: "Haitong Bi Do Brasil S.A.",
    description: "Haitong Bi Do Brasil S.A.",
    code: "078",
    ispb: null,
    website: "",
    slug: "haitong-bi-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 103,
    name: "Bco Original Do Agro S/A",
    description: "Bco Original Do Agro S/A",
    code: "079",
    ispb: null,
    website: "",
    slug: "bco-original-do-agro-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 292,
    name: "B&T Cc Ltda.",
    description: "B&T Cc Ltda.",
    code: "080",
    ispb: null,
    website: "",
    slug: "bandt-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 105,
    name: "Bancoseguro S.A.",
    description: "Bancoseguro S.A.",
    code: "081",
    ispb: null,
    website: "",
    slug: "bancoseguro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 85,
    name: "Banco Topázio S.A.",
    description: "Banco Topázio S.A.",
    code: "082",
    ispb: null,
    website: "",
    slug: "banco-topazio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 109,
    name: "Bco Da China Brasil S.A.",
    description: "Bco Da China Brasil S.A.",
    code: "083",
    ispb: null,
    website: "",
    slug: "bco-da-china-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 38,
    name: "Uniprime Do Brasil - Cc Ltda.",
    description: "Uniprime Do Brasil - Cc Ltda.",
    code: "084",
    ispb: null,
    website: "",
    slug: "uniprime-do-brasil-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 71,
    name: "Coopcentral Ailos",
    description: "Coopcentral Ailos",
    code: "085",
    ispb: null,
    website: "",
    slug: "coopcentral-ailos",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 114,
    name: "Banco Randon S.A.",
    description: "Banco Randon S.A.",
    code: "088",
    ispb: null,
    website: "",
    slug: "banco-randon-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 277,
    name: "Credisan Cc",
    description: "Credisan Cc",
    code: "089",
    ispb: null,
    website: "",
    slug: "credisan-cc",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 120,
    name: "Brk S.A. Cfi",
    description: "Brk S.A. Cfi",
    code: "092",
    ispb: null,
    website: "",
    slug: "brk-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 89,
    name: "Polocred Scmepp Ltda.",
    description: "Polocred Scmepp Ltda.",
    code: "093",
    ispb: null,
    website: "",
    slug: "polocred-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 117,
    name: "Banco Finaxis",
    description: "Banco Finaxis",
    code: "094",
    ispb: null,
    website: "",
    slug: "banco-finaxis",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 116,
    name: "Travelex Banco De Câmbio S.A.",
    description: "Travelex Banco De Câmbio S.A.",
    code: "095",
    ispb: null,
    website: "",
    slug: "travelex-banco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 22,
    name: "Bco B3 S.A.",
    description: "Bco B3 S.A.",
    code: "096",
    ispb: null,
    website: "",
    slug: "bco-b3-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 61,
    name: "Credisis Central De Cooperativas De Crédito Ltda.",
    description: "Credisis Central De Cooperativas De Crédito Ltda.",
    code: "097",
    ispb: null,
    website: "",
    slug: "credisis-central-de-cooperativas-de-credito-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 298,
    name: "Credialiança Ccr",
    description: "Credialiança Ccr",
    code: "098",
    ispb: null,
    website: "",
    slug: "credialianca-ccr",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 45,
    name: "Uniprime Central Ccc Ltda.",
    description: "Uniprime Central Ccc Ltda.",
    code: "099",
    ispb: null,
    website: "",
    slug: "uniprime-central-ccc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 21,
    name: "Planner Cv S.A.",
    description: "Planner Cv S.A.",
    code: "100",
    ispb: null,
    website: "",
    slug: "planner-cv-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 283,
    name: "Renascenca Dtvm Ltda",
    description: "Renascenca Dtvm Ltda",
    code: "101",
    ispb: null,
    website: "",
    slug: "renascenca-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 37,
    name: "Xp Investimentos Cctvm S/A",
    description: "Xp Investimentos Cctvm S/A",
    code: "102",
    ispb: null,
    website: "",
    slug: "xp-investimentos-cctvm-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 3,
    name: "Caixa Econômica Federal",
    description: "Caixa Econômica Federal",
    code: "104",
    ispb: "360305",
    website: "http://www.caixa.gov.br",
    slug: "caixa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 83,
    name: "Lecca Cfi S.A.",
    description: "Lecca Cfi S.A.",
    code: "105",
    ispb: null,
    website: "",
    slug: "lecca-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 136,
    name: "Bco Bocom Bbm S.A.",
    description: "Bco Bocom Bbm S.A.",
    code: "107",
    ispb: null,
    website: "",
    slug: "bco-bocom-bbm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 31,
    name: "Portocred S.A. - Cfi",
    description: "Portocred S.A. - Cfi",
    code: "108",
    ispb: null,
    website: "",
    slug: "portocred-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 212,
    name: "Oliveira Trust Dtvm S.A.",
    description: "Oliveira Trust Dtvm S.A.",
    code: "111",
    ispb: null,
    website: "",
    slug: "oliveira-trust-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 272,
    name: "Magliano S A  Ctvm",
    description: "Magliano S A  Ctvm",
    code: "113",
    ispb: null,
    website: "",
    slug: "magliano-s-a-ctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 75,
    name: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    description: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    code: "114",
    ispb: null,
    website: "",
    slug: "central-cooperativa-de-credito-no-estado-do-espirito-santo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 308,
    name: "Advanced Cc Ltda",
    description: "Advanced Cc Ltda",
    code: "117",
    ispb: null,
    website: "",
    slug: "advanced-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 130,
    name: "Bco Western Union",
    description: "Bco Western Union",
    code: "119",
    ispb: null,
    website: "",
    slug: "bco-western-union",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 198,
    name: "Bco Rodobens S.A.",
    description: "Bco Rodobens S.A.",
    code: "120",
    ispb: null,
    website: "",
    slug: "bco-rodobens-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 108,
    name: "Bco Agibank S.A.",
    description: "Bco Agibank S.A.",
    code: "121",
    ispb: null,
    website: "",
    slug: "bco-agibank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 193,
    name: "Bco Bradesco Berj S.A.",
    description: "Bco Bradesco Berj S.A.",
    code: "122",
    ispb: null,
    website: "",
    slug: "bco-bradesco-berj-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 138,
    name: "Bco Woori Bank Do Brasil S.A.",
    description: "Bco Woori Bank Do Brasil S.A.",
    code: "124",
    ispb: null,
    website: "",
    slug: "bco-woori-bank-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 239,
    name: "Banco Genial",
    description: "Banco Genial",
    code: "125",
    ispb: null,
    website: "",
    slug: "banco-genial",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 125,
    name: "Br Partners Bi",
    description: "Br Partners Bi",
    code: "126",
    ispb: null,
    website: "",
    slug: "br-partners-bi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 102,
    name: "Codepe Cvc S.A.",
    description: "Codepe Cvc S.A.",
    code: "127",
    ispb: null,
    website: "",
    slug: "codepe-cvc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 154,
    name: "Ms Bank S.A. Bco De Câmbio",
    description: "Ms Bank S.A. Bco De Câmbio",
    code: "128",
    ispb: null,
    website: "",
    slug: "ms-bank-sa-bco-de-cambio",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 153,
    name: "Ubs Brasil Bi S.A.",
    description: "Ubs Brasil Bi S.A.",
    code: "129",
    ispb: null,
    website: "",
    slug: "ubs-brasil-bi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 100,
    name: "Caruana Scfi",
    description: "Caruana Scfi",
    code: "130",
    ispb: null,
    website: "",
    slug: "caruana-scfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 273,
    name: "Tullett Prebon Brasil Cvc Ltda",
    description: "Tullett Prebon Brasil Cvc Ltda",
    code: "131",
    ispb: null,
    website: "",
    slug: "tullett-prebon-brasil-cvc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 148,
    name: "Icbc Do Brasil Bm S.A.",
    description: "Icbc Do Brasil Bm S.A.",
    code: "132",
    ispb: null,
    website: "",
    slug: "icbc-do-brasil-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 106,
    name: "Cresol Confederação",
    description: "Cresol Confederação",
    code: "133",
    ispb: null,
    website: "",
    slug: "cresol-confederacao",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 202,
    name: "Bgc Liquidez Dtvm Ltda",
    description: "Bgc Liquidez Dtvm Ltda",
    code: "134",
    ispb: null,
    website: "",
    slug: "bgc-liquidez-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 13,
    name: "Conf Nac Coop Centrais Unicred",
    description: "Conf Nac Coop Centrais Unicred",
    code: "136",
    ispb: null,
    website: "",
    slug: "conf-nac-coop-centrais-unicred",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 110,
    name: "Get Money Cc Ltda",
    description: "Get Money Cc Ltda",
    code: "138",
    ispb: null,
    website: "",
    slug: "get-money-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 248,
    name: "Intesa Sanpaolo Brasil S.A. Bm",
    description: "Intesa Sanpaolo Brasil S.A. Bm",
    code: "139",
    ispb: null,
    website: "",
    slug: "intesa-sanpaolo-brasil-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 279,
    name: "Nu Invest Corretora De Valores S.A.",
    description: "Nu Invest Corretora De Valores S.A.",
    code: "140",
    ispb: null,
    website: "",
    slug: "nu-invest-corretora-de-valores-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 143,
    name: "Broker Brasil Cc Ltda.",
    description: "Broker Brasil Cc Ltda.",
    code: "142",
    ispb: null,
    website: "",
    slug: "broker-brasil-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 42,
    name: "Treviso Cc S.A.",
    description: "Treviso Cc S.A.",
    code: "143",
    ispb: null,
    website: "",
    slug: "treviso-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 122,
    name: "Bexs Bco De Cambio S.A.",
    description: "Bexs Bco De Cambio S.A.",
    code: "144",
    ispb: null,
    website: "",
    slug: "bexs-bco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 243,
    name: "Levycam Ccv Ltda",
    description: "Levycam Ccv Ltda",
    code: "145",
    ispb: null,
    website: "",
    slug: "levycam-ccv-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 164,
    name: "Guitta Cc Ltda",
    description: "Guitta Cc Ltda",
    code: "146",
    ispb: null,
    website: "",
    slug: "guitta-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 139,
    name: "Facta S.A. Cfi",
    description: "Facta S.A. Cfi",
    code: "149",
    ispb: null,
    website: "",
    slug: "facta-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 97,
    name: "Icap Do Brasil Ctvm Ltda.",
    description: "Icap Do Brasil Ctvm Ltda.",
    code: "157",
    ispb: null,
    website: "",
    slug: "icap-do-brasil-ctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 70,
    name: "Casa Credito S.A. Scm",
    description: "Casa Credito S.A. Scm",
    code: "159",
    ispb: null,
    website: "",
    slug: "casa-credito-sa-scm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 162,
    name: "Commerzbank Brasil S.A. - Bco Múltiplo",
    description: "Commerzbank Brasil S.A. - Bco Múltiplo",
    code: "163",
    ispb: null,
    website: "",
    slug: "commerzbank-brasil-sa-bco-multiplo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 128,
    name: "Brl Trust Dtvm Sa",
    description: "Brl Trust Dtvm Sa",
    code: "173",
    ispb: null,
    website: "",
    slug: "brl-trust-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 236,
    name: "Pefisa S.A. - C.F.I.",
    description: "Pefisa S.A. - C.F.I.",
    code: "174",
    ispb: null,
    website: "",
    slug: "pefisa-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 286,
    name: "Guide",
    description: "Guide",
    code: "177",
    ispb: null,
    website: "",
    slug: "guide",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 39,
    name: "Cm Capital Markets Cctvm Ltda",
    description: "Cm Capital Markets Cctvm Ltda",
    code: "180",
    ispb: null,
    website: "",
    slug: "cm-capital-markets-cctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 98,
    name: "Socred Sa - Scmepp",
    description: "Socred Sa - Scmepp",
    code: "183",
    ispb: null,
    website: "",
    slug: "socred-sa-scmepp",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 145,
    name: "Bco Itaú Bba S.A.",
    description: "Bco Itaú Bba S.A.",
    code: "184",
    ispb: null,
    website: "",
    slug: "bco-itau-bba-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 201,
    name: "Ativa S.A. Investimentos Cctvm",
    description: "Ativa S.A. Investimentos Cctvm",
    code: "188",
    ispb: null,
    website: "",
    slug: "ativa-sa-investimentos-cctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 82,
    name: "Hs Financeira",
    description: "Hs Financeira",
    code: "189",
    ispb: null,
    website: "",
    slug: "hs-financeira",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 54,
    name: "Servicoop",
    description: "Servicoop",
    code: "190",
    ispb: null,
    website: "",
    slug: "servicoop",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 57,
    name: "Nova Futura Ctvm Ltda.",
    description: "Nova Futura Ctvm Ltda.",
    code: "191",
    ispb: null,
    website: "",
    slug: "nova-futura-ctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 157,
    name: "Parmetal Dtvm Ltda",
    description: "Parmetal Dtvm Ltda",
    code: "194",
    ispb: null,
    website: "",
    slug: "parmetal-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 87,
    name: "Valor Scd S.A.",
    description: "Valor Scd S.A.",
    code: "195",
    ispb: null,
    website: "",
    slug: "valor-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 188,
    name: "Fair Cc S.A.",
    description: "Fair Cc S.A.",
    code: "196",
    ispb: null,
    website: "",
    slug: "fair-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 140,
    name: "Stone Pagamentos S.A.",
    description: "Stone Pagamentos S.A.",
    code: "197",
    ispb: null,
    website: "",
    slug: "stone-pagamentos-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 178,
    name: "Banco Btg Pactual S.A.",
    description: "Banco Btg Pactual S.A.",
    code: "208",
    ispb: null,
    website: "",
    slug: "banco-btg-pactual-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 311,
    name: "Banco Original",
    description: "Banco Original",
    code: "212",
    ispb: null,
    website: "",
    slug: "banco-original",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 247,
    name: "Bco Arbi S.A.",
    description: "Bco Arbi S.A.",
    code: "213",
    ispb: null,
    website: "",
    slug: "bco-arbi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 306,
    name: "Banco John Deere S.A.",
    description: "Banco John Deere S.A.",
    code: "217",
    ispb: null,
    website: "",
    slug: "banco-john-deere-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 6,
    name: "BS2",
    description: "BS2",
    code: "218",
    ispb: "71027866",
    website: "https://www.bs2.com/banco/",
    slug: "bs2",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 294,
    name: "Bco Crédit Agricole Br S.A.",
    description: "Bco Crédit Agricole Br S.A.",
    code: "222",
    ispb: null,
    website: "",
    slug: "bco-credit-agricole-br-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 251,
    name: "Bco Fibra S.A.",
    description: "Bco Fibra S.A.",
    code: "224",
    ispb: null,
    website: "",
    slug: "bco-fibra-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 285,
    name: "Banco Cifra",
    description: "Banco Cifra",
    code: "233",
    ispb: null,
    website: "",
    slug: "banco-cifra",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 2,
    name: "Bradesco",
    description: "Banco Bradesco S.A.",
    code: "237",
    ispb: "60746948",
    website: "http://www.bradesco.com.br",
    slug: "bradesco",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 181,
    name: "Bco Classico S.A.",
    description: "Bco Classico S.A.",
    code: "241",
    ispb: null,
    website: "",
    slug: "bco-classico-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 205,
    name: "Banco Master",
    description: "Banco Master",
    code: "243",
    ispb: null,
    website: "",
    slug: "banco-master",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 174,
    name: "Bco Abc Brasil S.A.",
    description: "Bco Abc Brasil S.A.",
    code: "246",
    ispb: null,
    website: "",
    slug: "bco-abc-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 267,
    name: "Banco Investcred Unibanco S.A.",
    description: "Banco Investcred Unibanco S.A.",
    code: "249",
    ispb: null,
    website: "",
    slug: "banco-investcred-unibanco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 245,
    name: "Bexs Cc S.A.",
    description: "Bexs Cc S.A.",
    code: "253",
    ispb: null,
    website: "",
    slug: "bexs-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 133,
    name: "Parana Bco S.A.",
    description: "Parana Bco S.A.",
    code: "254",
    ispb: null,
    website: "",
    slug: "parana-bco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 94,
    name: "Moneycorp Bco De Câmbio S.A.",
    description: "Moneycorp Bco De Câmbio S.A.",
    code: "259",
    ispb: null,
    website: "",
    slug: "moneycorp-bco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 152,
    name: "Nu Pagamentos - Ip",
    description: "Nu Pagamentos - Ip",
    code: "260",
    ispb: "18236120",
    website: "",
    slug: "nu-pagamentos-ip",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 199,
    name: "Bco Fator S.A.",
    description: "Bco Fator S.A.",
    code: "265",
    ispb: null,
    website: "",
    slug: "bco-fator-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 192,
    name: "Bco Cedula S.A.",
    description: "Bco Cedula S.A.",
    code: "266",
    ispb: null,
    website: "",
    slug: "bco-cedula-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 134,
    name: "Bari Cia Hipotecária",
    description: "Bari Cia Hipotecária",
    code: "268",
    ispb: null,
    website: "",
    slug: "bari-cia-hipotecaria",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 246,
    name: "Bco Hsbc S.A.",
    description: "Bco Hsbc S.A.",
    code: "269",
    ispb: null,
    website: "",
    slug: "bco-hsbc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 270,
    name: "Sagitur Cc Ltda",
    description: "Sagitur Cc Ltda",
    code: "270",
    ispb: null,
    website: "",
    slug: "sagitur-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 172,
    name: "Ib Cctvm S.A.",
    description: "Ib Cctvm S.A.",
    code: "271",
    ispb: null,
    website: "",
    slug: "ib-cctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 12,
    name: "Agk Cc S.A.",
    description: "Agk Cc S.A.",
    code: "272",
    ispb: null,
    website: "",
    slug: "agk-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 91,
    name: "Ccr De São Miguel Do Oeste",
    description: "Ccr De São Miguel Do Oeste",
    code: "273",
    ispb: null,
    website: "",
    slug: "ccr-de-sao-miguel-do-oeste",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 9,
    name: "MONEY PLUS SCMEPP LTDA",
    description: "MONEY PLUS SCMEPP LTDA",
    code: "274",
    ispb: "11581339",
    website: "https://moneyp.com.br/",
    slug: "bmp",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 118,
    name: "Bco Senff S.A.",
    description: "Bco Senff S.A.",
    code: "276",
    ispb: null,
    website: "",
    slug: "bco-senff-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 171,
    name: "Genial Investimentos Cvm S.A.",
    description: "Genial Investimentos Cvm S.A.",
    code: "278",
    ispb: null,
    website: "",
    slug: "genial-investimentos-cvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 166,
    name: "Ccr De Primavera Do Leste",
    description: "Ccr De Primavera Do Leste",
    code: "279",
    ispb: null,
    website: "",
    slug: "ccr-de-primavera-do-leste",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 163,
    name: "Will Financeira S.A.Cfi",
    description: "Will Financeira S.A.Cfi",
    code: "280",
    ispb: null,
    website: "",
    slug: "will-financeira-sacfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 295,
    name: "Ccr Coopavel",
    description: "Ccr Coopavel",
    code: "281",
    ispb: null,
    website: "",
    slug: "ccr-coopavel",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 305,
    name: "Rb Investimentos Dtvm Ltda.",
    description: "Rb Investimentos Dtvm Ltda.",
    code: "283",
    ispb: null,
    website: "",
    slug: "rb-investimentos-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 291,
    name: "Frente Cc Ltda.",
    description: "Frente Cc Ltda.",
    code: "285",
    ispb: null,
    website: "",
    slug: "frente-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 88,
    name: "Ccr De Ouro",
    description: "Ccr De Ouro",
    code: "286",
    ispb: null,
    website: "",
    slug: "ccr-de-ouro",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 281,
    name: "Carol Dtvm Ltda.",
    description: "Carol Dtvm Ltda.",
    code: "288",
    ispb: null,
    website: "",
    slug: "carol-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 312,
    name: "Decyseo Cc Ltda.",
    description: "Decyseo Cc Ltda.",
    code: "289",
    ispb: null,
    website: "",
    slug: "decyseo-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 93,
    name: "Pagseguro S.A.",
    description: "Pagseguro S.A.",
    code: "290",
    ispb: null,
    website: "",
    slug: "pagseguro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 175,
    name: "Bs2 Dtvm S.A.",
    description: "Bs2 Dtvm S.A.",
    code: "292",
    ispb: null,
    website: "",
    slug: "bs2-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 290,
    name: "Lastro Rdv Dtvm Ltda",
    description: "Lastro Rdv Dtvm Ltda",
    code: "293",
    ispb: null,
    website: "",
    slug: "lastro-rdv-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 55,
    name: "Vision S.A. Cc",
    description: "Vision S.A. Cc",
    code: "296",
    ispb: null,
    website: "",
    slug: "vision-sa-cc",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 149,
    name: "Vips Cc Ltda.",
    description: "Vips Cc Ltda.",
    code: "298",
    ispb: null,
    website: "",
    slug: "vips-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 63,
    name: "Bco Sorocred S.A. - Bm",
    description: "Bco Sorocred S.A. - Bm",
    code: "299",
    ispb: null,
    website: "",
    slug: "bco-sorocred-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 190,
    name: "Bco La Nacion Argentina",
    description: "Bco La Nacion Argentina",
    code: "300",
    ispb: null,
    website: "",
    slug: "bco-la-nacion-argentina",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 127,
    name: "Bpp Ip S.A.",
    description: "Bpp Ip S.A.",
    code: "301",
    ispb: null,
    website: "",
    slug: "bpp-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 231,
    name: "Portopar Dtvm Ltda",
    description: "Portopar Dtvm Ltda",
    code: "306",
    ispb: null,
    website: "",
    slug: "portopar-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 52,
    name: "Terra Investimentos Dtvm",
    description: "Terra Investimentos Dtvm",
    code: "307",
    ispb: null,
    website: "",
    slug: "terra-investimentos-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 132,
    name: "Cambionet Cc Ltda",
    description: "Cambionet Cc Ltda",
    code: "309",
    ispb: null,
    website: "",
    slug: "cambionet-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 160,
    name: "Vortx Dtvm Ltda.",
    description: "Vortx Dtvm Ltda.",
    code: "310",
    ispb: null,
    website: "",
    slug: "vortx-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 297,
    name: "Dourada Corretora",
    description: "Dourada Corretora",
    code: "311",
    ispb: null,
    website: "",
    slug: "dourada-corretora",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 86,
    name: "Hscm Scmepp Ltda.",
    description: "Hscm Scmepp Ltda.",
    code: "312",
    ispb: null,
    website: "",
    slug: "hscm-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 142,
    name: "Amazônia Cc Ltda.",
    description: "Amazônia Cc Ltda.",
    code: "313",
    ispb: null,
    website: "",
    slug: "amazonia-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 49,
    name: "Pi Dtvm S.A.",
    description: "Pi Dtvm S.A.",
    code: "315",
    ispb: null,
    website: "",
    slug: "pi-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 268,
    name: "Bco Bmg S.A.",
    description: "Bco Bmg S.A.",
    code: "318",
    ispb: null,
    website: "",
    slug: "bco-bmg-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 115,
    name: "Om Dtvm Ltda",
    description: "Om Dtvm Ltda",
    code: "319",
    ispb: null,
    website: "",
    slug: "om-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 81,
    name: "Bco Ccb Brasil S.A.",
    description: "Bco Ccb Brasil S.A.",
    code: "320",
    ispb: null,
    website: "",
    slug: "bco-ccb-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 151,
    name: "Crefaz Scmepp Ltda",
    description: "Crefaz Scmepp Ltda",
    code: "321",
    ispb: null,
    website: "",
    slug: "crefaz-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 25,
    name: "Ccr De Abelardo Luz",
    description: "Ccr De Abelardo Luz",
    code: "322",
    ispb: null,
    website: "",
    slug: "ccr-de-abelardo-luz",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 107,
    name: "Mercado Pago Ip Ltda.",
    description: "Mercado Pago Ip Ltda.",
    code: "323",
    ispb: "10573521",
    website: "",
    slug: "mercado-pago-ip-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 159,
    name: "Cartos Scd S.A.",
    description: "Cartos Scd S.A.",
    code: "324",
    ispb: null,
    website: "",
    slug: "cartos-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 126,
    name: "Órama Dtvm S.A.",
    description: "Órama Dtvm S.A.",
    code: "325",
    ispb: null,
    website: "",
    slug: "orama-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 47,
    name: "Parati - Cfi S.A.",
    description: "Parati - Cfi S.A.",
    code: "326",
    ispb: null,
    website: "",
    slug: "parati-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 76,
    name: "Cecm Fabric Calçados Sapiranga",
    description: "Cecm Fabric Calçados Sapiranga",
    code: "328",
    ispb: null,
    website: "",
    slug: "cecm-fabric-calcados-sapiranga",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 187,
    name: "Qi Scd S.A.",
    description: "Qi Scd S.A.",
    code: "329",
    ispb: null,
    website: "",
    slug: "qi-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 18,
    name: "Banco Bari S.A.",
    description: "Banco Bari S.A.",
    code: "330",
    ispb: null,
    website: "",
    slug: "banco-bari-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 129,
    name: "Fram Capital Dtvm S.A.",
    description: "Fram Capital Dtvm S.A.",
    code: "331",
    ispb: null,
    website: "",
    slug: "fram-capital-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 123,
    name: "Acesso Solucoes Pagamento Sa",
    description: "Acesso Solucoes Pagamento Sa",
    code: "332",
    ispb: null,
    website: "",
    slug: "acesso-solucoes-pagamento-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 167,
    name: "Banco Digio",
    description: "Banco Digio",
    code: "335",
    ispb: null,
    website: "",
    slug: "banco-digio",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 183,
    name: "Bco C6 S.A.",
    description: "Bco C6 S.A.",
    code: "336",
    ispb: "31872495",
    website: "",
    slug: "bco-c6-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 104,
    name: "Super Pagamentos E Administracao De Meios Eletronicos S.A.",
    description: "Super Pagamentos E Administracao De Meios Eletronicos S.A.",
    code: "340",
    ispb: null,
    website: "",
    slug: "super-pagamentos-e-administracao-de-meios-eletronicos-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 4,
    name: "Itaú",
    description: "Itaú Unibanco S.A.",
    code: "341",
    ispb: "60701190",
    website: "http://www.itau.com.br",
    slug: "itau",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 189,
    name: "Creditas Scd",
    description: "Creditas Scd",
    code: "342",
    ispb: null,
    website: "",
    slug: "creditas-scd",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 165,
    name: "Ffa Scmepp Ltda.",
    description: "Ffa Scmepp Ltda.",
    code: "343",
    ispb: null,
    website: "",
    slug: "ffa-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 195,
    name: "Bco Xp S.A.",
    description: "Bco Xp S.A.",
    code: "348",
    ispb: "33264668",
    website: "",
    slug: "bco-xp-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 168,
    name: "Al5 S.A. Cfi",
    description: "Al5 S.A. Cfi",
    code: "349",
    ispb: null,
    website: "",
    slug: "al5-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 27,
    name: "Crehnor Laranjeiras",
    description: "Crehnor Laranjeiras",
    code: "350",
    ispb: null,
    website: "",
    slug: "crehnor-laranjeiras",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 177,
    name: "Toro Ctvm S.A.",
    description: "Toro Ctvm S.A.",
    code: "352",
    ispb: null,
    website: "",
    slug: "toro-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 244,
    name: "Necton Investimentos S.A Cvm",
    description: "Necton Investimentos S.A Cvm",
    code: "354",
    ispb: null,
    website: "",
    slug: "necton-investimentos-sa-cvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 208,
    name: "Ótimo Scd S.A.",
    description: "Ótimo Scd S.A.",
    code: "355",
    ispb: null,
    website: "",
    slug: "otimo-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 101,
    name: "Midway S.A. - Scfi",
    description: "Midway S.A. - Scfi",
    code: "358",
    ispb: null,
    website: "",
    slug: "midway-sa-scfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 69,
    name: "Zema Cfi S/A",
    description: "Zema Cfi S/A",
    code: "359",
    ispb: null,
    website: "",
    slug: "zema-cfi-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 35,
    name: "Trinus Capital Dtvm",
    description: "Trinus Capital Dtvm",
    code: "360",
    ispb: null,
    website: "",
    slug: "trinus-capital-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 24,
    name: "Cielo S.A.",
    description: "Cielo S.A.",
    code: "362",
    ispb: null,
    website: "",
    slug: "cielo-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 282,
    name: "Singulare Ctvm S.A.",
    description: "Singulare Ctvm S.A.",
    code: "363",
    ispb: null,
    website: "",
    slug: "singulare-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 96,
    name: "Gerencianet",
    description: "Gerencianet",
    code: "364",
    ispb: null,
    website: "",
    slug: "gerencianet",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 288,
    name: "Simpaul",
    description: "Simpaul",
    code: "365",
    ispb: null,
    website: "",
    slug: "simpaul",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 271,
    name: "Bco Societe Generale Brasil",
    description: "Bco Societe Generale Brasil",
    code: "366",
    ispb: null,
    website: "",
    slug: "bco-societe-generale-brasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 209,
    name: "Vitreo Dtvm S.A.",
    description: "Vitreo Dtvm S.A.",
    code: "367",
    ispb: null,
    website: "",
    slug: "vitreo-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 92,
    name: "Bco Csf S.A.",
    description: "Bco Csf S.A.",
    code: "368",
    ispb: null,
    website: "",
    slug: "bco-csf-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 266,
    name: "Bco Mizuho S.A.",
    description: "Bco Mizuho S.A.",
    code: "370",
    ispb: null,
    website: "",
    slug: "bco-mizuho-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 310,
    name: "Warren Cvmc Ltda",
    description: "Warren Cvmc Ltda",
    code: "371",
    ispb: null,
    website: "",
    slug: "warren-cvmc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 211,
    name: "Up.P Sep S.A.",
    description: "Up.P Sep S.A.",
    code: "373",
    ispb: null,
    website: "",
    slug: "upp-sep-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 170,
    name: "Realize Cfi S.A.",
    description: "Realize Cfi S.A.",
    code: "374",
    ispb: null,
    website: "",
    slug: "realize-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 194,
    name: "Bco J.P. Morgan S.A.",
    description: "Bco J.P. Morgan S.A.",
    code: "376",
    ispb: null,
    website: "",
    slug: "bco-jp-morgan-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 150,
    name: "Bms Scd S.A.",
    description: "Bms Scd S.A.",
    code: "377",
    ispb: null,
    website: "",
    slug: "bms-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 32,
    name: "Bco Brasileiro De Crédito S.A.",
    description: "Bco Brasileiro De Crédito S.A.",
    code: "378",
    ispb: null,
    website: "",
    slug: "bco-brasileiro-de-credito-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 29,
    name: "Cecm Cooperforte",
    description: "Cecm Cooperforte",
    code: "379",
    ispb: null,
    website: "",
    slug: "cecm-cooperforte",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 161,
    name: "Picpay",
    description: "Picpay",
    code: "380",
    ispb: null,
    website: "",
    slug: "picpay",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 260,
    name: "Bco Mercedes-Benz S.A.",
    description: "Bco Mercedes-Benz S.A.",
    code: "381",
    ispb: null,
    website: "",
    slug: "bco-mercedesbenz-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 58,
    name: "Fiducia Scmepp Ltda",
    description: "Fiducia Scmepp Ltda",
    code: "382",
    ispb: null,
    website: "",
    slug: "fiducia-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 158,
    name: "Ebanx Ip Ltda.",
    description: "Ebanx Ip Ltda.",
    code: "383",
    ispb: null,
    website: "",
    slug: "ebanx-ip-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 112,
    name: "Global Scm Ltda",
    description: "Global Scm Ltda",
    code: "384",
    ispb: null,
    website: "",
    slug: "global-scm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 179,
    name: "Nu Financeira S.A. Cfi",
    description: "Nu Financeira S.A. Cfi",
    code: "386",
    ispb: null,
    website: "",
    slug: "nu-financeira-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 46,
    name: "Bco Toyota Do Brasil S.A.",
    description: "Bco Toyota Do Brasil S.A.",
    code: "387",
    ispb: null,
    website: "",
    slug: "bco-toyota-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 144,
    name: "Bco Mercantil Do Brasil S.A.",
    description: "Bco Mercantil Do Brasil S.A.",
    code: "389",
    ispb: null,
    website: "",
    slug: "bco-mercantil-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 254,
    name: "Bco Gm S.A.",
    description: "Bco Gm S.A.",
    code: "390",
    ispb: null,
    website: "",
    slug: "bco-gm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 90,
    name: "Ccr De Ibiam",
    description: "Ccr De Ibiam",
    code: "391",
    ispb: null,
    website: "",
    slug: "ccr-de-ibiam",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 252,
    name: "Bco Volkswagen S.A",
    description: "Bco Volkswagen S.A",
    code: "393",
    ispb: null,
    website: "",
    slug: "bco-volkswagen-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 78,
    name: "Bco Bradesco Financ. S.A.",
    description: "Bco Bradesco Financ. S.A.",
    code: "394",
    ispb: null,
    website: "",
    slug: "bco-bradesco-financ-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 95,
    name: "F D Gold Dtvm Ltda",
    description: "F D Gold Dtvm Ltda",
    code: "395",
    ispb: null,
    website: "",
    slug: "f-d-gold-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 131,
    name: "Hub Pagamentos",
    description: "Hub Pagamentos",
    code: "396",
    ispb: null,
    website: "",
    slug: "hub-pagamentos",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 206,
    name: "Listo Scd S.A.",
    description: "Listo Scd S.A.",
    code: "397",
    ispb: null,
    website: "",
    slug: "listo-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 182,
    name: "Ideal Ctvm S.A.",
    description: "Ideal Ctvm S.A.",
    code: "398",
    ispb: null,
    website: "",
    slug: "ideal-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 30,
    name: "Kirton Bank",
    description: "Kirton Bank",
    code: "399",
    ispb: null,
    website: "",
    slug: "kirton-bank",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 72,
    name: "Coop Creditag",
    description: "Coop Creditag",
    code: "400",
    ispb: null,
    website: "",
    slug: "coop-creditag",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 135,
    name: "Iugu Ip S.A.",
    description: "Iugu Ip S.A.",
    code: "401",
    ispb: null,
    website: "",
    slug: "iugu-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 214,
    name: "Cobuccio Scd S.A.",
    description: "Cobuccio Scd S.A.",
    code: "402",
    ispb: null,
    website: "",
    slug: "cobuccio-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 220,
    name: "Cora Scd S.A.",
    description: "Cora Scd S.A.",
    code: "403",
    ispb: "37880206",
    website: "",
    slug: "cora-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 215,
    name: "Sumup Scd S.A.",
    description: "Sumup Scd S.A.",
    code: "404",
    ispb: null,
    website: "",
    slug: "sumup-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 219,
    name: "Accredito Scd S.A.",
    description: "Accredito Scd S.A.",
    code: "406",
    ispb: null,
    website: "",
    slug: "accredito-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 14,
    name: "Índigo Investimentos Dtvm Ltda.",
    description: "Índigo Investimentos Dtvm Ltda.",
    code: "407",
    ispb: null,
    website: "",
    slug: "indigo-investimentos-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 213,
    name: "Bonuspago Scd S.A.",
    description: "Bonuspago Scd S.A.",
    code: "408",
    ispb: null,
    website: "",
    slug: "bonuspago-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 74,
    name: "Planner Scm S.A.",
    description: "Planner Scm S.A.",
    code: "410",
    ispb: null,
    website: "",
    slug: "planner-scm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 68,
    name: "Via Certa Financiadora S.A. - Cfi",
    description: "Via Certa Financiadora S.A. - Cfi",
    code: "411",
    ispb: null,
    website: "",
    slug: "via-certa-financiadora-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 137,
    name: "Social Bank S/A",
    description: "Social Bank S/A",
    code: "412",
    ispb: null,
    website: "",
    slug: "social-bank-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 33,
    name: "Bco Bv S.A.",
    description: "Bco Bv S.A.",
    code: "413",
    ispb: null,
    website: "",
    slug: "bco-bv-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 217,
    name: "Work Scd S.A.",
    description: "Work Scd S.A.",
    code: "414",
    ispb: null,
    website: "",
    slug: "work-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 155,
    name: "Lamara Scd S.A.",
    description: "Lamara Scd S.A.",
    code: "416",
    ispb: null,
    website: "",
    slug: "lamara-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 216,
    name: "Zipdin Scd S.A.",
    description: "Zipdin Scd S.A.",
    code: "418",
    ispb: null,
    website: "",
    slug: "zipdin-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 221,
    name: "Numbrs Scd S.A.",
    description: "Numbrs Scd S.A.",
    code: "419",
    ispb: null,
    website: "",
    slug: "numbrs-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 223,
    name: "Cc Lar Credi",
    description: "Cc Lar Credi",
    code: "421",
    ispb: null,
    website: "",
    slug: "cc-lar-credi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 7,
    name: "Safra",
    description: "Safra",
    code: "422",
    ispb: "58160789",
    website: "https://www.safra.com.br",
    slug: "safra",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 16,
    name: "Coluna S.A. Dtvm",
    description: "Coluna S.A. Dtvm",
    code: "423",
    ispb: null,
    website: "",
    slug: "coluna-sa-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 53,
    name: "Socinal S.A. Cfi",
    description: "Socinal S.A. Cfi",
    code: "425",
    ispb: null,
    website: "",
    slug: "socinal-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 113,
    name: "Biorc Financeira - Cfi S.A.",
    description: "Biorc Financeira - Cfi S.A.",
    code: "426",
    ispb: null,
    website: "",
    slug: "biorc-financeira-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 169,
    name: "Cred-Ufes",
    description: "Cred-Ufes",
    code: "427",
    ispb: null,
    website: "",
    slug: "credufes",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 226,
    name: "Cred-System Scd S.A.",
    description: "Cred-System Scd S.A.",
    code: "428",
    ispb: null,
    website: "",
    slug: "credsystem-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 73,
    name: "Crediare Cfi S.A.",
    description: "Crediare Cfi S.A.",
    code: "429",
    ispb: null,
    website: "",
    slug: "crediare-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 11,
    name: "Ccr Seara",
    description: "Ccr Seara",
    code: "430",
    ispb: null,
    website: "",
    slug: "ccr-seara",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 237,
    name: "Br-Capital Dtvm S.A.",
    description: "Br-Capital Dtvm S.A.",
    code: "433",
    ispb: null,
    website: "",
    slug: "brcapital-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 222,
    name: "Delcred Scd S.A.",
    description: "Delcred Scd S.A.",
    code: "435",
    ispb: null,
    website: "",
    slug: "delcred-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 287,
    name: "Planner Trustee Dtvm Ltda",
    description: "Planner Trustee Dtvm Ltda",
    code: "438",
    ispb: null,
    website: "",
    slug: "planner-trustee-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 141,
    name: "Id Ctvm",
    description: "Id Ctvm",
    code: "439",
    ispb: null,
    website: "",
    slug: "id-ctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 303,
    name: "Credibrf Coop",
    description: "Credibrf Coop",
    code: "440",
    ispb: null,
    website: "",
    slug: "credibrf-coop",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 304,
    name: "Magnetis - Dtvm",
    description: "Magnetis - Dtvm",
    code: "442",
    ispb: null,
    website: "",
    slug: "magnetis-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 224,
    name: "Credihome Scd",
    description: "Credihome Scd",
    code: "443",
    ispb: null,
    website: "",
    slug: "credihome-scd",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 233,
    name: "Trinus Scd S.A.",
    description: "Trinus Scd S.A.",
    code: "444",
    ispb: null,
    website: "",
    slug: "trinus-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 210,
    name: "Plantae Cfi",
    description: "Plantae Cfi",
    code: "445",
    ispb: null,
    website: "",
    slug: "plantae-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 119,
    name: "Mirae Asset Cctvm Ltda",
    description: "Mirae Asset Cctvm Ltda",
    code: "447",
    ispb: null,
    website: "",
    slug: "mirae-asset-cctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 227,
    name: "Hemera Dtvm Ltda.",
    description: "Hemera Dtvm Ltda.",
    code: "448",
    ispb: null,
    website: "",
    slug: "hemera-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 218,
    name: "Dmcard Scd S.A.",
    description: "Dmcard Scd S.A.",
    code: "449",
    ispb: null,
    website: "",
    slug: "dmcard-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 124,
    name: "Fitbank Pagamentos Eletronicos S.A.",
    description: "Fitbank Pagamentos Eletronicos S.A.",
    code: "450",
    ispb: "13203354",
    website: null,
    slug: "fitbank",
    reference: "all",
    internal: false,
    default: true,
  },
  {
    id: 228,
    name: "Credifit Scd S.A.",
    description: "Credifit Scd S.A.",
    code: "452",
    ispb: null,
    website: "",
    slug: "credifit-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 234,
    name: "Mérito Dtvm Ltda.",
    description: "Mérito Dtvm Ltda.",
    code: "454",
    ispb: null,
    website: "",
    slug: "merito-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 258,
    name: "Bco Mufg Brasil S.A.",
    description: "Bco Mufg Brasil S.A.",
    code: "456",
    ispb: null,
    website: "",
    slug: "bco-mufg-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 225,
    name: "Uy3 Scd S/A",
    description: "Uy3 Scd S/A",
    code: "457",
    ispb: null,
    website: "",
    slug: "uy3-scd-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 80,
    name: "Hedge Investments Dtvm Ltda.",
    description: "Hedge Investments Dtvm Ltda.",
    code: "458",
    ispb: null,
    website: "",
    slug: "hedge-investments-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 60,
    name: "Ccm Serv. Públicos Sp",
    description: "Ccm Serv. Públicos Sp",
    code: "459",
    ispb: null,
    website: "",
    slug: "ccm-serv-publicos-sp",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 156,
    name: "Asaas Ip S.A.",
    description: "Asaas Ip S.A.",
    code: "461",
    ispb: null,
    website: "",
    slug: "asaas-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 229,
    name: "Stark Scd S.A.",
    description: "Stark Scd S.A.",
    code: "462",
    ispb: null,
    website: "",
    slug: "stark-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 232,
    name: "Azumi Dtvm",
    description: "Azumi Dtvm",
    code: "463",
    ispb: null,
    website: "",
    slug: "azumi-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 259,
    name: "Bco Sumitomo Mitsui Brasil S.A.",
    description: "Bco Sumitomo Mitsui Brasil S.A.",
    code: "464",
    ispb: null,
    website: "",
    slug: "bco-sumitomo-mitsui-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 230,
    name: "Capital Consig Scd S.A.",
    description: "Capital Consig Scd S.A.",
    code: "465",
    ispb: null,
    website: "",
    slug: "capital-consig-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 204,
    name: "Master S/A Cctvm",
    description: "Master S/A Cctvm",
    code: "467",
    ispb: null,
    website: "",
    slug: "master-s/a-cctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 196,
    name: "Bco Caixa Geral Brasil S.A.",
    description: "Bco Caixa Geral Brasil S.A.",
    code: "473",
    ispb: null,
    website: "",
    slug: "bco-caixa-geral-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 191,
    name: "Citibank N.A.",
    description: "Citibank N.A.",
    code: "477",
    ispb: null,
    website: "",
    slug: "citibank-na",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 257,
    name: "Bco Itaubank S.A.",
    description: "Bco Itaubank S.A.",
    code: "479",
    ispb: null,
    website: "",
    slug: "bco-itaubank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 284,
    name: "Deutsche Bank S.A.Bco Alemao",
    description: "Deutsche Bank S.A.Bco Alemao",
    code: "487",
    ispb: null,
    website: "",
    slug: "deutsche-bank-sabco-alemao",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 240,
    name: "Jpmorgan Chase Bank",
    description: "Jpmorgan Chase Bank",
    code: "488",
    ispb: null,
    website: "",
    slug: "jpmorgan-chase-bank",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 242,
    name: "Ing Bank N.V.",
    description: "Ing Bank N.V.",
    code: "492",
    ispb: null,
    website: "",
    slug: "ing-bank-nv",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 238,
    name: "Bco La Provincia B Aires Bce",
    description: "Bco La Provincia B Aires Bce",
    code: "495",
    ispb: null,
    website: "",
    slug: "bco-la-provincia-b-aires-bce",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 186,
    name: "Bco Credit Suisse S.A.",
    description: "Bco Credit Suisse S.A.",
    code: "505",
    ispb: null,
    website: "",
    slug: "bco-credit-suisse-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 317,
    name: "Neon Pagamentos",
    description: "Neon Pagamentos",
    code: "536",
    ispb: "20855875",
    website: null,
    slug: "neon-pagamentos",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 147,
    name: "Senso Ccvm S.A.",
    description: "Senso Ccvm S.A.",
    code: "545",
    ispb: null,
    website: "",
    slug: "senso-ccvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 253,
    name: "Bco Luso Brasileiro S.A.",
    description: "Bco Luso Brasileiro S.A.",
    code: "600",
    ispb: null,
    website: "",
    slug: "bco-luso-brasileiro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 185,
    name: "Bco Industrial Do Brasil S.A.",
    description: "Bco Industrial Do Brasil S.A.",
    code: "604",
    ispb: null,
    website: "",
    slug: "bco-industrial-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 299,
    name: "Bco Vr S.A.",
    description: "Bco Vr S.A.",
    code: "610",
    ispb: null,
    website: "",
    slug: "bco-vr-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 275,
    name: "Bco Paulista S.A.",
    description: "Bco Paulista S.A.",
    code: "611",
    ispb: null,
    website: "",
    slug: "bco-paulista-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 184,
    name: "Bco Guanabara S.A.",
    description: "Bco Guanabara S.A.",
    code: "612",
    ispb: null,
    website: "",
    slug: "bco-guanabara-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 261,
    name: "Omni Banco S.A.",
    description: "Omni Banco S.A.",
    code: "613",
    ispb: null,
    website: "",
    slug: "omni-banco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 255,
    name: "Banco Pan",
    description: "Banco Pan",
    code: "623",
    ispb: null,
    website: "",
    slug: "banco-pan",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 269,
    name: "Bco C6 Consig",
    description: "Bco C6 Consig",
    code: "626",
    ispb: null,
    website: "",
    slug: "bco-c6-consig",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 250,
    name: "Bco Letsbank S.A.",
    description: "Bco Letsbank S.A.",
    code: "630",
    ispb: null,
    website: "",
    slug: "bco-letsbank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 289,
    name: "Bco Rendimento S.A.",
    description: "Bco Rendimento S.A.",
    code: "633",
    ispb: null,
    website: "",
    slug: "bco-rendimento-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 146,
    name: "Bco Triangulo S.A.",
    description: "Bco Triangulo S.A.",
    code: "634",
    ispb: null,
    website: "",
    slug: "bco-triangulo-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 263,
    name: "Bco Sofisa S.A.",
    description: "Bco Sofisa S.A.",
    code: "637",
    ispb: null,
    website: "",
    slug: "bco-sofisa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 278,
    name: "Bco Pine S.A.",
    description: "Bco Pine S.A.",
    code: "643",
    ispb: null,
    website: "",
    slug: "bco-pine-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 262,
    name: "Itaú Unibanco Holding S.A.",
    description: "Itaú Unibanco Holding S.A.",
    code: "652",
    ispb: null,
    website: "",
    slug: "itau-unibanco-holding-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 264,
    name: "Banco Voiter",
    description: "Banco Voiter",
    code: "653",
    ispb: null,
    website: "",
    slug: "banco-voiter",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 309,
    name: "Bco Digimais S.A.",
    description: "Bco Digimais S.A.",
    code: "654",
    ispb: null,
    website: "",
    slug: "bco-digimais-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 256,
    name: "Bco Votorantim S.A.",
    description: "Bco Votorantim S.A.",
    code: "655",
    ispb: null,
    website: "",
    slug: "bco-votorantim-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 280,
    name: "Bco Daycoval S.A",
    description: "Bco Daycoval S.A",
    code: "707",
    ispb: null,
    website: "",
    slug: "bco-daycoval-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 300,
    name: "Bco Ourinvest S.A.",
    description: "Bco Ourinvest S.A.",
    code: "712",
    ispb: null,
    website: "",
    slug: "bco-ourinvest-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 301,
    name: "Bco Rnx S.A.",
    description: "Bco Rnx S.A.",
    code: "720",
    ispb: null,
    website: "",
    slug: "bco-rnx-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 19,
    name: "Bco Cetelem S.A.",
    description: "Bco Cetelem S.A.",
    code: "739",
    ispb: null,
    website: "",
    slug: "bco-cetelem-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 17,
    name: "Bco Ribeirao Preto S.A.",
    description: "Bco Ribeirao Preto S.A.",
    code: "741",
    ispb: null,
    website: "",
    slug: "bco-ribeirao-preto-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 20,
    name: "Banco Semear",
    description: "Banco Semear",
    code: "743",
    ispb: null,
    website: "",
    slug: "banco-semear",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 197,
    name: "Bco Citibank S.A.",
    description: "Bco Citibank S.A.",
    code: "745",
    ispb: null,
    website: "",
    slug: "bco-citibank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 180,
    name: "Bco Modal S.A.",
    description: "Bco Modal S.A.",
    code: "746",
    ispb: null,
    website: "",
    slug: "bco-modal-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 23,
    name: "Bco Rabobank Intl Brasil S.A.",
    description: "Bco Rabobank Intl Brasil S.A.",
    code: "747",
    ispb: null,
    website: "",
    slug: "bco-rabobank-intl-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 26,
    name: "Bco Cooperativo Sicredi S.A.",
    description: "Bco Cooperativo Sicredi S.A.",
    code: "748",
    ispb: null,
    website: "",
    slug: "bco-cooperativo-sicredi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 176,
    name: "Scotiabank Brasil",
    description: "Scotiabank Brasil",
    code: "751",
    ispb: null,
    website: "",
    slug: "scotiabank-brasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 28,
    name: "Bco Bnp Paribas Brasil S A",
    description: "Bco Bnp Paribas Brasil S A",
    code: "752",
    ispb: null,
    website: "",
    slug: "bco-bnp-paribas-brasil-s-a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 293,
    name: "Novo Bco Continental S.A. - Bm",
    description: "Novo Bco Continental S.A. - Bm",
    code: "753",
    ispb: null,
    website: "",
    slug: "novo-bco-continental-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 296,
    name: "Banco Sistema",
    description: "Banco Sistema",
    code: "754",
    ispb: null,
    website: "",
    slug: "banco-sistema",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 276,
    name: "Bofa Merrill Lynch Bm S.A.",
    description: "Bofa Merrill Lynch Bm S.A.",
    code: "755",
    ispb: null,
    website: "",
    slug: "bofa-merrill-lynch-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 34,
    name: "Banco Sicoob S.A.",
    description: "Banco Sicoob S.A.",
    code: "756",
    ispb: null,
    website: "",
    slug: "banco-sicoob-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 36,
    name: "Bco Keb Hana Do Brasil S.A.",
    description: "Bco Keb Hana Do Brasil S.A.",
    code: "757",
    ispb: null,
    website: "",
    slug: "bco-keb-hana-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 8,
    name: "Prosperità",
    description: "Prosperità",
    code: "999",
    ispb: "11581339",
    website: "localhost",
    slug: "whitelabel",
    reference: "all",
    internal: true,
    default: false,
  },
  {
    id: 315,
    name: "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS",
    description: "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS",
    code: "ID148",
    ispb: "02405189",
    website: null,
    slug: "cooperativa-de-credito-da-regiao-central-de-minas",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 319,
    name: "CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL",
    description: "CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL",
    code: "ID419",
    ispb: "",
    website: null,
    slug: "cc-unicred-valor-capital-ltda-unicred-valor-capital",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 316,
    name: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
    description:
      "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
    code: "ID449",
    ispb: "",
    website: null,
    slug: "cooperativa-de-credito-poupanca-e-investimento-do-planalto-central-sicredi-planalto-central",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 314,
    name: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO SI",
    description: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO SI",
    code: "ID481",
    ispb: "",
    website: null,
    slug: "cooperativa-de-credito-poupanca-e-investimento-si",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 313,
    name: "STARK BANK S.A.",
    description: "STARK BANK S.A.",
    code: "ID644",
    ispb: "20018183",
    website: null,
    slug: "stark-bank",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 318,
    name: "NEON PAGAMENTOS S.A.",
    description: "NEON PAGAMENTOS S.A.",
    code: "IDR03",
    ispb: "",
    website: null,
    slug: "neon-pagamentos-sa",
    reference: "all",
    internal: false,
    default: false,
  },

  {
    id: 1,
    name: "Banco do Brasil",
    description: "Banco do Brasil S.A.",
    code: "001",
    ispb: "0",
    website: "http://www.bb.com.br",
    slug: "bancodobrasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 65,
    name: "Bco Da Amazonia S.A.",
    description: "Bco Da Amazonia S.A.",
    code: "003",
    ispb: null,
    website: "",
    slug: "bco-da-amazonia-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 79,
    name: "Bco Do Nordeste Do Brasil S.A.",
    description: "Bco Do Nordeste Do Brasil S.A.",
    code: "004",
    ispb: null,
    website: "",
    slug: "bco-do-nordeste-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 200,
    name: "Bndes",
    description: "Bndes",
    code: "007",
    ispb: null,
    website: "",
    slug: "bndes",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 302,
    name: "Credicoamo",
    description: "Credicoamo",
    code: "010",
    ispb: null,
    website: "",
    slug: "credicoamo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 274,
    name: "C.Suisse Hedging-Griffo Cv S/A",
    description: "C.Suisse Hedging-Griffo Cv S/A",
    code: "011",
    ispb: null,
    website: "",
    slug: "csuisse-hedginggriffo-cv-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 64,
    name: "Banco Inbursa",
    description: "Banco Inbursa",
    code: "012",
    ispb: null,
    website: "",
    slug: "banco-inbursa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 99,
    name: "State Street Br S.A. Bco Comercial",
    description: "State Street Br S.A. Bco Comercial",
    code: "014",
    ispb: null,
    website: "",
    slug: "state-street-br-sa-bco-comercial",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 41,
    name: "Ubs Brasil Cctvm S.A.",
    description: "Ubs Brasil Cctvm S.A.",
    code: "015",
    ispb: null,
    website: "",
    slug: "ubs-brasil-cctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 62,
    name: "Ccm Desp Trâns Sc E Rs",
    description: "Ccm Desp Trâns Sc E Rs",
    code: "016",
    ispb: null,
    website: "",
    slug: "ccm-desp-trans-sc-e-rs",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 235,
    name: "Bny Mellon Bco S.A.",
    description: "Bny Mellon Bco S.A.",
    code: "017",
    ispb: null,
    website: "",
    slug: "bny-mellon-bco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 249,
    name: "Bco Tricury S.A.",
    description: "Bco Tricury S.A.",
    code: "018",
    ispb: null,
    website: "",
    slug: "bco-tricury-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 173,
    name: "Bco Banestes S.A.",
    description: "Bco Banestes S.A.",
    code: "021",
    ispb: null,
    website: "",
    slug: "bco-banestes-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 111,
    name: "Bco Bandepe S.A.",
    description: "Bco Bandepe S.A.",
    code: "024",
    ispb: null,
    website: "",
    slug: "bco-bandepe-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 48,
    name: "Bco Alfa S.A.",
    description: "Bco Alfa S.A.",
    code: "025",
    ispb: null,
    website: "",
    slug: "bco-alfa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 203,
    name: "Banco Itaú Consignado S.A.",
    description: "Banco Itaú Consignado S.A.",
    code: "029",
    ispb: null,
    website: "",
    slug: "banco-itau-consignado-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 5,
    name: "Santander",
    description: "Banco Santander (Brasil) S.A.",
    code: "033",
    ispb: "90400888",
    website: "http://www.santander.com.br",
    slug: "santander",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 77,
    name: "Bco Bbi S.A.",
    description: "Bco Bbi S.A.",
    code: "036",
    ispb: null,
    website: "",
    slug: "bco-bbi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 67,
    name: "Bco Do Est. Do Pa S.A.",
    description: "Bco Do Est. Do Pa S.A.",
    code: "037",
    ispb: null,
    website: "",
    slug: "bco-do-est-do-pa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 51,
    name: "Bco Cargill S.A.",
    description: "Bco Cargill S.A.",
    code: "040",
    ispb: null,
    website: "",
    slug: "bco-cargill-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 307,
    name: "Bco Do Estado Do Rs S.A.",
    description: "Bco Do Estado Do Rs S.A.",
    code: "041",
    ispb: null,
    website: "",
    slug: "bco-do-estado-do-rs-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 121,
    name: "Bco Do Est. De Se S.A.",
    description: "Bco Do Est. De Se S.A.",
    code: "047",
    ispb: null,
    website: "",
    slug: "bco-do-est-de-se-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 66,
    name: "Confidence Cc S.A.",
    description: "Confidence Cc S.A.",
    code: "060",
    ispb: null,
    website: "",
    slug: "confidence-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 43,
    name: "Hipercard Bm S.A.",
    description: "Hipercard Bm S.A.",
    code: "062",
    ispb: null,
    website: "",
    slug: "hipercard-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 56,
    name: "Banco Bradescard",
    description: "Banco Bradescard",
    code: "063",
    ispb: null,
    website: "",
    slug: "banco-bradescard",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 59,
    name: "Goldman Sachs Do Brasil Bm S.A",
    description: "Goldman Sachs Do Brasil Bm S.A",
    code: "064",
    ispb: null,
    website: "",
    slug: "goldman-sachs-do-brasil-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 241,
    name: "Bco Andbank S.A.",
    description: "Bco Andbank S.A.",
    code: "065",
    ispb: null,
    website: "",
    slug: "bco-andbank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 40,
    name: "Bco Morgan Stanley S.A.",
    description: "Bco Morgan Stanley S.A.",
    code: "066",
    ispb: null,
    website: "",
    slug: "bco-morgan-stanley-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 265,
    name: "Bco Crefisa S.A.",
    description: "Bco Crefisa S.A.",
    code: "069",
    ispb: null,
    website: "",
    slug: "bco-crefisa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 10,
    name: "Brb - Bco De Brasilia S.A.",
    description: "Brb - Bco De Brasilia S.A.",
    code: "070",
    ispb: null,
    website: "",
    slug: "brb-bco-de-brasilia-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 44,
    name: "Bco. J.Safra S.A.",
    description: "Bco. J.Safra S.A.",
    code: "074",
    ispb: null,
    website: "",
    slug: "bco-jsafra-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 50,
    name: "Bco Abn Amro S.A.",
    description: "Bco Abn Amro S.A.",
    code: "075",
    ispb: null,
    website: "",
    slug: "bco-abn-amro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 84,
    name: "Bco Kdb Brasil S.A.",
    description: "Bco Kdb Brasil S.A.",
    code: "076",
    ispb: null,
    website: "",
    slug: "bco-kdb-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 15,
    name: "Banco Inter",
    description: "Banco Inter",
    code: "077",
    ispb: "00416968",
    website: "",
    slug: "banco-inter",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 207,
    name: "Haitong Bi Do Brasil S.A.",
    description: "Haitong Bi Do Brasil S.A.",
    code: "078",
    ispb: null,
    website: "",
    slug: "haitong-bi-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 103,
    name: "Bco Original Do Agro S/A",
    description: "Bco Original Do Agro S/A",
    code: "079",
    ispb: null,
    website: "",
    slug: "bco-original-do-agro-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 292,
    name: "B&T Cc Ltda.",
    description: "B&T Cc Ltda.",
    code: "080",
    ispb: null,
    website: "",
    slug: "bandt-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 105,
    name: "Bancoseguro S.A.",
    description: "Bancoseguro S.A.",
    code: "081",
    ispb: null,
    website: "",
    slug: "bancoseguro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 85,
    name: "Banco Topázio S.A.",
    description: "Banco Topázio S.A.",
    code: "082",
    ispb: null,
    website: "",
    slug: "banco-topazio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 109,
    name: "Bco Da China Brasil S.A.",
    description: "Bco Da China Brasil S.A.",
    code: "083",
    ispb: null,
    website: "",
    slug: "bco-da-china-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 38,
    name: "Uniprime Do Brasil - Cc Ltda.",
    description: "Uniprime Do Brasil - Cc Ltda.",
    code: "084",
    ispb: null,
    website: "",
    slug: "uniprime-do-brasil-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 71,
    name: "Coopcentral Ailos",
    description: "Coopcentral Ailos",
    code: "085",
    ispb: null,
    website: "",
    slug: "coopcentral-ailos",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 114,
    name: "Banco Randon S.A.",
    description: "Banco Randon S.A.",
    code: "088",
    ispb: null,
    website: "",
    slug: "banco-randon-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 277,
    name: "Credisan Cc",
    description: "Credisan Cc",
    code: "089",
    ispb: null,
    website: "",
    slug: "credisan-cc",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 120,
    name: "Brk S.A. Cfi",
    description: "Brk S.A. Cfi",
    code: "092",
    ispb: null,
    website: "",
    slug: "brk-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 89,
    name: "Polocred Scmepp Ltda.",
    description: "Polocred Scmepp Ltda.",
    code: "093",
    ispb: null,
    website: "",
    slug: "polocred-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 117,
    name: "Banco Finaxis",
    description: "Banco Finaxis",
    code: "094",
    ispb: null,
    website: "",
    slug: "banco-finaxis",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 116,
    name: "Travelex Banco De Câmbio S.A.",
    description: "Travelex Banco De Câmbio S.A.",
    code: "095",
    ispb: null,
    website: "",
    slug: "travelex-banco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 22,
    name: "Bco B3 S.A.",
    description: "Bco B3 S.A.",
    code: "096",
    ispb: null,
    website: "",
    slug: "bco-b3-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 61,
    name: "Credisis Central De Cooperativas De Crédito Ltda.",
    description: "Credisis Central De Cooperativas De Crédito Ltda.",
    code: "097",
    ispb: null,
    website: "",
    slug: "credisis-central-de-cooperativas-de-credito-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 298,
    name: "Credialiança Ccr",
    description: "Credialiança Ccr",
    code: "098",
    ispb: null,
    website: "",
    slug: "credialianca-ccr",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 45,
    name: "Uniprime Central Ccc Ltda.",
    description: "Uniprime Central Ccc Ltda.",
    code: "099",
    ispb: null,
    website: "",
    slug: "uniprime-central-ccc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 21,
    name: "Planner Cv S.A.",
    description: "Planner Cv S.A.",
    code: "100",
    ispb: null,
    website: "",
    slug: "planner-cv-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 283,
    name: "Renascenca Dtvm Ltda",
    description: "Renascenca Dtvm Ltda",
    code: "101",
    ispb: null,
    website: "",
    slug: "renascenca-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 37,
    name: "Xp Investimentos Cctvm S/A",
    description: "Xp Investimentos Cctvm S/A",
    code: "102",
    ispb: null,
    website: "",
    slug: "xp-investimentos-cctvm-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 3,
    name: "Caixa Econômica Federal",
    description: "Caixa Econômica Federal",
    code: "104",
    ispb: "360305",
    website: "http://www.caixa.gov.br",
    slug: "caixa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 83,
    name: "Lecca Cfi S.A.",
    description: "Lecca Cfi S.A.",
    code: "105",
    ispb: null,
    website: "",
    slug: "lecca-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 136,
    name: "Bco Bocom Bbm S.A.",
    description: "Bco Bocom Bbm S.A.",
    code: "107",
    ispb: null,
    website: "",
    slug: "bco-bocom-bbm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 31,
    name: "Portocred S.A. - Cfi",
    description: "Portocred S.A. - Cfi",
    code: "108",
    ispb: null,
    website: "",
    slug: "portocred-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 212,
    name: "Oliveira Trust Dtvm S.A.",
    description: "Oliveira Trust Dtvm S.A.",
    code: "111",
    ispb: null,
    website: "",
    slug: "oliveira-trust-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 272,
    name: "Magliano S A  Ctvm",
    description: "Magliano S A  Ctvm",
    code: "113",
    ispb: null,
    website: "",
    slug: "magliano-s-a-ctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 75,
    name: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    description: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    code: "114",
    ispb: null,
    website: "",
    slug: "central-cooperativa-de-credito-no-estado-do-espirito-santo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 308,
    name: "Advanced Cc Ltda",
    description: "Advanced Cc Ltda",
    code: "117",
    ispb: null,
    website: "",
    slug: "advanced-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 130,
    name: "Bco Western Union",
    description: "Bco Western Union",
    code: "119",
    ispb: null,
    website: "",
    slug: "bco-western-union",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 198,
    name: "Bco Rodobens S.A.",
    description: "Bco Rodobens S.A.",
    code: "120",
    ispb: null,
    website: "",
    slug: "bco-rodobens-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 108,
    name: "Bco Agibank S.A.",
    description: "Bco Agibank S.A.",
    code: "121",
    ispb: null,
    website: "",
    slug: "bco-agibank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 193,
    name: "Bco Bradesco Berj S.A.",
    description: "Bco Bradesco Berj S.A.",
    code: "122",
    ispb: null,
    website: "",
    slug: "bco-bradesco-berj-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 138,
    name: "Bco Woori Bank Do Brasil S.A.",
    description: "Bco Woori Bank Do Brasil S.A.",
    code: "124",
    ispb: null,
    website: "",
    slug: "bco-woori-bank-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 239,
    name: "Banco Genial",
    description: "Banco Genial",
    code: "125",
    ispb: null,
    website: "",
    slug: "banco-genial",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 125,
    name: "Br Partners Bi",
    description: "Br Partners Bi",
    code: "126",
    ispb: null,
    website: "",
    slug: "br-partners-bi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 102,
    name: "Codepe Cvc S.A.",
    description: "Codepe Cvc S.A.",
    code: "127",
    ispb: null,
    website: "",
    slug: "codepe-cvc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 154,
    name: "Ms Bank S.A. Bco De Câmbio",
    description: "Ms Bank S.A. Bco De Câmbio",
    code: "128",
    ispb: null,
    website: "",
    slug: "ms-bank-sa-bco-de-cambio",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 153,
    name: "Ubs Brasil Bi S.A.",
    description: "Ubs Brasil Bi S.A.",
    code: "129",
    ispb: null,
    website: "",
    slug: "ubs-brasil-bi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 100,
    name: "Caruana Scfi",
    description: "Caruana Scfi",
    code: "130",
    ispb: null,
    website: "",
    slug: "caruana-scfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 273,
    name: "Tullett Prebon Brasil Cvc Ltda",
    description: "Tullett Prebon Brasil Cvc Ltda",
    code: "131",
    ispb: null,
    website: "",
    slug: "tullett-prebon-brasil-cvc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 148,
    name: "Icbc Do Brasil Bm S.A.",
    description: "Icbc Do Brasil Bm S.A.",
    code: "132",
    ispb: null,
    website: "",
    slug: "icbc-do-brasil-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 106,
    name: "Cresol Confederação",
    description: "Cresol Confederação",
    code: "133",
    ispb: null,
    website: "",
    slug: "cresol-confederacao",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 202,
    name: "Bgc Liquidez Dtvm Ltda",
    description: "Bgc Liquidez Dtvm Ltda",
    code: "134",
    ispb: null,
    website: "",
    slug: "bgc-liquidez-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 13,
    name: "Conf Nac Coop Centrais Unicred",
    description: "Conf Nac Coop Centrais Unicred",
    code: "136",
    ispb: null,
    website: "",
    slug: "conf-nac-coop-centrais-unicred",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 110,
    name: "Get Money Cc Ltda",
    description: "Get Money Cc Ltda",
    code: "138",
    ispb: null,
    website: "",
    slug: "get-money-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 248,
    name: "Intesa Sanpaolo Brasil S.A. Bm",
    description: "Intesa Sanpaolo Brasil S.A. Bm",
    code: "139",
    ispb: null,
    website: "",
    slug: "intesa-sanpaolo-brasil-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 279,
    name: "Nu Invest Corretora De Valores S.A.",
    description: "Nu Invest Corretora De Valores S.A.",
    code: "140",
    ispb: null,
    website: "",
    slug: "nu-invest-corretora-de-valores-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 143,
    name: "Broker Brasil Cc Ltda.",
    description: "Broker Brasil Cc Ltda.",
    code: "142",
    ispb: null,
    website: "",
    slug: "broker-brasil-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 42,
    name: "Treviso Cc S.A.",
    description: "Treviso Cc S.A.",
    code: "143",
    ispb: null,
    website: "",
    slug: "treviso-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 122,
    name: "Bexs Bco De Cambio S.A.",
    description: "Bexs Bco De Cambio S.A.",
    code: "144",
    ispb: null,
    website: "",
    slug: "bexs-bco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 243,
    name: "Levycam Ccv Ltda",
    description: "Levycam Ccv Ltda",
    code: "145",
    ispb: null,
    website: "",
    slug: "levycam-ccv-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 164,
    name: "Guitta Cc Ltda",
    description: "Guitta Cc Ltda",
    code: "146",
    ispb: null,
    website: "",
    slug: "guitta-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 139,
    name: "Facta S.A. Cfi",
    description: "Facta S.A. Cfi",
    code: "149",
    ispb: null,
    website: "",
    slug: "facta-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 97,
    name: "Icap Do Brasil Ctvm Ltda.",
    description: "Icap Do Brasil Ctvm Ltda.",
    code: "157",
    ispb: null,
    website: "",
    slug: "icap-do-brasil-ctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 70,
    name: "Casa Credito S.A. Scm",
    description: "Casa Credito S.A. Scm",
    code: "159",
    ispb: null,
    website: "",
    slug: "casa-credito-sa-scm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 162,
    name: "Commerzbank Brasil S.A. - Bco Múltiplo",
    description: "Commerzbank Brasil S.A. - Bco Múltiplo",
    code: "163",
    ispb: null,
    website: "",
    slug: "commerzbank-brasil-sa-bco-multiplo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 128,
    name: "Brl Trust Dtvm Sa",
    description: "Brl Trust Dtvm Sa",
    code: "173",
    ispb: null,
    website: "",
    slug: "brl-trust-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 236,
    name: "Pefisa S.A. - C.F.I.",
    description: "Pefisa S.A. - C.F.I.",
    code: "174",
    ispb: null,
    website: "",
    slug: "pefisa-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 286,
    name: "Guide",
    description: "Guide",
    code: "177",
    ispb: null,
    website: "",
    slug: "guide",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 39,
    name: "Cm Capital Markets Cctvm Ltda",
    description: "Cm Capital Markets Cctvm Ltda",
    code: "180",
    ispb: null,
    website: "",
    slug: "cm-capital-markets-cctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 98,
    name: "Socred Sa - Scmepp",
    description: "Socred Sa - Scmepp",
    code: "183",
    ispb: null,
    website: "",
    slug: "socred-sa-scmepp",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 145,
    name: "Bco Itaú Bba S.A.",
    description: "Bco Itaú Bba S.A.",
    code: "184",
    ispb: null,
    website: "",
    slug: "bco-itau-bba-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 201,
    name: "Ativa S.A. Investimentos Cctvm",
    description: "Ativa S.A. Investimentos Cctvm",
    code: "188",
    ispb: null,
    website: "",
    slug: "ativa-sa-investimentos-cctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 82,
    name: "Hs Financeira",
    description: "Hs Financeira",
    code: "189",
    ispb: null,
    website: "",
    slug: "hs-financeira",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 54,
    name: "Servicoop",
    description: "Servicoop",
    code: "190",
    ispb: null,
    website: "",
    slug: "servicoop",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 57,
    name: "Nova Futura Ctvm Ltda.",
    description: "Nova Futura Ctvm Ltda.",
    code: "191",
    ispb: null,
    website: "",
    slug: "nova-futura-ctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 157,
    name: "Parmetal Dtvm Ltda",
    description: "Parmetal Dtvm Ltda",
    code: "194",
    ispb: null,
    website: "",
    slug: "parmetal-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 87,
    name: "Valor Scd S.A.",
    description: "Valor Scd S.A.",
    code: "195",
    ispb: null,
    website: "",
    slug: "valor-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 188,
    name: "Fair Cc S.A.",
    description: "Fair Cc S.A.",
    code: "196",
    ispb: null,
    website: "",
    slug: "fair-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 140,
    name: "Stone Pagamentos S.A.",
    description: "Stone Pagamentos S.A.",
    code: "197",
    ispb: null,
    website: "",
    slug: "stone-pagamentos-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 178,
    name: "Banco Btg Pactual S.A.",
    description: "Banco Btg Pactual S.A.",
    code: "208",
    ispb: null,
    website: "",
    slug: "banco-btg-pactual-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 311,
    name: "Banco Original",
    description: "Banco Original",
    code: "212",
    ispb: null,
    website: "",
    slug: "banco-original",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 247,
    name: "Bco Arbi S.A.",
    description: "Bco Arbi S.A.",
    code: "213",
    ispb: null,
    website: "",
    slug: "bco-arbi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 306,
    name: "Banco John Deere S.A.",
    description: "Banco John Deere S.A.",
    code: "217",
    ispb: null,
    website: "",
    slug: "banco-john-deere-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 6,
    name: "BS2",
    description: "BS2",
    code: "218",
    ispb: "71027866",
    website: "https://www.bs2.com/banco/",
    slug: "bs2",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 294,
    name: "Bco Crédit Agricole Br S.A.",
    description: "Bco Crédit Agricole Br S.A.",
    code: "222",
    ispb: null,
    website: "",
    slug: "bco-credit-agricole-br-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 251,
    name: "Bco Fibra S.A.",
    description: "Bco Fibra S.A.",
    code: "224",
    ispb: null,
    website: "",
    slug: "bco-fibra-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 285,
    name: "Banco Cifra",
    description: "Banco Cifra",
    code: "233",
    ispb: null,
    website: "",
    slug: "banco-cifra",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 2,
    name: "Bradesco",
    description: "Banco Bradesco S.A.",
    code: "237",
    ispb: "60746948",
    website: "http://www.bradesco.com.br",
    slug: "bradesco",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 181,
    name: "Bco Classico S.A.",
    description: "Bco Classico S.A.",
    code: "241",
    ispb: null,
    website: "",
    slug: "bco-classico-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 205,
    name: "Banco Master",
    description: "Banco Master",
    code: "243",
    ispb: null,
    website: "",
    slug: "banco-master",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 174,
    name: "Bco Abc Brasil S.A.",
    description: "Bco Abc Brasil S.A.",
    code: "246",
    ispb: null,
    website: "",
    slug: "bco-abc-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 267,
    name: "Banco Investcred Unibanco S.A.",
    description: "Banco Investcred Unibanco S.A.",
    code: "249",
    ispb: null,
    website: "",
    slug: "banco-investcred-unibanco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 245,
    name: "Bexs Cc S.A.",
    description: "Bexs Cc S.A.",
    code: "253",
    ispb: null,
    website: "",
    slug: "bexs-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 133,
    name: "Parana Bco S.A.",
    description: "Parana Bco S.A.",
    code: "254",
    ispb: null,
    website: "",
    slug: "parana-bco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 94,
    name: "Moneycorp Bco De Câmbio S.A.",
    description: "Moneycorp Bco De Câmbio S.A.",
    code: "259",
    ispb: null,
    website: "",
    slug: "moneycorp-bco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 152,
    name: "Nu Pagamentos - Ip",
    description: "Nu Pagamentos - Ip",
    code: "260",
    ispb: "18236120",
    website: "",
    slug: "nu-pagamentos-ip",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 199,
    name: "Bco Fator S.A.",
    description: "Bco Fator S.A.",
    code: "265",
    ispb: null,
    website: "",
    slug: "bco-fator-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 192,
    name: "Bco Cedula S.A.",
    description: "Bco Cedula S.A.",
    code: "266",
    ispb: null,
    website: "",
    slug: "bco-cedula-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 134,
    name: "Bari Cia Hipotecária",
    description: "Bari Cia Hipotecária",
    code: "268",
    ispb: null,
    website: "",
    slug: "bari-cia-hipotecaria",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 246,
    name: "Bco Hsbc S.A.",
    description: "Bco Hsbc S.A.",
    code: "269",
    ispb: null,
    website: "",
    slug: "bco-hsbc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 270,
    name: "Sagitur Cc Ltda",
    description: "Sagitur Cc Ltda",
    code: "270",
    ispb: null,
    website: "",
    slug: "sagitur-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 172,
    name: "Ib Cctvm S.A.",
    description: "Ib Cctvm S.A.",
    code: "271",
    ispb: null,
    website: "",
    slug: "ib-cctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 12,
    name: "Agk Cc S.A.",
    description: "Agk Cc S.A.",
    code: "272",
    ispb: null,
    website: "",
    slug: "agk-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 91,
    name: "Ccr De São Miguel Do Oeste",
    description: "Ccr De São Miguel Do Oeste",
    code: "273",
    ispb: null,
    website: "",
    slug: "ccr-de-sao-miguel-do-oeste",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 9,
    name: "MONEY PLUS SCMEPP LTDA",
    description: "MONEY PLUS SCMEPP LTDA",
    code: "274",
    ispb: "11581339",
    website: "https://moneyp.com.br/",
    slug: "bmp",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 118,
    name: "Bco Senff S.A.",
    description: "Bco Senff S.A.",
    code: "276",
    ispb: null,
    website: "",
    slug: "bco-senff-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 171,
    name: "Genial Investimentos Cvm S.A.",
    description: "Genial Investimentos Cvm S.A.",
    code: "278",
    ispb: null,
    website: "",
    slug: "genial-investimentos-cvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 166,
    name: "Ccr De Primavera Do Leste",
    description: "Ccr De Primavera Do Leste",
    code: "279",
    ispb: null,
    website: "",
    slug: "ccr-de-primavera-do-leste",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 163,
    name: "Will Financeira S.A.Cfi",
    description: "Will Financeira S.A.Cfi",
    code: "280",
    ispb: null,
    website: "",
    slug: "will-financeira-sacfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 295,
    name: "Ccr Coopavel",
    description: "Ccr Coopavel",
    code: "281",
    ispb: null,
    website: "",
    slug: "ccr-coopavel",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 305,
    name: "Rb Investimentos Dtvm Ltda.",
    description: "Rb Investimentos Dtvm Ltda.",
    code: "283",
    ispb: null,
    website: "",
    slug: "rb-investimentos-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 291,
    name: "Frente Cc Ltda.",
    description: "Frente Cc Ltda.",
    code: "285",
    ispb: null,
    website: "",
    slug: "frente-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 88,
    name: "Ccr De Ouro",
    description: "Ccr De Ouro",
    code: "286",
    ispb: null,
    website: "",
    slug: "ccr-de-ouro",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 281,
    name: "Carol Dtvm Ltda.",
    description: "Carol Dtvm Ltda.",
    code: "288",
    ispb: null,
    website: "",
    slug: "carol-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 312,
    name: "Decyseo Cc Ltda.",
    description: "Decyseo Cc Ltda.",
    code: "289",
    ispb: null,
    website: "",
    slug: "decyseo-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 93,
    name: "Pagseguro S.A.",
    description: "Pagseguro S.A.",
    code: "290",
    ispb: null,
    website: "",
    slug: "pagseguro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 175,
    name: "Bs2 Dtvm S.A.",
    description: "Bs2 Dtvm S.A.",
    code: "292",
    ispb: null,
    website: "",
    slug: "bs2-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 290,
    name: "Lastro Rdv Dtvm Ltda",
    description: "Lastro Rdv Dtvm Ltda",
    code: "293",
    ispb: null,
    website: "",
    slug: "lastro-rdv-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 55,
    name: "Vision S.A. Cc",
    description: "Vision S.A. Cc",
    code: "296",
    ispb: null,
    website: "",
    slug: "vision-sa-cc",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 149,
    name: "Vips Cc Ltda.",
    description: "Vips Cc Ltda.",
    code: "298",
    ispb: null,
    website: "",
    slug: "vips-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 63,
    name: "Bco Sorocred S.A. - Bm",
    description: "Bco Sorocred S.A. - Bm",
    code: "299",
    ispb: null,
    website: "",
    slug: "bco-sorocred-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 190,
    name: "Bco La Nacion Argentina",
    description: "Bco La Nacion Argentina",
    code: "300",
    ispb: null,
    website: "",
    slug: "bco-la-nacion-argentina",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 127,
    name: "Bpp Ip S.A.",
    description: "Bpp Ip S.A.",
    code: "301",
    ispb: null,
    website: "",
    slug: "bpp-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 231,
    name: "Portopar Dtvm Ltda",
    description: "Portopar Dtvm Ltda",
    code: "306",
    ispb: null,
    website: "",
    slug: "portopar-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 52,
    name: "Terra Investimentos Dtvm",
    description: "Terra Investimentos Dtvm",
    code: "307",
    ispb: null,
    website: "",
    slug: "terra-investimentos-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 132,
    name: "Cambionet Cc Ltda",
    description: "Cambionet Cc Ltda",
    code: "309",
    ispb: null,
    website: "",
    slug: "cambionet-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 160,
    name: "Vortx Dtvm Ltda.",
    description: "Vortx Dtvm Ltda.",
    code: "310",
    ispb: null,
    website: "",
    slug: "vortx-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 297,
    name: "Dourada Corretora",
    description: "Dourada Corretora",
    code: "311",
    ispb: null,
    website: "",
    slug: "dourada-corretora",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 86,
    name: "Hscm Scmepp Ltda.",
    description: "Hscm Scmepp Ltda.",
    code: "312",
    ispb: null,
    website: "",
    slug: "hscm-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 142,
    name: "Amazônia Cc Ltda.",
    description: "Amazônia Cc Ltda.",
    code: "313",
    ispb: null,
    website: "",
    slug: "amazonia-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 49,
    name: "Pi Dtvm S.A.",
    description: "Pi Dtvm S.A.",
    code: "315",
    ispb: null,
    website: "",
    slug: "pi-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 268,
    name: "Bco Bmg S.A.",
    description: "Bco Bmg S.A.",
    code: "318",
    ispb: null,
    website: "",
    slug: "bco-bmg-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 115,
    name: "Om Dtvm Ltda",
    description: "Om Dtvm Ltda",
    code: "319",
    ispb: null,
    website: "",
    slug: "om-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 81,
    name: "Bco Ccb Brasil S.A.",
    description: "Bco Ccb Brasil S.A.",
    code: "320",
    ispb: null,
    website: "",
    slug: "bco-ccb-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 151,
    name: "Crefaz Scmepp Ltda",
    description: "Crefaz Scmepp Ltda",
    code: "321",
    ispb: null,
    website: "",
    slug: "crefaz-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 25,
    name: "Ccr De Abelardo Luz",
    description: "Ccr De Abelardo Luz",
    code: "322",
    ispb: null,
    website: "",
    slug: "ccr-de-abelardo-luz",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 107,
    name: "Mercado Pago Ip Ltda.",
    description: "Mercado Pago Ip Ltda.",
    code: "323",
    ispb: "10573521",
    website: "",
    slug: "mercado-pago-ip-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 159,
    name: "Cartos Scd S.A.",
    description: "Cartos Scd S.A.",
    code: "324",
    ispb: null,
    website: "",
    slug: "cartos-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 126,
    name: "Órama Dtvm S.A.",
    description: "Órama Dtvm S.A.",
    code: "325",
    ispb: null,
    website: "",
    slug: "orama-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 47,
    name: "Parati - Cfi S.A.",
    description: "Parati - Cfi S.A.",
    code: "326",
    ispb: null,
    website: "",
    slug: "parati-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 76,
    name: "Cecm Fabric Calçados Sapiranga",
    description: "Cecm Fabric Calçados Sapiranga",
    code: "328",
    ispb: null,
    website: "",
    slug: "cecm-fabric-calcados-sapiranga",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 187,
    name: "Qi Scd S.A.",
    description: "Qi Scd S.A.",
    code: "329",
    ispb: null,
    website: "",
    slug: "qi-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 18,
    name: "Banco Bari S.A.",
    description: "Banco Bari S.A.",
    code: "330",
    ispb: null,
    website: "",
    slug: "banco-bari-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 129,
    name: "Fram Capital Dtvm S.A.",
    description: "Fram Capital Dtvm S.A.",
    code: "331",
    ispb: null,
    website: "",
    slug: "fram-capital-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 123,
    name: "Acesso Solucoes Pagamento Sa",
    description: "Acesso Solucoes Pagamento Sa",
    code: "332",
    ispb: null,
    website: "",
    slug: "acesso-solucoes-pagamento-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 167,
    name: "Banco Digio",
    description: "Banco Digio",
    code: "335",
    ispb: null,
    website: "",
    slug: "banco-digio",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 183,
    name: "Bco C6 S.A.",
    description: "Bco C6 S.A.",
    code: "336",
    ispb: "31872495",
    website: "",
    slug: "bco-c6-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 104,
    name: "Super Pagamentos E Administracao De Meios Eletronicos S.A.",
    description: "Super Pagamentos E Administracao De Meios Eletronicos S.A.",
    code: "340",
    ispb: null,
    website: "",
    slug: "super-pagamentos-e-administracao-de-meios-eletronicos-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 4,
    name: "Itaú",
    description: "Itaú Unibanco S.A.",
    code: "341",
    ispb: "60701190",
    website: "http://www.itau.com.br",
    slug: "itau",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 189,
    name: "Creditas Scd",
    description: "Creditas Scd",
    code: "342",
    ispb: null,
    website: "",
    slug: "creditas-scd",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 165,
    name: "Ffa Scmepp Ltda.",
    description: "Ffa Scmepp Ltda.",
    code: "343",
    ispb: null,
    website: "",
    slug: "ffa-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 195,
    name: "Bco Xp S.A.",
    description: "Bco Xp S.A.",
    code: "348",
    ispb: "33264668",
    website: "",
    slug: "bco-xp-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 168,
    name: "Al5 S.A. Cfi",
    description: "Al5 S.A. Cfi",
    code: "349",
    ispb: null,
    website: "",
    slug: "al5-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 27,
    name: "Crehnor Laranjeiras",
    description: "Crehnor Laranjeiras",
    code: "350",
    ispb: null,
    website: "",
    slug: "crehnor-laranjeiras",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 177,
    name: "Toro Ctvm S.A.",
    description: "Toro Ctvm S.A.",
    code: "352",
    ispb: null,
    website: "",
    slug: "toro-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 244,
    name: "Necton Investimentos S.A Cvm",
    description: "Necton Investimentos S.A Cvm",
    code: "354",
    ispb: null,
    website: "",
    slug: "necton-investimentos-sa-cvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 208,
    name: "Ótimo Scd S.A.",
    description: "Ótimo Scd S.A.",
    code: "355",
    ispb: null,
    website: "",
    slug: "otimo-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 101,
    name: "Midway S.A. - Scfi",
    description: "Midway S.A. - Scfi",
    code: "358",
    ispb: null,
    website: "",
    slug: "midway-sa-scfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 69,
    name: "Zema Cfi S/A",
    description: "Zema Cfi S/A",
    code: "359",
    ispb: null,
    website: "",
    slug: "zema-cfi-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 35,
    name: "Trinus Capital Dtvm",
    description: "Trinus Capital Dtvm",
    code: "360",
    ispb: null,
    website: "",
    slug: "trinus-capital-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 24,
    name: "Cielo S.A.",
    description: "Cielo S.A.",
    code: "362",
    ispb: null,
    website: "",
    slug: "cielo-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 282,
    name: "Singulare Ctvm S.A.",
    description: "Singulare Ctvm S.A.",
    code: "363",
    ispb: null,
    website: "",
    slug: "singulare-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 96,
    name: "Gerencianet",
    description: "Gerencianet",
    code: "364",
    ispb: null,
    website: "",
    slug: "gerencianet",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 288,
    name: "Simpaul",
    description: "Simpaul",
    code: "365",
    ispb: null,
    website: "",
    slug: "simpaul",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 271,
    name: "Bco Societe Generale Brasil",
    description: "Bco Societe Generale Brasil",
    code: "366",
    ispb: null,
    website: "",
    slug: "bco-societe-generale-brasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 209,
    name: "Vitreo Dtvm S.A.",
    description: "Vitreo Dtvm S.A.",
    code: "367",
    ispb: null,
    website: "",
    slug: "vitreo-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 92,
    name: "Bco Csf S.A.",
    description: "Bco Csf S.A.",
    code: "368",
    ispb: null,
    website: "",
    slug: "bco-csf-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 266,
    name: "Bco Mizuho S.A.",
    description: "Bco Mizuho S.A.",
    code: "370",
    ispb: null,
    website: "",
    slug: "bco-mizuho-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 310,
    name: "Warren Cvmc Ltda",
    description: "Warren Cvmc Ltda",
    code: "371",
    ispb: null,
    website: "",
    slug: "warren-cvmc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 211,
    name: "Up.P Sep S.A.",
    description: "Up.P Sep S.A.",
    code: "373",
    ispb: null,
    website: "",
    slug: "upp-sep-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 170,
    name: "Realize Cfi S.A.",
    description: "Realize Cfi S.A.",
    code: "374",
    ispb: null,
    website: "",
    slug: "realize-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 194,
    name: "Bco J.P. Morgan S.A.",
    description: "Bco J.P. Morgan S.A.",
    code: "376",
    ispb: null,
    website: "",
    slug: "bco-jp-morgan-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 150,
    name: "Bms Scd S.A.",
    description: "Bms Scd S.A.",
    code: "377",
    ispb: null,
    website: "",
    slug: "bms-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 32,
    name: "Bco Brasileiro De Crédito S.A.",
    description: "Bco Brasileiro De Crédito S.A.",
    code: "378",
    ispb: null,
    website: "",
    slug: "bco-brasileiro-de-credito-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 29,
    name: "Cecm Cooperforte",
    description: "Cecm Cooperforte",
    code: "379",
    ispb: null,
    website: "",
    slug: "cecm-cooperforte",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 161,
    name: "Picpay",
    description: "Picpay",
    code: "380",
    ispb: null,
    website: "",
    slug: "picpay",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 260,
    name: "Bco Mercedes-Benz S.A.",
    description: "Bco Mercedes-Benz S.A.",
    code: "381",
    ispb: null,
    website: "",
    slug: "bco-mercedesbenz-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 58,
    name: "Fiducia Scmepp Ltda",
    description: "Fiducia Scmepp Ltda",
    code: "382",
    ispb: null,
    website: "",
    slug: "fiducia-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 158,
    name: "Ebanx Ip Ltda.",
    description: "Ebanx Ip Ltda.",
    code: "383",
    ispb: null,
    website: "",
    slug: "ebanx-ip-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 112,
    name: "Global Scm Ltda",
    description: "Global Scm Ltda",
    code: "384",
    ispb: null,
    website: "",
    slug: "global-scm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 179,
    name: "Nu Financeira S.A. Cfi",
    description: "Nu Financeira S.A. Cfi",
    code: "386",
    ispb: null,
    website: "",
    slug: "nu-financeira-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 46,
    name: "Bco Toyota Do Brasil S.A.",
    description: "Bco Toyota Do Brasil S.A.",
    code: "387",
    ispb: null,
    website: "",
    slug: "bco-toyota-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 144,
    name: "Bco Mercantil Do Brasil S.A.",
    description: "Bco Mercantil Do Brasil S.A.",
    code: "389",
    ispb: null,
    website: "",
    slug: "bco-mercantil-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 254,
    name: "Bco Gm S.A.",
    description: "Bco Gm S.A.",
    code: "390",
    ispb: null,
    website: "",
    slug: "bco-gm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 90,
    name: "Ccr De Ibiam",
    description: "Ccr De Ibiam",
    code: "391",
    ispb: null,
    website: "",
    slug: "ccr-de-ibiam",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 252,
    name: "Bco Volkswagen S.A",
    description: "Bco Volkswagen S.A",
    code: "393",
    ispb: null,
    website: "",
    slug: "bco-volkswagen-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 78,
    name: "Bco Bradesco Financ. S.A.",
    description: "Bco Bradesco Financ. S.A.",
    code: "394",
    ispb: null,
    website: "",
    slug: "bco-bradesco-financ-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 95,
    name: "F D Gold Dtvm Ltda",
    description: "F D Gold Dtvm Ltda",
    code: "395",
    ispb: null,
    website: "",
    slug: "f-d-gold-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 131,
    name: "Hub Pagamentos",
    description: "Hub Pagamentos",
    code: "396",
    ispb: null,
    website: "",
    slug: "hub-pagamentos",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 206,
    name: "Listo Scd S.A.",
    description: "Listo Scd S.A.",
    code: "397",
    ispb: null,
    website: "",
    slug: "listo-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 182,
    name: "Ideal Ctvm S.A.",
    description: "Ideal Ctvm S.A.",
    code: "398",
    ispb: null,
    website: "",
    slug: "ideal-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 30,
    name: "Kirton Bank",
    description: "Kirton Bank",
    code: "399",
    ispb: null,
    website: "",
    slug: "kirton-bank",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 72,
    name: "Coop Creditag",
    description: "Coop Creditag",
    code: "400",
    ispb: null,
    website: "",
    slug: "coop-creditag",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 135,
    name: "Iugu Ip S.A.",
    description: "Iugu Ip S.A.",
    code: "401",
    ispb: null,
    website: "",
    slug: "iugu-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 214,
    name: "Cobuccio Scd S.A.",
    description: "Cobuccio Scd S.A.",
    code: "402",
    ispb: null,
    website: "",
    slug: "cobuccio-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 220,
    name: "Cora Scd S.A.",
    description: "Cora Scd S.A.",
    code: "403",
    ispb: "37880206",
    website: "",
    slug: "cora-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 215,
    name: "Sumup Scd S.A.",
    description: "Sumup Scd S.A.",
    code: "404",
    ispb: null,
    website: "",
    slug: "sumup-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 219,
    name: "Accredito Scd S.A.",
    description: "Accredito Scd S.A.",
    code: "406",
    ispb: null,
    website: "",
    slug: "accredito-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 14,
    name: "Índigo Investimentos Dtvm Ltda.",
    description: "Índigo Investimentos Dtvm Ltda.",
    code: "407",
    ispb: null,
    website: "",
    slug: "indigo-investimentos-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 213,
    name: "Bonuspago Scd S.A.",
    description: "Bonuspago Scd S.A.",
    code: "408",
    ispb: null,
    website: "",
    slug: "bonuspago-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 74,
    name: "Planner Scm S.A.",
    description: "Planner Scm S.A.",
    code: "410",
    ispb: null,
    website: "",
    slug: "planner-scm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 68,
    name: "Via Certa Financiadora S.A. - Cfi",
    description: "Via Certa Financiadora S.A. - Cfi",
    code: "411",
    ispb: null,
    website: "",
    slug: "via-certa-financiadora-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 137,
    name: "Social Bank S/A",
    description: "Social Bank S/A",
    code: "412",
    ispb: null,
    website: "",
    slug: "social-bank-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 33,
    name: "Bco Bv S.A.",
    description: "Bco Bv S.A.",
    code: "413",
    ispb: null,
    website: "",
    slug: "bco-bv-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 217,
    name: "Work Scd S.A.",
    description: "Work Scd S.A.",
    code: "414",
    ispb: null,
    website: "",
    slug: "work-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 155,
    name: "Lamara Scd S.A.",
    description: "Lamara Scd S.A.",
    code: "416",
    ispb: null,
    website: "",
    slug: "lamara-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 216,
    name: "Zipdin Scd S.A.",
    description: "Zipdin Scd S.A.",
    code: "418",
    ispb: null,
    website: "",
    slug: "zipdin-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 221,
    name: "Numbrs Scd S.A.",
    description: "Numbrs Scd S.A.",
    code: "419",
    ispb: null,
    website: "",
    slug: "numbrs-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 223,
    name: "Cc Lar Credi",
    description: "Cc Lar Credi",
    code: "421",
    ispb: null,
    website: "",
    slug: "cc-lar-credi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 7,
    name: "Safra",
    description: "Safra",
    code: "422",
    ispb: "58160789",
    website: "https://www.safra.com.br",
    slug: "safra",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 16,
    name: "Coluna S.A. Dtvm",
    description: "Coluna S.A. Dtvm",
    code: "423",
    ispb: null,
    website: "",
    slug: "coluna-sa-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 53,
    name: "Socinal S.A. Cfi",
    description: "Socinal S.A. Cfi",
    code: "425",
    ispb: null,
    website: "",
    slug: "socinal-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 113,
    name: "Biorc Financeira - Cfi S.A.",
    description: "Biorc Financeira - Cfi S.A.",
    code: "426",
    ispb: null,
    website: "",
    slug: "biorc-financeira-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 169,
    name: "Cred-Ufes",
    description: "Cred-Ufes",
    code: "427",
    ispb: null,
    website: "",
    slug: "credufes",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 226,
    name: "Cred-System Scd S.A.",
    description: "Cred-System Scd S.A.",
    code: "428",
    ispb: null,
    website: "",
    slug: "credsystem-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 73,
    name: "Crediare Cfi S.A.",
    description: "Crediare Cfi S.A.",
    code: "429",
    ispb: null,
    website: "",
    slug: "crediare-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 11,
    name: "Ccr Seara",
    description: "Ccr Seara",
    code: "430",
    ispb: null,
    website: "",
    slug: "ccr-seara",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 237,
    name: "Br-Capital Dtvm S.A.",
    description: "Br-Capital Dtvm S.A.",
    code: "433",
    ispb: null,
    website: "",
    slug: "brcapital-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 222,
    name: "Delcred Scd S.A.",
    description: "Delcred Scd S.A.",
    code: "435",
    ispb: null,
    website: "",
    slug: "delcred-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 287,
    name: "Planner Trustee Dtvm Ltda",
    description: "Planner Trustee Dtvm Ltda",
    code: "438",
    ispb: null,
    website: "",
    slug: "planner-trustee-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 141,
    name: "Id Ctvm",
    description: "Id Ctvm",
    code: "439",
    ispb: null,
    website: "",
    slug: "id-ctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 303,
    name: "Credibrf Coop",
    description: "Credibrf Coop",
    code: "440",
    ispb: null,
    website: "",
    slug: "credibrf-coop",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 304,
    name: "Magnetis - Dtvm",
    description: "Magnetis - Dtvm",
    code: "442",
    ispb: null,
    website: "",
    slug: "magnetis-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 224,
    name: "Credihome Scd",
    description: "Credihome Scd",
    code: "443",
    ispb: null,
    website: "",
    slug: "credihome-scd",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 233,
    name: "Trinus Scd S.A.",
    description: "Trinus Scd S.A.",
    code: "444",
    ispb: null,
    website: "",
    slug: "trinus-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 210,
    name: "Plantae Cfi",
    description: "Plantae Cfi",
    code: "445",
    ispb: null,
    website: "",
    slug: "plantae-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 119,
    name: "Mirae Asset Cctvm Ltda",
    description: "Mirae Asset Cctvm Ltda",
    code: "447",
    ispb: null,
    website: "",
    slug: "mirae-asset-cctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 227,
    name: "Hemera Dtvm Ltda.",
    description: "Hemera Dtvm Ltda.",
    code: "448",
    ispb: null,
    website: "",
    slug: "hemera-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 218,
    name: "Dmcard Scd S.A.",
    description: "Dmcard Scd S.A.",
    code: "449",
    ispb: null,
    website: "",
    slug: "dmcard-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 124,
    name: "Fitbank Pagamentos Eletronicos S.A.",
    description: "Fitbank Pagamentos Eletronicos S.A.",
    code: "450",
    ispb: "13203354",
    website: null,
    slug: "fitbank",
    reference: "all",
    internal: false,
    default: true,
  },
  {
    id: 228,
    name: "Credifit Scd S.A.",
    description: "Credifit Scd S.A.",
    code: "452",
    ispb: null,
    website: "",
    slug: "credifit-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 234,
    name: "Mérito Dtvm Ltda.",
    description: "Mérito Dtvm Ltda.",
    code: "454",
    ispb: null,
    website: "",
    slug: "merito-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 258,
    name: "Bco Mufg Brasil S.A.",
    description: "Bco Mufg Brasil S.A.",
    code: "456",
    ispb: null,
    website: "",
    slug: "bco-mufg-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 225,
    name: "Uy3 Scd S/A",
    description: "Uy3 Scd S/A",
    code: "457",
    ispb: null,
    website: "",
    slug: "uy3-scd-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 80,
    name: "Hedge Investments Dtvm Ltda.",
    description: "Hedge Investments Dtvm Ltda.",
    code: "458",
    ispb: null,
    website: "",
    slug: "hedge-investments-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 60,
    name: "Ccm Serv. Públicos Sp",
    description: "Ccm Serv. Públicos Sp",
    code: "459",
    ispb: null,
    website: "",
    slug: "ccm-serv-publicos-sp",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 156,
    name: "Asaas Ip S.A.",
    description: "Asaas Ip S.A.",
    code: "461",
    ispb: null,
    website: "",
    slug: "asaas-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 229,
    name: "Stark Scd S.A.",
    description: "Stark Scd S.A.",
    code: "462",
    ispb: null,
    website: "",
    slug: "stark-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 232,
    name: "Azumi Dtvm",
    description: "Azumi Dtvm",
    code: "463",
    ispb: null,
    website: "",
    slug: "azumi-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 259,
    name: "Bco Sumitomo Mitsui Brasil S.A.",
    description: "Bco Sumitomo Mitsui Brasil S.A.",
    code: "464",
    ispb: null,
    website: "",
    slug: "bco-sumitomo-mitsui-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 230,
    name: "Capital Consig Scd S.A.",
    description: "Capital Consig Scd S.A.",
    code: "465",
    ispb: null,
    website: "",
    slug: "capital-consig-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 204,
    name: "Master S/A Cctvm",
    description: "Master S/A Cctvm",
    code: "467",
    ispb: null,
    website: "",
    slug: "master-s/a-cctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 196,
    name: "Bco Caixa Geral Brasil S.A.",
    description: "Bco Caixa Geral Brasil S.A.",
    code: "473",
    ispb: null,
    website: "",
    slug: "bco-caixa-geral-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 191,
    name: "Citibank N.A.",
    description: "Citibank N.A.",
    code: "477",
    ispb: null,
    website: "",
    slug: "citibank-na",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 257,
    name: "Bco Itaubank S.A.",
    description: "Bco Itaubank S.A.",
    code: "479",
    ispb: null,
    website: "",
    slug: "bco-itaubank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 284,
    name: "Deutsche Bank S.A.Bco Alemao",
    description: "Deutsche Bank S.A.Bco Alemao",
    code: "487",
    ispb: null,
    website: "",
    slug: "deutsche-bank-sabco-alemao",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 240,
    name: "Jpmorgan Chase Bank",
    description: "Jpmorgan Chase Bank",
    code: "488",
    ispb: null,
    website: "",
    slug: "jpmorgan-chase-bank",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 242,
    name: "Ing Bank N.V.",
    description: "Ing Bank N.V.",
    code: "492",
    ispb: null,
    website: "",
    slug: "ing-bank-nv",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 238,
    name: "Bco La Provincia B Aires Bce",
    description: "Bco La Provincia B Aires Bce",
    code: "495",
    ispb: null,
    website: "",
    slug: "bco-la-provincia-b-aires-bce",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 186,
    name: "Bco Credit Suisse S.A.",
    description: "Bco Credit Suisse S.A.",
    code: "505",
    ispb: null,
    website: "",
    slug: "bco-credit-suisse-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 317,
    name: "Neon Pagamentos",
    description: "Neon Pagamentos",
    code: "536",
    ispb: "20855875",
    website: null,
    slug: "neon-pagamentos",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 147,
    name: "Senso Ccvm S.A.",
    description: "Senso Ccvm S.A.",
    code: "545",
    ispb: null,
    website: "",
    slug: "senso-ccvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 253,
    name: "Bco Luso Brasileiro S.A.",
    description: "Bco Luso Brasileiro S.A.",
    code: "600",
    ispb: null,
    website: "",
    slug: "bco-luso-brasileiro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 185,
    name: "Bco Industrial Do Brasil S.A.",
    description: "Bco Industrial Do Brasil S.A.",
    code: "604",
    ispb: null,
    website: "",
    slug: "bco-industrial-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 299,
    name: "Bco Vr S.A.",
    description: "Bco Vr S.A.",
    code: "610",
    ispb: null,
    website: "",
    slug: "bco-vr-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 275,
    name: "Bco Paulista S.A.",
    description: "Bco Paulista S.A.",
    code: "611",
    ispb: null,
    website: "",
    slug: "bco-paulista-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 184,
    name: "Bco Guanabara S.A.",
    description: "Bco Guanabara S.A.",
    code: "612",
    ispb: null,
    website: "",
    slug: "bco-guanabara-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 261,
    name: "Omni Banco S.A.",
    description: "Omni Banco S.A.",
    code: "613",
    ispb: null,
    website: "",
    slug: "omni-banco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 255,
    name: "Banco Pan",
    description: "Banco Pan",
    code: "623",
    ispb: null,
    website: "",
    slug: "banco-pan",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 269,
    name: "Bco C6 Consig",
    description: "Bco C6 Consig",
    code: "626",
    ispb: null,
    website: "",
    slug: "bco-c6-consig",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 250,
    name: "Bco Letsbank S.A.",
    description: "Bco Letsbank S.A.",
    code: "630",
    ispb: null,
    website: "",
    slug: "bco-letsbank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 289,
    name: "Bco Rendimento S.A.",
    description: "Bco Rendimento S.A.",
    code: "633",
    ispb: null,
    website: "",
    slug: "bco-rendimento-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 146,
    name: "Bco Triangulo S.A.",
    description: "Bco Triangulo S.A.",
    code: "634",
    ispb: null,
    website: "",
    slug: "bco-triangulo-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 263,
    name: "Bco Sofisa S.A.",
    description: "Bco Sofisa S.A.",
    code: "637",
    ispb: null,
    website: "",
    slug: "bco-sofisa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 278,
    name: "Bco Pine S.A.",
    description: "Bco Pine S.A.",
    code: "643",
    ispb: null,
    website: "",
    slug: "bco-pine-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 262,
    name: "Itaú Unibanco Holding S.A.",
    description: "Itaú Unibanco Holding S.A.",
    code: "652",
    ispb: null,
    website: "",
    slug: "itau-unibanco-holding-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 264,
    name: "Banco Voiter",
    description: "Banco Voiter",
    code: "653",
    ispb: null,
    website: "",
    slug: "banco-voiter",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 309,
    name: "Bco Digimais S.A.",
    description: "Bco Digimais S.A.",
    code: "654",
    ispb: null,
    website: "",
    slug: "bco-digimais-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 256,
    name: "Bco Votorantim S.A.",
    description: "Bco Votorantim S.A.",
    code: "655",
    ispb: null,
    website: "",
    slug: "bco-votorantim-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 280,
    name: "Bco Daycoval S.A",
    description: "Bco Daycoval S.A",
    code: "707",
    ispb: null,
    website: "",
    slug: "bco-daycoval-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 300,
    name: "Bco Ourinvest S.A.",
    description: "Bco Ourinvest S.A.",
    code: "712",
    ispb: null,
    website: "",
    slug: "bco-ourinvest-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 301,
    name: "Bco Rnx S.A.",
    description: "Bco Rnx S.A.",
    code: "720",
    ispb: null,
    website: "",
    slug: "bco-rnx-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 19,
    name: "Bco Cetelem S.A.",
    description: "Bco Cetelem S.A.",
    code: "739",
    ispb: null,
    website: "",
    slug: "bco-cetelem-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 17,
    name: "Bco Ribeirao Preto S.A.",
    description: "Bco Ribeirao Preto S.A.",
    code: "741",
    ispb: null,
    website: "",
    slug: "bco-ribeirao-preto-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 20,
    name: "Banco Semear",
    description: "Banco Semear",
    code: "743",
    ispb: null,
    website: "",
    slug: "banco-semear",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 197,
    name: "Bco Citibank S.A.",
    description: "Bco Citibank S.A.",
    code: "745",
    ispb: null,
    website: "",
    slug: "bco-citibank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 180,
    name: "Bco Modal S.A.",
    description: "Bco Modal S.A.",
    code: "746",
    ispb: null,
    website: "",
    slug: "bco-modal-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 23,
    name: "Bco Rabobank Intl Brasil S.A.",
    description: "Bco Rabobank Intl Brasil S.A.",
    code: "747",
    ispb: null,
    website: "",
    slug: "bco-rabobank-intl-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 26,
    name: "Bco Cooperativo Sicredi S.A.",
    description: "Bco Cooperativo Sicredi S.A.",
    code: "748",
    ispb: null,
    website: "",
    slug: "bco-cooperativo-sicredi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 176,
    name: "Scotiabank Brasil",
    description: "Scotiabank Brasil",
    code: "751",
    ispb: null,
    website: "",
    slug: "scotiabank-brasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 28,
    name: "Bco Bnp Paribas Brasil S A",
    description: "Bco Bnp Paribas Brasil S A",
    code: "752",
    ispb: null,
    website: "",
    slug: "bco-bnp-paribas-brasil-s-a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 293,
    name: "Novo Bco Continental S.A. - Bm",
    description: "Novo Bco Continental S.A. - Bm",
    code: "753",
    ispb: null,
    website: "",
    slug: "novo-bco-continental-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 296,
    name: "Banco Sistema",
    description: "Banco Sistema",
    code: "754",
    ispb: null,
    website: "",
    slug: "banco-sistema",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 276,
    name: "Bofa Merrill Lynch Bm S.A.",
    description: "Bofa Merrill Lynch Bm S.A.",
    code: "755",
    ispb: null,
    website: "",
    slug: "bofa-merrill-lynch-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 34,
    name: "Banco Sicoob S.A.",
    description: "Banco Sicoob S.A.",
    code: "756",
    ispb: null,
    website: "",
    slug: "banco-sicoob-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 36,
    name: "Bco Keb Hana Do Brasil S.A.",
    description: "Bco Keb Hana Do Brasil S.A.",
    code: "757",
    ispb: null,
    website: "",
    slug: "bco-keb-hana-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 8,
    name: "Prosperità",
    description: "Prosperità",
    code: "999",
    ispb: "11581339",
    website: "localhost",
    slug: "whitelabel",
    reference: "all",
    internal: true,
    default: false,
  },
  {
    id: 315,
    name: "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS",
    description: "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS",
    code: "ID148",
    ispb: "02405189",
    website: null,
    slug: "cooperativa-de-credito-da-regiao-central-de-minas",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 319,
    name: "CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL",
    description: "CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL",
    code: "ID419",
    ispb: "",
    website: null,
    slug: "cc-unicred-valor-capital-ltda-unicred-valor-capital",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 316,
    name: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
    description:
      "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
    code: "ID449",
    ispb: "",
    website: null,
    slug: "cooperativa-de-credito-poupanca-e-investimento-do-planalto-central-sicredi-planalto-central",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 314,
    name: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO SI",
    description: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO SI",
    code: "ID481",
    ispb: "",
    website: null,
    slug: "cooperativa-de-credito-poupanca-e-investimento-si",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 313,
    name: "STARK BANK S.A.",
    description: "STARK BANK S.A.",
    code: "ID644",
    ispb: "20018183",
    website: null,
    slug: "stark-bank",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 318,
    name: "NEON PAGAMENTOS S.A.",
    description: "NEON PAGAMENTOS S.A.",
    code: "IDR03",
    ispb: "",
    website: null,
    slug: "neon-pagamentos-sa",
    reference: "all",
    internal: false,
    default: false,
  },

  {
    id: 1,
    name: "Banco do Brasil",
    description: "Banco do Brasil S.A.",
    code: "001",
    ispb: "0",
    website: "http://www.bb.com.br",
    slug: "bancodobrasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 65,
    name: "Bco Da Amazonia S.A.",
    description: "Bco Da Amazonia S.A.",
    code: "003",
    ispb: null,
    website: "",
    slug: "bco-da-amazonia-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 79,
    name: "Bco Do Nordeste Do Brasil S.A.",
    description: "Bco Do Nordeste Do Brasil S.A.",
    code: "004",
    ispb: null,
    website: "",
    slug: "bco-do-nordeste-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 200,
    name: "Bndes",
    description: "Bndes",
    code: "007",
    ispb: null,
    website: "",
    slug: "bndes",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 302,
    name: "Credicoamo",
    description: "Credicoamo",
    code: "010",
    ispb: null,
    website: "",
    slug: "credicoamo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 274,
    name: "C.Suisse Hedging-Griffo Cv S/A",
    description: "C.Suisse Hedging-Griffo Cv S/A",
    code: "011",
    ispb: null,
    website: "",
    slug: "csuisse-hedginggriffo-cv-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 64,
    name: "Banco Inbursa",
    description: "Banco Inbursa",
    code: "012",
    ispb: null,
    website: "",
    slug: "banco-inbursa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 99,
    name: "State Street Br S.A. Bco Comercial",
    description: "State Street Br S.A. Bco Comercial",
    code: "014",
    ispb: null,
    website: "",
    slug: "state-street-br-sa-bco-comercial",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 41,
    name: "Ubs Brasil Cctvm S.A.",
    description: "Ubs Brasil Cctvm S.A.",
    code: "015",
    ispb: null,
    website: "",
    slug: "ubs-brasil-cctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 62,
    name: "Ccm Desp Trâns Sc E Rs",
    description: "Ccm Desp Trâns Sc E Rs",
    code: "016",
    ispb: null,
    website: "",
    slug: "ccm-desp-trans-sc-e-rs",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 235,
    name: "Bny Mellon Bco S.A.",
    description: "Bny Mellon Bco S.A.",
    code: "017",
    ispb: null,
    website: "",
    slug: "bny-mellon-bco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 249,
    name: "Bco Tricury S.A.",
    description: "Bco Tricury S.A.",
    code: "018",
    ispb: null,
    website: "",
    slug: "bco-tricury-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 173,
    name: "Bco Banestes S.A.",
    description: "Bco Banestes S.A.",
    code: "021",
    ispb: null,
    website: "",
    slug: "bco-banestes-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 111,
    name: "Bco Bandepe S.A.",
    description: "Bco Bandepe S.A.",
    code: "024",
    ispb: null,
    website: "",
    slug: "bco-bandepe-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 48,
    name: "Bco Alfa S.A.",
    description: "Bco Alfa S.A.",
    code: "025",
    ispb: null,
    website: "",
    slug: "bco-alfa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 203,
    name: "Banco Itaú Consignado S.A.",
    description: "Banco Itaú Consignado S.A.",
    code: "029",
    ispb: null,
    website: "",
    slug: "banco-itau-consignado-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 5,
    name: "Santander",
    description: "Banco Santander (Brasil) S.A.",
    code: "033",
    ispb: "90400888",
    website: "http://www.santander.com.br",
    slug: "santander",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 77,
    name: "Bco Bbi S.A.",
    description: "Bco Bbi S.A.",
    code: "036",
    ispb: null,
    website: "",
    slug: "bco-bbi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 67,
    name: "Bco Do Est. Do Pa S.A.",
    description: "Bco Do Est. Do Pa S.A.",
    code: "037",
    ispb: null,
    website: "",
    slug: "bco-do-est-do-pa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 51,
    name: "Bco Cargill S.A.",
    description: "Bco Cargill S.A.",
    code: "040",
    ispb: null,
    website: "",
    slug: "bco-cargill-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 307,
    name: "Bco Do Estado Do Rs S.A.",
    description: "Bco Do Estado Do Rs S.A.",
    code: "041",
    ispb: null,
    website: "",
    slug: "bco-do-estado-do-rs-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 121,
    name: "Bco Do Est. De Se S.A.",
    description: "Bco Do Est. De Se S.A.",
    code: "047",
    ispb: null,
    website: "",
    slug: "bco-do-est-de-se-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 66,
    name: "Confidence Cc S.A.",
    description: "Confidence Cc S.A.",
    code: "060",
    ispb: null,
    website: "",
    slug: "confidence-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 43,
    name: "Hipercard Bm S.A.",
    description: "Hipercard Bm S.A.",
    code: "062",
    ispb: null,
    website: "",
    slug: "hipercard-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 56,
    name: "Banco Bradescard",
    description: "Banco Bradescard",
    code: "063",
    ispb: null,
    website: "",
    slug: "banco-bradescard",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 59,
    name: "Goldman Sachs Do Brasil Bm S.A",
    description: "Goldman Sachs Do Brasil Bm S.A",
    code: "064",
    ispb: null,
    website: "",
    slug: "goldman-sachs-do-brasil-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 241,
    name: "Bco Andbank S.A.",
    description: "Bco Andbank S.A.",
    code: "065",
    ispb: null,
    website: "",
    slug: "bco-andbank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 40,
    name: "Bco Morgan Stanley S.A.",
    description: "Bco Morgan Stanley S.A.",
    code: "066",
    ispb: null,
    website: "",
    slug: "bco-morgan-stanley-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 265,
    name: "Bco Crefisa S.A.",
    description: "Bco Crefisa S.A.",
    code: "069",
    ispb: null,
    website: "",
    slug: "bco-crefisa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 10,
    name: "Brb - Bco De Brasilia S.A.",
    description: "Brb - Bco De Brasilia S.A.",
    code: "070",
    ispb: null,
    website: "",
    slug: "brb-bco-de-brasilia-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 44,
    name: "Bco. J.Safra S.A.",
    description: "Bco. J.Safra S.A.",
    code: "074",
    ispb: null,
    website: "",
    slug: "bco-jsafra-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 50,
    name: "Bco Abn Amro S.A.",
    description: "Bco Abn Amro S.A.",
    code: "075",
    ispb: null,
    website: "",
    slug: "bco-abn-amro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 84,
    name: "Bco Kdb Brasil S.A.",
    description: "Bco Kdb Brasil S.A.",
    code: "076",
    ispb: null,
    website: "",
    slug: "bco-kdb-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 15,
    name: "Banco Inter",
    description: "Banco Inter",
    code: "077",
    ispb: "00416968",
    website: "",
    slug: "banco-inter",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 207,
    name: "Haitong Bi Do Brasil S.A.",
    description: "Haitong Bi Do Brasil S.A.",
    code: "078",
    ispb: null,
    website: "",
    slug: "haitong-bi-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 103,
    name: "Bco Original Do Agro S/A",
    description: "Bco Original Do Agro S/A",
    code: "079",
    ispb: null,
    website: "",
    slug: "bco-original-do-agro-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 292,
    name: "B&T Cc Ltda.",
    description: "B&T Cc Ltda.",
    code: "080",
    ispb: null,
    website: "",
    slug: "bandt-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 105,
    name: "Bancoseguro S.A.",
    description: "Bancoseguro S.A.",
    code: "081",
    ispb: null,
    website: "",
    slug: "bancoseguro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 85,
    name: "Banco Topázio S.A.",
    description: "Banco Topázio S.A.",
    code: "082",
    ispb: null,
    website: "",
    slug: "banco-topazio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 109,
    name: "Bco Da China Brasil S.A.",
    description: "Bco Da China Brasil S.A.",
    code: "083",
    ispb: null,
    website: "",
    slug: "bco-da-china-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 38,
    name: "Uniprime Do Brasil - Cc Ltda.",
    description: "Uniprime Do Brasil - Cc Ltda.",
    code: "084",
    ispb: null,
    website: "",
    slug: "uniprime-do-brasil-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 71,
    name: "Coopcentral Ailos",
    description: "Coopcentral Ailos",
    code: "085",
    ispb: null,
    website: "",
    slug: "coopcentral-ailos",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 114,
    name: "Banco Randon S.A.",
    description: "Banco Randon S.A.",
    code: "088",
    ispb: null,
    website: "",
    slug: "banco-randon-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 277,
    name: "Credisan Cc",
    description: "Credisan Cc",
    code: "089",
    ispb: null,
    website: "",
    slug: "credisan-cc",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 120,
    name: "Brk S.A. Cfi",
    description: "Brk S.A. Cfi",
    code: "092",
    ispb: null,
    website: "",
    slug: "brk-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 89,
    name: "Polocred Scmepp Ltda.",
    description: "Polocred Scmepp Ltda.",
    code: "093",
    ispb: null,
    website: "",
    slug: "polocred-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 117,
    name: "Banco Finaxis",
    description: "Banco Finaxis",
    code: "094",
    ispb: null,
    website: "",
    slug: "banco-finaxis",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 116,
    name: "Travelex Banco De Câmbio S.A.",
    description: "Travelex Banco De Câmbio S.A.",
    code: "095",
    ispb: null,
    website: "",
    slug: "travelex-banco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 22,
    name: "Bco B3 S.A.",
    description: "Bco B3 S.A.",
    code: "096",
    ispb: null,
    website: "",
    slug: "bco-b3-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 61,
    name: "Credisis Central De Cooperativas De Crédito Ltda.",
    description: "Credisis Central De Cooperativas De Crédito Ltda.",
    code: "097",
    ispb: null,
    website: "",
    slug: "credisis-central-de-cooperativas-de-credito-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 298,
    name: "Credialiança Ccr",
    description: "Credialiança Ccr",
    code: "098",
    ispb: null,
    website: "",
    slug: "credialianca-ccr",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 45,
    name: "Uniprime Central Ccc Ltda.",
    description: "Uniprime Central Ccc Ltda.",
    code: "099",
    ispb: null,
    website: "",
    slug: "uniprime-central-ccc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 21,
    name: "Planner Cv S.A.",
    description: "Planner Cv S.A.",
    code: "100",
    ispb: null,
    website: "",
    slug: "planner-cv-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 283,
    name: "Renascenca Dtvm Ltda",
    description: "Renascenca Dtvm Ltda",
    code: "101",
    ispb: null,
    website: "",
    slug: "renascenca-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 37,
    name: "Xp Investimentos Cctvm S/A",
    description: "Xp Investimentos Cctvm S/A",
    code: "102",
    ispb: null,
    website: "",
    slug: "xp-investimentos-cctvm-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 3,
    name: "Caixa Econômica Federal",
    description: "Caixa Econômica Federal",
    code: "104",
    ispb: "360305",
    website: "http://www.caixa.gov.br",
    slug: "caixa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 83,
    name: "Lecca Cfi S.A.",
    description: "Lecca Cfi S.A.",
    code: "105",
    ispb: null,
    website: "",
    slug: "lecca-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 136,
    name: "Bco Bocom Bbm S.A.",
    description: "Bco Bocom Bbm S.A.",
    code: "107",
    ispb: null,
    website: "",
    slug: "bco-bocom-bbm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 31,
    name: "Portocred S.A. - Cfi",
    description: "Portocred S.A. - Cfi",
    code: "108",
    ispb: null,
    website: "",
    slug: "portocred-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 212,
    name: "Oliveira Trust Dtvm S.A.",
    description: "Oliveira Trust Dtvm S.A.",
    code: "111",
    ispb: null,
    website: "",
    slug: "oliveira-trust-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 272,
    name: "Magliano S A  Ctvm",
    description: "Magliano S A  Ctvm",
    code: "113",
    ispb: null,
    website: "",
    slug: "magliano-s-a-ctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 75,
    name: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    description: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    code: "114",
    ispb: null,
    website: "",
    slug: "central-cooperativa-de-credito-no-estado-do-espirito-santo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 308,
    name: "Advanced Cc Ltda",
    description: "Advanced Cc Ltda",
    code: "117",
    ispb: null,
    website: "",
    slug: "advanced-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 130,
    name: "Bco Western Union",
    description: "Bco Western Union",
    code: "119",
    ispb: null,
    website: "",
    slug: "bco-western-union",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 198,
    name: "Bco Rodobens S.A.",
    description: "Bco Rodobens S.A.",
    code: "120",
    ispb: null,
    website: "",
    slug: "bco-rodobens-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 108,
    name: "Bco Agibank S.A.",
    description: "Bco Agibank S.A.",
    code: "121",
    ispb: null,
    website: "",
    slug: "bco-agibank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 193,
    name: "Bco Bradesco Berj S.A.",
    description: "Bco Bradesco Berj S.A.",
    code: "122",
    ispb: null,
    website: "",
    slug: "bco-bradesco-berj-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 138,
    name: "Bco Woori Bank Do Brasil S.A.",
    description: "Bco Woori Bank Do Brasil S.A.",
    code: "124",
    ispb: null,
    website: "",
    slug: "bco-woori-bank-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 239,
    name: "Banco Genial",
    description: "Banco Genial",
    code: "125",
    ispb: null,
    website: "",
    slug: "banco-genial",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 125,
    name: "Br Partners Bi",
    description: "Br Partners Bi",
    code: "126",
    ispb: null,
    website: "",
    slug: "br-partners-bi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 102,
    name: "Codepe Cvc S.A.",
    description: "Codepe Cvc S.A.",
    code: "127",
    ispb: null,
    website: "",
    slug: "codepe-cvc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 154,
    name: "Ms Bank S.A. Bco De Câmbio",
    description: "Ms Bank S.A. Bco De Câmbio",
    code: "128",
    ispb: null,
    website: "",
    slug: "ms-bank-sa-bco-de-cambio",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 153,
    name: "Ubs Brasil Bi S.A.",
    description: "Ubs Brasil Bi S.A.",
    code: "129",
    ispb: null,
    website: "",
    slug: "ubs-brasil-bi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 100,
    name: "Caruana Scfi",
    description: "Caruana Scfi",
    code: "130",
    ispb: null,
    website: "",
    slug: "caruana-scfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 273,
    name: "Tullett Prebon Brasil Cvc Ltda",
    description: "Tullett Prebon Brasil Cvc Ltda",
    code: "131",
    ispb: null,
    website: "",
    slug: "tullett-prebon-brasil-cvc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 148,
    name: "Icbc Do Brasil Bm S.A.",
    description: "Icbc Do Brasil Bm S.A.",
    code: "132",
    ispb: null,
    website: "",
    slug: "icbc-do-brasil-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 106,
    name: "Cresol Confederação",
    description: "Cresol Confederação",
    code: "133",
    ispb: null,
    website: "",
    slug: "cresol-confederacao",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 202,
    name: "Bgc Liquidez Dtvm Ltda",
    description: "Bgc Liquidez Dtvm Ltda",
    code: "134",
    ispb: null,
    website: "",
    slug: "bgc-liquidez-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 13,
    name: "Conf Nac Coop Centrais Unicred",
    description: "Conf Nac Coop Centrais Unicred",
    code: "136",
    ispb: null,
    website: "",
    slug: "conf-nac-coop-centrais-unicred",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 110,
    name: "Get Money Cc Ltda",
    description: "Get Money Cc Ltda",
    code: "138",
    ispb: null,
    website: "",
    slug: "get-money-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 248,
    name: "Intesa Sanpaolo Brasil S.A. Bm",
    description: "Intesa Sanpaolo Brasil S.A. Bm",
    code: "139",
    ispb: null,
    website: "",
    slug: "intesa-sanpaolo-brasil-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 279,
    name: "Nu Invest Corretora De Valores S.A.",
    description: "Nu Invest Corretora De Valores S.A.",
    code: "140",
    ispb: null,
    website: "",
    slug: "nu-invest-corretora-de-valores-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 143,
    name: "Broker Brasil Cc Ltda.",
    description: "Broker Brasil Cc Ltda.",
    code: "142",
    ispb: null,
    website: "",
    slug: "broker-brasil-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 42,
    name: "Treviso Cc S.A.",
    description: "Treviso Cc S.A.",
    code: "143",
    ispb: null,
    website: "",
    slug: "treviso-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 122,
    name: "Bexs Bco De Cambio S.A.",
    description: "Bexs Bco De Cambio S.A.",
    code: "144",
    ispb: null,
    website: "",
    slug: "bexs-bco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 243,
    name: "Levycam Ccv Ltda",
    description: "Levycam Ccv Ltda",
    code: "145",
    ispb: null,
    website: "",
    slug: "levycam-ccv-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 164,
    name: "Guitta Cc Ltda",
    description: "Guitta Cc Ltda",
    code: "146",
    ispb: null,
    website: "",
    slug: "guitta-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 139,
    name: "Facta S.A. Cfi",
    description: "Facta S.A. Cfi",
    code: "149",
    ispb: null,
    website: "",
    slug: "facta-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 97,
    name: "Icap Do Brasil Ctvm Ltda.",
    description: "Icap Do Brasil Ctvm Ltda.",
    code: "157",
    ispb: null,
    website: "",
    slug: "icap-do-brasil-ctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 70,
    name: "Casa Credito S.A. Scm",
    description: "Casa Credito S.A. Scm",
    code: "159",
    ispb: null,
    website: "",
    slug: "casa-credito-sa-scm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 162,
    name: "Commerzbank Brasil S.A. - Bco Múltiplo",
    description: "Commerzbank Brasil S.A. - Bco Múltiplo",
    code: "163",
    ispb: null,
    website: "",
    slug: "commerzbank-brasil-sa-bco-multiplo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 128,
    name: "Brl Trust Dtvm Sa",
    description: "Brl Trust Dtvm Sa",
    code: "173",
    ispb: null,
    website: "",
    slug: "brl-trust-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 236,
    name: "Pefisa S.A. - C.F.I.",
    description: "Pefisa S.A. - C.F.I.",
    code: "174",
    ispb: null,
    website: "",
    slug: "pefisa-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 286,
    name: "Guide",
    description: "Guide",
    code: "177",
    ispb: null,
    website: "",
    slug: "guide",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 39,
    name: "Cm Capital Markets Cctvm Ltda",
    description: "Cm Capital Markets Cctvm Ltda",
    code: "180",
    ispb: null,
    website: "",
    slug: "cm-capital-markets-cctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 98,
    name: "Socred Sa - Scmepp",
    description: "Socred Sa - Scmepp",
    code: "183",
    ispb: null,
    website: "",
    slug: "socred-sa-scmepp",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 145,
    name: "Bco Itaú Bba S.A.",
    description: "Bco Itaú Bba S.A.",
    code: "184",
    ispb: null,
    website: "",
    slug: "bco-itau-bba-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 201,
    name: "Ativa S.A. Investimentos Cctvm",
    description: "Ativa S.A. Investimentos Cctvm",
    code: "188",
    ispb: null,
    website: "",
    slug: "ativa-sa-investimentos-cctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 82,
    name: "Hs Financeira",
    description: "Hs Financeira",
    code: "189",
    ispb: null,
    website: "",
    slug: "hs-financeira",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 54,
    name: "Servicoop",
    description: "Servicoop",
    code: "190",
    ispb: null,
    website: "",
    slug: "servicoop",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 57,
    name: "Nova Futura Ctvm Ltda.",
    description: "Nova Futura Ctvm Ltda.",
    code: "191",
    ispb: null,
    website: "",
    slug: "nova-futura-ctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 157,
    name: "Parmetal Dtvm Ltda",
    description: "Parmetal Dtvm Ltda",
    code: "194",
    ispb: null,
    website: "",
    slug: "parmetal-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 87,
    name: "Valor Scd S.A.",
    description: "Valor Scd S.A.",
    code: "195",
    ispb: null,
    website: "",
    slug: "valor-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 188,
    name: "Fair Cc S.A.",
    description: "Fair Cc S.A.",
    code: "196",
    ispb: null,
    website: "",
    slug: "fair-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 140,
    name: "Stone Pagamentos S.A.",
    description: "Stone Pagamentos S.A.",
    code: "197",
    ispb: null,
    website: "",
    slug: "stone-pagamentos-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 178,
    name: "Banco Btg Pactual S.A.",
    description: "Banco Btg Pactual S.A.",
    code: "208",
    ispb: null,
    website: "",
    slug: "banco-btg-pactual-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 311,
    name: "Banco Original",
    description: "Banco Original",
    code: "212",
    ispb: null,
    website: "",
    slug: "banco-original",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 247,
    name: "Bco Arbi S.A.",
    description: "Bco Arbi S.A.",
    code: "213",
    ispb: null,
    website: "",
    slug: "bco-arbi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 306,
    name: "Banco John Deere S.A.",
    description: "Banco John Deere S.A.",
    code: "217",
    ispb: null,
    website: "",
    slug: "banco-john-deere-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 6,
    name: "BS2",
    description: "BS2",
    code: "218",
    ispb: "71027866",
    website: "https://www.bs2.com/banco/",
    slug: "bs2",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 294,
    name: "Bco Crédit Agricole Br S.A.",
    description: "Bco Crédit Agricole Br S.A.",
    code: "222",
    ispb: null,
    website: "",
    slug: "bco-credit-agricole-br-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 251,
    name: "Bco Fibra S.A.",
    description: "Bco Fibra S.A.",
    code: "224",
    ispb: null,
    website: "",
    slug: "bco-fibra-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 285,
    name: "Banco Cifra",
    description: "Banco Cifra",
    code: "233",
    ispb: null,
    website: "",
    slug: "banco-cifra",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 2,
    name: "Bradesco",
    description: "Banco Bradesco S.A.",
    code: "237",
    ispb: "60746948",
    website: "http://www.bradesco.com.br",
    slug: "bradesco",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 181,
    name: "Bco Classico S.A.",
    description: "Bco Classico S.A.",
    code: "241",
    ispb: null,
    website: "",
    slug: "bco-classico-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 205,
    name: "Banco Master",
    description: "Banco Master",
    code: "243",
    ispb: null,
    website: "",
    slug: "banco-master",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 174,
    name: "Bco Abc Brasil S.A.",
    description: "Bco Abc Brasil S.A.",
    code: "246",
    ispb: null,
    website: "",
    slug: "bco-abc-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 267,
    name: "Banco Investcred Unibanco S.A.",
    description: "Banco Investcred Unibanco S.A.",
    code: "249",
    ispb: null,
    website: "",
    slug: "banco-investcred-unibanco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 245,
    name: "Bexs Cc S.A.",
    description: "Bexs Cc S.A.",
    code: "253",
    ispb: null,
    website: "",
    slug: "bexs-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 133,
    name: "Parana Bco S.A.",
    description: "Parana Bco S.A.",
    code: "254",
    ispb: null,
    website: "",
    slug: "parana-bco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 94,
    name: "Moneycorp Bco De Câmbio S.A.",
    description: "Moneycorp Bco De Câmbio S.A.",
    code: "259",
    ispb: null,
    website: "",
    slug: "moneycorp-bco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 152,
    name: "Nu Pagamentos - Ip",
    description: "Nu Pagamentos - Ip",
    code: "260",
    ispb: "18236120",
    website: "",
    slug: "nu-pagamentos-ip",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 199,
    name: "Bco Fator S.A.",
    description: "Bco Fator S.A.",
    code: "265",
    ispb: null,
    website: "",
    slug: "bco-fator-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 192,
    name: "Bco Cedula S.A.",
    description: "Bco Cedula S.A.",
    code: "266",
    ispb: null,
    website: "",
    slug: "bco-cedula-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 134,
    name: "Bari Cia Hipotecária",
    description: "Bari Cia Hipotecária",
    code: "268",
    ispb: null,
    website: "",
    slug: "bari-cia-hipotecaria",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 246,
    name: "Bco Hsbc S.A.",
    description: "Bco Hsbc S.A.",
    code: "269",
    ispb: null,
    website: "",
    slug: "bco-hsbc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 270,
    name: "Sagitur Cc Ltda",
    description: "Sagitur Cc Ltda",
    code: "270",
    ispb: null,
    website: "",
    slug: "sagitur-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 172,
    name: "Ib Cctvm S.A.",
    description: "Ib Cctvm S.A.",
    code: "271",
    ispb: null,
    website: "",
    slug: "ib-cctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 12,
    name: "Agk Cc S.A.",
    description: "Agk Cc S.A.",
    code: "272",
    ispb: null,
    website: "",
    slug: "agk-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 91,
    name: "Ccr De São Miguel Do Oeste",
    description: "Ccr De São Miguel Do Oeste",
    code: "273",
    ispb: null,
    website: "",
    slug: "ccr-de-sao-miguel-do-oeste",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 9,
    name: "MONEY PLUS SCMEPP LTDA",
    description: "MONEY PLUS SCMEPP LTDA",
    code: "274",
    ispb: "11581339",
    website: "https://moneyp.com.br/",
    slug: "bmp",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 118,
    name: "Bco Senff S.A.",
    description: "Bco Senff S.A.",
    code: "276",
    ispb: null,
    website: "",
    slug: "bco-senff-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 171,
    name: "Genial Investimentos Cvm S.A.",
    description: "Genial Investimentos Cvm S.A.",
    code: "278",
    ispb: null,
    website: "",
    slug: "genial-investimentos-cvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 166,
    name: "Ccr De Primavera Do Leste",
    description: "Ccr De Primavera Do Leste",
    code: "279",
    ispb: null,
    website: "",
    slug: "ccr-de-primavera-do-leste",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 163,
    name: "Will Financeira S.A.Cfi",
    description: "Will Financeira S.A.Cfi",
    code: "280",
    ispb: null,
    website: "",
    slug: "will-financeira-sacfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 295,
    name: "Ccr Coopavel",
    description: "Ccr Coopavel",
    code: "281",
    ispb: null,
    website: "",
    slug: "ccr-coopavel",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 305,
    name: "Rb Investimentos Dtvm Ltda.",
    description: "Rb Investimentos Dtvm Ltda.",
    code: "283",
    ispb: null,
    website: "",
    slug: "rb-investimentos-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 291,
    name: "Frente Cc Ltda.",
    description: "Frente Cc Ltda.",
    code: "285",
    ispb: null,
    website: "",
    slug: "frente-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 88,
    name: "Ccr De Ouro",
    description: "Ccr De Ouro",
    code: "286",
    ispb: null,
    website: "",
    slug: "ccr-de-ouro",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 281,
    name: "Carol Dtvm Ltda.",
    description: "Carol Dtvm Ltda.",
    code: "288",
    ispb: null,
    website: "",
    slug: "carol-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 312,
    name: "Decyseo Cc Ltda.",
    description: "Decyseo Cc Ltda.",
    code: "289",
    ispb: null,
    website: "",
    slug: "decyseo-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 93,
    name: "Pagseguro S.A.",
    description: "Pagseguro S.A.",
    code: "290",
    ispb: null,
    website: "",
    slug: "pagseguro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 175,
    name: "Bs2 Dtvm S.A.",
    description: "Bs2 Dtvm S.A.",
    code: "292",
    ispb: null,
    website: "",
    slug: "bs2-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 290,
    name: "Lastro Rdv Dtvm Ltda",
    description: "Lastro Rdv Dtvm Ltda",
    code: "293",
    ispb: null,
    website: "",
    slug: "lastro-rdv-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 55,
    name: "Vision S.A. Cc",
    description: "Vision S.A. Cc",
    code: "296",
    ispb: null,
    website: "",
    slug: "vision-sa-cc",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 149,
    name: "Vips Cc Ltda.",
    description: "Vips Cc Ltda.",
    code: "298",
    ispb: null,
    website: "",
    slug: "vips-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 63,
    name: "Bco Sorocred S.A. - Bm",
    description: "Bco Sorocred S.A. - Bm",
    code: "299",
    ispb: null,
    website: "",
    slug: "bco-sorocred-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 190,
    name: "Bco La Nacion Argentina",
    description: "Bco La Nacion Argentina",
    code: "300",
    ispb: null,
    website: "",
    slug: "bco-la-nacion-argentina",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 127,
    name: "Bpp Ip S.A.",
    description: "Bpp Ip S.A.",
    code: "301",
    ispb: null,
    website: "",
    slug: "bpp-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 231,
    name: "Portopar Dtvm Ltda",
    description: "Portopar Dtvm Ltda",
    code: "306",
    ispb: null,
    website: "",
    slug: "portopar-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 52,
    name: "Terra Investimentos Dtvm",
    description: "Terra Investimentos Dtvm",
    code: "307",
    ispb: null,
    website: "",
    slug: "terra-investimentos-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 132,
    name: "Cambionet Cc Ltda",
    description: "Cambionet Cc Ltda",
    code: "309",
    ispb: null,
    website: "",
    slug: "cambionet-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 160,
    name: "Vortx Dtvm Ltda.",
    description: "Vortx Dtvm Ltda.",
    code: "310",
    ispb: null,
    website: "",
    slug: "vortx-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 297,
    name: "Dourada Corretora",
    description: "Dourada Corretora",
    code: "311",
    ispb: null,
    website: "",
    slug: "dourada-corretora",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 86,
    name: "Hscm Scmepp Ltda.",
    description: "Hscm Scmepp Ltda.",
    code: "312",
    ispb: null,
    website: "",
    slug: "hscm-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 142,
    name: "Amazônia Cc Ltda.",
    description: "Amazônia Cc Ltda.",
    code: "313",
    ispb: null,
    website: "",
    slug: "amazonia-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 49,
    name: "Pi Dtvm S.A.",
    description: "Pi Dtvm S.A.",
    code: "315",
    ispb: null,
    website: "",
    slug: "pi-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 268,
    name: "Bco Bmg S.A.",
    description: "Bco Bmg S.A.",
    code: "318",
    ispb: null,
    website: "",
    slug: "bco-bmg-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 115,
    name: "Om Dtvm Ltda",
    description: "Om Dtvm Ltda",
    code: "319",
    ispb: null,
    website: "",
    slug: "om-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 81,
    name: "Bco Ccb Brasil S.A.",
    description: "Bco Ccb Brasil S.A.",
    code: "320",
    ispb: null,
    website: "",
    slug: "bco-ccb-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 151,
    name: "Crefaz Scmepp Ltda",
    description: "Crefaz Scmepp Ltda",
    code: "321",
    ispb: null,
    website: "",
    slug: "crefaz-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 25,
    name: "Ccr De Abelardo Luz",
    description: "Ccr De Abelardo Luz",
    code: "322",
    ispb: null,
    website: "",
    slug: "ccr-de-abelardo-luz",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 107,
    name: "Mercado Pago Ip Ltda.",
    description: "Mercado Pago Ip Ltda.",
    code: "323",
    ispb: "10573521",
    website: "",
    slug: "mercado-pago-ip-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 159,
    name: "Cartos Scd S.A.",
    description: "Cartos Scd S.A.",
    code: "324",
    ispb: null,
    website: "",
    slug: "cartos-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 126,
    name: "Órama Dtvm S.A.",
    description: "Órama Dtvm S.A.",
    code: "325",
    ispb: null,
    website: "",
    slug: "orama-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 47,
    name: "Parati - Cfi S.A.",
    description: "Parati - Cfi S.A.",
    code: "326",
    ispb: null,
    website: "",
    slug: "parati-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 76,
    name: "Cecm Fabric Calçados Sapiranga",
    description: "Cecm Fabric Calçados Sapiranga",
    code: "328",
    ispb: null,
    website: "",
    slug: "cecm-fabric-calcados-sapiranga",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 187,
    name: "Qi Scd S.A.",
    description: "Qi Scd S.A.",
    code: "329",
    ispb: null,
    website: "",
    slug: "qi-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 18,
    name: "Banco Bari S.A.",
    description: "Banco Bari S.A.",
    code: "330",
    ispb: null,
    website: "",
    slug: "banco-bari-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 129,
    name: "Fram Capital Dtvm S.A.",
    description: "Fram Capital Dtvm S.A.",
    code: "331",
    ispb: null,
    website: "",
    slug: "fram-capital-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 123,
    name: "Acesso Solucoes Pagamento Sa",
    description: "Acesso Solucoes Pagamento Sa",
    code: "332",
    ispb: null,
    website: "",
    slug: "acesso-solucoes-pagamento-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 167,
    name: "Banco Digio",
    description: "Banco Digio",
    code: "335",
    ispb: null,
    website: "",
    slug: "banco-digio",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 183,
    name: "Bco C6 S.A.",
    description: "Bco C6 S.A.",
    code: "336",
    ispb: "31872495",
    website: "",
    slug: "bco-c6-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 104,
    name: "Super Pagamentos E Administracao De Meios Eletronicos S.A.",
    description: "Super Pagamentos E Administracao De Meios Eletronicos S.A.",
    code: "340",
    ispb: null,
    website: "",
    slug: "super-pagamentos-e-administracao-de-meios-eletronicos-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 4,
    name: "Itaú",
    description: "Itaú Unibanco S.A.",
    code: "341",
    ispb: "60701190",
    website: "http://www.itau.com.br",
    slug: "itau",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 189,
    name: "Creditas Scd",
    description: "Creditas Scd",
    code: "342",
    ispb: null,
    website: "",
    slug: "creditas-scd",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 165,
    name: "Ffa Scmepp Ltda.",
    description: "Ffa Scmepp Ltda.",
    code: "343",
    ispb: null,
    website: "",
    slug: "ffa-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 195,
    name: "Bco Xp S.A.",
    description: "Bco Xp S.A.",
    code: "348",
    ispb: "33264668",
    website: "",
    slug: "bco-xp-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 168,
    name: "Al5 S.A. Cfi",
    description: "Al5 S.A. Cfi",
    code: "349",
    ispb: null,
    website: "",
    slug: "al5-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 27,
    name: "Crehnor Laranjeiras",
    description: "Crehnor Laranjeiras",
    code: "350",
    ispb: null,
    website: "",
    slug: "crehnor-laranjeiras",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 177,
    name: "Toro Ctvm S.A.",
    description: "Toro Ctvm S.A.",
    code: "352",
    ispb: null,
    website: "",
    slug: "toro-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 244,
    name: "Necton Investimentos S.A Cvm",
    description: "Necton Investimentos S.A Cvm",
    code: "354",
    ispb: null,
    website: "",
    slug: "necton-investimentos-sa-cvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 208,
    name: "Ótimo Scd S.A.",
    description: "Ótimo Scd S.A.",
    code: "355",
    ispb: null,
    website: "",
    slug: "otimo-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 101,
    name: "Midway S.A. - Scfi",
    description: "Midway S.A. - Scfi",
    code: "358",
    ispb: null,
    website: "",
    slug: "midway-sa-scfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 69,
    name: "Zema Cfi S/A",
    description: "Zema Cfi S/A",
    code: "359",
    ispb: null,
    website: "",
    slug: "zema-cfi-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 35,
    name: "Trinus Capital Dtvm",
    description: "Trinus Capital Dtvm",
    code: "360",
    ispb: null,
    website: "",
    slug: "trinus-capital-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 24,
    name: "Cielo S.A.",
    description: "Cielo S.A.",
    code: "362",
    ispb: null,
    website: "",
    slug: "cielo-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 282,
    name: "Singulare Ctvm S.A.",
    description: "Singulare Ctvm S.A.",
    code: "363",
    ispb: null,
    website: "",
    slug: "singulare-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 96,
    name: "Gerencianet",
    description: "Gerencianet",
    code: "364",
    ispb: null,
    website: "",
    slug: "gerencianet",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 288,
    name: "Simpaul",
    description: "Simpaul",
    code: "365",
    ispb: null,
    website: "",
    slug: "simpaul",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 271,
    name: "Bco Societe Generale Brasil",
    description: "Bco Societe Generale Brasil",
    code: "366",
    ispb: null,
    website: "",
    slug: "bco-societe-generale-brasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 209,
    name: "Vitreo Dtvm S.A.",
    description: "Vitreo Dtvm S.A.",
    code: "367",
    ispb: null,
    website: "",
    slug: "vitreo-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 92,
    name: "Bco Csf S.A.",
    description: "Bco Csf S.A.",
    code: "368",
    ispb: null,
    website: "",
    slug: "bco-csf-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 266,
    name: "Bco Mizuho S.A.",
    description: "Bco Mizuho S.A.",
    code: "370",
    ispb: null,
    website: "",
    slug: "bco-mizuho-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 310,
    name: "Warren Cvmc Ltda",
    description: "Warren Cvmc Ltda",
    code: "371",
    ispb: null,
    website: "",
    slug: "warren-cvmc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 211,
    name: "Up.P Sep S.A.",
    description: "Up.P Sep S.A.",
    code: "373",
    ispb: null,
    website: "",
    slug: "upp-sep-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 170,
    name: "Realize Cfi S.A.",
    description: "Realize Cfi S.A.",
    code: "374",
    ispb: null,
    website: "",
    slug: "realize-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 194,
    name: "Bco J.P. Morgan S.A.",
    description: "Bco J.P. Morgan S.A.",
    code: "376",
    ispb: null,
    website: "",
    slug: "bco-jp-morgan-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 150,
    name: "Bms Scd S.A.",
    description: "Bms Scd S.A.",
    code: "377",
    ispb: null,
    website: "",
    slug: "bms-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 32,
    name: "Bco Brasileiro De Crédito S.A.",
    description: "Bco Brasileiro De Crédito S.A.",
    code: "378",
    ispb: null,
    website: "",
    slug: "bco-brasileiro-de-credito-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 29,
    name: "Cecm Cooperforte",
    description: "Cecm Cooperforte",
    code: "379",
    ispb: null,
    website: "",
    slug: "cecm-cooperforte",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 161,
    name: "Picpay",
    description: "Picpay",
    code: "380",
    ispb: null,
    website: "",
    slug: "picpay",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 260,
    name: "Bco Mercedes-Benz S.A.",
    description: "Bco Mercedes-Benz S.A.",
    code: "381",
    ispb: null,
    website: "",
    slug: "bco-mercedesbenz-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 58,
    name: "Fiducia Scmepp Ltda",
    description: "Fiducia Scmepp Ltda",
    code: "382",
    ispb: null,
    website: "",
    slug: "fiducia-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 158,
    name: "Ebanx Ip Ltda.",
    description: "Ebanx Ip Ltda.",
    code: "383",
    ispb: null,
    website: "",
    slug: "ebanx-ip-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 112,
    name: "Global Scm Ltda",
    description: "Global Scm Ltda",
    code: "384",
    ispb: null,
    website: "",
    slug: "global-scm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 179,
    name: "Nu Financeira S.A. Cfi",
    description: "Nu Financeira S.A. Cfi",
    code: "386",
    ispb: null,
    website: "",
    slug: "nu-financeira-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 46,
    name: "Bco Toyota Do Brasil S.A.",
    description: "Bco Toyota Do Brasil S.A.",
    code: "387",
    ispb: null,
    website: "",
    slug: "bco-toyota-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 144,
    name: "Bco Mercantil Do Brasil S.A.",
    description: "Bco Mercantil Do Brasil S.A.",
    code: "389",
    ispb: null,
    website: "",
    slug: "bco-mercantil-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 254,
    name: "Bco Gm S.A.",
    description: "Bco Gm S.A.",
    code: "390",
    ispb: null,
    website: "",
    slug: "bco-gm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 90,
    name: "Ccr De Ibiam",
    description: "Ccr De Ibiam",
    code: "391",
    ispb: null,
    website: "",
    slug: "ccr-de-ibiam",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 252,
    name: "Bco Volkswagen S.A",
    description: "Bco Volkswagen S.A",
    code: "393",
    ispb: null,
    website: "",
    slug: "bco-volkswagen-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 78,
    name: "Bco Bradesco Financ. S.A.",
    description: "Bco Bradesco Financ. S.A.",
    code: "394",
    ispb: null,
    website: "",
    slug: "bco-bradesco-financ-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 95,
    name: "F D Gold Dtvm Ltda",
    description: "F D Gold Dtvm Ltda",
    code: "395",
    ispb: null,
    website: "",
    slug: "f-d-gold-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 131,
    name: "Hub Pagamentos",
    description: "Hub Pagamentos",
    code: "396",
    ispb: null,
    website: "",
    slug: "hub-pagamentos",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 206,
    name: "Listo Scd S.A.",
    description: "Listo Scd S.A.",
    code: "397",
    ispb: null,
    website: "",
    slug: "listo-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 182,
    name: "Ideal Ctvm S.A.",
    description: "Ideal Ctvm S.A.",
    code: "398",
    ispb: null,
    website: "",
    slug: "ideal-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 30,
    name: "Kirton Bank",
    description: "Kirton Bank",
    code: "399",
    ispb: null,
    website: "",
    slug: "kirton-bank",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 72,
    name: "Coop Creditag",
    description: "Coop Creditag",
    code: "400",
    ispb: null,
    website: "",
    slug: "coop-creditag",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 135,
    name: "Iugu Ip S.A.",
    description: "Iugu Ip S.A.",
    code: "401",
    ispb: null,
    website: "",
    slug: "iugu-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 214,
    name: "Cobuccio Scd S.A.",
    description: "Cobuccio Scd S.A.",
    code: "402",
    ispb: null,
    website: "",
    slug: "cobuccio-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 220,
    name: "Cora Scd S.A.",
    description: "Cora Scd S.A.",
    code: "403",
    ispb: "37880206",
    website: "",
    slug: "cora-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 215,
    name: "Sumup Scd S.A.",
    description: "Sumup Scd S.A.",
    code: "404",
    ispb: null,
    website: "",
    slug: "sumup-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 219,
    name: "Accredito Scd S.A.",
    description: "Accredito Scd S.A.",
    code: "406",
    ispb: null,
    website: "",
    slug: "accredito-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 14,
    name: "Índigo Investimentos Dtvm Ltda.",
    description: "Índigo Investimentos Dtvm Ltda.",
    code: "407",
    ispb: null,
    website: "",
    slug: "indigo-investimentos-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 213,
    name: "Bonuspago Scd S.A.",
    description: "Bonuspago Scd S.A.",
    code: "408",
    ispb: null,
    website: "",
    slug: "bonuspago-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 74,
    name: "Planner Scm S.A.",
    description: "Planner Scm S.A.",
    code: "410",
    ispb: null,
    website: "",
    slug: "planner-scm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 68,
    name: "Via Certa Financiadora S.A. - Cfi",
    description: "Via Certa Financiadora S.A. - Cfi",
    code: "411",
    ispb: null,
    website: "",
    slug: "via-certa-financiadora-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 137,
    name: "Social Bank S/A",
    description: "Social Bank S/A",
    code: "412",
    ispb: null,
    website: "",
    slug: "social-bank-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 33,
    name: "Bco Bv S.A.",
    description: "Bco Bv S.A.",
    code: "413",
    ispb: null,
    website: "",
    slug: "bco-bv-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 217,
    name: "Work Scd S.A.",
    description: "Work Scd S.A.",
    code: "414",
    ispb: null,
    website: "",
    slug: "work-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 155,
    name: "Lamara Scd S.A.",
    description: "Lamara Scd S.A.",
    code: "416",
    ispb: null,
    website: "",
    slug: "lamara-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 216,
    name: "Zipdin Scd S.A.",
    description: "Zipdin Scd S.A.",
    code: "418",
    ispb: null,
    website: "",
    slug: "zipdin-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 221,
    name: "Numbrs Scd S.A.",
    description: "Numbrs Scd S.A.",
    code: "419",
    ispb: null,
    website: "",
    slug: "numbrs-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 223,
    name: "Cc Lar Credi",
    description: "Cc Lar Credi",
    code: "421",
    ispb: null,
    website: "",
    slug: "cc-lar-credi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 7,
    name: "Safra",
    description: "Safra",
    code: "422",
    ispb: "58160789",
    website: "https://www.safra.com.br",
    slug: "safra",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 16,
    name: "Coluna S.A. Dtvm",
    description: "Coluna S.A. Dtvm",
    code: "423",
    ispb: null,
    website: "",
    slug: "coluna-sa-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 53,
    name: "Socinal S.A. Cfi",
    description: "Socinal S.A. Cfi",
    code: "425",
    ispb: null,
    website: "",
    slug: "socinal-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 113,
    name: "Biorc Financeira - Cfi S.A.",
    description: "Biorc Financeira - Cfi S.A.",
    code: "426",
    ispb: null,
    website: "",
    slug: "biorc-financeira-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 169,
    name: "Cred-Ufes",
    description: "Cred-Ufes",
    code: "427",
    ispb: null,
    website: "",
    slug: "credufes",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 226,
    name: "Cred-System Scd S.A.",
    description: "Cred-System Scd S.A.",
    code: "428",
    ispb: null,
    website: "",
    slug: "credsystem-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 73,
    name: "Crediare Cfi S.A.",
    description: "Crediare Cfi S.A.",
    code: "429",
    ispb: null,
    website: "",
    slug: "crediare-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 11,
    name: "Ccr Seara",
    description: "Ccr Seara",
    code: "430",
    ispb: null,
    website: "",
    slug: "ccr-seara",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 237,
    name: "Br-Capital Dtvm S.A.",
    description: "Br-Capital Dtvm S.A.",
    code: "433",
    ispb: null,
    website: "",
    slug: "brcapital-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 222,
    name: "Delcred Scd S.A.",
    description: "Delcred Scd S.A.",
    code: "435",
    ispb: null,
    website: "",
    slug: "delcred-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 287,
    name: "Planner Trustee Dtvm Ltda",
    description: "Planner Trustee Dtvm Ltda",
    code: "438",
    ispb: null,
    website: "",
    slug: "planner-trustee-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 141,
    name: "Id Ctvm",
    description: "Id Ctvm",
    code: "439",
    ispb: null,
    website: "",
    slug: "id-ctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 303,
    name: "Credibrf Coop",
    description: "Credibrf Coop",
    code: "440",
    ispb: null,
    website: "",
    slug: "credibrf-coop",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 304,
    name: "Magnetis - Dtvm",
    description: "Magnetis - Dtvm",
    code: "442",
    ispb: null,
    website: "",
    slug: "magnetis-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 224,
    name: "Credihome Scd",
    description: "Credihome Scd",
    code: "443",
    ispb: null,
    website: "",
    slug: "credihome-scd",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 233,
    name: "Trinus Scd S.A.",
    description: "Trinus Scd S.A.",
    code: "444",
    ispb: null,
    website: "",
    slug: "trinus-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 210,
    name: "Plantae Cfi",
    description: "Plantae Cfi",
    code: "445",
    ispb: null,
    website: "",
    slug: "plantae-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 119,
    name: "Mirae Asset Cctvm Ltda",
    description: "Mirae Asset Cctvm Ltda",
    code: "447",
    ispb: null,
    website: "",
    slug: "mirae-asset-cctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 227,
    name: "Hemera Dtvm Ltda.",
    description: "Hemera Dtvm Ltda.",
    code: "448",
    ispb: null,
    website: "",
    slug: "hemera-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 218,
    name: "Dmcard Scd S.A.",
    description: "Dmcard Scd S.A.",
    code: "449",
    ispb: null,
    website: "",
    slug: "dmcard-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 124,
    name: "Fitbank Pagamentos Eletronicos S.A.",
    description: "Fitbank Pagamentos Eletronicos S.A.",
    code: "450",
    ispb: "13203354",
    website: null,
    slug: "fitbank",
    reference: "all",
    internal: false,
    default: true,
  },
  {
    id: 228,
    name: "Credifit Scd S.A.",
    description: "Credifit Scd S.A.",
    code: "452",
    ispb: null,
    website: "",
    slug: "credifit-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 234,
    name: "Mérito Dtvm Ltda.",
    description: "Mérito Dtvm Ltda.",
    code: "454",
    ispb: null,
    website: "",
    slug: "merito-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 258,
    name: "Bco Mufg Brasil S.A.",
    description: "Bco Mufg Brasil S.A.",
    code: "456",
    ispb: null,
    website: "",
    slug: "bco-mufg-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 225,
    name: "Uy3 Scd S/A",
    description: "Uy3 Scd S/A",
    code: "457",
    ispb: null,
    website: "",
    slug: "uy3-scd-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 80,
    name: "Hedge Investments Dtvm Ltda.",
    description: "Hedge Investments Dtvm Ltda.",
    code: "458",
    ispb: null,
    website: "",
    slug: "hedge-investments-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 60,
    name: "Ccm Serv. Públicos Sp",
    description: "Ccm Serv. Públicos Sp",
    code: "459",
    ispb: null,
    website: "",
    slug: "ccm-serv-publicos-sp",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 156,
    name: "Asaas Ip S.A.",
    description: "Asaas Ip S.A.",
    code: "461",
    ispb: null,
    website: "",
    slug: "asaas-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 229,
    name: "Stark Scd S.A.",
    description: "Stark Scd S.A.",
    code: "462",
    ispb: null,
    website: "",
    slug: "stark-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 232,
    name: "Azumi Dtvm",
    description: "Azumi Dtvm",
    code: "463",
    ispb: null,
    website: "",
    slug: "azumi-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 259,
    name: "Bco Sumitomo Mitsui Brasil S.A.",
    description: "Bco Sumitomo Mitsui Brasil S.A.",
    code: "464",
    ispb: null,
    website: "",
    slug: "bco-sumitomo-mitsui-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 230,
    name: "Capital Consig Scd S.A.",
    description: "Capital Consig Scd S.A.",
    code: "465",
    ispb: null,
    website: "",
    slug: "capital-consig-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 204,
    name: "Master S/A Cctvm",
    description: "Master S/A Cctvm",
    code: "467",
    ispb: null,
    website: "",
    slug: "master-s/a-cctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 196,
    name: "Bco Caixa Geral Brasil S.A.",
    description: "Bco Caixa Geral Brasil S.A.",
    code: "473",
    ispb: null,
    website: "",
    slug: "bco-caixa-geral-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 191,
    name: "Citibank N.A.",
    description: "Citibank N.A.",
    code: "477",
    ispb: null,
    website: "",
    slug: "citibank-na",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 257,
    name: "Bco Itaubank S.A.",
    description: "Bco Itaubank S.A.",
    code: "479",
    ispb: null,
    website: "",
    slug: "bco-itaubank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 284,
    name: "Deutsche Bank S.A.Bco Alemao",
    description: "Deutsche Bank S.A.Bco Alemao",
    code: "487",
    ispb: null,
    website: "",
    slug: "deutsche-bank-sabco-alemao",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 240,
    name: "Jpmorgan Chase Bank",
    description: "Jpmorgan Chase Bank",
    code: "488",
    ispb: null,
    website: "",
    slug: "jpmorgan-chase-bank",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 242,
    name: "Ing Bank N.V.",
    description: "Ing Bank N.V.",
    code: "492",
    ispb: null,
    website: "",
    slug: "ing-bank-nv",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 238,
    name: "Bco La Provincia B Aires Bce",
    description: "Bco La Provincia B Aires Bce",
    code: "495",
    ispb: null,
    website: "",
    slug: "bco-la-provincia-b-aires-bce",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 186,
    name: "Bco Credit Suisse S.A.",
    description: "Bco Credit Suisse S.A.",
    code: "505",
    ispb: null,
    website: "",
    slug: "bco-credit-suisse-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 317,
    name: "Neon Pagamentos",
    description: "Neon Pagamentos",
    code: "536",
    ispb: "20855875",
    website: null,
    slug: "neon-pagamentos",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 147,
    name: "Senso Ccvm S.A.",
    description: "Senso Ccvm S.A.",
    code: "545",
    ispb: null,
    website: "",
    slug: "senso-ccvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 253,
    name: "Bco Luso Brasileiro S.A.",
    description: "Bco Luso Brasileiro S.A.",
    code: "600",
    ispb: null,
    website: "",
    slug: "bco-luso-brasileiro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 185,
    name: "Bco Industrial Do Brasil S.A.",
    description: "Bco Industrial Do Brasil S.A.",
    code: "604",
    ispb: null,
    website: "",
    slug: "bco-industrial-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 299,
    name: "Bco Vr S.A.",
    description: "Bco Vr S.A.",
    code: "610",
    ispb: null,
    website: "",
    slug: "bco-vr-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 275,
    name: "Bco Paulista S.A.",
    description: "Bco Paulista S.A.",
    code: "611",
    ispb: null,
    website: "",
    slug: "bco-paulista-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 184,
    name: "Bco Guanabara S.A.",
    description: "Bco Guanabara S.A.",
    code: "612",
    ispb: null,
    website: "",
    slug: "bco-guanabara-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 261,
    name: "Omni Banco S.A.",
    description: "Omni Banco S.A.",
    code: "613",
    ispb: null,
    website: "",
    slug: "omni-banco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 255,
    name: "Banco Pan",
    description: "Banco Pan",
    code: "623",
    ispb: null,
    website: "",
    slug: "banco-pan",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 269,
    name: "Bco C6 Consig",
    description: "Bco C6 Consig",
    code: "626",
    ispb: null,
    website: "",
    slug: "bco-c6-consig",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 250,
    name: "Bco Letsbank S.A.",
    description: "Bco Letsbank S.A.",
    code: "630",
    ispb: null,
    website: "",
    slug: "bco-letsbank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 289,
    name: "Bco Rendimento S.A.",
    description: "Bco Rendimento S.A.",
    code: "633",
    ispb: null,
    website: "",
    slug: "bco-rendimento-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 146,
    name: "Bco Triangulo S.A.",
    description: "Bco Triangulo S.A.",
    code: "634",
    ispb: null,
    website: "",
    slug: "bco-triangulo-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 263,
    name: "Bco Sofisa S.A.",
    description: "Bco Sofisa S.A.",
    code: "637",
    ispb: null,
    website: "",
    slug: "bco-sofisa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 278,
    name: "Bco Pine S.A.",
    description: "Bco Pine S.A.",
    code: "643",
    ispb: null,
    website: "",
    slug: "bco-pine-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 262,
    name: "Itaú Unibanco Holding S.A.",
    description: "Itaú Unibanco Holding S.A.",
    code: "652",
    ispb: null,
    website: "",
    slug: "itau-unibanco-holding-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 264,
    name: "Banco Voiter",
    description: "Banco Voiter",
    code: "653",
    ispb: null,
    website: "",
    slug: "banco-voiter",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 309,
    name: "Bco Digimais S.A.",
    description: "Bco Digimais S.A.",
    code: "654",
    ispb: null,
    website: "",
    slug: "bco-digimais-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 256,
    name: "Bco Votorantim S.A.",
    description: "Bco Votorantim S.A.",
    code: "655",
    ispb: null,
    website: "",
    slug: "bco-votorantim-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 280,
    name: "Bco Daycoval S.A",
    description: "Bco Daycoval S.A",
    code: "707",
    ispb: null,
    website: "",
    slug: "bco-daycoval-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 300,
    name: "Bco Ourinvest S.A.",
    description: "Bco Ourinvest S.A.",
    code: "712",
    ispb: null,
    website: "",
    slug: "bco-ourinvest-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 301,
    name: "Bco Rnx S.A.",
    description: "Bco Rnx S.A.",
    code: "720",
    ispb: null,
    website: "",
    slug: "bco-rnx-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 19,
    name: "Bco Cetelem S.A.",
    description: "Bco Cetelem S.A.",
    code: "739",
    ispb: null,
    website: "",
    slug: "bco-cetelem-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 17,
    name: "Bco Ribeirao Preto S.A.",
    description: "Bco Ribeirao Preto S.A.",
    code: "741",
    ispb: null,
    website: "",
    slug: "bco-ribeirao-preto-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 20,
    name: "Banco Semear",
    description: "Banco Semear",
    code: "743",
    ispb: null,
    website: "",
    slug: "banco-semear",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 197,
    name: "Bco Citibank S.A.",
    description: "Bco Citibank S.A.",
    code: "745",
    ispb: null,
    website: "",
    slug: "bco-citibank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 180,
    name: "Bco Modal S.A.",
    description: "Bco Modal S.A.",
    code: "746",
    ispb: null,
    website: "",
    slug: "bco-modal-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 23,
    name: "Bco Rabobank Intl Brasil S.A.",
    description: "Bco Rabobank Intl Brasil S.A.",
    code: "747",
    ispb: null,
    website: "",
    slug: "bco-rabobank-intl-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 26,
    name: "Bco Cooperativo Sicredi S.A.",
    description: "Bco Cooperativo Sicredi S.A.",
    code: "748",
    ispb: null,
    website: "",
    slug: "bco-cooperativo-sicredi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 176,
    name: "Scotiabank Brasil",
    description: "Scotiabank Brasil",
    code: "751",
    ispb: null,
    website: "",
    slug: "scotiabank-brasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 28,
    name: "Bco Bnp Paribas Brasil S A",
    description: "Bco Bnp Paribas Brasil S A",
    code: "752",
    ispb: null,
    website: "",
    slug: "bco-bnp-paribas-brasil-s-a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 293,
    name: "Novo Bco Continental S.A. - Bm",
    description: "Novo Bco Continental S.A. - Bm",
    code: "753",
    ispb: null,
    website: "",
    slug: "novo-bco-continental-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 296,
    name: "Banco Sistema",
    description: "Banco Sistema",
    code: "754",
    ispb: null,
    website: "",
    slug: "banco-sistema",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 276,
    name: "Bofa Merrill Lynch Bm S.A.",
    description: "Bofa Merrill Lynch Bm S.A.",
    code: "755",
    ispb: null,
    website: "",
    slug: "bofa-merrill-lynch-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 34,
    name: "Banco Sicoob S.A.",
    description: "Banco Sicoob S.A.",
    code: "756",
    ispb: null,
    website: "",
    slug: "banco-sicoob-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 36,
    name: "Bco Keb Hana Do Brasil S.A.",
    description: "Bco Keb Hana Do Brasil S.A.",
    code: "757",
    ispb: null,
    website: "",
    slug: "bco-keb-hana-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 8,
    name: "Prosperità",
    description: "Prosperità",
    code: "999",
    ispb: "11581339",
    website: "localhost",
    slug: "whitelabel",
    reference: "all",
    internal: true,
    default: false,
  },
  {
    id: 315,
    name: "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS",
    description: "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS",
    code: "ID148",
    ispb: "02405189",
    website: null,
    slug: "cooperativa-de-credito-da-regiao-central-de-minas",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 319,
    name: "CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL",
    description: "CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL",
    code: "ID419",
    ispb: "",
    website: null,
    slug: "cc-unicred-valor-capital-ltda-unicred-valor-capital",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 316,
    name: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
    description:
      "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
    code: "ID449",
    ispb: "",
    website: null,
    slug: "cooperativa-de-credito-poupanca-e-investimento-do-planalto-central-sicredi-planalto-central",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 314,
    name: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO SI",
    description: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO SI",
    code: "ID481",
    ispb: "",
    website: null,
    slug: "cooperativa-de-credito-poupanca-e-investimento-si",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 313,
    name: "STARK BANK S.A.",
    description: "STARK BANK S.A.",
    code: "ID644",
    ispb: "20018183",
    website: null,
    slug: "stark-bank",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 318,
    name: "NEON PAGAMENTOS S.A.",
    description: "NEON PAGAMENTOS S.A.",
    code: "IDR03",
    ispb: "",
    website: null,
    slug: "neon-pagamentos-sa",
    reference: "all",
    internal: false,
    default: false,
  },

  {
    id: 1,
    name: "Banco do Brasil",
    description: "Banco do Brasil S.A.",
    code: "001",
    ispb: "0",
    website: "http://www.bb.com.br",
    slug: "bancodobrasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 65,
    name: "Bco Da Amazonia S.A.",
    description: "Bco Da Amazonia S.A.",
    code: "003",
    ispb: null,
    website: "",
    slug: "bco-da-amazonia-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 79,
    name: "Bco Do Nordeste Do Brasil S.A.",
    description: "Bco Do Nordeste Do Brasil S.A.",
    code: "004",
    ispb: null,
    website: "",
    slug: "bco-do-nordeste-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 200,
    name: "Bndes",
    description: "Bndes",
    code: "007",
    ispb: null,
    website: "",
    slug: "bndes",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 302,
    name: "Credicoamo",
    description: "Credicoamo",
    code: "010",
    ispb: null,
    website: "",
    slug: "credicoamo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 274,
    name: "C.Suisse Hedging-Griffo Cv S/A",
    description: "C.Suisse Hedging-Griffo Cv S/A",
    code: "011",
    ispb: null,
    website: "",
    slug: "csuisse-hedginggriffo-cv-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 64,
    name: "Banco Inbursa",
    description: "Banco Inbursa",
    code: "012",
    ispb: null,
    website: "",
    slug: "banco-inbursa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 99,
    name: "State Street Br S.A. Bco Comercial",
    description: "State Street Br S.A. Bco Comercial",
    code: "014",
    ispb: null,
    website: "",
    slug: "state-street-br-sa-bco-comercial",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 41,
    name: "Ubs Brasil Cctvm S.A.",
    description: "Ubs Brasil Cctvm S.A.",
    code: "015",
    ispb: null,
    website: "",
    slug: "ubs-brasil-cctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 62,
    name: "Ccm Desp Trâns Sc E Rs",
    description: "Ccm Desp Trâns Sc E Rs",
    code: "016",
    ispb: null,
    website: "",
    slug: "ccm-desp-trans-sc-e-rs",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 235,
    name: "Bny Mellon Bco S.A.",
    description: "Bny Mellon Bco S.A.",
    code: "017",
    ispb: null,
    website: "",
    slug: "bny-mellon-bco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 249,
    name: "Bco Tricury S.A.",
    description: "Bco Tricury S.A.",
    code: "018",
    ispb: null,
    website: "",
    slug: "bco-tricury-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 173,
    name: "Bco Banestes S.A.",
    description: "Bco Banestes S.A.",
    code: "021",
    ispb: null,
    website: "",
    slug: "bco-banestes-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 111,
    name: "Bco Bandepe S.A.",
    description: "Bco Bandepe S.A.",
    code: "024",
    ispb: null,
    website: "",
    slug: "bco-bandepe-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 48,
    name: "Bco Alfa S.A.",
    description: "Bco Alfa S.A.",
    code: "025",
    ispb: null,
    website: "",
    slug: "bco-alfa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 203,
    name: "Banco Itaú Consignado S.A.",
    description: "Banco Itaú Consignado S.A.",
    code: "029",
    ispb: null,
    website: "",
    slug: "banco-itau-consignado-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 5,
    name: "Santander",
    description: "Banco Santander (Brasil) S.A.",
    code: "033",
    ispb: "90400888",
    website: "http://www.santander.com.br",
    slug: "santander",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 77,
    name: "Bco Bbi S.A.",
    description: "Bco Bbi S.A.",
    code: "036",
    ispb: null,
    website: "",
    slug: "bco-bbi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 67,
    name: "Bco Do Est. Do Pa S.A.",
    description: "Bco Do Est. Do Pa S.A.",
    code: "037",
    ispb: null,
    website: "",
    slug: "bco-do-est-do-pa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 51,
    name: "Bco Cargill S.A.",
    description: "Bco Cargill S.A.",
    code: "040",
    ispb: null,
    website: "",
    slug: "bco-cargill-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 307,
    name: "Bco Do Estado Do Rs S.A.",
    description: "Bco Do Estado Do Rs S.A.",
    code: "041",
    ispb: null,
    website: "",
    slug: "bco-do-estado-do-rs-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 121,
    name: "Bco Do Est. De Se S.A.",
    description: "Bco Do Est. De Se S.A.",
    code: "047",
    ispb: null,
    website: "",
    slug: "bco-do-est-de-se-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 66,
    name: "Confidence Cc S.A.",
    description: "Confidence Cc S.A.",
    code: "060",
    ispb: null,
    website: "",
    slug: "confidence-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 43,
    name: "Hipercard Bm S.A.",
    description: "Hipercard Bm S.A.",
    code: "062",
    ispb: null,
    website: "",
    slug: "hipercard-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 56,
    name: "Banco Bradescard",
    description: "Banco Bradescard",
    code: "063",
    ispb: null,
    website: "",
    slug: "banco-bradescard",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 59,
    name: "Goldman Sachs Do Brasil Bm S.A",
    description: "Goldman Sachs Do Brasil Bm S.A",
    code: "064",
    ispb: null,
    website: "",
    slug: "goldman-sachs-do-brasil-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 241,
    name: "Bco Andbank S.A.",
    description: "Bco Andbank S.A.",
    code: "065",
    ispb: null,
    website: "",
    slug: "bco-andbank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 40,
    name: "Bco Morgan Stanley S.A.",
    description: "Bco Morgan Stanley S.A.",
    code: "066",
    ispb: null,
    website: "",
    slug: "bco-morgan-stanley-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 265,
    name: "Bco Crefisa S.A.",
    description: "Bco Crefisa S.A.",
    code: "069",
    ispb: null,
    website: "",
    slug: "bco-crefisa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 10,
    name: "Brb - Bco De Brasilia S.A.",
    description: "Brb - Bco De Brasilia S.A.",
    code: "070",
    ispb: null,
    website: "",
    slug: "brb-bco-de-brasilia-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 44,
    name: "Bco. J.Safra S.A.",
    description: "Bco. J.Safra S.A.",
    code: "074",
    ispb: null,
    website: "",
    slug: "bco-jsafra-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 50,
    name: "Bco Abn Amro S.A.",
    description: "Bco Abn Amro S.A.",
    code: "075",
    ispb: null,
    website: "",
    slug: "bco-abn-amro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 84,
    name: "Bco Kdb Brasil S.A.",
    description: "Bco Kdb Brasil S.A.",
    code: "076",
    ispb: null,
    website: "",
    slug: "bco-kdb-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 15,
    name: "Banco Inter",
    description: "Banco Inter",
    code: "077",
    ispb: "00416968",
    website: "",
    slug: "banco-inter",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 207,
    name: "Haitong Bi Do Brasil S.A.",
    description: "Haitong Bi Do Brasil S.A.",
    code: "078",
    ispb: null,
    website: "",
    slug: "haitong-bi-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 103,
    name: "Bco Original Do Agro S/A",
    description: "Bco Original Do Agro S/A",
    code: "079",
    ispb: null,
    website: "",
    slug: "bco-original-do-agro-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 292,
    name: "B&T Cc Ltda.",
    description: "B&T Cc Ltda.",
    code: "080",
    ispb: null,
    website: "",
    slug: "bandt-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 105,
    name: "Bancoseguro S.A.",
    description: "Bancoseguro S.A.",
    code: "081",
    ispb: null,
    website: "",
    slug: "bancoseguro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 85,
    name: "Banco Topázio S.A.",
    description: "Banco Topázio S.A.",
    code: "082",
    ispb: null,
    website: "",
    slug: "banco-topazio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 109,
    name: "Bco Da China Brasil S.A.",
    description: "Bco Da China Brasil S.A.",
    code: "083",
    ispb: null,
    website: "",
    slug: "bco-da-china-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 38,
    name: "Uniprime Do Brasil - Cc Ltda.",
    description: "Uniprime Do Brasil - Cc Ltda.",
    code: "084",
    ispb: null,
    website: "",
    slug: "uniprime-do-brasil-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 71,
    name: "Coopcentral Ailos",
    description: "Coopcentral Ailos",
    code: "085",
    ispb: null,
    website: "",
    slug: "coopcentral-ailos",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 114,
    name: "Banco Randon S.A.",
    description: "Banco Randon S.A.",
    code: "088",
    ispb: null,
    website: "",
    slug: "banco-randon-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 277,
    name: "Credisan Cc",
    description: "Credisan Cc",
    code: "089",
    ispb: null,
    website: "",
    slug: "credisan-cc",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 120,
    name: "Brk S.A. Cfi",
    description: "Brk S.A. Cfi",
    code: "092",
    ispb: null,
    website: "",
    slug: "brk-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 89,
    name: "Polocred Scmepp Ltda.",
    description: "Polocred Scmepp Ltda.",
    code: "093",
    ispb: null,
    website: "",
    slug: "polocred-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 117,
    name: "Banco Finaxis",
    description: "Banco Finaxis",
    code: "094",
    ispb: null,
    website: "",
    slug: "banco-finaxis",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 116,
    name: "Travelex Banco De Câmbio S.A.",
    description: "Travelex Banco De Câmbio S.A.",
    code: "095",
    ispb: null,
    website: "",
    slug: "travelex-banco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 22,
    name: "Bco B3 S.A.",
    description: "Bco B3 S.A.",
    code: "096",
    ispb: null,
    website: "",
    slug: "bco-b3-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 61,
    name: "Credisis Central De Cooperativas De Crédito Ltda.",
    description: "Credisis Central De Cooperativas De Crédito Ltda.",
    code: "097",
    ispb: null,
    website: "",
    slug: "credisis-central-de-cooperativas-de-credito-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 298,
    name: "Credialiança Ccr",
    description: "Credialiança Ccr",
    code: "098",
    ispb: null,
    website: "",
    slug: "credialianca-ccr",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 45,
    name: "Uniprime Central Ccc Ltda.",
    description: "Uniprime Central Ccc Ltda.",
    code: "099",
    ispb: null,
    website: "",
    slug: "uniprime-central-ccc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 21,
    name: "Planner Cv S.A.",
    description: "Planner Cv S.A.",
    code: "100",
    ispb: null,
    website: "",
    slug: "planner-cv-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 283,
    name: "Renascenca Dtvm Ltda",
    description: "Renascenca Dtvm Ltda",
    code: "101",
    ispb: null,
    website: "",
    slug: "renascenca-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 37,
    name: "Xp Investimentos Cctvm S/A",
    description: "Xp Investimentos Cctvm S/A",
    code: "102",
    ispb: null,
    website: "",
    slug: "xp-investimentos-cctvm-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 3,
    name: "Caixa Econômica Federal",
    description: "Caixa Econômica Federal",
    code: "104",
    ispb: "360305",
    website: "http://www.caixa.gov.br",
    slug: "caixa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 83,
    name: "Lecca Cfi S.A.",
    description: "Lecca Cfi S.A.",
    code: "105",
    ispb: null,
    website: "",
    slug: "lecca-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 136,
    name: "Bco Bocom Bbm S.A.",
    description: "Bco Bocom Bbm S.A.",
    code: "107",
    ispb: null,
    website: "",
    slug: "bco-bocom-bbm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 31,
    name: "Portocred S.A. - Cfi",
    description: "Portocred S.A. - Cfi",
    code: "108",
    ispb: null,
    website: "",
    slug: "portocred-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 212,
    name: "Oliveira Trust Dtvm S.A.",
    description: "Oliveira Trust Dtvm S.A.",
    code: "111",
    ispb: null,
    website: "",
    slug: "oliveira-trust-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 272,
    name: "Magliano S A  Ctvm",
    description: "Magliano S A  Ctvm",
    code: "113",
    ispb: null,
    website: "",
    slug: "magliano-s-a-ctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 75,
    name: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    description: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    code: "114",
    ispb: null,
    website: "",
    slug: "central-cooperativa-de-credito-no-estado-do-espirito-santo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 308,
    name: "Advanced Cc Ltda",
    description: "Advanced Cc Ltda",
    code: "117",
    ispb: null,
    website: "",
    slug: "advanced-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 130,
    name: "Bco Western Union",
    description: "Bco Western Union",
    code: "119",
    ispb: null,
    website: "",
    slug: "bco-western-union",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 198,
    name: "Bco Rodobens S.A.",
    description: "Bco Rodobens S.A.",
    code: "120",
    ispb: null,
    website: "",
    slug: "bco-rodobens-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 108,
    name: "Bco Agibank S.A.",
    description: "Bco Agibank S.A.",
    code: "121",
    ispb: null,
    website: "",
    slug: "bco-agibank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 193,
    name: "Bco Bradesco Berj S.A.",
    description: "Bco Bradesco Berj S.A.",
    code: "122",
    ispb: null,
    website: "",
    slug: "bco-bradesco-berj-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 138,
    name: "Bco Woori Bank Do Brasil S.A.",
    description: "Bco Woori Bank Do Brasil S.A.",
    code: "124",
    ispb: null,
    website: "",
    slug: "bco-woori-bank-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 239,
    name: "Banco Genial",
    description: "Banco Genial",
    code: "125",
    ispb: null,
    website: "",
    slug: "banco-genial",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 125,
    name: "Br Partners Bi",
    description: "Br Partners Bi",
    code: "126",
    ispb: null,
    website: "",
    slug: "br-partners-bi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 102,
    name: "Codepe Cvc S.A.",
    description: "Codepe Cvc S.A.",
    code: "127",
    ispb: null,
    website: "",
    slug: "codepe-cvc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 154,
    name: "Ms Bank S.A. Bco De Câmbio",
    description: "Ms Bank S.A. Bco De Câmbio",
    code: "128",
    ispb: null,
    website: "",
    slug: "ms-bank-sa-bco-de-cambio",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 153,
    name: "Ubs Brasil Bi S.A.",
    description: "Ubs Brasil Bi S.A.",
    code: "129",
    ispb: null,
    website: "",
    slug: "ubs-brasil-bi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 100,
    name: "Caruana Scfi",
    description: "Caruana Scfi",
    code: "130",
    ispb: null,
    website: "",
    slug: "caruana-scfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 273,
    name: "Tullett Prebon Brasil Cvc Ltda",
    description: "Tullett Prebon Brasil Cvc Ltda",
    code: "131",
    ispb: null,
    website: "",
    slug: "tullett-prebon-brasil-cvc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 148,
    name: "Icbc Do Brasil Bm S.A.",
    description: "Icbc Do Brasil Bm S.A.",
    code: "132",
    ispb: null,
    website: "",
    slug: "icbc-do-brasil-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 106,
    name: "Cresol Confederação",
    description: "Cresol Confederação",
    code: "133",
    ispb: null,
    website: "",
    slug: "cresol-confederacao",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 202,
    name: "Bgc Liquidez Dtvm Ltda",
    description: "Bgc Liquidez Dtvm Ltda",
    code: "134",
    ispb: null,
    website: "",
    slug: "bgc-liquidez-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 13,
    name: "Conf Nac Coop Centrais Unicred",
    description: "Conf Nac Coop Centrais Unicred",
    code: "136",
    ispb: null,
    website: "",
    slug: "conf-nac-coop-centrais-unicred",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 110,
    name: "Get Money Cc Ltda",
    description: "Get Money Cc Ltda",
    code: "138",
    ispb: null,
    website: "",
    slug: "get-money-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 248,
    name: "Intesa Sanpaolo Brasil S.A. Bm",
    description: "Intesa Sanpaolo Brasil S.A. Bm",
    code: "139",
    ispb: null,
    website: "",
    slug: "intesa-sanpaolo-brasil-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 279,
    name: "Nu Invest Corretora De Valores S.A.",
    description: "Nu Invest Corretora De Valores S.A.",
    code: "140",
    ispb: null,
    website: "",
    slug: "nu-invest-corretora-de-valores-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 143,
    name: "Broker Brasil Cc Ltda.",
    description: "Broker Brasil Cc Ltda.",
    code: "142",
    ispb: null,
    website: "",
    slug: "broker-brasil-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 42,
    name: "Treviso Cc S.A.",
    description: "Treviso Cc S.A.",
    code: "143",
    ispb: null,
    website: "",
    slug: "treviso-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 122,
    name: "Bexs Bco De Cambio S.A.",
    description: "Bexs Bco De Cambio S.A.",
    code: "144",
    ispb: null,
    website: "",
    slug: "bexs-bco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 243,
    name: "Levycam Ccv Ltda",
    description: "Levycam Ccv Ltda",
    code: "145",
    ispb: null,
    website: "",
    slug: "levycam-ccv-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 164,
    name: "Guitta Cc Ltda",
    description: "Guitta Cc Ltda",
    code: "146",
    ispb: null,
    website: "",
    slug: "guitta-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 139,
    name: "Facta S.A. Cfi",
    description: "Facta S.A. Cfi",
    code: "149",
    ispb: null,
    website: "",
    slug: "facta-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 97,
    name: "Icap Do Brasil Ctvm Ltda.",
    description: "Icap Do Brasil Ctvm Ltda.",
    code: "157",
    ispb: null,
    website: "",
    slug: "icap-do-brasil-ctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 70,
    name: "Casa Credito S.A. Scm",
    description: "Casa Credito S.A. Scm",
    code: "159",
    ispb: null,
    website: "",
    slug: "casa-credito-sa-scm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 162,
    name: "Commerzbank Brasil S.A. - Bco Múltiplo",
    description: "Commerzbank Brasil S.A. - Bco Múltiplo",
    code: "163",
    ispb: null,
    website: "",
    slug: "commerzbank-brasil-sa-bco-multiplo",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 128,
    name: "Brl Trust Dtvm Sa",
    description: "Brl Trust Dtvm Sa",
    code: "173",
    ispb: null,
    website: "",
    slug: "brl-trust-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 236,
    name: "Pefisa S.A. - C.F.I.",
    description: "Pefisa S.A. - C.F.I.",
    code: "174",
    ispb: null,
    website: "",
    slug: "pefisa-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 286,
    name: "Guide",
    description: "Guide",
    code: "177",
    ispb: null,
    website: "",
    slug: "guide",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 39,
    name: "Cm Capital Markets Cctvm Ltda",
    description: "Cm Capital Markets Cctvm Ltda",
    code: "180",
    ispb: null,
    website: "",
    slug: "cm-capital-markets-cctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 98,
    name: "Socred Sa - Scmepp",
    description: "Socred Sa - Scmepp",
    code: "183",
    ispb: null,
    website: "",
    slug: "socred-sa-scmepp",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 145,
    name: "Bco Itaú Bba S.A.",
    description: "Bco Itaú Bba S.A.",
    code: "184",
    ispb: null,
    website: "",
    slug: "bco-itau-bba-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 201,
    name: "Ativa S.A. Investimentos Cctvm",
    description: "Ativa S.A. Investimentos Cctvm",
    code: "188",
    ispb: null,
    website: "",
    slug: "ativa-sa-investimentos-cctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 82,
    name: "Hs Financeira",
    description: "Hs Financeira",
    code: "189",
    ispb: null,
    website: "",
    slug: "hs-financeira",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 54,
    name: "Servicoop",
    description: "Servicoop",
    code: "190",
    ispb: null,
    website: "",
    slug: "servicoop",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 57,
    name: "Nova Futura Ctvm Ltda.",
    description: "Nova Futura Ctvm Ltda.",
    code: "191",
    ispb: null,
    website: "",
    slug: "nova-futura-ctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 157,
    name: "Parmetal Dtvm Ltda",
    description: "Parmetal Dtvm Ltda",
    code: "194",
    ispb: null,
    website: "",
    slug: "parmetal-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 87,
    name: "Valor Scd S.A.",
    description: "Valor Scd S.A.",
    code: "195",
    ispb: null,
    website: "",
    slug: "valor-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 188,
    name: "Fair Cc S.A.",
    description: "Fair Cc S.A.",
    code: "196",
    ispb: null,
    website: "",
    slug: "fair-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 140,
    name: "Stone Pagamentos S.A.",
    description: "Stone Pagamentos S.A.",
    code: "197",
    ispb: null,
    website: "",
    slug: "stone-pagamentos-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 178,
    name: "Banco Btg Pactual S.A.",
    description: "Banco Btg Pactual S.A.",
    code: "208",
    ispb: null,
    website: "",
    slug: "banco-btg-pactual-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 311,
    name: "Banco Original",
    description: "Banco Original",
    code: "212",
    ispb: null,
    website: "",
    slug: "banco-original",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 247,
    name: "Bco Arbi S.A.",
    description: "Bco Arbi S.A.",
    code: "213",
    ispb: null,
    website: "",
    slug: "bco-arbi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 306,
    name: "Banco John Deere S.A.",
    description: "Banco John Deere S.A.",
    code: "217",
    ispb: null,
    website: "",
    slug: "banco-john-deere-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 6,
    name: "BS2",
    description: "BS2",
    code: "218",
    ispb: "71027866",
    website: "https://www.bs2.com/banco/",
    slug: "bs2",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 294,
    name: "Bco Crédit Agricole Br S.A.",
    description: "Bco Crédit Agricole Br S.A.",
    code: "222",
    ispb: null,
    website: "",
    slug: "bco-credit-agricole-br-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 251,
    name: "Bco Fibra S.A.",
    description: "Bco Fibra S.A.",
    code: "224",
    ispb: null,
    website: "",
    slug: "bco-fibra-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 285,
    name: "Banco Cifra",
    description: "Banco Cifra",
    code: "233",
    ispb: null,
    website: "",
    slug: "banco-cifra",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 2,
    name: "Bradesco",
    description: "Banco Bradesco S.A.",
    code: "237",
    ispb: "60746948",
    website: "http://www.bradesco.com.br",
    slug: "bradesco",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 181,
    name: "Bco Classico S.A.",
    description: "Bco Classico S.A.",
    code: "241",
    ispb: null,
    website: "",
    slug: "bco-classico-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 205,
    name: "Banco Master",
    description: "Banco Master",
    code: "243",
    ispb: null,
    website: "",
    slug: "banco-master",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 174,
    name: "Bco Abc Brasil S.A.",
    description: "Bco Abc Brasil S.A.",
    code: "246",
    ispb: null,
    website: "",
    slug: "bco-abc-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 267,
    name: "Banco Investcred Unibanco S.A.",
    description: "Banco Investcred Unibanco S.A.",
    code: "249",
    ispb: null,
    website: "",
    slug: "banco-investcred-unibanco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 245,
    name: "Bexs Cc S.A.",
    description: "Bexs Cc S.A.",
    code: "253",
    ispb: null,
    website: "",
    slug: "bexs-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 133,
    name: "Parana Bco S.A.",
    description: "Parana Bco S.A.",
    code: "254",
    ispb: null,
    website: "",
    slug: "parana-bco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 94,
    name: "Moneycorp Bco De Câmbio S.A.",
    description: "Moneycorp Bco De Câmbio S.A.",
    code: "259",
    ispb: null,
    website: "",
    slug: "moneycorp-bco-de-cambio-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 152,
    name: "Nu Pagamentos - Ip",
    description: "Nu Pagamentos - Ip",
    code: "260",
    ispb: "18236120",
    website: "",
    slug: "nu-pagamentos-ip",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 199,
    name: "Bco Fator S.A.",
    description: "Bco Fator S.A.",
    code: "265",
    ispb: null,
    website: "",
    slug: "bco-fator-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 192,
    name: "Bco Cedula S.A.",
    description: "Bco Cedula S.A.",
    code: "266",
    ispb: null,
    website: "",
    slug: "bco-cedula-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 134,
    name: "Bari Cia Hipotecária",
    description: "Bari Cia Hipotecária",
    code: "268",
    ispb: null,
    website: "",
    slug: "bari-cia-hipotecaria",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 246,
    name: "Bco Hsbc S.A.",
    description: "Bco Hsbc S.A.",
    code: "269",
    ispb: null,
    website: "",
    slug: "bco-hsbc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 270,
    name: "Sagitur Cc Ltda",
    description: "Sagitur Cc Ltda",
    code: "270",
    ispb: null,
    website: "",
    slug: "sagitur-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 172,
    name: "Ib Cctvm S.A.",
    description: "Ib Cctvm S.A.",
    code: "271",
    ispb: null,
    website: "",
    slug: "ib-cctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 12,
    name: "Agk Cc S.A.",
    description: "Agk Cc S.A.",
    code: "272",
    ispb: null,
    website: "",
    slug: "agk-cc-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 91,
    name: "Ccr De São Miguel Do Oeste",
    description: "Ccr De São Miguel Do Oeste",
    code: "273",
    ispb: null,
    website: "",
    slug: "ccr-de-sao-miguel-do-oeste",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 9,
    name: "MONEY PLUS SCMEPP LTDA",
    description: "MONEY PLUS SCMEPP LTDA",
    code: "274",
    ispb: "11581339",
    website: "https://moneyp.com.br/",
    slug: "bmp",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 118,
    name: "Bco Senff S.A.",
    description: "Bco Senff S.A.",
    code: "276",
    ispb: null,
    website: "",
    slug: "bco-senff-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 171,
    name: "Genial Investimentos Cvm S.A.",
    description: "Genial Investimentos Cvm S.A.",
    code: "278",
    ispb: null,
    website: "",
    slug: "genial-investimentos-cvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 166,
    name: "Ccr De Primavera Do Leste",
    description: "Ccr De Primavera Do Leste",
    code: "279",
    ispb: null,
    website: "",
    slug: "ccr-de-primavera-do-leste",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 163,
    name: "Will Financeira S.A.Cfi",
    description: "Will Financeira S.A.Cfi",
    code: "280",
    ispb: null,
    website: "",
    slug: "will-financeira-sacfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 295,
    name: "Ccr Coopavel",
    description: "Ccr Coopavel",
    code: "281",
    ispb: null,
    website: "",
    slug: "ccr-coopavel",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 305,
    name: "Rb Investimentos Dtvm Ltda.",
    description: "Rb Investimentos Dtvm Ltda.",
    code: "283",
    ispb: null,
    website: "",
    slug: "rb-investimentos-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 291,
    name: "Frente Cc Ltda.",
    description: "Frente Cc Ltda.",
    code: "285",
    ispb: null,
    website: "",
    slug: "frente-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 88,
    name: "Ccr De Ouro",
    description: "Ccr De Ouro",
    code: "286",
    ispb: null,
    website: "",
    slug: "ccr-de-ouro",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 281,
    name: "Carol Dtvm Ltda.",
    description: "Carol Dtvm Ltda.",
    code: "288",
    ispb: null,
    website: "",
    slug: "carol-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 312,
    name: "Decyseo Cc Ltda.",
    description: "Decyseo Cc Ltda.",
    code: "289",
    ispb: null,
    website: "",
    slug: "decyseo-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 93,
    name: "Pagseguro S.A.",
    description: "Pagseguro S.A.",
    code: "290",
    ispb: null,
    website: "",
    slug: "pagseguro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 175,
    name: "Bs2 Dtvm S.A.",
    description: "Bs2 Dtvm S.A.",
    code: "292",
    ispb: null,
    website: "",
    slug: "bs2-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 290,
    name: "Lastro Rdv Dtvm Ltda",
    description: "Lastro Rdv Dtvm Ltda",
    code: "293",
    ispb: null,
    website: "",
    slug: "lastro-rdv-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 55,
    name: "Vision S.A. Cc",
    description: "Vision S.A. Cc",
    code: "296",
    ispb: null,
    website: "",
    slug: "vision-sa-cc",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 149,
    name: "Vips Cc Ltda.",
    description: "Vips Cc Ltda.",
    code: "298",
    ispb: null,
    website: "",
    slug: "vips-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 63,
    name: "Bco Sorocred S.A. - Bm",
    description: "Bco Sorocred S.A. - Bm",
    code: "299",
    ispb: null,
    website: "",
    slug: "bco-sorocred-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 190,
    name: "Bco La Nacion Argentina",
    description: "Bco La Nacion Argentina",
    code: "300",
    ispb: null,
    website: "",
    slug: "bco-la-nacion-argentina",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 127,
    name: "Bpp Ip S.A.",
    description: "Bpp Ip S.A.",
    code: "301",
    ispb: null,
    website: "",
    slug: "bpp-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 231,
    name: "Portopar Dtvm Ltda",
    description: "Portopar Dtvm Ltda",
    code: "306",
    ispb: null,
    website: "",
    slug: "portopar-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 52,
    name: "Terra Investimentos Dtvm",
    description: "Terra Investimentos Dtvm",
    code: "307",
    ispb: null,
    website: "",
    slug: "terra-investimentos-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 132,
    name: "Cambionet Cc Ltda",
    description: "Cambionet Cc Ltda",
    code: "309",
    ispb: null,
    website: "",
    slug: "cambionet-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 160,
    name: "Vortx Dtvm Ltda.",
    description: "Vortx Dtvm Ltda.",
    code: "310",
    ispb: null,
    website: "",
    slug: "vortx-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 297,
    name: "Dourada Corretora",
    description: "Dourada Corretora",
    code: "311",
    ispb: null,
    website: "",
    slug: "dourada-corretora",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 86,
    name: "Hscm Scmepp Ltda.",
    description: "Hscm Scmepp Ltda.",
    code: "312",
    ispb: null,
    website: "",
    slug: "hscm-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 142,
    name: "Amazônia Cc Ltda.",
    description: "Amazônia Cc Ltda.",
    code: "313",
    ispb: null,
    website: "",
    slug: "amazonia-cc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 49,
    name: "Pi Dtvm S.A.",
    description: "Pi Dtvm S.A.",
    code: "315",
    ispb: null,
    website: "",
    slug: "pi-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 268,
    name: "Bco Bmg S.A.",
    description: "Bco Bmg S.A.",
    code: "318",
    ispb: null,
    website: "",
    slug: "bco-bmg-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 115,
    name: "Om Dtvm Ltda",
    description: "Om Dtvm Ltda",
    code: "319",
    ispb: null,
    website: "",
    slug: "om-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 81,
    name: "Bco Ccb Brasil S.A.",
    description: "Bco Ccb Brasil S.A.",
    code: "320",
    ispb: null,
    website: "",
    slug: "bco-ccb-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 151,
    name: "Crefaz Scmepp Ltda",
    description: "Crefaz Scmepp Ltda",
    code: "321",
    ispb: null,
    website: "",
    slug: "crefaz-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 25,
    name: "Ccr De Abelardo Luz",
    description: "Ccr De Abelardo Luz",
    code: "322",
    ispb: null,
    website: "",
    slug: "ccr-de-abelardo-luz",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 107,
    name: "Mercado Pago Ip Ltda.",
    description: "Mercado Pago Ip Ltda.",
    code: "323",
    ispb: "10573521",
    website: "",
    slug: "mercado-pago-ip-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 159,
    name: "Cartos Scd S.A.",
    description: "Cartos Scd S.A.",
    code: "324",
    ispb: null,
    website: "",
    slug: "cartos-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 126,
    name: "Órama Dtvm S.A.",
    description: "Órama Dtvm S.A.",
    code: "325",
    ispb: null,
    website: "",
    slug: "orama-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 47,
    name: "Parati - Cfi S.A.",
    description: "Parati - Cfi S.A.",
    code: "326",
    ispb: null,
    website: "",
    slug: "parati-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 76,
    name: "Cecm Fabric Calçados Sapiranga",
    description: "Cecm Fabric Calçados Sapiranga",
    code: "328",
    ispb: null,
    website: "",
    slug: "cecm-fabric-calcados-sapiranga",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 187,
    name: "Qi Scd S.A.",
    description: "Qi Scd S.A.",
    code: "329",
    ispb: null,
    website: "",
    slug: "qi-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 18,
    name: "Banco Bari S.A.",
    description: "Banco Bari S.A.",
    code: "330",
    ispb: null,
    website: "",
    slug: "banco-bari-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 129,
    name: "Fram Capital Dtvm S.A.",
    description: "Fram Capital Dtvm S.A.",
    code: "331",
    ispb: null,
    website: "",
    slug: "fram-capital-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 123,
    name: "Acesso Solucoes Pagamento Sa",
    description: "Acesso Solucoes Pagamento Sa",
    code: "332",
    ispb: null,
    website: "",
    slug: "acesso-solucoes-pagamento-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 167,
    name: "Banco Digio",
    description: "Banco Digio",
    code: "335",
    ispb: null,
    website: "",
    slug: "banco-digio",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 183,
    name: "Bco C6 S.A.",
    description: "Bco C6 S.A.",
    code: "336",
    ispb: "31872495",
    website: "",
    slug: "bco-c6-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 104,
    name: "Super Pagamentos E Administracao De Meios Eletronicos S.A.",
    description: "Super Pagamentos E Administracao De Meios Eletronicos S.A.",
    code: "340",
    ispb: null,
    website: "",
    slug: "super-pagamentos-e-administracao-de-meios-eletronicos-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 4,
    name: "Itaú",
    description: "Itaú Unibanco S.A.",
    code: "341",
    ispb: "60701190",
    website: "http://www.itau.com.br",
    slug: "itau",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 189,
    name: "Creditas Scd",
    description: "Creditas Scd",
    code: "342",
    ispb: null,
    website: "",
    slug: "creditas-scd",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 165,
    name: "Ffa Scmepp Ltda.",
    description: "Ffa Scmepp Ltda.",
    code: "343",
    ispb: null,
    website: "",
    slug: "ffa-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 195,
    name: "Bco Xp S.A.",
    description: "Bco Xp S.A.",
    code: "348",
    ispb: "33264668",
    website: "",
    slug: "bco-xp-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 168,
    name: "Al5 S.A. Cfi",
    description: "Al5 S.A. Cfi",
    code: "349",
    ispb: null,
    website: "",
    slug: "al5-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 27,
    name: "Crehnor Laranjeiras",
    description: "Crehnor Laranjeiras",
    code: "350",
    ispb: null,
    website: "",
    slug: "crehnor-laranjeiras",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 177,
    name: "Toro Ctvm S.A.",
    description: "Toro Ctvm S.A.",
    code: "352",
    ispb: null,
    website: "",
    slug: "toro-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 244,
    name: "Necton Investimentos S.A Cvm",
    description: "Necton Investimentos S.A Cvm",
    code: "354",
    ispb: null,
    website: "",
    slug: "necton-investimentos-sa-cvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 208,
    name: "Ótimo Scd S.A.",
    description: "Ótimo Scd S.A.",
    code: "355",
    ispb: null,
    website: "",
    slug: "otimo-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 101,
    name: "Midway S.A. - Scfi",
    description: "Midway S.A. - Scfi",
    code: "358",
    ispb: null,
    website: "",
    slug: "midway-sa-scfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 69,
    name: "Zema Cfi S/A",
    description: "Zema Cfi S/A",
    code: "359",
    ispb: null,
    website: "",
    slug: "zema-cfi-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 35,
    name: "Trinus Capital Dtvm",
    description: "Trinus Capital Dtvm",
    code: "360",
    ispb: null,
    website: "",
    slug: "trinus-capital-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 24,
    name: "Cielo S.A.",
    description: "Cielo S.A.",
    code: "362",
    ispb: null,
    website: "",
    slug: "cielo-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 282,
    name: "Singulare Ctvm S.A.",
    description: "Singulare Ctvm S.A.",
    code: "363",
    ispb: null,
    website: "",
    slug: "singulare-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 96,
    name: "Gerencianet",
    description: "Gerencianet",
    code: "364",
    ispb: null,
    website: "",
    slug: "gerencianet",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 288,
    name: "Simpaul",
    description: "Simpaul",
    code: "365",
    ispb: null,
    website: "",
    slug: "simpaul",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 271,
    name: "Bco Societe Generale Brasil",
    description: "Bco Societe Generale Brasil",
    code: "366",
    ispb: null,
    website: "",
    slug: "bco-societe-generale-brasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 209,
    name: "Vitreo Dtvm S.A.",
    description: "Vitreo Dtvm S.A.",
    code: "367",
    ispb: null,
    website: "",
    slug: "vitreo-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 92,
    name: "Bco Csf S.A.",
    description: "Bco Csf S.A.",
    code: "368",
    ispb: null,
    website: "",
    slug: "bco-csf-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 266,
    name: "Bco Mizuho S.A.",
    description: "Bco Mizuho S.A.",
    code: "370",
    ispb: null,
    website: "",
    slug: "bco-mizuho-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 310,
    name: "Warren Cvmc Ltda",
    description: "Warren Cvmc Ltda",
    code: "371",
    ispb: null,
    website: "",
    slug: "warren-cvmc-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 211,
    name: "Up.P Sep S.A.",
    description: "Up.P Sep S.A.",
    code: "373",
    ispb: null,
    website: "",
    slug: "upp-sep-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 170,
    name: "Realize Cfi S.A.",
    description: "Realize Cfi S.A.",
    code: "374",
    ispb: null,
    website: "",
    slug: "realize-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 194,
    name: "Bco J.P. Morgan S.A.",
    description: "Bco J.P. Morgan S.A.",
    code: "376",
    ispb: null,
    website: "",
    slug: "bco-jp-morgan-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 150,
    name: "Bms Scd S.A.",
    description: "Bms Scd S.A.",
    code: "377",
    ispb: null,
    website: "",
    slug: "bms-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 32,
    name: "Bco Brasileiro De Crédito S.A.",
    description: "Bco Brasileiro De Crédito S.A.",
    code: "378",
    ispb: null,
    website: "",
    slug: "bco-brasileiro-de-credito-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 29,
    name: "Cecm Cooperforte",
    description: "Cecm Cooperforte",
    code: "379",
    ispb: null,
    website: "",
    slug: "cecm-cooperforte",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 161,
    name: "Picpay",
    description: "Picpay",
    code: "380",
    ispb: null,
    website: "",
    slug: "picpay",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 260,
    name: "Bco Mercedes-Benz S.A.",
    description: "Bco Mercedes-Benz S.A.",
    code: "381",
    ispb: null,
    website: "",
    slug: "bco-mercedesbenz-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 58,
    name: "Fiducia Scmepp Ltda",
    description: "Fiducia Scmepp Ltda",
    code: "382",
    ispb: null,
    website: "",
    slug: "fiducia-scmepp-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 158,
    name: "Ebanx Ip Ltda.",
    description: "Ebanx Ip Ltda.",
    code: "383",
    ispb: null,
    website: "",
    slug: "ebanx-ip-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 112,
    name: "Global Scm Ltda",
    description: "Global Scm Ltda",
    code: "384",
    ispb: null,
    website: "",
    slug: "global-scm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 179,
    name: "Nu Financeira S.A. Cfi",
    description: "Nu Financeira S.A. Cfi",
    code: "386",
    ispb: null,
    website: "",
    slug: "nu-financeira-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 46,
    name: "Bco Toyota Do Brasil S.A.",
    description: "Bco Toyota Do Brasil S.A.",
    code: "387",
    ispb: null,
    website: "",
    slug: "bco-toyota-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 144,
    name: "Bco Mercantil Do Brasil S.A.",
    description: "Bco Mercantil Do Brasil S.A.",
    code: "389",
    ispb: null,
    website: "",
    slug: "bco-mercantil-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 254,
    name: "Bco Gm S.A.",
    description: "Bco Gm S.A.",
    code: "390",
    ispb: null,
    website: "",
    slug: "bco-gm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 90,
    name: "Ccr De Ibiam",
    description: "Ccr De Ibiam",
    code: "391",
    ispb: null,
    website: "",
    slug: "ccr-de-ibiam",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 252,
    name: "Bco Volkswagen S.A",
    description: "Bco Volkswagen S.A",
    code: "393",
    ispb: null,
    website: "",
    slug: "bco-volkswagen-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 78,
    name: "Bco Bradesco Financ. S.A.",
    description: "Bco Bradesco Financ. S.A.",
    code: "394",
    ispb: null,
    website: "",
    slug: "bco-bradesco-financ-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 95,
    name: "F D Gold Dtvm Ltda",
    description: "F D Gold Dtvm Ltda",
    code: "395",
    ispb: null,
    website: "",
    slug: "f-d-gold-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 131,
    name: "Hub Pagamentos",
    description: "Hub Pagamentos",
    code: "396",
    ispb: null,
    website: "",
    slug: "hub-pagamentos",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 206,
    name: "Listo Scd S.A.",
    description: "Listo Scd S.A.",
    code: "397",
    ispb: null,
    website: "",
    slug: "listo-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 182,
    name: "Ideal Ctvm S.A.",
    description: "Ideal Ctvm S.A.",
    code: "398",
    ispb: null,
    website: "",
    slug: "ideal-ctvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 30,
    name: "Kirton Bank",
    description: "Kirton Bank",
    code: "399",
    ispb: null,
    website: "",
    slug: "kirton-bank",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 72,
    name: "Coop Creditag",
    description: "Coop Creditag",
    code: "400",
    ispb: null,
    website: "",
    slug: "coop-creditag",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 135,
    name: "Iugu Ip S.A.",
    description: "Iugu Ip S.A.",
    code: "401",
    ispb: null,
    website: "",
    slug: "iugu-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 214,
    name: "Cobuccio Scd S.A.",
    description: "Cobuccio Scd S.A.",
    code: "402",
    ispb: null,
    website: "",
    slug: "cobuccio-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 220,
    name: "Cora Scd S.A.",
    description: "Cora Scd S.A.",
    code: "403",
    ispb: "37880206",
    website: "",
    slug: "cora-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 215,
    name: "Sumup Scd S.A.",
    description: "Sumup Scd S.A.",
    code: "404",
    ispb: null,
    website: "",
    slug: "sumup-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 219,
    name: "Accredito Scd S.A.",
    description: "Accredito Scd S.A.",
    code: "406",
    ispb: null,
    website: "",
    slug: "accredito-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 14,
    name: "Índigo Investimentos Dtvm Ltda.",
    description: "Índigo Investimentos Dtvm Ltda.",
    code: "407",
    ispb: null,
    website: "",
    slug: "indigo-investimentos-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 213,
    name: "Bonuspago Scd S.A.",
    description: "Bonuspago Scd S.A.",
    code: "408",
    ispb: null,
    website: "",
    slug: "bonuspago-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 74,
    name: "Planner Scm S.A.",
    description: "Planner Scm S.A.",
    code: "410",
    ispb: null,
    website: "",
    slug: "planner-scm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 68,
    name: "Via Certa Financiadora S.A. - Cfi",
    description: "Via Certa Financiadora S.A. - Cfi",
    code: "411",
    ispb: null,
    website: "",
    slug: "via-certa-financiadora-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 137,
    name: "Social Bank S/A",
    description: "Social Bank S/A",
    code: "412",
    ispb: null,
    website: "",
    slug: "social-bank-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 33,
    name: "Bco Bv S.A.",
    description: "Bco Bv S.A.",
    code: "413",
    ispb: null,
    website: "",
    slug: "bco-bv-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 217,
    name: "Work Scd S.A.",
    description: "Work Scd S.A.",
    code: "414",
    ispb: null,
    website: "",
    slug: "work-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 155,
    name: "Lamara Scd S.A.",
    description: "Lamara Scd S.A.",
    code: "416",
    ispb: null,
    website: "",
    slug: "lamara-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 216,
    name: "Zipdin Scd S.A.",
    description: "Zipdin Scd S.A.",
    code: "418",
    ispb: null,
    website: "",
    slug: "zipdin-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 221,
    name: "Numbrs Scd S.A.",
    description: "Numbrs Scd S.A.",
    code: "419",
    ispb: null,
    website: "",
    slug: "numbrs-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 223,
    name: "Cc Lar Credi",
    description: "Cc Lar Credi",
    code: "421",
    ispb: null,
    website: "",
    slug: "cc-lar-credi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 7,
    name: "Safra",
    description: "Safra",
    code: "422",
    ispb: "58160789",
    website: "https://www.safra.com.br",
    slug: "safra",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 16,
    name: "Coluna S.A. Dtvm",
    description: "Coluna S.A. Dtvm",
    code: "423",
    ispb: null,
    website: "",
    slug: "coluna-sa-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 53,
    name: "Socinal S.A. Cfi",
    description: "Socinal S.A. Cfi",
    code: "425",
    ispb: null,
    website: "",
    slug: "socinal-sa-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 113,
    name: "Biorc Financeira - Cfi S.A.",
    description: "Biorc Financeira - Cfi S.A.",
    code: "426",
    ispb: null,
    website: "",
    slug: "biorc-financeira-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 169,
    name: "Cred-Ufes",
    description: "Cred-Ufes",
    code: "427",
    ispb: null,
    website: "",
    slug: "credufes",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 226,
    name: "Cred-System Scd S.A.",
    description: "Cred-System Scd S.A.",
    code: "428",
    ispb: null,
    website: "",
    slug: "credsystem-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 73,
    name: "Crediare Cfi S.A.",
    description: "Crediare Cfi S.A.",
    code: "429",
    ispb: null,
    website: "",
    slug: "crediare-cfi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 11,
    name: "Ccr Seara",
    description: "Ccr Seara",
    code: "430",
    ispb: null,
    website: "",
    slug: "ccr-seara",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 237,
    name: "Br-Capital Dtvm S.A.",
    description: "Br-Capital Dtvm S.A.",
    code: "433",
    ispb: null,
    website: "",
    slug: "brcapital-dtvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 222,
    name: "Delcred Scd S.A.",
    description: "Delcred Scd S.A.",
    code: "435",
    ispb: null,
    website: "",
    slug: "delcred-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 287,
    name: "Planner Trustee Dtvm Ltda",
    description: "Planner Trustee Dtvm Ltda",
    code: "438",
    ispb: null,
    website: "",
    slug: "planner-trustee-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 141,
    name: "Id Ctvm",
    description: "Id Ctvm",
    code: "439",
    ispb: null,
    website: "",
    slug: "id-ctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 303,
    name: "Credibrf Coop",
    description: "Credibrf Coop",
    code: "440",
    ispb: null,
    website: "",
    slug: "credibrf-coop",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 304,
    name: "Magnetis - Dtvm",
    description: "Magnetis - Dtvm",
    code: "442",
    ispb: null,
    website: "",
    slug: "magnetis-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 224,
    name: "Credihome Scd",
    description: "Credihome Scd",
    code: "443",
    ispb: null,
    website: "",
    slug: "credihome-scd",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 233,
    name: "Trinus Scd S.A.",
    description: "Trinus Scd S.A.",
    code: "444",
    ispb: null,
    website: "",
    slug: "trinus-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 210,
    name: "Plantae Cfi",
    description: "Plantae Cfi",
    code: "445",
    ispb: null,
    website: "",
    slug: "plantae-cfi",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 119,
    name: "Mirae Asset Cctvm Ltda",
    description: "Mirae Asset Cctvm Ltda",
    code: "447",
    ispb: null,
    website: "",
    slug: "mirae-asset-cctvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 227,
    name: "Hemera Dtvm Ltda.",
    description: "Hemera Dtvm Ltda.",
    code: "448",
    ispb: null,
    website: "",
    slug: "hemera-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 218,
    name: "Dmcard Scd S.A.",
    description: "Dmcard Scd S.A.",
    code: "449",
    ispb: null,
    website: "",
    slug: "dmcard-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 124,
    name: "Fitbank Pagamentos Eletronicos S.A.",
    description: "Fitbank Pagamentos Eletronicos S.A.",
    code: "450",
    ispb: "13203354",
    website: null,
    slug: "fitbank",
    reference: "all",
    internal: false,
    default: true,
  },
  {
    id: 228,
    name: "Credifit Scd S.A.",
    description: "Credifit Scd S.A.",
    code: "452",
    ispb: null,
    website: "",
    slug: "credifit-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 234,
    name: "Mérito Dtvm Ltda.",
    description: "Mérito Dtvm Ltda.",
    code: "454",
    ispb: null,
    website: "",
    slug: "merito-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 258,
    name: "Bco Mufg Brasil S.A.",
    description: "Bco Mufg Brasil S.A.",
    code: "456",
    ispb: null,
    website: "",
    slug: "bco-mufg-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 225,
    name: "Uy3 Scd S/A",
    description: "Uy3 Scd S/A",
    code: "457",
    ispb: null,
    website: "",
    slug: "uy3-scd-s/a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 80,
    name: "Hedge Investments Dtvm Ltda.",
    description: "Hedge Investments Dtvm Ltda.",
    code: "458",
    ispb: null,
    website: "",
    slug: "hedge-investments-dtvm-ltda",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 60,
    name: "Ccm Serv. Públicos Sp",
    description: "Ccm Serv. Públicos Sp",
    code: "459",
    ispb: null,
    website: "",
    slug: "ccm-serv-publicos-sp",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 156,
    name: "Asaas Ip S.A.",
    description: "Asaas Ip S.A.",
    code: "461",
    ispb: null,
    website: "",
    slug: "asaas-ip-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 229,
    name: "Stark Scd S.A.",
    description: "Stark Scd S.A.",
    code: "462",
    ispb: null,
    website: "",
    slug: "stark-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 232,
    name: "Azumi Dtvm",
    description: "Azumi Dtvm",
    code: "463",
    ispb: null,
    website: "",
    slug: "azumi-dtvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 259,
    name: "Bco Sumitomo Mitsui Brasil S.A.",
    description: "Bco Sumitomo Mitsui Brasil S.A.",
    code: "464",
    ispb: null,
    website: "",
    slug: "bco-sumitomo-mitsui-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 230,
    name: "Capital Consig Scd S.A.",
    description: "Capital Consig Scd S.A.",
    code: "465",
    ispb: null,
    website: "",
    slug: "capital-consig-scd-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 204,
    name: "Master S/A Cctvm",
    description: "Master S/A Cctvm",
    code: "467",
    ispb: null,
    website: "",
    slug: "master-s/a-cctvm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 196,
    name: "Bco Caixa Geral Brasil S.A.",
    description: "Bco Caixa Geral Brasil S.A.",
    code: "473",
    ispb: null,
    website: "",
    slug: "bco-caixa-geral-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 191,
    name: "Citibank N.A.",
    description: "Citibank N.A.",
    code: "477",
    ispb: null,
    website: "",
    slug: "citibank-na",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 257,
    name: "Bco Itaubank S.A.",
    description: "Bco Itaubank S.A.",
    code: "479",
    ispb: null,
    website: "",
    slug: "bco-itaubank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 284,
    name: "Deutsche Bank S.A.Bco Alemao",
    description: "Deutsche Bank S.A.Bco Alemao",
    code: "487",
    ispb: null,
    website: "",
    slug: "deutsche-bank-sabco-alemao",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 240,
    name: "Jpmorgan Chase Bank",
    description: "Jpmorgan Chase Bank",
    code: "488",
    ispb: null,
    website: "",
    slug: "jpmorgan-chase-bank",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 242,
    name: "Ing Bank N.V.",
    description: "Ing Bank N.V.",
    code: "492",
    ispb: null,
    website: "",
    slug: "ing-bank-nv",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 238,
    name: "Bco La Provincia B Aires Bce",
    description: "Bco La Provincia B Aires Bce",
    code: "495",
    ispb: null,
    website: "",
    slug: "bco-la-provincia-b-aires-bce",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 186,
    name: "Bco Credit Suisse S.A.",
    description: "Bco Credit Suisse S.A.",
    code: "505",
    ispb: null,
    website: "",
    slug: "bco-credit-suisse-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 317,
    name: "Neon Pagamentos",
    description: "Neon Pagamentos",
    code: "536",
    ispb: "20855875",
    website: null,
    slug: "neon-pagamentos",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 147,
    name: "Senso Ccvm S.A.",
    description: "Senso Ccvm S.A.",
    code: "545",
    ispb: null,
    website: "",
    slug: "senso-ccvm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 253,
    name: "Bco Luso Brasileiro S.A.",
    description: "Bco Luso Brasileiro S.A.",
    code: "600",
    ispb: null,
    website: "",
    slug: "bco-luso-brasileiro-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 185,
    name: "Bco Industrial Do Brasil S.A.",
    description: "Bco Industrial Do Brasil S.A.",
    code: "604",
    ispb: null,
    website: "",
    slug: "bco-industrial-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 299,
    name: "Bco Vr S.A.",
    description: "Bco Vr S.A.",
    code: "610",
    ispb: null,
    website: "",
    slug: "bco-vr-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 275,
    name: "Bco Paulista S.A.",
    description: "Bco Paulista S.A.",
    code: "611",
    ispb: null,
    website: "",
    slug: "bco-paulista-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 184,
    name: "Bco Guanabara S.A.",
    description: "Bco Guanabara S.A.",
    code: "612",
    ispb: null,
    website: "",
    slug: "bco-guanabara-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 261,
    name: "Omni Banco S.A.",
    description: "Omni Banco S.A.",
    code: "613",
    ispb: null,
    website: "",
    slug: "omni-banco-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 255,
    name: "Banco Pan",
    description: "Banco Pan",
    code: "623",
    ispb: null,
    website: "",
    slug: "banco-pan",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 269,
    name: "Bco C6 Consig",
    description: "Bco C6 Consig",
    code: "626",
    ispb: null,
    website: "",
    slug: "bco-c6-consig",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 250,
    name: "Bco Letsbank S.A.",
    description: "Bco Letsbank S.A.",
    code: "630",
    ispb: null,
    website: "",
    slug: "bco-letsbank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 289,
    name: "Bco Rendimento S.A.",
    description: "Bco Rendimento S.A.",
    code: "633",
    ispb: null,
    website: "",
    slug: "bco-rendimento-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 146,
    name: "Bco Triangulo S.A.",
    description: "Bco Triangulo S.A.",
    code: "634",
    ispb: null,
    website: "",
    slug: "bco-triangulo-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 263,
    name: "Bco Sofisa S.A.",
    description: "Bco Sofisa S.A.",
    code: "637",
    ispb: null,
    website: "",
    slug: "bco-sofisa-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 278,
    name: "Bco Pine S.A.",
    description: "Bco Pine S.A.",
    code: "643",
    ispb: null,
    website: "",
    slug: "bco-pine-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 262,
    name: "Itaú Unibanco Holding S.A.",
    description: "Itaú Unibanco Holding S.A.",
    code: "652",
    ispb: null,
    website: "",
    slug: "itau-unibanco-holding-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 264,
    name: "Banco Voiter",
    description: "Banco Voiter",
    code: "653",
    ispb: null,
    website: "",
    slug: "banco-voiter",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 309,
    name: "Bco Digimais S.A.",
    description: "Bco Digimais S.A.",
    code: "654",
    ispb: null,
    website: "",
    slug: "bco-digimais-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 256,
    name: "Bco Votorantim S.A.",
    description: "Bco Votorantim S.A.",
    code: "655",
    ispb: null,
    website: "",
    slug: "bco-votorantim-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 280,
    name: "Bco Daycoval S.A",
    description: "Bco Daycoval S.A",
    code: "707",
    ispb: null,
    website: "",
    slug: "bco-daycoval-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 300,
    name: "Bco Ourinvest S.A.",
    description: "Bco Ourinvest S.A.",
    code: "712",
    ispb: null,
    website: "",
    slug: "bco-ourinvest-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 301,
    name: "Bco Rnx S.A.",
    description: "Bco Rnx S.A.",
    code: "720",
    ispb: null,
    website: "",
    slug: "bco-rnx-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 19,
    name: "Bco Cetelem S.A.",
    description: "Bco Cetelem S.A.",
    code: "739",
    ispb: null,
    website: "",
    slug: "bco-cetelem-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 17,
    name: "Bco Ribeirao Preto S.A.",
    description: "Bco Ribeirao Preto S.A.",
    code: "741",
    ispb: null,
    website: "",
    slug: "bco-ribeirao-preto-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 20,
    name: "Banco Semear",
    description: "Banco Semear",
    code: "743",
    ispb: null,
    website: "",
    slug: "banco-semear",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 197,
    name: "Bco Citibank S.A.",
    description: "Bco Citibank S.A.",
    code: "745",
    ispb: null,
    website: "",
    slug: "bco-citibank-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 180,
    name: "Bco Modal S.A.",
    description: "Bco Modal S.A.",
    code: "746",
    ispb: null,
    website: "",
    slug: "bco-modal-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 23,
    name: "Bco Rabobank Intl Brasil S.A.",
    description: "Bco Rabobank Intl Brasil S.A.",
    code: "747",
    ispb: null,
    website: "",
    slug: "bco-rabobank-intl-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 26,
    name: "Bco Cooperativo Sicredi S.A.",
    description: "Bco Cooperativo Sicredi S.A.",
    code: "748",
    ispb: null,
    website: "",
    slug: "bco-cooperativo-sicredi-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 176,
    name: "Scotiabank Brasil",
    description: "Scotiabank Brasil",
    code: "751",
    ispb: null,
    website: "",
    slug: "scotiabank-brasil",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 28,
    name: "Bco Bnp Paribas Brasil S A",
    description: "Bco Bnp Paribas Brasil S A",
    code: "752",
    ispb: null,
    website: "",
    slug: "bco-bnp-paribas-brasil-s-a",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 293,
    name: "Novo Bco Continental S.A. - Bm",
    description: "Novo Bco Continental S.A. - Bm",
    code: "753",
    ispb: null,
    website: "",
    slug: "novo-bco-continental-sa-bm",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 296,
    name: "Banco Sistema",
    description: "Banco Sistema",
    code: "754",
    ispb: null,
    website: "",
    slug: "banco-sistema",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 276,
    name: "Bofa Merrill Lynch Bm S.A.",
    description: "Bofa Merrill Lynch Bm S.A.",
    code: "755",
    ispb: null,
    website: "",
    slug: "bofa-merrill-lynch-bm-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 34,
    name: "Banco Sicoob S.A.",
    description: "Banco Sicoob S.A.",
    code: "756",
    ispb: null,
    website: "",
    slug: "banco-sicoob-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 36,
    name: "Bco Keb Hana Do Brasil S.A.",
    description: "Bco Keb Hana Do Brasil S.A.",
    code: "757",
    ispb: null,
    website: "",
    slug: "bco-keb-hana-do-brasil-sa",
    reference: "all",
    internal: null,
    default: false,
  },
  {
    id: 8,
    name: "Prosperità",
    description: "Prosperità",
    code: "999",
    ispb: "11581339",
    website: "localhost",
    slug: "whitelabel",
    reference: "all",
    internal: true,
    default: false,
  },
  {
    id: 315,
    name: "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS",
    description: "COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS",
    code: "ID148",
    ispb: "02405189",
    website: null,
    slug: "cooperativa-de-credito-da-regiao-central-de-minas",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 319,
    name: "CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL",
    description: "CC UNICRED VALOR CAPITAL LTDA - UNICRED VALOR CAPITAL",
    code: "ID419",
    ispb: "",
    website: null,
    slug: "cc-unicred-valor-capital-ltda-unicred-valor-capital",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 316,
    name: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
    description:
      "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
    code: "ID449",
    ispb: "",
    website: null,
    slug: "cooperativa-de-credito-poupanca-e-investimento-do-planalto-central-sicredi-planalto-central",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 314,
    name: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO SI",
    description: "COOPERATIVA DE CRÉDITO; POUPANÇA E INVESTIMENTO SI",
    code: "ID481",
    ispb: "",
    website: null,
    slug: "cooperativa-de-credito-poupanca-e-investimento-si",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 313,
    name: "STARK BANK S.A.",
    description: "STARK BANK S.A.",
    code: "ID644",
    ispb: "20018183",
    website: null,
    slug: "stark-bank",
    reference: "all",
    internal: false,
    default: false,
  },
  {
    id: 318,
    name: "NEON PAGAMENTOS S.A.",
    description: "NEON PAGAMENTOS S.A.",
    code: "IDR03",
    ispb: "",
    website: null,
    slug: "neon-pagamentos-sa",
    reference: "all",
    internal: false,
    default: false,
  },
];
