import Margin from "~/components/Margin";
import { Outlet, useNavigate } from "react-router-dom";
import {
  FunctionComponent,
  useState,
  // useEffect,
} from "react";

import { Breadcrumb, Button, Input, Modal } from "prosperita-dumbo-react";
import activityIcon from "~/assets/Activity.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import NewDebitor from "./NewDebitor";
import useOperation from "~/hooks/useOperation";
import NewInvolved from "./NewInvolved";
import Stepper from "~/components/Stepper";
import NewDocument from "./NewDocument";
import { getSimulateOperationAPI } from "~/services/api/operations";
import useLoading from "~/hooks/useLoading";
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  align-items: start;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const Title = styled.h2`
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 100;
  line-height: 40px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const canViewOperations = true;

const NewOperations: FunctionComponent = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { tab, setRecoverSimulation, recoverSimulation } = useOperation();

  const steps = [
    {
      label: "Operação",
      isCompleted: tab > 0,
      isActive: tab === 0,
    },
    {
      label: "Informações do produto",
      isCompleted: tab > 1,
      isActive: tab === 1,
    },
    { label: "Envolvidos", isCompleted: tab > 2, isActive: tab === 2 },
    { label: "Documentos", isCompleted: tab > 3, isActive: tab === 3 },
  ];

  const handleClose = () => {
    setRecoverSimulation(false);
  };

  const [uuid, setUuid] = useState<string>("");

  const fetchSimulation = async () => {
    setLoading(true);

    try {
      const { data } = await getSimulateOperationAPI(uuid);

      const simulation = data.payload;

      localStorage.setItem(
        "@:simulation_uuid",
        JSON.stringify({ simulation_uuid: data.payload.uuid })
      );

      const payload = {
        nickname: simulation.nickname,
        product_uuid: simulation.product.uuid,
        calculation_type: simulation.calculation_type,
        requested_amount: String(simulation.requested_amount),
        unit_amount: String(simulation.unit_amount),
        duration: simulation.duration,
        calculation_base: simulation.calculation_base,
        release_date: simulation.release_date,
        due_day: simulation.due_day,
        taxes_settings: {
          interest_rate_monthly:
            simulation.taxes_settings.interest_rate_monthly,
          interest_periodicity: simulation.taxes_settings.interest_periodicity,
          amortization_grace_period:
            simulation.taxes_settings.amortization_grace_period,
          interest_grace_period:
            simulation.taxes_settings.interest_grace_period,
          commission: simulation.taxes_settings.commission,
          indexer: simulation.taxes_settings.indexer,
          indexer_rate: simulation.taxes_settings.indexer_rate,
        },
      };

      localStorage.setItem("@:simulate_operation", JSON.stringify(payload));

      location.reload();

      setRecoverSimulation(false);

      // setTimeout(() => {
      //   toast.success("Simulação recuperada com sucesso !");
      // }, 3000);

      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Não foi possível recuperar a simulação !");
      setLoading(false);
    }
  };

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          { crumb: "Início" },
          { crumb: "Crédito" },
          {
            crumb: "Operações",
            onClick: () => navigate("/app/credit/operations"),
          },
          {
            crumb: "Nova operação (Nota Comercial)",
          },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div style={{ width: "100%" }}>
        <>
          {canViewOperations && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px ",
                }}
              >
                <Title>Nova operação - Nota Comercial</Title>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div style={{ width: "250px" }}>
                    <Button
                      kind="secondary"
                      size="large"
                      label="Fazer Simulação"
                      icon={activityIcon}
                      onClick={() => navigate("/app/credit/new-simulate")}
                    />
                  </div>
                  <div style={{ width: "250px" }}>
                    <Button
                      kind="primary"
                      size="large"
                      label="Recuperar simulação"
                      onClick={() => setRecoverSimulation(true)}
                    />
                  </div>
                  {/* <div style={{ width: "250px" }}>
                    <Button
                      kind="secondary"
                      size="large"
                      label="Recuperar simulação"
                    />
                  </div> */}
                </div>
              </div>
              <Stepper steps={steps} />
              <Margin mt={50} />
              <Outlet />
            </div>
          )}
        </>

        {!canViewOperations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para criar operações de crédito, <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>

      <NewDebitor />
      <div style={{ zIndex: 9999 }}>
        <NewInvolved />
      </div>

      <div style={{ zIndex: 9999 }}>
        <NewDocument />
      </div>
      <div style={{ zIndex: 999 }}>
        <Modal
          radius
          height={"368px"}
          width="760px"
          background="#f4f4f4"
          open={recoverSimulation}
          onClose={() => handleClose()}
          label={<h4>Recuperar simulação</h4>}
          buttons={[
            {
              kind: "secondary",
              label: "Cancelar",
              onClick: () => handleClose(),
            },
            {
              kind: "primary",
              label: "Recuperar simulação",
              onClick: () => fetchSimulation(),
            },
          ]}
        >
          <div style={{ padding: "20px" }}>
            <Input
              label="Insira um ID para recuperar a simulação"
              size="large"
              type="text"
              placeholder="ID*"
              value={uuid}
              onChange={(e) => setUuid(e.target.value)}
            />
          </div>
        </Modal>
      </div>
    </Container>
  );
};

export default NewOperations;
