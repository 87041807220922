import ApiService from "../api";

type Address = {
  cep: string;
  complemento: string;
  unidade: string;
  logradouro: string;
  erro?: string;
  bairro: string;
  localidade: string;
  uf: string;
  estado: string;
  regiao: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

export const getCepAPI = (cep: string) => {
  return ApiService.HttpGet<Address>({
    route: `https://viacep.com.br/ws/${cep}/json/`,
    token: true,
    external: true,
  });
};
