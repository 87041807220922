import { Modal } from "prosperita-dumbo-react";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useLoading from "~/hooks/useLoading";
import useOperation from "~/hooks/useOperation";
import {
  deleteOperationDocumentAPI,
  getOperationDetailAPI,
} from "~/services/api/operations";
import {
  deleteSubscriberDocumentAPI,
  getSubscriberAPI,
} from "~/services/api/subscriber";

const DeleteDocument = () => {
  const {
    setDeleteDocumentData,
    deleteDocumentData,
    setSubscriber,
    setOperationDetails,
  } = useOperation();
  const { setLoading } = useLoading();
  const { id, uuid } = useParams();

  const handleClose = useCallback(() => {
    setDeleteDocumentData(null);
  }, [setDeleteDocumentData]);

  const fetchRemoveInvolved = useCallback(async () => {
    setLoading(true);

    try {
      if (id) {
        await deleteSubscriberDocumentAPI(String(deleteDocumentData?.uuid));
        setDeleteDocumentData(null);
        toast.success("Documento removido com sucesso!");
        setLoading(false);

        const { data } = await getSubscriberAPI(String(id));

        setSubscriber(data.payload);
        setLoading(false);
      } else {
        await deleteOperationDocumentAPI(String(deleteDocumentData?.uuid));
        setDeleteDocumentData(null);
        toast.success("Documento  removido com sucesso!");

        const { data } = await getOperationDetailAPI(String(uuid));
        setOperationDetails(data.payload);

        setLoading(false);

        console.log();
      }

      setLoading(false);
    } catch (e) {
      console.log(e);

      toast.error(
        "Não foi possível remover o Documento! Tente novamente mais tarde"
      );
    }
  }, [
    setLoading,
    setDeleteDocumentData,
    id,
    setOperationDetails,
    setSubscriber,
    uuid,
    deleteDocumentData,
  ]);

  return (
    <Modal
      height={"468px"}
      width="960px"
      background="#f4f4f4"
      label={<h4>Remover envolvido</h4>}
      radius
      onClose={() => {
        handleClose();
      }}
      open={deleteDocumentData !== null}
      buttons={[
        { kind: "ghost", label: "Cancelar", onClick: () => handleClose() },
        {
          kind: "primary",
          label: "Sim, remover",
          onClick: () => fetchRemoveInvolved(),
        },
      ]}
    >
      <div style={{ padding: "50px" }}>
        <h3>Deseja realmente remover o documento ?</h3>
      </div>
    </Modal>
  );
};
export default DeleteDocument;
