import { Button, FileUploader, InputSelect } from "prosperita-dumbo-react";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import LateralModal from "~/components/LateralModal";
import useOperation from "~/hooks/useOperation";
import CloseIcon from "~/assets/Close--outline.png";
import SaveIcon from "~/assets/Save.png";

import { zIndex } from "~/services/utils";
import { useFormik } from "formik";

import { DocumentData } from "~/types";

import { IPartner } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
const Container = styled.div`
  width: 100%;
  padding: 30px;
  display: grid;
  height: auto;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  z-index: 2;
`;

const Footer = styled.div`
  width: 90%;
  position: fixed;
  bottom: 0;
  display: grid;
  padding-bottom: 50px;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
`;

type Values = {
  related_to_uuid: string;
  type: string;
};

const initialValues: Values = {
  related_to_uuid: "",
  type: "",
};

const NewDocument = () => {
  const { addDocument, setAddDocument, setDocumentsRender } = useOperation();
  const { partners } = usePartner();
  const [binaryContent, setBinaryContent] = useState<string | null>(null);

  const { setFieldValue, values, resetForm } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleClose = () => {
    setAddDocument(false);
    resetForm();
  };

  const bodyRef = useRef(document.body);

  const handleAddDocumentInStorage = async () => {
    const documentData: DocumentData[] = JSON.parse(
      localStorage.getItem("@:documents") || "[]"
    );

    const newDocument: DocumentData = {
      related_to_name: values.related_to_uuid.split("/*?")[0],
      related_to_uuid: values.related_to_uuid.split("/*?")[1],
      type_name: values.type.split("/*?")[0],
      type: values.type.split("/*?")[1],
      description: values.type.split("/*?")[1],
      file: binaryContent,
    };

    documentData.push(newDocument);
    localStorage.setItem("@:documents", JSON.stringify(documentData));

    setFieldValue("type", "");
    setFieldValue("related_to_uuid", "");
    setBinaryContent(null);

    handleClose();
    getDocumentsFromStorage();
  };
  const areValuesEmptyOrFalse = (values) => {
    if (!values.type || !values.related_to_uuid || binaryContent === null) {
      return true;
    }

    return false;
  };

  const listDocuments = useMemo(() => {
    return [
      { label: "Documento", value: "document" },
      { label: "Autorização", value: "authorization" },
      { label: "Carteira de Motorista", value: "drivers_license" },
      { label: "Carteira de Trabalho", value: "work_card" },
      {
        label: "Comprovante de Residência da Empresa",
        value: "residence_proof_company",
      },
      {
        label: "Comprovante de Residência dos Sócios",
        value: "residence_proof_partners",
      },
      { label: "Rascunho", value: "draft" },
      { label: "Contrato Social", value: "social_contract" },
      { label: "Número de Registro", value: "registration_number" },
      {
        label: "Documento de Identificação com Foto",
        value: "identification_document_with_photo",
      },
      { label: "Extrato Bancário", value: "bank_statement" },
      { label: "Faturamento Gerencial", value: "managerial_billing" },
      { label: "Formulário de Cadastro", value: "registration_form" },
      { label: "Outros", value: "others" },
      { label: "Selfie", value: "selfie" },
      {
        label: "Selfie com Documento de Identidade",
        value: "selfie_with_identity_card",
      },
      { label: "Última Declaração", value: "last_declaration" },
      { label: "Contrato Assinado", value: "signed_contract" },
      {
        label: "Contrato com Selo de Assinatura",
        value: "signing_stamp_contract",
      },
      { label: "Relatório de Crédito", value: "credit_report" },
      { label: "Cessão", value: "assignment" },
      { label: "Adição", value: "addition" },
      { label: "Cessão Fiduciária", value: "fiduciary_assignment" },
      { label: "P7S", value: "p7s" },
      { label: "Arquivos Compactados", value: "ziped_files" },
      { label: "CNAB", value: "cnab" },
      { label: "Nota Fiscal", value: "invoice" },
      { label: "Ata de Eleição", value: "minutes_of_election" },
      { label: "Procuração", value: "procuration" },
      { label: "Termo de Posse", value: "term_of_ownership" },
      { label: "Estatuto Social", value: "bylaws" },
      { label: "Carteira de Identidade", value: "identity_card" },
      { label: "Garantia", value: "collateral" },
      { label: "Regulamento", value: "regulation" },
      { label: "Saldo", value: "balance" },
      { label: "Balanço Patrimonial", value: "balance_sheet" },
      { label: "PLDFT", value: "pldft" },
      { label: "Imposto de Renda", value: "income_tax" },
      { label: "DRE", value: "dre" },
      { label: "Recibo", value: "receipt" },
      { label: "Cartão da Empresa", value: "company_card" },
      { label: "Relatório de Cessão", value: "assignment_report" },
      {
        label: "Registro Nacional de Estrangeiros",
        value: "national_registry_of_foreigners",
      },
      {
        label: "Documento Nacional de Identidade",
        value: "national_identity_card",
      },
      {
        label: "Carteira de Registro Regional",
        value: "regional_registration_card",
      },
      { label: "Passaporte", value: "passport" },
      { label: "Contra-cheque", value: "paycheck" },
    ];
  }, []);

  const documentsOptions = useMemo(() => {
    return listDocuments
      .map((document) => ({
        value: `${document.label}/*?${document.value}`,
        label: document.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listDocuments]);

  const partnersOptions = useMemo(() => {
    return partners
      .map((partner: IPartner) => ({
        value: `${partner.name}/*?${partner.uuid}`,
        label: partner.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partners]);

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  const getDocumentsFromStorage = () => {
    const data = localStorage.getItem("@:documents");
    if (data) {
      setDocumentsRender(JSON.parse(data));
    }
  };

  useEffect(() => {
    if (addDocument) {
      const body = bodyRef.current;
      body.style.overflow = "hidden";
    } else {
      const body = bodyRef.current;
      body.style.overflow = "";
    }
    return () => {
      const body = bodyRef.current;
      body.style.overflow = "";
    };
  }, [addDocument]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target?.result as string;
        setBinaryContent(content);
        console.log("Arquivo convertido para Base64:", content);
      };
      reader.onerror = () => {
        console.error("Erro ao ler o arquivo.");
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Nenhum arquivo selecionado.");
    }
  };

  return (
    <LateralModal
      open={addDocument}
      title="Enviar documentos"
      header
      toggleModal={() => handleClose()}
    >
      <Container>
        <div style={{ gridColumn: "span 6", zIndex: zIndex }}>
          <InputSelect
            name="related_to_uuid"
            id="related_to_uuid"
            kind="default"
            size="large"
            label="Relacionado à"
            onChange={(e) => handleSelect("related_to_uuid", e)}
            options={partnersOptions}
            placeholder="Selecione uma opção"
          />
        </div>

        <div
          style={{
            gridColumn: "span 6",
            zIndex: zIndex,
            marginBottom: "30px",
          }}
        >
          <InputSelect
            id="type"
            name="type"
            kind="default"
            size="large"
            label="Tipo de documento"
            placeholder="Selecione uma opção"
            onChange={(e) => handleSelect("type", e)}
            options={documentsOptions}
          />
        </div>

        <div style={{ gridColumn: "span 12" }}>
          <FileUploader
            maxlenghtindividual={1}
            size="large"
            accept=".png, .pdf, .jpeg, .jpg"
            onChange={handleFileChange}
            type="drag and top"
            headers={{
              label: "Envio de documentos",
              TextLabel:
                "O tamanho máximo do arquivo é 500kb. Os tipos de arquivo suportados são .jpg, .png. e .pdf",
            }}
          >
            <div
              style={{
                paddingTop: "10px",
              }}
            >
              Clique aqui para fazer o carregamento dos arquivos
            </div>
          </FileUploader>
        </div>
        <div style={{ height: "200px" }}></div>
        <Footer>
          {" "}
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="ghost"
              size="large"
              onClick={() => handleClose()}
              label="Cancelar"
              icon={CloseIcon}
            />
          </div>
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="primary"
              size="large"
              icon={SaveIcon}
              onClick={() => handleAddDocumentInStorage()}
              label="Adicionar documento"
              disabled={areValuesEmptyOrFalse(values)}
            />
          </div>
        </Footer>

        {/* <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
           
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
          
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer> */}
      </Container>
    </LateralModal>
  );
};

export default NewDocument;
